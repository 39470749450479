import React from 'react'
import '../../css/Utils/Sort.css'
import {Dropdown, MenuItem} from 'react-bootstrap'

function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}


function getName(value){
  if(value === "dont"){
    return "Don't Share"
  }else{
    return capitalize(value)
  }
}

const Share = ({value, updateShare}) => {

  return (
    <div className="sort-box">
      <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" >
        <Dropdown.Toggle noCaret >
          <div className="sort-text">
            <span>{getName(value)}</span>
            <span className="my-small-caret"/>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {value === "dont" ? null : <MenuItem eventKey="1" active={value === "dont" || value === null} onSelect={() => updateShare("dont")}>{"Don't Share"}</MenuItem> }
          {value === "share" ? null : <MenuItem eventKey="2" active={value === "share"} onSelect={() => updateShare("share")}>Share</MenuItem>}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

}

export default Share
