import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom';
import { ShoppingCart, Search, Settings2, UserRound, LayoutDashboard, CalendarCheck} from 'lucide-react'
import CartPage from '../CartPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as cartActions from '../../actions/'

const HeartIcon = ({ className = '', size }) => (
    <svg 
    width={size}
    height={size} 
    viewBox="6 5 22 22" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="currentColor">
      <path d="M15.9999998,24.5993291 C13.9098794,24.5993291 6.00038092,19.5598812 6.00038092,12.7537007 C5.96446137,9.61758175 8.4733903,7.04393963 11.6094071,7 C13.3311478,7.00718505 14.9524784,7.81140305 15.9999998,9.17783698 C17.0475601,7.81134278 18.6689799,7.00711715 20.3907924,7 C23.5267401,7.04402692 26.0355609,9.61764988 25.9996186,12.7537007 C25.9996186,19.5598812 18.0902801,24.5993291 15.9999998,24.5993291 Z M20.3907924,8.59993901 C17.7978912,8.59993901 16.9182847,10.9998475 15.9999998,10.9998475 C15.0817148,10.9998475 14.2021083,8.59993901 11.6092471,8.59993901 C9.35657749,8.64313577 7.56357428,10.5009166 7.60031993,12.7537007 C7.60031993,18.7937504 14.9533996,22.9869106 15.9999998,22.9993901 C17.0466399,22.9869106 24.3996796,18.7937504 24.3996796,12.7537007 C24.4364038,10.5010087 22.6435275,8.64328455 20.3909524,8.59997901 L20.3907924,8.59993901 Z" id="Shape"></path>
      </g>
    </g>
  </svg>
);

const HoverIcon = ({icon: Icon, 
    size = 24, 
    defaultColor = 'black',
    hoverColor = '#9EDD22',
    selected,
    isHovered,
    getCartCount,
  }) => {
  
    return (
      <div className="cursor-pointer transition-colors duration-200 relative">
        <Icon 
          size={size}
          className={"mb-1 " + (selected ?  "text-alt-dark-highlight" : "text-strong-content-color") + " group-hover:text-alt-dark-highlight"}
        />
        {getCartCount &&            
             (<span className={"absolute -top-2 -right-2 bg-highlight-color text-white text-xxs rounded-full h-4 w-4 flex items-center justify-center " + (getCartCount() === 0 ? "hidden" : '')} >
                {getCartCount()}
              </span>)}
      </div>
    );
};

function getIcon(name, selected, isHovered, getCartCount) {
    switch(name) {
        case 'wishlists':
            return <HoverIcon icon={HeartIcon} selected={"wishlists" === selected} isHovered={isHovered}/>
            //<img src={HeartButton} className='mt-[-2px]' />
        case 'Your Events':
            return <HoverIcon icon={CalendarCheck} selected={"Your Events" === selected} isHovered={isHovered}/>
        case 'cart':
            return <HoverIcon icon={ShoppingCart} getCartCount={getCartCount} selected={"cart" === selected} isHovered={isHovered}/>
        case 'admin':
            return <HoverIcon icon={Settings2} selected={"admin" === selected} isHovered={isHovered}/>
        case 'vendor':
            return <HoverIcon icon={LayoutDashboard} selected={"vendor" === selected} isHovered={isHovered}/>
        case 'account':
            return <HoverIcon icon={UserRound} selected={"account" === selected} isHovered={isHovered}/>
        case 'Sign In':
            return <HoverIcon icon={UserRound} selected={"sign in" === selected} isHovered={isHovered}/>
        default:
            return <HoverIcon icon={Search} selected={"browse" === selected} isHovered={isHovered}/>
    }
}

const Tab = ({name, selectedTab, getCartCount}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className={`group min-w-[72px] py-3 px-2 flex flex-col justify-center items-center transition-colors relative ${
            selectedTab === name
                ? 'text-alt-dark-highlight font-medium'
                : 'text-strong-content-color hover:text-alt-dark-highlight'
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            {getIcon(name, selectedTab, isHovered, getCartCount)}
            <div className='text-xxs text-center'>{name.capitalize()}</div>
            {selectedTab === name && (
            <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-dark-highlight" />
            )}
        </div>
    )
}

const BottomTabBar = ({login, selected, app, cart, actions, location}) => {
    // const [showCart, setShowCart] = useState(false)

    // useEffect(() => {
    //     setShowCart(false)
    // }, [selected])

    const getCartCount = () => {
        var cart_count = 0;
        for (var key in cart){
            cart_count += cart[key].length
        }
        return cart_count
    }

    return (
        <div className="shadow-[0_-1px_6px_-1px_rgba(0,0,0,0.1)] sm:hidden sticky w-full bottom-0 bg-white z-40">
        <div className='absolute' /> {/* empty element to prevent the bottom tab bar from covering the content */}
          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex min-w-max">
                <Link className="flex-1" to="/events"><Tab name="browse" selectedTab={selected}/></Link>
                {login.loggedIn ? <Link  className="flex-1" to="/wishlists"><Tab name="wishlists" selectedTab={selected}/></Link> : null}
                {login.loggedIn ? <Link className="flex-1" to="/your-events"><Tab name="Your Events" selectedTab={selected}/></Link> : null}
                {login.vendor_id ? <Link className="flex-1" to="/vendors/dashboard"><Tab name="vendor" selectedTab={selected}/></Link>: null}
                {login.admin ? <Link className="flex-1" to="/admin_panel"><Tab name="admin" selectedTab={selected}/></Link>: null}
                {login.loggedIn ? <Link className="flex-1" to="/account"><Tab name="account" selectedTab={selected}/></Link> :
                <Link className="flex-1" to={{pathname: '/signin', state: {from: location.pathname}}}><Tab name="Sign In" selectedTab={selected}/></Link>}
                <Link className="flex-1" to="/cart"><Tab name="cart" getCartCount={() => getCartCount()} selectedTab={selected}/></Link>
                {/* <div className="flex-1" onClick={() => actions.openCart()} ><Tab name="cart" getCartCount={() => getCartCount()} selectedTab={selected}/></div> */}
            </div>
          </div>
          <CartPage width={"100%"} show={app.cart} onHide={() => actions.closeCart()} />
      </div>
        


    )
}

const mapStateToProps = state => ({
    ...state
  })
  
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
mapStateToProps,
mapDispatchToProps
)(BottomTabBar))

