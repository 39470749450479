import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import request from '../../request'
import '../UserDash/Overview.css'
import _ from 'underscore'
import { UpcomingSummary }  from '../UserDash/Summaries'
import Static from '../StaticPages/StaticWrapper'
import moment from 'moment-timezone'

class Overview extends Component{

  constructor(props){
    super(props)
    this.state = {
      upcomingEvents: [],
      search: ""
    }
  }


  async componentDidMount(){
    var res = await request('/api/admin/events/past')
    if (res.err){
      console.log(res.err)
      return
    }else{
      var upcomingEvents = _(res).sortBy( e => e.start_time).reverse()
      console.log("upcoming", upcomingEvents)
      this.setState({upcomingEvents})
    }
  }

  search(events){
    if(!this.state.search) return events
    var filt = []
    for(var e of events){
      var names  = e.components.map(c => c.name)
      if(e.package) names.push(e.package)
      names.push(e.company_name)
      names.push(e.email)
      names.push(moment(e.start_time).tz(e.timezone).format("dddd, MMMM, D, YYYY"))
      names = names.join(" ")
      if(names.includes(this.state.search)) filt.push(e)
    }
    return filt
  }

  render(){
    if(!this.state.upcomingEvents) return null

    var events = this.search(this.state.upcomingEvents)

    return (
      <Static className="wide">
        <div className="static-page-header">Past Events</div>
        <div className="static-page-subheader">Last two weeks of events.</div>
        <div className="section-wrapper">
          <div className="dashboard">
            <input type="text" className="text-field" placeholder="Search name/email/service/date" value={this.state.search} style={{width: 400, marginBottom: 20}} onChange={(e) => this.setState({search: e.target.value})}/>
            <div className="event-list">
            {events.map(e =>
              <UpcomingSummary admin={true} e={e} components={e.components} pack={e.packages ? e.packages[0] : null} venue={e.venue} />
            )}
            </div>
          </div>
        </div>
      </Static>
    )
  }
}

export default withRouter(Overview)
