import React from 'react'
import '../../css/Utils/Sort.css'
import {Dropdown, MenuItem} from 'react-bootstrap'

const Selector = ({value, name, update, options, placeholder, pretext, className, disabled, style}) => {
  let found = (options.find(o => o.value === value))
  let n =  name || (value ? (value === "check" ? "Check" : (found ? found.name : null)) : null)
  return (
    <div className={"sort-box " + (className || "") } style={style}>
      <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" disabled={disabled} >
        <Dropdown.Toggle noCaret >
          <div className="sort-text">
            {value ? <span>{pretext}{n}</span> : <span>{placeholder || "Choose One"}</span>}
            <span className="my-small-caret"/>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {options.map((opt, i) =>
            <MenuItem key={i} eventKey={i} active={value ? value === opt.value: false} onSelect={() => update(opt.value)}>{opt.name}</MenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

}

export default Selector
