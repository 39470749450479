import React from 'react'
import {Table} from 'react-bootstrap'
import request from '../../request'


export default class ComponentSearch extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      search: "",
      search_id: "",
      allComponents: []
    }
  }

  componentDidMount(){
    this.getComponents()
  }

  async getComponents(){
    var allComponents = await request('/api/admin/getComponents/', {method: "POST"})
    this.setState({allComponents})
  }

  render(){
    return (
      <div className="card" style={{width: 300, marginLeft: 20, marginTop: 10, padding: 10, position: "sticky", top: 100, display: "inline-block", verticalAlign: "top"}}>
        <input type="text" placeholder="Search by name" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
        <input type="number" placeholder="Search by id" value={this.state.search_id} onChange={(e) => this.setState({search_id: e.target.value})}/>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Locations</th>
            </tr>
          </thead>
          <tbody>
            {this.state.allComponents.map((e, i) =>
              ( (this.state.search && e.name.toLowerCase().includes(this.state.search.toLowerCase())) || ( this.state.search_id && Number(e.id) === Number(this.state.search_id) )?
                <tr key={i}>
                  <td>{e.id}</td>
                  <td>{e.name}</td>
                  <td>{e.locations.join(", ")}</td>
                </tr> : null)
              )}
          </tbody>
        </Table>
      </div>
    )
  }

}
