import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import request from '../../request'
import {Dropdown, MenuItem} from 'react-bootstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import VendorRequest from './Request'
import moment from 'moment-timezone'
import Chevron from '../../img/Chevron.svg'
import EventSummary from './EventSummary'
import pluralize from 'pluralize'
import './EventView.css'
import ConfirmationModal from '../Utils/ConfirmationModal'
import { getServicePrice, getEarliestStart, getLatestEnd } from '../Utils/math'
import ServicePage from '../Services/ServicePage'

const detailText = (component) => {

  var theme =  component.option_values ? component.option_values["Theme"] : null
  if(theme  && theme.toLowerCase() === "custom" ) theme = component.option_values["customTheme"]

  var qty = ""
  var showQty = true

  var text = ""
  if(theme){
    text = theme
  }
  if(component.num_staff > 1){
    text += (theme  ? " for ": "For ")
    text += component.num_staff + " " + pluralize(component.name)
  }
  if(showQty){
    text += (theme || component.num_staff > 1 ? " for ": "For ")
    var q = component.qty
    switch (component.unit){
      case "hour":
        text += q + (q === 1 ? " hour" : " hours")
        break;
      case "kit":
        text += q + " " + pluralize(component.unit_name || "kit")
        break
      default:
        text += q + " people"
    }
  }
  return text
}

function getOptions(type, option_values){
  var str = ""
  if(type.type === "multiple"){
    if(option_values[type.name]) str += option_values[type.name].join(", ")
    if(option_values["custom" + type.name]){
      if(option_values[type.name].length > 0) str += ", "
      str += option_values["custom" + type.name]
    }
  }else{
    if(option_values[type.name] === "Custom"){
      str += option_values["custom" + type.name]
    }else{
      str += option_values[type.name]
    }
  }
  return str
}

function optionsDescription(option_values, options){
  if(!options || !option_values) return null
  return (
    <div>
      { options.types.map( (type, i) => {
        if(type.name !== "Theme"){
          return (
            <div className="notes" key={i}>{type.name}: {getOptions(type, option_values)}</div>
          )
        }else{
          return null
        }
      })
    }
    </div>
  )

}



const ServiceSummary = ({data, cancelService, booking, start_time, timezone, earliest_start, onClick}) => {
  var image = ""
  if(data.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + data.images[0]
  //            <div className="powerup-price">{currencyFormatter.format(data.cost,  { code: 'USD', precision: 0})} </div>
  var paid = Number(data.deposit_amount + data.remainder_amount)/100
  console.log(paid, data)
  return (
    <div>
      <div style={{position: "relative"}}>
        <div className="powerup" >
          <div className="powerup-image-container" onClick={onClick}>
            <img className="powerup-image" src={image} alt={data.name}/>
          </div>
          <div className="powerup-wrapper">
            <div className={"powerup-details qty"}>
              <div className="powerup-name"  onClick={onClick}> {data.name} </div>
              <div className="start-time">
                Starts at: {moment(data.start_time).tz(timezone).format("h:mma")}
              </div>
            </div>
            <div className={"quantity qty"}>
              {detailText(data)}
            </div>
            <div className="hovered">
              <span className="remove" onClick={() => cancelService(data.event_component_id)}>Cancel</span>
            </div>
          </div>
        </div>

        <div className="earn">Earn {currencyFormatter.format(getServicePrice(data, booking, start_time, timezone), { code: 'USD', precision: 0} )}</div>
        {paid ? <div className="paid">Paid {currencyFormatter.format(paid, { code: 'USD', precision: 2} )}</div> : null }

      </div>
      {data.unit !== "hour"? <div className="notes">Duration: {data.duration || booking.duration} hours</div> : null  }
      {optionsDescription(data.option_values, data.options)}
      <div className="notes">Notes: {data.notes || "none"}</div>

    </div>
  )
}

class EventView extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      credit: 0,
      referral_count: 0,
      event: null, //{start_time: moment(), end_time: moment().add(3, "hours"), kits: 2, cost: 95, name: "Goat Yoga", address: "698 De Haro Street, San Francisco", setup_time: 0.5, time_remaining: "3h 2m", num_people: 40 },
      // services: [{id: 4, qty: 40, notes: "Me I want a hoola hoop" ,start_time: moment(), end_time: moment().add(3, "hours"), kits: 2, cost: 95, name: "Goat Yoga",  location: "San Francisco",   time_remaining: "3h 2m", num_people: 40 }, {id: 10, start_time: moment(), end_time: moment().add(3, "hours"), kits: 2, cost: 95, name: "Goat Yoga", location: "San Francisco", time_remaining: "3h 2m", num_people: 40 }],
      ratings: [],
      new_name: "",
      new_email: "",
      new_phone: "",
      change_contact_info: "",
      cancel: null,
      viewService: false
    }
  }

  async componentDidMount(){
    this.getEvent()
  }

  getTotal(services, booking, start_time, timezone){
    var total = 0
    for(var s of services){
      total += getServicePrice(s, booking, start_time, timezone)
    }
    return total
  }

  async getEvent(){
    var event = await request('/api/vendor/event/' + this.props.id )
    if(event.err){
      if(event.err === "no_longer_servicing"){
        this.props.history.push("/vendors/overview")
      }
      console.log(event)
      return
    }
    this.setState({event})
  }

  async saveContactInfo(){
    console.log(JSON.stringify({name: this.state.new_name, email: this.state.new_email, phone: this.state.new_phone}))
    var event = await request('/api/vendor/contact/' + this.props.id, {method: "PUT", body: JSON.stringify({name: this.state.new_name, email: this.state.new_email, phone: this.state.new_phone})} )
    if(event.err){
      console.log(event)
      return
    }
    this.setState({change_contact_info: false})
  }

  async cancel(ec_id){
    var event = await request('/api/vendor/event/' + ec_id, {method: "DELETE"})
    if(event.err){
      if(event.err === "no_longer_servicing"){
        this.props.history.push("/vendors/overview")
      }
      console.log(event)
    }else{
      this.getEvent()
    }
  }

  render(){
    if(!this.state.event) return null
    console.log("event", this.state.event)
    let timezone =  this.state.event.services.every(s => s.remote) ? this.props.vendor_timezone : this.state.event.timezone
    console.log("timezone", this.state.event.services.every(s => s.remote), this.props.vendor_timezone, this.state.event.timezone, timezone, this.state.event.services)
    var booking = {duration: (this.state.event.hours_needed), people: this.state.event.num_people, start_time: moment(this.state.event.start_time).tz(timezone)}
    var setup_times = this.state.event.services.map(s => s.setup_time || 0 )
    console.log("thing", setup_times)
    var setup_time = Math.max(...setup_times)
    let earliest_start = getEarliestStart(this.state.event.services)
    var latest_end = getLatestEnd(this.state.event.services)
    let arrive_by = moment(earliest_start).tz(timezone).subtract(setup_time, "hours").format("h:mm a")
    var total = this.getTotal(this.state.event.services, booking, this.state.event.start_time, timezone)
    return (
      <div className="event-view">
        <div className="checkout-details">
          <div className="section-head">Event Info</div>
          <div className="card section" style={{overflow:"hidden"}}>
            <div className="booking">
              <div className="subheader">Event information:</div>
              {this.state.event.contact_company ? <div className="text"><span ><b>Company:</b> {this.state.event.contact_company}</span></div> : null }
              <div className="text date"><span ><b>{booking.start_time.format("ddd, MMMM Do")}</b></span></div>
              <div className="text date"><span ><b>{moment(earliest_start).tz(timezone).format('h:mm')}-{moment(latest_end).tz(timezone).format('h:mma z')}</b></span></div>
              <div className="text location"><span><b>Location:</b> {this.state.event.location || "To be determined"}</span></div>
              <div className="text"><span>Arrive by {arrive_by}</span></div>
            </div>
            <div className="contact">
              <div className="subheader">Your point of contact for this event:</div>
              {moment(booking.start_time).diff(moment(), "days") > 4 ?
              <div>
                <div>1up Events</div>
                <div>info@1upevents.com</div>
                <div>(415) 915-5184</div>
              </div>
              :
              <div>
                <div>{this.state.event.contact_name}</div>
                <div>{this.state.event.contact_email}</div>
                {this.state.event.contact_phone ? <div>{this.state.event.contact_phone}</div> : null}
              </div>
              }

            </div>
            <div className="contact poc">
              <div className="subheader">Your contact info for this event:</div>
              {this.state.change_contact_info ?
                <div>
                  <input className="text-field" type="text" value={this.state.new_name} onChange={(e) => this.setState({new_name: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Contact Name" />
                  <input className="text-field" type="email" value={this.state.new_email} onChange={(e) => this.setState({new_email: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Email Address"/>
                  <input className="text-field" type="tel" value={this.state.new_phone} onChange={(e) => this.setState({new_phone: e.target.value})} placeholder="E.g. (650) 888-5252" style={{width: 240, marginTop: 10}} />
                  <div onClick={()=> this.saveContactInfo()} className="change">Save Changes</div>
                </div>
                :
                <div>
                  <div>{this.state.event.services[0].vendor_name}</div>
                  <div>{this.state.event.services[0].vendor_email}</div>
                  {this.state.event.services[0].vendor_phone ? <div>{this.state.event.services[0].vendor_phone}</div> : null}
                  <div onClick={()=> this.setState({change_contact_info: true, new_name: this.state.name, new_email: this.state.email, new_phone: this.state.phone, new_company_name: this.state.company_name})} className="change">Change Contact</div>
                </div>
              }
            </div>
            <div className="contact poc">
              <div className="subheader">Load in/load out instructions:</div>
              <div>{this.state.event.day_notes || "None"}</div>
              <div className="subheader" style={{marginTop: 8}}>Parking:</div>
              <div>{this.state.event.parking || "None"}</div>
            </div>
          </div>

          <div className="section-head">Services To Provide</div>
          <div className="card section" style={{overflow:"hidden"}}>
            <div className="your-events">
              <div className="title">{"You'll need to provide the following services:"}</div>
              <div className="divider" />
              {this.state.event.services.map( (e, i) =>
                  <div key={i}>
                    <ServiceSummary data={e} onClick={() => this.setState({viewService: e})} cancelService={(id) => this.setState({cancel: id})} booking={booking} start_time={this.state.event.start_time} timezone={timezone} earliest_start={earliest_start}/>
                    {this.state.event.services.length === 1 ? null : <div className="divider"/>}
                  </div>
              )}
              {this.state.event.services.length > 1 ?
                <div className="bottom">
                  <div className="total-container">
                    <div className="total-earnings"> Total Earnings: <b>{currencyFormatter.format(total, { code: 'USD', precision: 0})}</b></div>
                  </div>
                </div>
                : null
              }

            </div>
          </div>
          <ConfirmationModal title="Are you sure you want to cancel on this event?" text="This cannot be undone. If not prompted by a client rescheduling or sigificant change, it may reflect negatively on your rating." confirmationText="Confirm Cancellation" secondaryText="Don't Cancel" show={!!this.state.cancel} onHide={()=> this.setState({cancel: null})} onConfirm={()=> this.cancel(this.state.cancel)}/>
          {this.state.viewService ? <ServicePage readOnly={true} setBooking={this.props.setBooking} hidePrice={true} included={true} booking={{}} add={false} edit={false} fromCart={true} show={!!this.state.viewService} inCart={false} component={this.state.viewService} onHide={()=> this.setState({viewService: false})} category={"none"} />  : null}
        </div>
      </div>
    )
  }
}
export default withRouter(EventView)
