import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, Table} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import pretty from 'pretty'
import PackagePage from '../Packages/PackagePage'
import ComponentSearch from './ComponentSearch'
import CheckboxControl  from '../Utils/CheckboxControl'
import Header from '../Header/Header'
import CheckboxTree from 'react-checkbox-tree';
import { getCurrentRegion, regionsToNodes, getLocationNames } from '../Utils/Locations'

var fields = {description: "textarea", daily_max: "number", components: "textarea", add_ons: "textarea", is_active: "checkbox", component_details: "hide" }
function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}


class EditPackage extends Component{

  constructor(props){
    super(props)
    this.state = {
      pack: null,
      preview: false,
      id: null,
      new_id: "",
      error: "",
      search: "",
      search_id: "",
      regions: [],
      locations: [],
      expanded: []
    }
  }

  showComponentForm(){
    this.setState({showForm: true})
  }

  componentDidMount(){
    console.log(this.props.location)
    this.getRegions()
    this.getData(this.props)
  }

  async getRegions(){
    var regions = await request('/api/regions')
    regions = regionsToNodes(regions)
    this.setState({regions})
  }

  async getData(props){
    var pack = await request('/api/package/'+props.match.params.id)
    pack.components = JSON.stringify(pack.components, null, 2)
    pack.add_ons = JSON.stringify(pack.add_ons, null, 2)
    pack.categories = JSON.stringify(pack.categories)
    pack.keywords = JSON.stringify(pack.keywords)
    pack.images = JSON.stringify(pack.images)
    pack.seasonal = JSON.stringify(pack.seasonal)
    pack.description = pretty(pack.description)
    delete pack.id
    delete pack.created_at
    delete pack.modified_at
    console.log("Admin:", pack)
    this.setState({pack, id: props.match.params.id, locations: pack.locations})
  }

  componentWillReceiveProps(nextProps){
    if(this.props.match.params.id !== nextProps.match.params.id){
      this.getData(nextProps)
    }
  }

  handleChange(key, value){
    let clone = Object.assign({}, this.state.pack)
    clone[key] = value
    if(this.state.error){
      this.setState({pack: clone, error: ""})
      return
    }
    this.setState({pack: clone})
  }

  preview(){

    var  pack = Object.assign({id: this.state.id}, this.state.pack)
    console.log("preview", pack)
    pack.components = JSON.parse(pack.components)
    //pack.component_details = pack.components_details
    pack.add_ons = JSON.parse(pack.add_ons)
    pack.categories = JSON.parse(pack.categories)
    pack.keywords = JSON.parse(pack.keywords)
    pack.images = JSON.parse(pack.images)
    pack.seasonal = JSON.parse(pack.seasonal)
    return pack
  }

  async setPreview(){
    var validated = this.validate(this.state.pack)
    if(validated){
      this.setState({preview: true})
    }
  }

  validate(pack){
    let jsonList = ["images", "components", "add_ons", "seasonal", "keywords", "categories"]
    try{
      for(var c of jsonList){
        if(pack[c]){
          try{
            JSON.parse(pack[c])
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      console.log(pack)
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate(this.state.pack)
    if(validated){
      var pack = Object.assign({}, this.state.pack)
      delete pack.component_details
      var res  = await request("/api/admin/package", {method: "PUT", body: JSON.stringify({package: pack, id: this.state.id} )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err === "Invalid html"){
        let d = document.getElementById("description")
        console.log("Description:", d)
        d.focus()
        this.setState({error: res.err})
        return
      }else if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        window.location.reload();
      }
    }
  }

  async duplicate(){

  }
  // <Button style={{marginLeft: 20}} onClick={()=> this.duplicate()}>Duplicate</Button>

  render(){
    if (!this.state.pack) return null
    console.log(this.state.pack)
    return (
      <div>
      <Header />
      <div style={{marginTop: 40, marginLeft: 40, marginBottom: 20}}>
        <input type="number" className="text-field" value={this.state.new_id} placeholder="package id" onChange={(e) => this.setState({new_id: e.target.value})}/>
        <Button style={{marginLeft: 20}} onClick={()=> this.props.history.push("/admin_panel/package/" + this.state.new_id )}>Change Package</Button>

      </div>
      <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          {Object.keys(this.state.pack).map((key, i) => {
            if(fields[key] === "textarea"){
              return (
                <FormGroup key={i} controlId={key}>
                  <ControlLabel>{key}</ControlLabel>
                  <FormControl className="text-field" componentClass="textarea" rows="8" style={{resize: "vertical"}}  bsSize="sm" placeholder={key} value={this.state.pack[key]} onChange={(e)=> this.handleChange(key, e.target.value)}/>
                </FormGroup>
              )
            }if(fields[key] === "checkbox"){
              return (
                <div>
                <CheckboxControl checked={this.state.pack[key]} onChange={(check) => this.handleChange(key, check)} />
                <label style={{marginLeft: 10}} onClick={() => this.handleChange(key, !this.state.pack[key])}>{key}</label>
                </div>
              )
            }if(fields[key] === "hide"){
              return null
            }else{
              if(key === "locations"){
                return null
              }
              return (
                <FieldGroup
                     id={key}
                     key={i}
                     type={fields[key]}
                     label={key}
                     className="text-field"
                     placeholder={key}
                     value={this.state.pack[key] || ""}
                     onChange={(e)=> this.handleChange(key, e.target.value)}
                     bsSize="sm"
                   />
              )
            }
          })}
          <div className="locations-section">
            <ControlLabel>{"Locations"}</ControlLabel>
            <CheckboxTree
                nodes={this.state.regions}
                checked={this.state.locations}
                expanded={this.state.expanded}
                onCheck={locations => this.setState({ locations })}
                onExpand={expanded => this.setState({ expanded })}
            />
          </div>
          <Button style={{marginRight: 20}} onClick={()=> this.setPreview()}>Preview</Button>
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
      </div>
      <ComponentSearch />
      {this.state.preview ? <PackagePage booking={this.props.booking} show={this.state.preview} inCart={false} component={this.preview()} onHide={()=> this.setState({preview: false})} addToCart={() => null } /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  booking: state.booking
})


export default withRouter(connect(
  mapStateToProps
)(EditPackage))
