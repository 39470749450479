import React, {Component} from 'react'
import Modal from '../Modal'
import pluralize from 'pluralize'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import People from '../../img/BookBarPeople.svg'
import Duration from '../../img/BookBarDuration.svg'

class CustomizationModal extends Component {

  constructor(props){
    super(props)
    var qty = 1
    let custom = "none"
    if(props.component.qty){
      qty = props.component.qty
      custom = "custom"
    }else if(props.component.unit === "hour"){
      qty = props.params.duration
    }else if(props.component.unit === "person"){
      qty =  props.params.people
    }
    var num_staff = props.component.num_staff || 1

    this.state = {
      custom,
      qty,
      num_staff
    }
  }

  render(){
    if(!this.props.component || !this.props.component.id) return null

    var clone = Object.assign({}, this.props.component)
    var label = ""
    switch (this.props.component.unit) {
      case "hour":
        label = "Event duration ("+(this.props.params.duration !== 1 ? (this.props.params.duration || 0) + " hours)" : this.props.params.duration + " hour)" )
        break;
      case "person":
        label = "Number of people attending (currently set at "+(this.props.params.people !== 1 ? (this.props.params.people || 0) + " people)" : this.props.params.people + " person)" )
        break;
      default:
        label = "1 kit"
    }

    var title = () => {
      switch (this.props.component.unit) {
        case "hour":
          if(this.state.num_staff > 1){
            return "How many hours would you like your " + pluralize(this.props.component.name) + " for?"
          }
          return "How many hours would you like your " + this.props.component.name + " for?"
        case "person":
          return "How many people would you like " + this.props.component.name + " for?"
        default:
          return "How many " + pluralize.plural(this.props.component.name) + " would you like?"
      }
    }

    var confirm = (this.props.component.maximum && Number(this.props.component.maximum) > 0 && this.state.qty > this.props.component.maximum) || (this.props.component.daily_max && this.state.num_staff > this.props.component.daily_max) ?
     <div className="custom-button disabled" style={{float: "right", marginLeft: 9}} >Confirm Quantity</div>
    : <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={() => this.props.onConfirm(this.state.custom === "custom" ? this.state.qty: null, this.state.num_staff > 1 ? this.state.num_staff : null)} >Confirm Quantity</div>

    var requirements = () => {
      // if(clone.maximum && clone.minimum > 1){
      //   return "" + clone.minimum + " to " + clone.maximum + " required"
      if(clone.maximum && Number(clone.maximum) > 0){
        return "Maximum of " + clone.maximum
      }
      // else if(clone.minimum > 1){
      //   return "Minimum of " +  clone.minimum + " required"
      // }
      return null
    }

    return (
      <Modal show={this.props.show} zIndex={2050} onHide={this.props.onHide} customClassName="customize-modal" backdropClassName="customize-backdrop">
        <div className="customize-powerups">
          <div className={"title"}> {title()}</div>
          <div onChange={(e) => this.setState({custom: e.target.value}) } style={{marginTop: 11}}>
            <input type="radio" id="none" value="none" name="none" checked={this.state.custom === "none"} />
            <label htmlFor="none">{label}</label>
            <br/>
            <input type="radio" id="custom" value="custom" name="custom" checked={this.state.custom === "custom"} />
            <label htmlFor="custom">Custom number of {pluralize(this.props.component.unit)}</label>
          </div>
          <div style={{position: "relative"}}>
            {this.props.component.unit !== 'kit' ? <img alt="" style={{position: "absolute", left: 30, top: 7}} src={this.props.component.unit === 'person' ? People: Duration}/> : null}
            <input type="number" className={"text-field " + this.props.component.unit} value={this.state.qty} onChange={(e) => this.setState({qty: e.target.value, custom: "custom"})}/>
            <span className="requirements">{requirements()}</span>
          </div>
          {confirm}
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
      </Modal>
    )
  }

}



export default CustomizationModal






// export default CustomizationModal
