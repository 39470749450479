import React from 'react'
import '../App.css'

const Card = ({children, className}) => {
    return (
      <div className={"card " + className} >
      {children}
      </div>
    )
}


export default Card
