import React, {useState} from 'react';
import { EllipsisVertical, ShoppingCart, UserRound } from 'lucide-react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as cartActions from '../actions'
import {Link, withRouter} from 'react-router-dom'
import EventDetailsBar from './Header/EventDetailsBar';
import HeroImage from '../img/HomeHeroBarHappyPeople@2x.jpg'
import OneUpLogo from '../img/1upLarge.svg'
import BookBar from './Header/BookBar'
import Thumb from '../img/HomeThumbsUp.svg'
import Verified from '../img/HomeVerified.svg'
import EventPackage from '../img/HomeEventPackage.svg'
import Availability from '../img/HomeAvailability.svg'
import ShortLogos from '../img/TopHeroLogos@2x.png'
import PackageList from './Packages/PackageList';
import Header from './Header/SimpleHeader'
import BigLogos from '../img/LogoGrid@2x.png'
import Footer from './Utils/BigFooter'
import request from '../request';
import MobileHeader from './Header/MobileHeader';
import '../css/Landing.css';


const ResponsiveLanding = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);


  const handleNavigation = (path) => {
    console.log(`Navigating to: ${path}`);
    props.history.push(path)
  };

  const signOut = () => {
    props.actions.logOut()
    request('/api/signout/', {method: "POST"})
    setIsAccountMenuOpen(false)
  }

  return (
    <div className="new min-h-screen relative bg-white">
      {/* Mobile Header */}
      <MobileHeader className="lg:hidden"/>
      <header className='hidden lg:block' >
        <Header className="white" />
      </header>

      {/* Hero Section - Using 1Up's signature gradient */}
      <div className="hero relative">
        {/* <div className="absolute inset-0 bg-repeat opacity-20" /> */}
        <div className="image-wrapper h-full w-full">
            <div className='newimage h-full w-full float-right mr-[-10%]'>
              <img src={HeroImage} className="h-full  object-cover float-right"  alt="Hoppy Hour" / >
            </div>
        </div>
        <div className="overlay-wrapper w-full h-full">
          <div className="overlay h-full"/>
        </div>
        <div className="confetti h-full" />
        <div className="relative px-4 py-16 sm:px-6 lg:px-8 lg:py-24 lg:pt-36">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-white sm:text-5xl lg:text-6xl">
              Instantly book your event.
            </h1>
            <p className="mt-6 text-xl text-white opacity-90 max-w-2xl mx-auto">
              Choose an all-inclusive package or build your event from scratch. Just enter your info to see real-time availability and pricing.
            </p>
            <div className="mt-10 mx-auto z-20 flex justify-center">
              <EventDetailsBar callToAction="Get Started" customClasses="shadow-lg" eventDetails={props.booking} large={true} confirm={() => props.history.push('/events/food')} />
              <div className='hidden lg:block'><BookBar large={true} /></div>
            </div>
            <div className="mt-10 mx-auto flex z-20 justify-center lg:hidden">
              <Link to="/events/food/all" className='bg-primary-action-color hover:bg-[#EAA600] text-white items-center flex justify-center rounded-full border-white border-[1px] shadow-lg font-semibold text-lg max-w-full w-[170px] sm:w-[200px] cursor-pointer h-[48px] sm:h-[52px]'>Get Started</Link>
            </div>
          </div>
        </div>
      </div>

      {/* Trusted By Section */}
      <div className="bg-white relative lg:h-28 p-1 h-16 sm:h-20 z-0">
        <div className="overflow-scroll h-full pt-5 justify-center flex">
          <img src={ShortLogos} alt="Customer logos" className="h-full object-cover" />
        </div>
      </div>
      {/* Features Section */}
      <div className="px-4 py-16 sm:px-6 lg:px-8 bg-gray-50">
        <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-4 max-w-7xl mx-auto">
          <div className="text-center group">
            <div className="bg-white rounded-2xl shadow-md p-6 transform group-hover:-translate-y-1 transition-all">
              <img src={Thumb} alt="Plan online" className="mx-auto h-16 w-16" />
              <h2 className="mt-4 text-xl font-bold text-strong-content-color">Plan and book entirely online</h2>
              <p className="mt-3 text-primary-content-color">From food to decor to logistics, we have a vendor for all your event needs!</p>
            </div>
          </div>
          <div className="text-center group">
            <div className="bg-white rounded-2xl shadow-md p-6 transform group-hover:-translate-y-1 transition-all">
              <img src={EventPackage} alt="Packages" className="mx-auto h-16 w-16" />
              <h2 className="mt-4 text-xl font-bold text-strong-content-color">Choose or build your package</h2>
              <p className="mt-3 text-primary-content-color">Select from our curated packages or create your own custom event.</p>
            </div>
          </div>
          <div className="text-center group">
            <div className="bg-white rounded-2xl shadow-md p-6 transform group-hover:-translate-y-1 transition-all">
              <img src={Availability} alt="Real-time" className="mx-auto h-16 w-16" />
              <h2 className="mt-4 text-xl font-bold text-strong-content-color">Real-time availability and pricing</h2>
              <p className="mt-3 text-primary-content-color">No more email back and forths! See instant pricing and availability for all our offerings.</p>
            </div>
          </div>
          <div className="text-center group">
            <div className="bg-white rounded-2xl shadow-md p-6 transform group-hover:-translate-y-1 transition-all">
              <img src={Verified} alt="Verified" className="mx-auto h-16 w-16" />
              <h2 className="mt-4 text-xl font-bold text-strong-content-color">We review everything to ensure it’s perfect.</h2>
              <p className="mt-3 text-primary-content-color">Our trained staff will look over everything to make sure your event goes off without a hitch!</p>
            </div>
          </div>
        </div>
        <Link to="/how-it-works" className="landing custom-button mx-auto mt-16 mb-0" style={{width: 289}}>See How It Works in Detail</Link>
      </div>

      {/* Packages Preview */}
      <div className="px-4 py-10 sm:px-6 sm:py-16 lg:px-8">
        <div className="text-center max-w-3xl mx-auto">
          <h2 className="text-4xl font-bold text-strong-content-color">
            Instantly book an event package
          </h2>
          <p className="mt-4 text-xl text-primary-content-color">
            Event packages include everything you need for an awesome event
          </p>
        </div>
        {/* <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
          {[1, 2, 3].map((i) => (
            <div key={i} className="group">
              <div className="overflow-hidden rounded-2xl bg-white shadow-lg transform group-hover:-translate-y-1 transition-all">
                <img src="/api/placeholder/400/200" alt={`Package ${i}`} className="w-full h-48 object-cover" />
                <div className="p-8">
                  <h3 className="text-2xl font-bold text-gray-900">Event Package {i}</h3>
                  <p className="mt-3 text-gray-600">All-inclusive package with everything you need.</p>
                  <button 
                    onClick={() => handleNavigation(`/package/${i}`)}
                    className="mt-6 rounded-xl bg-green-500 px-6 py-3 text-white font-semibold hover:bg-green-600 transform hover:-translate-y-0.5 transition-all"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div> */}
        <PackageList className="mt-10" listClass="list"><div className="overlay"/></PackageList>
        <Link to="/events/packages" className="landing custom-button mx-auto mt-16 mb-0" style={{width: 289}}>Browse All Event Packages</Link>
      </div>

      {/* Company Logos */}
      <div className="bg-gray-50 px-4 py-16 sm:px-6 sm:py-10 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-center text-4xl font-bold text-gray-900">
            You're in good company
          </h2>
          <p className="mt-4 text-center text-xl text-gray-600">
            We've planned events for over 250 companies in the Bay Area
          </p>
          {/* <div className="mt-12 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <div key={i} className="bg-white rounded-xl shadow-sm p-6 flex items-center justify-center">
                <img
                  src="/api/placeholder/160/80"
                  alt={`Company ${i}`}
                  className="h-12 object-contain opacity-60 hover:opacity-100 transition-opacity"
                />
              </div>
            ))}
          </div> */}
        </div>

      </div>
      <div className="bg-white lg:h-[440px] p-1 mb-10 h-[300px] sm:h-[370px] px-4">
            <div className="overflow-scroll h-full pt-5 justify-center flex">
              <img src={BigLogos} alt="Customer logos" className="h-full object-cover" />
            </div>
      </div>
      <Footer className={"relative flex gap-4 p-4 new pt-16"} />
    </div>
  );
};

{/* export default ResponsiveLanding; */}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponsiveLanding))