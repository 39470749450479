import React, {Component, useEffect, useState, useRef} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import moment from 'moment'
import Modal from '../Modal'
import AddToCartButton from '../../img/AddToCartButtonWhite.svg'
import CartPowerup from '../Powerups/CartPowerup'
import ServicePage from '../Services/ServicePage'
import SectionHeader from '../Utils/SectionHeader'
import currencyFormatter from 'currency-formatter'
import request from '../../request'
import InCartButton from '../../img/InCartButton.svg'
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import AvailabilityIcon from '../../img/SoldOutViewAvailabilityIcon.svg'
import LimitationModal from '../Utils/LimitationModal'
import pluralize from 'pluralize'
import { getPackagePrice, getStartTime, getLateFee } from '../Utils/math'
import CircleCheck from '../../img/CheckmarkCircle.svg'
import Hearting from '../Utils/Hearting'
import AvailabilityViewer from '../Utils/AvailabilityViewer'
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from 'lucide-react'
import BookingModal from '../Utils/BookingModal'
import useWindowDimensions from '../Utils/GetWindowDimensions'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function changeParams(){
  var element = window.document.getElementById("date_input");
  element.focus()
}

function getPrice(component, store, componentList, discount){
  if(component.calculated_price){
    return <span><span className="price">{currencyFormatter.format(component.calculated_price, { code: 'USD', precision: 0})} </span> for <span className="emphasis"> {store.people} people</span></span>
  }
  var late_fee = getLateFee(moment(), getStartTime(store.date, store.start_time, store.timezone))
  console.log(componentList)
  var price = getPackagePrice((componentList || []), store, discount, component.setup_time, component.takedown_time, late_fee)

  if(!store.people || !store.duration){
     return <span>Starting at <span className="price">{currencyFormatter.format(price , { code: 'USD', precision: 0})}</span></span>
  }
  return <span><span className="price">{currencyFormatter.format(price, { code: 'USD', precision: 0})} </span> for <span className="emphasis"> {store.people} people</span></span>
}

function PrevArrow({ className, style, onClick }) {
  return (
    <div
      className={'absolute z-[1] shadow-lg left-4 top-1/2 bg-black/50 transform -translate-y-1/2 rounded-full cursor-pointer'}
      onClick={onClick}
    >
      <ChevronLeft color="white" className='w-8 h-8'/>
    </div>
  );
}


function NextArrow({ className, style, onClick }) {
  return (
    <div
      className={'absolute shadow-lg right-4 top-1/2 transform -translate-y-1/2 bg-black/50 rounded-full  cursor-pointer'}
      onClick={onClick}
    >
      <ChevronRight color="white" className='w-8 h-8'/>
    </div>
  );
}


function ImageCarousel({images, name}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [countModifier, setCountModifier] = useState(2);
  const { height, width } = useWindowDimensions();
  
  useEffect(() => {
    console.log("WindoeWidth", width)
    if(width > 768) {
      setCountModifier(2)
    } else {
      setCountModifier(1)
    }
  }, [width])

  const severalImages =  images.length > 2

  let imageCount = slideIndex + countModifier
  if(imageCount > images.length) {
    imageCount -= 1
  }

  // useEffect(() => {console.log("COUNT MODIFIER MODIFIED", countModifier)}, [countModifier])

  var settings = {
    dots: false,
    draggable: severalImages,
    dotsClass: "bottom-6 shadow-lg bg-black/50 p-2 w-fit left-1/2 transform -translate-x-1/2 rounded-full flex absolute",
    infinite: severalImages,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    appendDots: dots => (
      <div className=''>
        <ul className='flex space-x-5'> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className='h-2 w-2 rounded-full bg-white/50 '/>
    ),
    nextArrow: severalImages ? <NextArrow /> : null,
    prevArrow: severalImages ? <PrevArrow /> : null,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          infinite: true,
          dots: false,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
          beforeChange: (current, next) => setSlideIndex(next),
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          infinite: true,
          dots: false,
          arrows: false,
          beforeChange: (current, next) => setSlideIndex(next),
        }
      },
    ]
  };
  // relative before:absolute before:left-[-1px] before:top-0 before:h-full before:w-[1px] before:bg-white
  return (
    <div className="relative">
      <Slider {...settings}>
        {images.map((i, index) => 
          <div key={index} className='pr-[1px]'>
            <img className="object-cover max-w-none h-[364px] w-full" src={"https://d2c831se9gn8j1.cloudfront.net/"  + i}  alt={name}/>
          </div>
        )}
      </Slider>
      <div className="bottom-6 shadow-lg bg-black/50 py-2 px-3 w-fit right-6 rounded-full flex absolute text-white font-semibold">
        {imageCount + "/" +images.length}
      </div>
    </div>
  );

}

const PriceWrapper = ({ children }) => (
  <div className="flex w-full xs:w-auto flex-wrap items-center gap-1">
    {children}
  </div>
);

class PackagePage extends Component{

  constructor(props){
    super(props)
    this.state = {
      includes: {},
      showDetails: false,
      calendar: false,
      unavailable: [],
      limitation: false,
      componentList: [],
      availability: [],
      showEventDetails: false,
    }

  }

  describeParams(num_hours, price, params, component, componentList, discount, noChange){
    console.log("Package modal", num_hours, params)
    return (
      <div className="params flex flex-wrap w-full gap-1">
        <div className="flex flex-none items-center">
            {getPrice(component, params, componentList, discount)}
        </div>
        <div>
        {params.date ? <span className='hidden sm:inline'>{" on "}<span className="emphasis">{moment(params.date).format("ddd, MMM D, YYYY")} </span></span>: null}
        {params.date ? <span className="sm:hidden">{" on "}<span className="emphasis">{moment(params.date).format("MMM D")} </span></span>: null}
        {num_hours? <span> for <span className="emphasis">{num_hours + (num_hours === 1 ? " hour" : " hours")} </span></span> : null}
        {noChange ? null : <span className="change text-base pl-2 lg:hidden" onClick={ () => this.props.setBooking ? this.props.setBooking() : this.setState({showEventDetails: true}) }> Change</span>}
        {noChange ? null : <span className="change text-base hidden lg:inline" onClick={ () => this.props.setBooking ? this.props.setBooking() : changeParams() }> Change</span>}
        </div >
      </div>
    )
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.component && nextProps.component.id !== (this.props.component ? this.props.component.id : undefined)) {
      this.getIncluded(nextProps)
      this.getAvailability(nextProps, "recevied")
      document.title = nextProps.component.name ? nextProps.component.name + " - 1up Events": "1up Events"
      document.description = nextProps.component.short_description || "1up Events"
    }
  }

  async getAvailability(props, received){
    // var unavailable = await request('/api/availability/package/' + props.component.id)
    // console.log("pack availability", unavailable)
    // if(!unavailable.err && !unavailable.name){
    //   this.setState({unavailable})
    // }
    var start_time = moment(props.booking.date).set('hour', 0).set('minute', 0).set('second', 0);
    var end_time = moment(start_time).add(1, "day").add(props.booking.duration, "hours")
    let timezone = props.booking.timezone || ""
    timezone = timezone.replace("/", "%2F")
    var availability = await request('/api/availability/package/' + props.component.id + "/" + start_time + "/" + end_time + "/" + timezone)
    console.log(props.component.name, availability)
    if(!availability.err){
      this.setState({availability})
    }
  }

  async getIncluded(props){

    var params = { method: 'POST',
                  body: JSON.stringify({ids: props.component.components.map(c => c.id ) })
              };
    var res =  await request('/api/getComponentsByIDs', params)
    if (res.err){
      console.log(res.err)
      return
    }
    var includes = {}
    for ( var r of res){
      // eslint-disable-next-line
      r = Object.assign(r, props.component.components.find(c => Number(c.id) === Number(r.id)))
      if(includes[r.categories[0]]){
        includes[r.categories[0]].push(r)
      }else{
        includes[r.categories[0]] = [r]
      }
    }
    this.setState({includes, componentList: res})
  }


  componentDidMount(){
    // this.props.actions.closeCart()
    if(this.props.component){
      this.getIncluded(this.props)
      this.getAvailability(this.props)
      document.title = this.props.component.name + " - 1up Events" || "1up Events"
      document.description = this.props.component.short_description || "1up Events"
    }
  }

  blockOut(date){
    if(date < moment().add(this.props.component.booking_lead_time || 2, 'days')){
      return true
    }
    for (var d of this.state.unavailable){
      if(moment(d).isSame(date, 'day')){
        return true
      }
    }
    return false
  }

  itemList(cart, component){
    var categories = ["remote", "food", "drink", "animals", "entertainment", "decor", "logistics"]

    return (
      <div className="cart-contents">
        {categories.map( (cat, i) => {
          return (cart[cat] && cart[cat].length > 0 ?
            <div key={i}>
              <SectionHeader name={cat} pack={false} />
                {cart[cat].map(c =>
                    <CartPowerup key={c.id} c_id={c.id} component={c} onClick={() => this.setState({showDetails: c})} theme={component.components.find(p => Number(p.id) === Number(c.id)).theme} category={c.categories[0]} included={true} />
                )}
            </div>
          : null)
        }
        )}
      </div>
    )
  }

  addToCart(component){
    if(component.maximum && component.maximum > this.props.booking.people){
      this.setState({limitation: "max"})
    }else{
      this.props.addToCart()
    }
  }


  confirmedAddToCart(){
    this.setState({limitation: false})
    this.props.addToCart()
  }


  render(){

    var component = this.props.component || {img: ""}
    if(!component.id){
      return null;
    }

    var c = () => {
      if(component.is_available){
        if(this.props.inCart){
          return (
            <div className="buttons w-full relative top-0 right-0 sm:absolute sm:top-[382px] sm:right-[21px] sm:w-fit gap-3 flex">
              <div className='hidden sm:flex'>
                <Hearting right={true}  lists={this.props.login.wishlists || []} menuClassName={"top-10"} component={component} isPackage={true} />
              </div>
              <div className="added w-full flex justify-center relative sm:w-[140px] top-0 right-0  ">
                <img className="icon ml-[-8px] inline" src={InCartButton} alt=""/>
                <span className="text">Added</span>
              </div>
            </div>
          )
        }
        return (
          <>

          <div className="buttons w-full relative top-0 right-0 sm:absolute sm:top-[382px] sm:right-[21px] sm:w-fit gap-3 flex">
            <div className='hidden sm:flex'>
              <Hearting right={true}  lists={this.props.login.wishlists || []} menuClassName={"top-10"} component={component} isPackage={true} />
            </div>
            <div className="add w-full items-center flex justify-center h-[40px] sm:h-[36px]" onClick={()=> this.addToCart(component)}>
              <img className="cart-icon inline" src={AddToCartButton} alt=""/>
              <span className="add-text" >Add to Cart</span>
            </div>
          </div>
          </>
        )
      }
      return (
        <div className="buttons w-full relative top-0 right-0 sm:absolute sm:top-[382px] sm:right-[21px] sm:w-fit gap-3 flex">
          <div className='hidden sm:flex'>
            <Hearting right={true} menuClassName={"top-10"} lists={this.props.login.wishlists || []} component={component} isPackage={true} />
          </div>
          <div className='hidden sm:flex'><AvailabilityViewer name={component.name} customDropdownStyles="w-full" customDropdownMenuStyles={"top-[36px]"} lead_time={component.booking_lead_time} start={getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)} right={true} modal={true} availability={this.state.availability} /></div>
          <div className='w-full sm:hidden '><AvailabilityViewer name={component.name} customDropdownStyles="w-full h-[40px]" customDropdownMenuStyles={"fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"} lead_time={component.booking_lead_time} start={getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)} right={true} modal={true} availability={this.state.availability} /></div>
        </div>
      )
    }
    
    let remainder = []
    return (
      <>
      <Modal {...this.props} customClassName="package-modal overflow-hidden m-0 h-full rounded-none lg:rounded-[5px] lg:mx-auto lg:mt-[8vh] lg:h-[86vh] w-full lg:w-[996px] lg:max-w-full">
        <div className="close-button hidden left-[21px] top-4 z-[9999] lg:block" onClick={this.props.onHide} />
        <div className="content-wrapper w-full overflow-hidden rounded-none lg:rounded-[5px]">
          <div className="scroll-container w-full">
          <div className="absolute flex justify-center items-center bg-white shadow-lg cursor-pointer h-10 w-10 rounded-full z-[9999] left-5 top-5 lg:hidden " onClick={this.props.onHide}>
              <ChevronLeft color="#848484" className='w-7 h-7 ml-[-2px]' />
            </div>
            <div className="corner-heart flex absolute right-5 top-5 justify-center items-center bg-white shadow-lg cursor-pointer h-10 w-10 rounded-full z-[9999] sm:hidden ">
              <Hearting right={true}  lists={this.props.login.wishlists || []} component={component} isPackage={true} />
            </div>    
            <div className='w-[calc(100%+1px)] relative overflow-hidden h-[364px]'>
              <ImageCarousel images={component.images} name={component.name}/> 
              <div className="actual hidden lg:block">
                All photos from actual events
              </div>
            </div>
            <div className="name text-[24px] sm:text-[24px]"> {component.name}</div>
            <div className=''>
              {this.describeParams(this.props.booking.duration, component.price, this.props.booking, component, component.component_details, component.discount, this.props.noChange)}
            </div>
            <div className="short-includes">{component.includes}</div>
            <div className='hidden sm:block'>
              {c()}
            </div>
            {this.state.calendar ?
              <DayPickerSingleDateController
                date={this.props.booking.date ? moment(this.props.booking.date) : null}
                focused={this.state.calendar}
                onDateChange={(date) => this.props.actions.changeParams("date", date)}
                onFocusChange={({ focused }) => {console.log("focus change", focused); this.setState({calendar: focused})} }
                isDayBlocked={this.blockOut.bind(this)}
                hideKeyboardShortcutsPanel={true}
                numberOfMonths={1}/> : null
              }
            <div className="divider"/>
            <div className="the-rest flex-col sm:flex-row">
              <div className="details min-w-full max-w-full px-[21px] pt-[19px] pb-0 flex sm:w-[inherit] sm:min-w-[inherit]">
                <div className="description p-0 m-0 sm:pb-[10px] min-w-full" >
                  <div dangerouslySetInnerHTML={{ __html: component.description }} />
                  <div className={'hidden sm:block'} style={{height: 25}}/>
                </div>
                {remainder && remainder.length > 0 ?
                  <div>
                  <div className="divider" />
                  <div className="photo-header">Photos From Past {pluralize(component.name)}</div>
                  <ul className="photos">
                    {remainder.map((im, i) =>
                      <li key={i}><img src={"https://d2c831se9gn8j1.cloudfront.net/" + im} alt={component.name} /></li>
                    )}
                  </ul>
                  </div>
                  : null
                }
                </div>
              <div className="divider sm:hidden" />
              <div className="components flex flex-col pl-[21px] sm:pl-[19px] w-full min-w-full sm:w-[400px] sm:min-w-[320px] sm:flex-1 border-none sm:border-solid sm:border-[rgba(0,0,0,0.17)] sm:border-l-[1px]">
                <div className="includes">{"What's Included: "}</div>
                { component.discount > 0 ? <div className="saved">Save {Math.round((component.discount) * 100)}% by booking this package, or check out our other categories to mix and match items for a fully custom event</div>  : null}
                {this.itemList(this.state.includes, component)}
              </div>
            </div>
          </div>
          {!!this.state.showDetails ? <ServicePage booking={this.props.booking} show={!!this.state.showDetails} component={this.state.showDetails} onHide={() => this.setState({showDetails: false})} included={true}/> : null}
          <LimitationModal component={component} limitation={this.state.limitation} store={this.props.booking} show={!!this.state.limitation} onHide={() => this.setState({limitation: false})} onConfirm={() => this.confirmedAddToCart()}/>
        </div>
        <div className="bottom-bar pb-3 pt-3 sm:hidden h-fit">
            {/* <div className='h-fit w-full min-w-full flex'>
              {this.describeParams(this.props.booking.duration, component.price, this.props.booking, component, component.component_details, component.discount, this.props.noChange)}
            </div> */}

            <div className='h-fit  min-w-full items-center flex flex-1 relative w-full px-[21px]'>
              {/* {this.props.noChange ? null:
              <span className=" font-semibold min-w-[50%] text-primary-action-color text-lg lg:hidden" onClick={ () => this.props.setBooking ? this.props.setBooking() : this.setState({showEventDetails: true}) }> Change</span>
              } */}
              {c()}
            </div>
        </div>
      </Modal>
      <BookingModal show={this.state.showEventDetails} onHide={() => this.setState({showEventDetails: false})} />
      </>
    )
  }
}


const mapStateToProps = state => ({
  login: state.login
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagePage))
