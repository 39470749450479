import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import request from '../../request'
import moment from 'moment'
import { getComponentPrice } from '../Utils/math'
import './EventView.css'
import PastEvent from './PastEvent'
import UpcomingEvent from './UpcomingEvent'
import ConfirmationModal from '../Utils/ConfirmationModal'

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}


class EventView extends Component {

  constructor(props){
    super(props)
    this.state = {
      event: {},
      merge: null,
      unavailable: false,
      notify: false
    }
  }

  async reloadEvent(){
    console.log("RELOADING")
    var url = '/api/events/' + this.props.match.params.id
    if(this.props.location.pathname.includes("admin_panel")){
      url = '/api/admin/events/' + this.props.match.params.id
    }
    var res =  await request(url)
    if (res.err){
      console.log(res.err)
      return
    }else{
      console.log(res)
      for(var comp of res.components){
        comp.start_offset = {name: moment(comp.start_time).format("h:mma"), value: Number(comp.start_offset)}
        comp.is_available = true
      }
      this.setState({event: res})
    }
  }

  async componentDidMount(){
    this.reloadEvent()
  }

  // getPrice(e, booking){
  //   var price = 0
  //   for (var c of e.components){
  //     price += getComponentPrice(c, booking, null, true)
  //   }
  //   price += Number(e.package ? e.package.calculated_price: 0)
  //   return price
  // }

  merge(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: this.state.merge, componentsRemoved: []}})
  }

  replace(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: true, event_id: this.state.merge, componentsRemoved: []}})
  }


  cartify(pack, components, people){
    var cart = {}
    if (pack){
      var p_clone = cloneObj(pack)
      p_clone.qty = people
      cart.package = [{id: p_clone.id, component: p_clone}]
    }
    components.forEach( (c, i) => {
      var clone = cloneObj(c)
      if(clone.is_available === undefined) clone.is_available = true
      clone.index =  i
      cart[clone.categories[0]] ? cart[clone.categories[0]].push({id: clone.id, component: clone}) : cart[clone.categories[0]] = [{id: clone.id, component: clone}]
    })
    return cart
  }

  shouldMerge(id){
    var cart_count = 0;
    for (var key in this.props.cart){
      cart_count += this.props.cart[key].length
    }
    if(cart_count === 0){
      this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: id, componentsRemoved: []}})
    }else{
      this.setState({merge: id})
    }
  }

//&& !this.props.location.pathname.includes("admin_panel") wait until better handling of vendors for editing past events

  render(){

    if(!this.state.event.event){
      return null
    }
    if((moment(this.state.event.event.start_time) < moment() || this.state.event.event.canceled) && !this.props.login.admin ){
      return (
        <div>
          <PastEvent {...this.props} event={this.state.event} cartify={this.cartify} getPrice={this.getPrice} rebook={() => this.shouldMerge(this.state.event.event.id)} />
          <ConfirmationModal title="You have items in your cart already" text="Would you like to replace your cart or merge it with your event?" show={!!this.state.merge} onHide={() => this.setState({merge: null})} secondaryAction={ () => this.merge()} secondaryText={"Merge"} confirmationText={"Replace"} onConfirm={() => this.replace()} tertiary={true}/>
        </div>
      )
    }

    return <UpcomingEvent {...this.props} reloadEvent={ () => this.reloadEvent()} event={this.state.event} cartify={this.cartify} getPrice={this.getPrice} getComponents={(date) => this.getComponentsWithAvailability(date)} />
  }

}

const mapStateToProps = state => ({
  store: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EventView))
