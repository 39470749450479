import React from 'react'
import Static from './StaticWrapper'
import MyMap from '../../img/ContactUsMap@2x.png'
import {Link} from 'react-router-dom'
const Contact = () => {
  return (
    <Static className="contact px-4 max-w-full mt-8 mb-8 sm:mb-[115px] lg:mt-[115px] lg:mx-auto flex-1">
        <div className="static-page-header max-w-full md:text-[44px] text-[36px] ">Contact Us</div>
        <div className="static-page-subheader max-w-full lg:mb-[50px] mb-4">{"We’re located in sunny (well, mostly) San Francisco and can’t wait to hear from you."}</div>
        <div className='flex flex-row flex-wrap gap-x-12' >
          <img className="map mb-4 max-w-full mr-0" src={MyMap} alt="map"/>
          <div className="info flex-col flex justify-center">
            <div className="section">
              <div className="info-header" style={{marginBottom: 16, fontSize: 16, width: "100%"}}>Feel free to check out our <Link to="/faq" className="link">FAQ page </Link> and see if we've got your questions covered.</div>
              <div className="info-header">Primary Phone</div>
              <div className="number"><a href="tel:+14159155184">(415) 915-5184</a></div>
              <div className="small-text">Mon-Fri, 8am to 8pm (PST)</div>
            </div>
            <div className="section">
              <div className="info-header">Email</div>
              <a className="text link" href="mailto:info@1upevents.com?subject=Inquiry from Contact Us Page">info@1upevents.com</a>
            </div>
            <div className="section">
              <div className="info-header">Address</div>
              <div className="text">1up Events</div>
              <div className="text">698 De Haro St.</div>
              <div className="text">San Francisco, CA 94107</div>
            </div>
          </div>
        </div>
    </Static>
  )
}

export default Contact
