import React from 'react'
import EventSummary from './EventSummary'
import request from '../../request'
import Calendar from './Calendar'
import moment from 'moment-timezone'
import {withRouter} from 'react-router-dom'
import NewEvent from '../Utils/NewEvent'
import BlockOff from '../Utils/BlockOff'

function getDescription(data){
  var names = data.services.map(s => s.name)
  names = names.join(", ")
  return names
}


function assignColors(events){
  var colors = ["red", "green", "orange", "fuchsia", "blue", "olive", "maroon", "lime", "aqua"]
  var col = {}
  for(var e of events){
    if(!col[e.calendar_id]){
      col[e.calendar_id] = colors.shift()
      colors.push(col[e.calendar_id])
    }
  }
  return col
}

class Events extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      events: [],
      pastEvents: [],
      vacations: [],
      syncedEvents: [],
      view: "list",
      add_event: false,
      range: {},
      bookVacation: false,
      editVacation: false,
      timezone: "America/Los_Angeles",
      showSynced: true
    }
  }

  async componentDidMount(){
    var events = await request("/api/vendor/events")
    var vacations = await request("/api/vendor/vacations")
    var pastEvents = await request("/api/vendor/pastEvents")
    var syncedEvents = await request("/api/vendor/syncedEvents")

    var timezone = moment.tz.guess();
    console.log(pastEvents)
    if(events.err){
      console.log(events)
      this.setState({timezone})
    }else{
      console.log(events)
      for (let e of events){
        e.title = e.company_name
        e.start_time = new Date(e.start_time)
        e.end_time = new Date(e.end_time)
        e.desc = getDescription(e)
      }
      for (let e of pastEvents){
        e.title = e.company_name
        e.start_time = new Date(e.start_time)
        e.end_time = new Date(e.end_time)
        e.desc = getDescription(e)
      }
      // --primary-action-color: #FBB200;
      for (let v of vacations){
        v.title = "Vacation"
        v.hexColor = "#848484"
        v.start_time = new Date(v.start_time)
        v.end_time = new Date(v.end_time)
        v.desc = "You will receive no bookings during these blocked off periods"
      }
      var assigned_colors = assignColors(syncedEvents)
      for (let e of syncedEvents){
        e.hexColor = assigned_colors[e.calendar_id]
        e.start_time = new Date(e.start_time)
        e.end_time = new Date(e.end_time)
      }
      this.setState({events, vacations, pastEvents, timezone, syncedEvents})
    }
  }

  viewDetails(e){
    if(e.title !== "Vacation"){
      this.props.history.push("/vendors/event/" + e.id)
    }else{
      this.setState({editVacation: e})
    }
  }

  save(service_id, start, end){

  }

  async bookVacation(start_time, end_time){
    var booked = await request('/api/vendor/vacation', {method: "POST", body: JSON.stringify({start_time, end_time})})
    if(booked.err){
      this.setState({error: booked.err})
    }else{
      this.setState({bookVacation: false})
    }
    var vacations = await request("/api/vendor/vacations")
    if(vacations.err){
      console.log(vacations)
    }else{
      console.log(vacations)
      vacations = this.vacationDetails(vacations)
      this.setState({vacations})
    }
  }

  vacationDetails(vacations){
    for (let v of vacations){
      v.title = "Vacation"
      v.hexColor = "848484"
      v.start_time = new Date(v.start_time)
      v.end_time = new Date(v.end_time)
      v.desc = "You will receive no bookings during these blocked off periods"
    }
    return vacations
  }


  async deleteVacation(){
    var booked = await request('/api/vendor/vacations/' + this.state.editVacation.id, {method: "DELETE"})
    if(booked.err){
      this.setState({error: booked.err})
    }else{
      this.setState({editVacation: false})
    }
    var vacations = await request("/api/vendor/vacations")
    if(vacations.err){
      console.log(vacations)
    }else{
      console.log(vacations)
      vacations = this.vacationDetails(vacations)
      this.setState({vacations})
    }
  }

  async editVacation(start_time, end_time){
    var booked = await request('/api/vendor/vacations/' + this.state.editVacation.id, {method: "PUT", body: JSON.stringify({start_time, end_time})})
    if(booked.err){
      this.setState({error: booked.err})
    }else{
      this.setState({editVacation: false})
    }
    var vacations = await request("/api/vendor/vacations")
    if(vacations.err){
      console.log(vacations)
    }else{
      console.log(vacations)
      vacations = this.vacationDetails(vacations)
      this.setState({vacations})
    }
  }

  render(){
    return (
      <div style={{position: 'relative'}}>
        <div className="section-head" style={{width: "100%", position: "relative"}}>Your Events</div>
        <div style={{right: 0, top: 0, position: "absolute", margin: 0}} >
          <button className="secondary-button" style={{marginRight: 20, marginTop: 0}} onClick={() => this.setState({bookVacation: true})}>Block Off Vacation</button>

          {this.state.view === "calendar" ? <button style={{marginTop: 0}}  className="secondary-button" onClick={() => this.setState({view: "list"})}>List</button> :
          <button className="secondary-button" style={{marginTop: 0}} onClick={() => this.setState({view: "calendar"})}>Calendar</button>
          }
        </div>

        {this.state.view === "calendar" ? <button className="secondary-button" style={{marginRight: 0, marginTop: 0,  marginBottom: 12}} onClick={() => this.setState({showSynced: !this.state.showSynced})}>{this.state.showSynced ?  "Only Show 1up Events": "Show All Events"}</button> : null}
      {this.state.view === "calendar" ? <Calendar events={[...this.state.events, ...this.state.vacations, ...this.state.pastEvents].concat(this.state.showSynced ? this.state.syncedEvents : [])} viewDetails={(e) => this.viewDetails(e)} newEvent={(slot) => this.setState({add_event: true, range: slot})}/> :
        <div>
        <div className="card section" style={{overflow:"hidden", padding: 20}}>
        {this.state.events.length === 0 ?
          <div><i>No Upcoming Events</i></div>
        : null}
          <div className="your-events">
            {
              this.state.events.map( (e, i) =>
                <div key={i}>
                  <div>{e.company_name}</div>
                  <EventSummary vendor_timezone={this.props.vendor_timezone} data={e}/>
                  {i < this.state.events.length -1 ? <div className="divider" /> : null}
                </div>
            )}
          </div>
        </div>
        {this.state.pastEvents.length > 0 ?
          <div>
          <div className="section-head" style={{width: "100%", position: "relative", marginTop: 30}}>Past Events</div>
          <div className="card section" style={{overflow:"hidden", padding: 20}}>
            <div className="your-events">
              {
                this.state.pastEvents.map( (e, i) =>
                  <div key={i}>
                    <div>{e.company_name}</div>
                    <EventSummary vendor_timezone={this.props.vendor_timezone} data={e}/>
                    {i < this.state.events.length -1 ? <div className="divider" /> : null}
                  </div>
              )}
            </div>
          </div>
          </div>
        : null
        }
        </div>
      }
      {this.state.add_event ? <NewEvent show={this.state.add_event} values={{start: this.state.range.start, end: this.state.range.end}} save={(service_id, start, end) => this.save(service_id, start, end)} onHide={() => this.setState({add_event: false})}/>
      : null}

      <BlockOff onHide={() => this.setState({bookVacation: false})} show={this.state.bookVacation} save={(start, end) => this.bookVacation(start, end)}  timezone={this.state.timezone} confirmText={"Block Off"} title={"Block off your vacation time"} />

      {!!this.state.editVacation ? <BlockOff start_time={this.state.editVacation.start_time} end_time={this.state.editVacation.end_time} onHide={() => this.setState({editVacation: false})} show={!!this.state.editVacation} save={(start, end) => this.editVacation(start, end)} tertiaryAction={() => this.deleteVacation()}  timezone={this.state.timezone} confirmText={"Save"} title={"Edit or Delete Vacation"} />
      : null
      }

      </div>

    )
  }
}

export default withRouter(Events)
