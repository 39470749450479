import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Static from '../StaticPages/StaticWrapper'
import '../StaticPages/EventHost.css'
import './Dashboard.css'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import Title from '../Title'
import request from '../../request'
import Alert from '../Utils/Alert'
import Image from '../Utils/SpecialImage'
import SurveyFlow from '../Utils/SurveyFlow'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Checkmark from '../../img/CheckmarkCheck.svg'
import CheckIcon from '../../img/CheckmarkCircle.svg'
import {Dropdown, MenuItem} from 'react-bootstrap'
import './VendorApplication.css'
import PlacesAutocomplete from 'react-places-autocomplete'
import _ from 'underscore'
import Selector from '../Utils/Selector'
import Workhours from './Workhours'
import { getCurrentRegion, regionsToNodes, getLocationNames, getCurrentRegionWithZipcode } from '../Utils/Locations'
import TimezonePicker from '../Utils/TimezonePicker'

// var workinghours = {
//     0: null,
//     1: [['09:30:00', '17:00:00']],
//     2: [['09:30:00', '17:00:00']],
//     3: [['09:30:00', '17:00:00']],
//     4: [['09:30:00', '17:00:00']],
//     5: [['09:30:00', '17:00:00']],
//     6: null
// }


async function getLocation(){
  var res = await request("https://ipinfo.io/json")
  return res
}
//
// function getCurrentRegion(locations, regions){
//   var expanded = []
//   for (var r of regions){
//     for(var s of r.sub_regions){
//       for(var l of s.locations){
//         if(locations.includes(l.id)){
//           expanded.push(String(r.id + "r"))
//           expanded.push(String(s.id + "sub"))
//         }
//       }
//     }
//
//   }
//   return expanded
// }
//
// function regionsToNodes(regions){
//   const nodes = []
//   for(var r of regions){
//     var n = {value: String(r.id) + "r", label: r.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
//     for(var s of r.sub_regions){
//       var p = {value: String(s.id) + "sub", label: s.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
//       for (var l of s.locations){
//         p.children.push({value: String(l.id), label: l.name, icon: <img src={Checkmark} className="checkmark" />})
//       }
//       p.children = _(p.children).sortBy("label")
//       n.children.push(p)
//     }
//     nodes.push(n)
//   }
//   return nodes
// }
//
//
// function getLocationNames(regions, ids){
//   const nodes = []
//   for(var r of regions){
//     for(var s of r.children){
//       for (var l of s.children){
//         if(ids.includes(l.value)) nodes.push(l.label)
//       }
//     }
//   }
//   return nodes
// }


class VendorApplication extends React.Component{

  constructor(props){
    super(props)
    var s = JSON.parse(localStorage.getItem("vendor_application"))
    // console.log("add serice constructor",s)

    if(s){
      s.regions =  []
      if(!s.workinghours){
        s.workinghours = {
          0: [['00:00:00', '24:00:00']],
          1: [['00:00:00', '24:00:00']],
          2: [['00:00:00', '24:00:00']],
          3: [['00:00:00', '24:00:00']],
          4: [['00:00:00', '24:00:00']],
          5: [['00:00:00', '24:00:00']],
          6: [['00:00:00', '24:00:00']]
        }
      }
      for(var i in s.workinghours){
        if(!s.workinghours[i]){
          s.workinghours[i] = [['00:00:00', '24:00:00']]
        }
      }
      if(!s.workingdays){
        s.workingdays = [0,1,2,3,4,5,6]
      }
      this.state = s
    }else{
      this.state = {
        name: props.login.name || "",
        index: props.match.params.index || 0,
        regions: [],
        expanded: [],
        locations: [],
        timezone: moment.tz.guess(),
        company_name: "",
        company_address: "",
        phone: "",
        email: props.login.email || "",
        four_hour_window: "",
        short_description: "",
        photos: "",
        social_media: "",
        website: "",
        questions: "",
        payment: {name: "Direct Transfer", value: "direct"},
        services: "",
        source: "",
        contact: "email",
        workingdays: [0,1,2,3,4,5,6],
        workinghours: {
            0: [['00:00:00', '24:00:00']],
            1: [['00:00:00', '24:00:00']],
            2: [['00:00:00', '24:00:00']],
            3: [['00:00:00', '24:00:00']],
            4: [['00:00:00', '24:00:00']],
            5: [['00:00:00', '24:00:00']],
            6: [['00:00:00', '24:00:00']]
        }
      }
    }
  }

  working(t, d){
    if(t){
      this.changeState({workingdays: [...this.state.workingdays, d]})
    }else{
      var clone = JSON.parse(JSON.stringify(this.state.workingdays))
      var index = clone.indexOf(d);
      if (index !== -1) clone.splice(index, 1);
      this.changeState({workingdays: clone})
    }
  }

  changeTime(d, r, i, value){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    console.log(d, r, i, value)
    clone[d][r][i] = value
    this.changeState({workinghours: clone})
  }

  addRange(d){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    console.log(clone[d], d, clone)
    clone[d].push(['00:00:00', '24:00:00'])
    this.changeState({workinghours: clone})
  }

  removeRange(d){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    clone[d].pop()
    this.changeState({workinghours: clone})
  }

  getWorkinghours(){
   let wh = {}
   for(var i of [0,1,2,3,4,5,6]){
     if(this.state.workingdays.includes(i)){
       wh[i] = this.state.workinghours[i]
     }else{
       wh[i] = []
     }
   }
   return wh
  }

  async componentDidMount(){
    var regions = await request('/api/regions')
    var location = await getLocation()
    var expanded = getCurrentRegionWithZipcode(location.postal, regions)
    // var expanded = getCurrentRegion(location.postal, regions)
    console.log(regions)
    regions = regionsToNodes(regions)
    console.log(regions)
    this.changeState({regions, expanded})
  }

  changeState(state){
    this.setState(state, () => localStorage.setItem("vendor_application", JSON.stringify(this.state)))
  }

  sections(index){
    const cssClasses = {
      input: 'address text-field long',
      autocompleteContainer: 'my-autocomplete-container'
    }

    switch(index){
      case 1:
        return (
          <div className="application locations-section">
            <Title title="Select what areas you service" />
            <CheckboxTree
                nodes={this.state.regions}
                checked={this.state.locations}
                expanded={this.state.expanded}
                onCheck={locations => this.changeState({ locations })}
                onExpand={expanded => this.changeState({ expanded })}
            />
          </div>
        )
      case 0:
        return (
          <div className="application">
            <div>
              <Title title="Apply Now to be a 1up Vendor"  />
            </div>
            <div className="group" >
              <div className="question">Your Full Name:*</div>
              <input className="text-field" value={this.state.name} onChange={(e) => this.changeState({name: e.target.value})} />
            </div>
            <div className="group" >
              <div className="question">Company (if applicable):</div>
              <input className="text-field" value={this.state.company_name} onChange={(e) => this.changeState({company_name: e.target.value})} />
            </div>
            <div className="group" >
              <div className="question">Email:*</div>
              <input type="email" className="text-field" value={this.state.email} onChange={(e) => this.changeState({email: e.target.value})}/>
            </div>
            <div className="group" >
              <div className="question">Phone:*</div>
              <input type="tel" className="text-field" value={this.state.phone} onChange={(e) => this.changeState({phone: e.target.value})} />
            </div>
            <div className="question">Website:</div>
            <input type="text" className="text-field long" value={this.state.website} onChange={(e) => this.changeState({website: e.target.value})}/>
            <div className="question">Address:</div>
            <PlacesAutocomplete classNames={cssClasses} inputProps={{id: "address", value: this.state.company_address, onChange: (company_address) => this.changeState({company_address}), placeholder: "E.g. 123 Main St, San Francisco" }}   />
            <div className="question">Preferred Timezone:</div>
            <TimezonePicker className={"left-drop"} style={{marginTop: 10}} value={this.state.timezone} update={(timezone) => this.changeState({timezone})} />
            <div className="question">{"What's the best way to contact you?*"}</div>
            <div className="sort-box left-drop" style={{display: "block", float: "none", marginBottom: 20}}>
              <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" className="source-dropdown">
                <Dropdown.Toggle noCaret >
                  <div className="text-field" style={{display: "block", width: 200, height: 30, float: "none"}}>
                    <span style={{textTransform: "capitalize"}}>{this.state.contact}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                  <MenuItem eventKey="1" active={this.state.contact === "email"}  onSelect={() => this.changeState({contact: "email" })}>Email</MenuItem>
                  <MenuItem eventKey="2" active={this.state.contact === "text"} onSelect={() => this.changeState({contact: "text" })}>Text</MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="question">Please briefly describe the service(s) you provide and your pricing*:</div>
            <div className="subtext"><i>{"We'll be asking for more detailed information after accepting your application"}</i></div>
            <textarea className="text-field" value={this.state.services} onChange={(e) => this.changeState({services: e.target.value})} />
            <div className="question">Would you be able to confirm orders by email within four business hours?*</div>
            <div className="four-hour">
              <div className="opt" >
                <input type="radio" id={"yes"} value={"yes"} name={"yes"} onChange={(e) => this.changeState({four_hour_window: e.currentTarget.value})} checked={this.state.four_hour_window === "yes"} />
                <label htmlFor={"yes"}>Yes</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"no"} value={"no"} name={"no"} onChange={(e) => this.changeState({four_hour_window: e.currentTarget.value})} checked={this.state.four_hour_window === "no"} />
                <label htmlFor={"no"}>No</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"maybe"} value={"maybe"} name={"maybe"} onChange={(e) => this.changeState({four_hour_window: e.currentTarget.value})} checked={this.state.four_hour_window === "maybe"} />
                <label htmlFor={"maybe"}>Maybe</label>
              </div>
            </div>
            <div className="question">How did you hear about us?*</div>
            <div className="sort-box left-drop" style={{display: "block", float: "none", marginBottom: 20}}>
              <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" className="source-dropdown">
                <Dropdown.Toggle noCaret >
                  <div className="text-field" style={{display: "block", width: 200, height: 30, float: "none"}}>
                    <span>{this.state.source}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                  <MenuItem eventKey="1" active={this.state.source === "Google"}  onSelect={() => this.changeState({source: "Google" })}>Google</MenuItem>
                  <MenuItem eventKey="2" active={this.state.source === "Yelp"} onSelect={() => this.changeState({source: "Yelp" })}>Yelp</MenuItem>
                  <MenuItem eventKey="3" active={this.state.source === "Facebook"} onSelect={() => this.changeState({source: "Facebook" })}>Facebook</MenuItem>
                  <MenuItem eventKey="4" active={this.state.source === "Email"} onSelect={() => this.changeState({source: "Email" })}>Email</MenuItem>
                  <MenuItem eventKey="5" active={this.state.source === "Other"} onSelect={() => this.changeState({source: "Other" })}>Other</MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {this.state.source === "Other" ?
              <div>
                <div className="question">Other</div>
                <input className="text-field" style={{width: 622, marginBottom: 20}} type="text" value={this.state.referral} onChange={(e)=> this.changeState({referral: e.target.value})}/>
              </div>
              : null
            }
          </div>
        )
      case 2:

        return (
          <div className="application">
            <Title title="Further details" />
            <div className="question">When would you like to offer your services for events?*</div>
            <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Customize your start and end times, or add multiple time-blocks.</div>
            <Workhours style={{marginBottom: 20}} workhours={this.state.workinghours} workingdays={this.state.workingdays} changeTime={this.changeTime.bind(this)} working={this.working.bind(this)} addRange={(d) => this.addRange(d)} removeRange={(d) => this.removeRange(d)}/>
            <div className="question">What is the maximum number events you can serve at one time?</div>
            <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Leave blank if there is no limit.</div>
            <input type="number" style={{marginBottom: 30}} className="text-field" value={this.state.max_events} onChange={(e) => this.changeState({max_events: e.target.value})}/>
            <div className="question">Please provide a short description we can use for promotional materials:*</div>
            <textarea className="text-field" value={this.state.short_description} onChange={(e) => this.changeState({short_description: e.target.value})} />
            <div className="question">Please provide links to photos we can use for promotional materials:</div>
            <textarea className="text-field" value={this.state.photos} onChange={(e) => this.changeState({photos: e.target.value})} />
            <div className="question">Please provide links to reviews from past customers (yelp, social media, etc.):</div>
            <textarea className="text-field" value={this.state.social_media} onChange={(e) => this.changeState({social_media: e.target.value})} />
            <div className="question">How would you like to be paid?*</div>
            <div>
            <Selector className={"left-drop"} options={[{name: "Credit Card", value: "cc"}, {name: "Direct Transfer", value: "direct"}]} value={this.state.payment} update={(payment) => this.changeState({payment})} />
            </div>
            <div className="question">Do you have any questions for us?</div>
            <textarea className="text-field" value={this.state.questions} onChange={(e) => this.changeState({questions: e.target.value})} />
          </div>
        )
      default:
        return (
          <div className="finished">
            <img src={CheckIcon} className="finished-check" alt=""/>
            <div className="title">We’ve received your application!</div>
            <div className="text">We’ll review your application within 2-3 business days and get in touch. Thanks so much for applying!</div>
          </div>
        )
    }
  }

  async submit(){
    var copy = Object.assign({}, this.state)
    copy.payment = copy.payment.value
    copy.location_names = getLocationNames(this.state.regions, this.state.locations)
    copy.workinghours = this.getWorkinghours()
    console.log(copy.location_names.join(", "))
    var response = await request('/api/vendor/create', {method: "POST", body: JSON.stringify(copy)})
    if(response.err){
      console.log(response)
    }else{
      localStorage.setItem("vendor_application", null)
      this.setIndex(Number(this.props.match.params.index) + 1)
    }
  }

  canProgress(index){
    switch(index){
      case 0:
        return !!this.state.name && !!this.state.email && !!this.state.phone && !!this.state.services && !!this.state.four_hour_window && !!this.state.source
      case 1:
        return this.state.locations.length > 0
      case 2:
        return !!this.state.payment && !!this.state.short_description
      default:
        return true
    }
  }

  setIndex(index){
    // this.changeState({index})
    this.props.history.push("/vendor/application/" + index)
  }

  render(){
    var v = {company_name: "1up Events"}
    var i = Number(this.props.match.params.index || 0)
    return (
      <Static className={'px-4'}>
        <div className="static-page-header max-w-full">Vendor Application</div>
        <div className='max-w-full mx-auto mb-20 '>
        <SurveyFlow setIndex={(index) => this.setIndex(index)} canProgress={this.canProgress(i)} length={3} index={i} submit={() => this.submit()} next={() => this.setIndex(i + 1)} back={() => this.setIndex(i - 1)} >
          {this.sections(i)}
        </SurveyFlow>
        </div>
      </Static>
    )
  }

}

const mapStateToProps = state => ({
  login: state.login
})


export default withRouter(connect(
  mapStateToProps
)(VendorApplication))
