import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import BookBar from '../Header/IndependentBookBar'
import moment from 'moment'

class SetBookingDetails extends Component {

  constructor(props){
    super(props)
    this.state = {
      date: props.values.date ? moment(props.values.date) : "",
      location: props.values.location || "",
      people: props.values.num_people || "",
      duration: props.values.duration || "",
      zipcode: props.values.zipcode ||""
    }
  }

  async save(){
    this.props.onSave(this.state)

  }

  render(){
    // var confirm = this.state.name === "" ?
    // <div className="custom-button disabled" style={{float: "right", marginLeft: 9}}>Save</div>
    // : <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={() => this.create(this.state.name)}>Save</div>

    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="customize-modal booking-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Add event information to this list.</div>
          <div className="subtitle">Add event information for you and others to see pricing and easily book.</div>
          <BookBar values={this.state} changeZipcode={(zipcode) => this.setState({zipcode})} changeVenue={(location) => this.setState({location})} changeDuration={(duration) => this.setState({duration})} changeDate={(date) => this.setState({date})} changePeople={(people) => this.setState({people})}/>
          <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={() => this.save()}>Save</div>
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SetBookingDetails))
// export default CreateWishlist
