import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import Static from '../StaticPages/StaticWrapper'
import Title from '../Title'
import CheckboxControl from '../Utils/CheckboxControl'
import ItemList from '../Utils/ItemList'
import WarningIcon from '../../img/WarningIconWhite.svg'
import { SingleDatePicker } from 'react-dates';
import request from '../../request'
import currencyFormatter from 'currency-formatter'
import { getComponentPrice, getPackagePrice, finalTotal, getCouponValue, getTax, getLateFee, getBookedTax, getBookedPrice } from '../Utils/math'
import './EventView.css'
import Chevron from '../../img/Chevron.svg'
import moment from 'moment-timezone'
import TimePicker from 'rc-time-picker';
import Duration from '../../img/BookBarDuration.svg'
import Date from '../../img/BookBarDate.svg'
import Location from '../../img/BookBarLocation.svg'
import People from '../../img/BookBarPeople.svg'
// import Track from '../Utils/Analytics'
import PlacesAutocomplete from 'react-places-autocomplete'
import Alert from '../Utils/Alert'
import { BeatLoader } from 'react-spinners';
import ItemAdder from './ItemAdder'
import ConfirmationModal from '../Utils/ConfirmationModal'
import RenameWishlist from '../Utils/RenameWishlist'
import SetBookingDetails from '../Utils/SetBookingDetails'
import Share from '../Utils/UpdateShare'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _ from 'underscore'
import BookingModal from '../Utils/BookingModal'
import { duration } from 'moment'

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}

function getLocationText(e, venue){
  if(e.location === "find"){
    return <span>Find Venue (+$500)</span>
  }
  return <span>Your venue </span>
}

function getOldVersion(cart, id){
  for(var cat in cart){
    for (var c of cart[cat]){
      if(Number(c.id) === Number(id)){
        return cat
      }
    }
  }
}

function getLocations(all_locations, zipcode){
  var locs = []
  if(all_locations){
    for(var l of all_locations){
      if(l.zipcodes.includes(zipcode)){
        locs.push(l)
      }
    }
  }
  return locs
}

function mergeDateAndTime(dateObj, timeObj) {
  // Validate inputs are moment objects
  if (!moment.isMoment(dateObj) || !moment.isMoment(timeObj)) {
      throw new Error('Both inputs must be moment objects');
  }

  // Create a new moment object from the date
  const result = moment(dateObj);

  // Set the time components from the time object
  result.hours(timeObj.hours());
  result.minutes(timeObj.minutes());
  result.seconds(timeObj.seconds());
  result.milliseconds(timeObj.milliseconds());

  return result;
}

class Wishlist extends Component {

  constructor(props){
    super(props)
    console.log("wishlist", props)
    this.state = {
      list_name: "",
      change_name: false,
      name: "",
      new_name: "",
      email: "",
      new_email: "",
      company_name: "",
      new_company_name: "",
      address: "",
      start_time: moment(),
      time_open: false,
      coi_req: "",
      phone: "",
      new_phone: "",
      disabled: false,
      coupon: "",
      invalid_coupon: "",
      coupon_details: null,
      error: "",
      change_contact_info: false,
      showAlert: false,
      addItems: false,
      wishlist: null,
      added: [],
      removed: [],
      edited: false,
      cancel: false,
      decrease: false,
      reschedule: false,
      new_date: null,
      focused: false,
      unavailable: false,
      notify: false,
      pack_unavailable: [],
      booking: false,
      merge: null,
      too_many: false,
      share: "dont",
      url: null,
      duration: null,
      location: null,
      people: null,
      zipcode: "",
      date: null,
    }
  }

  async componentDidMount(){
    console.log("wishlist mounted")
    this.getWishlist()
  }

  async getWishlist(){
    const { zipcode } =  this.props.store
    var wishlist = await request(`/api/wishlists/${this.props.match.params.id}/${zipcode}` )
    if(wishlist.err){
      console.log("wishlist", wishlist)
    }else{
      console.log("wishlist", wishlist)

      wishlist.components =  _(wishlist.components).sortBy((p) => p.name )
      wishlist.packages =  _(wishlist.packages).sortBy((p) => p.name )
      this.setState({wishlist, wishlist_name: wishlist.event.name, edited: false, share: wishlist.event.share ? "share" : "dont", url: wishlist.event.url, new_date: wishlist.event.date, date: wishlist.event.date, location: wishlist.event.location, people: wishlist.event.num_people, duration: wishlist.event.duration, zipcode: wishlist.event.zipcode || "", start_time: moment(wishlist.event.date)})
    }
  }

  changeDetails(detail, value){
    this.setState({[detail]: value})
  }

  // async saveWishlistEventDetails(state){
  //   console.log("saveWishlistEventDetails", state)
  //   var wishlist = await request('/api/wishlist/booking', {method: "PUT", body: JSON.stringify({date: state.date, location: state.location, people: state.people, duration: state.duration, zipcode: state.zipcode, list_id: this.state.wishlist.event.list_id}) })
  //   if(wishlist.err){
  //     console.log(wishlist.err)
  //   }
  //   this.setState({booking: false})
  //   this.getWishlist()
  // }

  async saveWishlistEventDetails(){
    // console.log("saveWishlistEventDetails", state)
    var wishlist = await request('/api/wishlist/booking', {method: "PUT", body: JSON.stringify({date: mergeDateAndTime(moment(this.state.date || moment()), moment(this.state.start_time)), location: this.state.location, people: this.state.people, duration: this.state.duration, zipcode: this.state.zipcode, list_id: this.state.wishlist.event.list_id}) })
    if(wishlist.err){
      console.log(wishlist.err)
    }
    this.setState({booking: false})
    this.getWishlist()
  }

  getPrice(e){
    var price = 0
    var late_fee = getLateFee(moment(), moment(this.state.new_date))
    console.log("getPrice",late_fee)
    for (var c of e.components){
      console.log(c)
      price += getComponentPrice(c, {duration: e.event.duration, people: e.event.num_people}, null, true, null, late_fee)
      console.log(price, late_fee)
    }

    for (var comp of e.packages){
      console.log(comp)
      price += getPackagePrice(comp.component_details, {duration: e.event.duration, people: e.event.num_people}, comp.discount, comp.setup_time, comp.takedown_time, late_fee)
    }
    // price += Number(e.package ? e.package.calculated_price: 0)
    return price
  }

  async deleteList(list_id){
    var list = await request('/api/wishlists', {method: "DELETE" , body: JSON.stringify({list_id}) })
    this.props.actions.deleteWishlist(list_id)
    this.props.history.push("/wishlists")
  }

  changeState(obj){
    obj.edited = true
    this.setState(obj)
  }

  changeNotes(value, component, cat){
    let clone = cloneObj(this.state.wishlist)
    if(cat === "packages"){
      c = clone.packages[component.index]
      if(c.change !== "add"){
        c.change = "update"
      }
      c.notes = value
    }else{
      var c  = clone.components[component.index]
      if(c.change !== "add"){
        c.change = "update"
      }
      c.notes =  value
    }
    this.setState({wishlist: clone, edited: true})
  }

  async saveChanges(){
    var result = await request('/api/wishlist/contents', {method: "PUT", body: JSON.stringify({components: this.state.wishlist.components, packages: this.state.wishlist.packages, list_id: this.state.wishlist.event.list_id})})
    if (result && !result.err){
      await this.getWishlist()
      // this.cancelChanges()
    }else{
      console.log(result)
      this.setState({error: "There was an issue saving your changes. Please try again or contact us.", showAlert: true})
    }
  }



  editComponent(comp){
    console.log(comp, comp.index)
    let clone = cloneObj(this.state.wishlist)
    var index = comp.index
    var c  = clone.components[index]
    if(c.change !== "add"){
      comp.change = "update"
    }
    console.log(JSON.stringify(clone.components.map(c => {return {name: c.name, index: c.index, id: c.id}})))
    clone.components.splice(index, 1, comp);
    console.log(clone.components)
    this.setState({wishlist: clone, edited: true})
  }

  async remove(comp){
    var res;
    var clone = cloneObj(this.state.wishlist)
    if(comp.wishlist_component_id){
      res = await request("/api/wishlist/component", {method: "DELETE", body: JSON.stringify({wishlist_component_id: comp.wishlist_component_id, list_id: this.state.wishlist.event.list_id})})
      clone.components  = clone.components.filter(c => c.wishlist_component_id !== comp.wishlist_component_id)
      this.props.actions.removeComponentFromWishlist({wishlist_component_id: comp.wishlist_component_id, wishlist_id: this.state.wishlist.event.list_id})

    }else{
      res = await request("/api/wishlist/package", {method: "DELETE", body: JSON.stringify({wishlist_package_id: comp.wishlist_package_id, list_id: this.state.wishlist.event.list_id})})
      clone.packages = clone.packages.filter(c => c.wishlist_package_id !== comp.wishlist_package_id)
      this.props.actions.removePackageFromWishlist({wishlist_package_id: comp.wishlist_package_id, wishlist_id: this.state.wishlist.event.list_id})
    }
    if(res.err){
      this.setState({error: "Could not delete"})
    }else{
      this.setState({wishlist: clone})
    }
  }

  cancelChanges(){
    var state = {
      edited: false
    }
    this.setState(state)
  }

  displayFormat(){
    return "ddd, MMM D, YYYY"
  }

  blockOut(date){
    if(this.props.login.admin) return false
    return date < moment().add(3, 'days').subtract(5, "hours")
  }

  isOutsideRange(date){
    if(this.props.login.admin) return false
    return date < moment()
  }

  changeDate(date){
    // this.getComponentsWithAvailability(date)
    this.setState({new_date: date})
  }

  componentWillUpdate(nextProps, nextState){
    //console.log("WillUpdate", nextState.event.components, JSON.stringify(nextState.event.components[0]), nextState.event.components[0].is_available )
  }

  async getComponentsWithAvailability(zipcode, start, end){

    //TODO: fix for new availabilty
    // if(date.isSame(this.state.wishlist.event.start_time, "day")){
    //   let clone =  cloneObj(this.state.wishlist)
    //   if(clone.package){
    //     clone.package.is_available = true
    //   }
    //   for(var cc in clone.components){
    //     cc.is_available = true
    //   }
    //   this.setState({event: clone, notify: false, unavailable: false})
    //   return
    // }
    // var unavailable = false
    // var notify = false
    // var ids = []
    // if(this.state.wishlist.package){
    //     ids = ids.concat(this.state.wishlist.package.components.map(c => Number(c.id)))
    // }
    // for (var ec of this.state.wishlist.components){
    //   ids = ids.concat(ec.id)
    // }
    // let result = await request("/api/availability/components", {method: "POST", body: JSON.stringify({date, ids})})
    // if(!result || result.err){
    //   return
    // }
    // console.log("results", result)
    // var s = new Set(result.map(c => c.id ))
    // let clone =  cloneObj(this.state.wishlist)
    // let pack_unavailable = []
    // if(clone.package){
    //   let pack_ids = clone.package.components.map(c => Number(c.id))
    //   let avail =  true
    //   for (let c of pack_ids){
    //     if(!s.has(c)){
    //       clone.package.is_available = false
    //       avail = false
    //       unavailable = true
    //       notify =  true
    //       console.log("pack details", clone, clone.package, clone.package.component_details.find( p => Number(p.id) === Number(c)), clone.package.components, c)
    //       pack_unavailable.push(clone.package.component_details.find( p => Number(p.id) === Number(c)))
    //       console.log(pack_unavailable)
    //     }
    //   }
    //   if(avail || clone.package.canceled){
    //     clone.package.is_available = true
    //     unavailable = false
    //     notify =  false
    //   }
    // }
    // for (var c of clone.components){
    //   // eslint-disable-next-line
    //   var updated = result.find(u => u.id === c.id)
    //
    //   c.remaining = updated ? updated.remaining : null
    //   if((!s.has(c.id) || (updated.remaining !== null && Number(c.num_staff) > Number(updated.remaining))) && !c.canceled ){
    //     c.is_available = false
    //     unavailable = true
    //     notify =  true
    //   }else{
    //     c.is_available = true
    //   }
    // }
    // this.setState({event: clone, unavailable, notify, new_date: date, pack_unavailable})
  }

  getAvailability(event){
    console.log("New date",this.state.new_date )
    if(moment(this.state.new_date).isSame(this.state.wishlist.event.start_time, "day")){
      return true
    }
    // if(event.package){
    //   if(!event.package.is_available  && !event.package.canceled) return false
    // }
    for(var c of event.components){
      if(!c.is_available && !c.canceled) return false
    }
    return true
  }

  getText(){
    var comp_count = this.state.wishlist.components.length
    var unavailable = []
    for (var c of this.state.wishlist.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length
    var un, untext;
    if(this.state.pack_unavailable.length > 0){
      if(!this.state.wishlist.package.is_available  && !this.state.wishlist.package.canceled){
        if(unavailable_count === 0){
          if(this.state.pack_unavailable.length > 1){
            un = this.state.pack_unavailable.map(c => c.name)
            untext = un.slice(0, un.length-1).join(", ").trim()
            untext += " and " + un[un.length]
            return "The " + untext + " parts of your package are unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + untext + " will not be at your event."
          }else{
            return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event."
          }
        }else{
          if(this.state.pack_unavailable.length > 1){
            un = this.state.pack_unavailable.map(c => c.name)
            untext = un.slice(0, un.length-1).join(", ").trim()
            untext += " and " + un[un.length-1]
            return "The " + untext + " parts of your package are unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + untext + " will not be at your event. You will need to remove any and all addons that are not available."
          }else{
            return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event. You will need to remove any and all addons that are not available."
          }
        }

      }
    }

    if(comp_count === unavailable_count){
      return "None of the items in your cart are available on the new event date you chose. Please check another event date, keep your current event date, or cancel your current event."
    }else{
      if(unavailable_count > 1){
        un = unavailable.map(c => c.name)
        untext = un.slice(0, un.length-1).join(", ").trim()
        untext += " and " + un[un.length-1]
        return untext + " are unavailable on the new event date you chose. Please either cancel the items, check another event date, or keep your current event date."
      }else if(unavailable_count === 1){
        return unavailable[0].name + " is unavailable on the new event date you chose. Please either cancel the item, check another event date, or keep your current event date."
      }else{
        return "All good"
      }
    }
  }

  getTitle(){
    var comp_count = this.state.wishlist.components.length
    var unavailable = []
    for (var c of this.state.wishlist.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length

    if(this.state.pack_unavailable.length > 0){
      if(unavailable_count > 0){
        return "Part of Package and Some Items Not Available"
      }
      return "Part of Package Not Available"
    }
    if(unavailable_count > 0){
      if(comp_count === unavailable_count){
        return "Items Not Available"
      }
      return "Some Items Not Available"
    }
    return "Item Not Available"
  }

  cartify(packs, components, people){
    var cart = {}
    packs.forEach((c,i) => {
      var clone = cloneObj(c)
      if(clone.is_available === undefined) clone.is_available = true
      clone.index =  i
      cart["packages"] ? cart["packages"].push({id: clone.id, component: clone}) : cart["packages"] = [{id: clone.id, component: clone}]
    })
    components.forEach( (c, i) => {
      var clone = cloneObj(c)
      if(clone.is_available === undefined) clone.is_available = true
      clone.index =  i
      cart[clone.categories[0]] ? cart[clone.categories[0]].push({id: clone.id, component: clone}) : cart[clone.categories[0]] = [{id: clone.id, component: clone}]
    })
    return cart
  }

  rename(name){

    this.setState({wishlist_name: name})
  }

  print(){
    window.print()
  }

  async clearEventInfo(){
    this.saveWishlistEventDetails({date: null, location: null, people: null, duration: null, zipcode: null, list_id: this.state.wishlist.event.list_id})
  }

  addItems(){
    if(this.state.wishlist.packages.length > 1){
      this.setState({too_many: true})
    }else{
      let e = this.state.wishlist.event
      if(e.date) this.props.actions.changeParams("date", e.date)
      if(e.duration) this.props.actions.changeParams("duration", e.duration)
      if(e.num_people) this.props.actions.changeParams("people", e.num_people)
      if(e.location) this.props.actions.changeParams("location", e.location)
      if(e.zipcode) this.props.actions.changeParams("zipcode", e.zipcode)
      if(this.state.wishlist.packages.length === 1){
        this.props.actions.addToCart(this.state.wishlist.packages[0], "package", true)
      }
      for(var component of this.state.wishlist.components){
        var old_category = getOldVersion(this.props.cart, component.id)
        if(old_category){
          this.props.actions.replaceCartItem(component.id, old_category, component)
        }else{
          this.props.actions.addToCart(component, component.categories[0], true)
        }
      }
      this.props.history.push('/events')
    }
  }

  async updateShare(value){
    if(value === "dont"){
      await request('/api/wishlist/privatize', {method: "PUT", body: JSON.stringify({list_id: this.state.wishlist.event.list_id})})
      this.setState({share: value})
    }else{
      var res = await request('/api/wishlist/share', {method: "PUT", body: JSON.stringify({list_id: this.state.wishlist.event.list_id})})
      console.log('Sharing', value, res)
      this.setState({share: value, url: res.url})
    }
  }


  render(){
    if(!this.state.wishlist){
      return null
    }
    var noChange = false
    const cssClasses = {
      input: 'address text-field',
      autocompleteContainer: 'my-autocomplete-container'
    }

    let e = this.state.wishlist.event
    var findVenue = this.state.wishlist.event.location === "find"
    var fee = (findVenue ? 500: 0)
    const zipcode = e.zipcode || this.props.store.zipcode
    const locations = getLocations(this.props.store.all_locations, zipcode)
    const location_ids = locations.map(l => l.id)
    var booking = {
      duration: e.duration,
      people: e.num_people,
      date: this.state.new_date,
      start_time: this.state.new_date,
      zipcode,
      location_ids,
    }
    console.log("booking", booking)
    let end_time = moment(this.state.new_date).add(booking.duration, "hours").tz(e.timezone)
    let total = getBookedPrice(this.state.wishlist, booking)
    // let venueFound = this.state.event.venue && this.state.event.venue.name && e.location
    var admin = this.props.location.pathname.includes("admin_panel")
    var coupon_value = getCouponValue(coupon, total, this.cartify(this.state.wishlist.packages, this.state.wishlist.components, this.state.wishlist.event.num_people), booking)
    let final_total = finalTotal(total, coupon_value, fee, this.state.wishlist.event.credited )
    let tax = this.props.store.tax
    let tax_amount = getBookedTax(this.state.wishlist, booking, tax, coupon, total)
    // let total = this.getPrice(this.state.wishlist)
    var coupon = this.state.wishlist.event.coupon


    // console.log("comps", this.state.wishlist.components, this.state.wishlist.components[0], this.state.wishlist.components[0].is_available )
    let available = this.getAvailability(this.state.wishlist)
    let no_information = !e.date && !e.num_people && !e.location && !e.duration
    console.log(this.state.wishlist)
    return (
      <Static className="events new wide wishlist-view">
        <div className="nav-links"> <Link to="/account" className="link">Account</Link> <img src={Chevron} alt=""/> <Link className="link" to="/wishlists">Wish Lists</Link> <img src={Chevron} alt=""/> </div>
        <div className="static-page-header">{this.state.wishlist_name}</div>
        <div style={{marginRight: "auto", marginLeft: "auto", width: 996, marginBottom: 40}}>
          <button className="secondary-button" style={{float: 'none'}} onClick={() => this.setState({change_name: true})}>Change Name</button>
          <button className="secondary-button" style={{float: 'none', marginLeft: 12}} onClick={() => this.print()}>Print</button>
          <div className="delete-text" onClick={() => this.deleteList(this.state.wishlist.event.list_id)}>Delete List</div>
        </div>
        <div className="section-wrapper">
        <div className="checkout-container upcoming-event">
          <div className="checkout-details" id="to-print">
            { noChange && !admin?
            <div className="card warning">
              <div className="icon p-0 inline-flex items-center justify-center ">
                <img src={WarningIcon} alt="" />
              </div>
              <div className="text-container">
                <div className="text">This event can’t be edited online since it’s less than 3 days away.</div>
                <div className="subtext"><Link className="link" to="/contact-us">Contact us</Link> if you need to change details of this event.</div>
              </div>
            </div>
            : null}
            <Title title={"Event Info & List Sharing"} subtitle={"Here are the high level details for your wish list."} />
            <div className="card section wishlist" >
              {no_information ?
                <div className="booking">
                  <div className="subheader">Event information:</div>
                  <div className="disclaimer-text">Add event information to this list to see pricing.</div>
                  <div onClick={() => this.setState({booking: true})} className="custom-button full">Add Event Information</div>
                </div>
                :
                <div className="booking">
                  <div className="subheader">Event information:</div>
                  <div><img src={Date} alt=""/><span className="text">{e.date ? moment(e.date).format("ddd, MMM D, YYYY") : "Set date"}</span></div>
                  <div><img src={People} alt=""/><span className="text">{e.num_people || "Set"} people</span></div>
                  <div><img src={Location} alt=""/><span className="text">{e.location ? getLocationText(e, findVenue) : "Set location"}</span></div>
                  <div><img src={Duration} alt=""/><span className="text">{e.duration || "Set"} {Number(e.duration) === 1 ? "hour" : "hours"}</span></div>
                  <span onClick={() => this.setState({booking: true})} className="change">Change</span>
                  <span onClick={() => this.clearEventInfo()} style={{marginLeft: 22}} className="change">Clear</span>

                </div>
              }
              <div className="sharing">
                <div className="subheader">List Sharing:</div>
                <Share value={this.state.share} updateShare={(value) => this.updateShare(value)} />
                {this.state.share === "share" && this.state.url ?
                  <div style={{display: "inline-block"}}>
                  <CopyToClipboard text={"1upevents.com/lists/" + this.state.url} onCopy={() => this.setState({copied: true})}>
                    <div className="ref-wrapper">
                      {!this.state.copied ?
                          <span className="copy">Copy</span>
                        : <span className="copy">Copied</span>
                       }
                      <div className="text-field share-link" style={{marginTop: 8, marginBottom: 8}}>1upevents.com/lists/{this.state.url} </div>
                    </div>
                  </CopyToClipboard>
                  <div className="description">Share the above link with others so they can view your list. Only others with the link can see this list.</div>
                  </div>
                  : null}
              </div>
            </div>
            <Title title={"Items"} subtitle={"Edit your wishlist items."} />
            <div className="card section items" style={{paddingTop: 4}}>
              <ItemList setBooking={() => this.setState({booking: true})} booking={booking} added={this.state.wishlist.components} dash={true} noChange={false} cart={this.cartify(this.state.wishlist.packages, this.state.wishlist.components, this.state.wishlist.event.num_people)} edit={(c) => this.editComponent(c)} remove={(c) => this.remove(c)} onChange={(v,c,cat) => this.changeNotes(v,c,cat)} hideStartTime={true} lateFee={true} />
              <Link className="custom-button" to="/events"> Add New Items </Link>
            </div>
            {noChange || !this.state.edited ? null :
            <div className="bottom-order">
              <div style={{ width: 88, marginBottom: 6, marginRight: 12, display: "inline-block", height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} onClick={() => this.cancelChanges()} className="secondary-button" >Cancel</div>
              <button style={{ width: 180}} className={"custom-button" + (this.state.disabled ? " disabled" : "") } onClick={()=> this.state.disabled ? null : this.saveChanges()}>
                Save Changes
                {this.state.disabled ?
                  <div className="loader-wrapper">
                    <div className="loader">
                      <BeatLoader
                        color={'#FBB200'}
                        size={12}
                        loading={this.state.loading}
                      />
                    </div>
                  </div> : null
                }
                </button>
                <span className="cost">Total Cost: </span> <span className="price">{currencyFormatter.format(final_total + tax_amount, { code: 'USD', precision: 0})}</span>
              <div className="disclaimer" style={{textAlign: 'left'}}>We’ll review your event changes and contact you if any additional information is needed.</div>
            </div>
          }
          </div>
          <div className="order">
            <div className={"wrap" + (!this.state.edited || noChange ? " no-button" : "")}>
            {noChange || !this.state.edited ? null :
              <div>
                <div style={{ width: 88, marginBottom: 6, marginRight: 12, display: "inline-block", height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} onClick={() => this.cancelChanges()} className="secondary-button" >Cancel</div>
                <button style={{ width: 180, marginBottom: 6, height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} className={"custom-button" + (this.state.disabled  ? " disabled" : "") } onClick={()=> this.state.disabled ? null : this.saveChanges()}>
                  Save Changes
                  {this.state.disabled ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <BeatLoader
                          color={'#FBB200'}
                          size={12}
                          loading={this.state.loading}
                        />
                      </div>
                    </div> : null
                  }
                </button>
                <div className="disclaimer">We’ll review your event changes and contact you if any additional information is needed.</div>
              </div>
            }
              <div className={"summary" + (!this.state.edited || noChange ? " no-button" : "")}>
                <div className="summary-header">Price Summary</div>
                <div className="lineItem" ><span>Items:</span><span className="price">{currencyFormatter.format(total, { code: 'USD', precision: 0})}</span></div>
                {this.state.coupon_details? <div className="lineItem" ><span>Coupon:</span><span className="price">{currencyFormatter.format(-coupon_value , { code: 'USD', precision: 0})}</span></div> : null}
                {findVenue ? <div className="lineItem" ><span>Venue Finding Fee:</span><span className="price">{currencyFormatter.format(500, { code: 'USD', precision: 0})}</span></div> : null}
                {this.state.wishlist.event.credited > 0 ? <div className="lineItem" ><span>Credit:</span><span className="price">{currencyFormatter.format(-this.state.wishlist.event.credited, { code: 'USD', precision: 0})}</span></div> : null}
                <div className="lineItem"><span>Tax:</span><span className="price">{currencyFormatter.format(tax_amount, { code: 'USD', precision: 0})}</span> </div>
              </div>
              <div className="total">
                <div><span className="cost">Total Wish List Cost:</span> <span className="price">{currencyFormatter.format(final_total + tax_amount, { code: 'USD', precision: 0})}</span></div>
              </div>
              <button className="secondary-button full" style={{marginTop: 20}} onClick={() => this.addItems()} >Add All Items to Cart</button>

            </div>
          </div>

        </div>
        </div>
        <Alert title={() => this.state.error} text="" show={this.state.showAlert} onHide={()=> this.changeState({showAlert: false})} />
        <Alert title={"Decreasing quantity by 25% or more may incure fees."} text="We'll contact if there's an issue." show={this.state.decrease} onHide={()=> this.changeState({decrease: false})} />
        <Alert title={() => this.getTitle()} text={() => this.getText()} show={this.state.notify} onHide={()=> this.setState({notify: false})} buttonText="Got It"/>
        <Alert title={"Too many packages."} text="You can only book one package at a time. Please remove the extra packages." show={this.state.too_many} onHide={()=> this.changeState({too_many: false})} />
        {/* {this.state.booking ? <SetBookingDetails values={this.state.wishlist.event} list_id={this.state.wishlist.event.list_id} onSave={(details) => this.saveWishlistEventDetails(details)} show={this.state.booking} onHide={() => this.setState({booking: false})} /> : null } */}
        <BookingModal callToAction={"Save"} customValues={this.state} list_id={this.state.wishlist.event.list_id} onHide={(details) => this.saveWishlistEventDetails(details)} show={this.state.booking} changeDetails={(detail, val) => this.changeDetails(detail, val)} />
        {this.state.change_name ? <RenameWishlist list_id={this.state.wishlist.event.list_id} show={this.state.change_name} onHide={() => this.setState({change_name: false})} name={this.state.wishlist_name} rename={(name) => this.rename(name)}/> : null}
      </Static>
    )
  }

}


const mapStateToProps = state => ({
  store: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Wishlist))
