import React from 'react'
import Static from './StaticWrapper'
import './Testimonials.css'
import Karaoke from '../../img/HowItWorks/Karaoke.jpg'
import Jungle from '../../img/HowItWorks/JungleParty.jpg'
import MarioCart from '../../img/HowItWorks/MarioCart.jpg'
import Collage from '../../img/HowItWorks/HowItWorksCollage@2x.png'
import Image from '../Utils/SpecialImage'
import Mallory from '../../img/Testimonials/Mallory.png'
import Kirill from '../../img/Testimonials/Kirill.png'
import Tim from '../../img/Testimonials/Tim.png'
import Jeff from '../../img/Testimonials/Jeff.png'
import Laylee from '../../img/Testimonials/Laylee.png'
import Cecily from '../../img/Testimonials/Cecily.png'
import Google from '../../img/EventHost.jpg'


const Testimonials = () => {
  return (
    <Static className="testimonials">
      <div className="static-page-header">Testimonials</div>
      <div className="page-subheader">{"We take pride in our many happy customers! Here are a few."}</div>
      <div className="section-wrapper">
        <div className="section">
          <div className="quote">
            <div className="card">
              <div className="card-contents">
                Working with the 1up team has been spectacular for me personally and our office culture. Their hard work, planning and organization leads to an effortlessly successful event every time no matter the scale. With the 1up team I am truly the office hero - thank you!
              </div>
            </div>
            <div className="picture">
              <img src={Mallory} alt="Mallory"/>
              <div className="name-title">
                <div className="name">Mallory</div>
                <div className="title">Office Manager at Shippo</div>
              </div>
            </div>
          </div>
          <Image src={MarioCart} className="left" caption="Live Mario Kart at Twitch" />
        </div>
        <div className="section">
          <Image src={Jungle} caption="Jungle Party at Segment" className="segment" />
          <div className="quote left">
            <div className="card">
              <div className="card-contents">
                1up organized a Christmas party for our company. everything was flawless and fun. the food was really good, the venue was amazing, and most important it was a really good atmosphere. I’m looking forward to other events by 1up!
              </div>
            </div>
            <div className="picture">
              <img src={Kirill} alt="Kirill"/>
              <div className="name-title">
                <div className="name">Kirill</div>
                <div className="title">Data Scientist at 6sense</div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="quote">
            <div className="card">
              <div className="card-contents">
                The event was fantastic, from the selection of arcade machines to the puns for the drink names. The attention to detail was phenomenal. It really felt like it was custom tailored to our company and culture, which we actually enjoyed, instead of a cookie cutter thing that people reluctantly attend.
              </div>
            </div>
            <div className="picture">
              <img src={Jeff} alt="Jeff"/>
              <div className="name-title">
                <div className="name">Jeff</div>
                <div className="title">CEO at Humble Bundle</div>
              </div>
            </div>
          </div>
          <Image src={Google} className="left" caption="Google's Holiday Party 2017"/>
        </div>
        <div className="section">
          <Image src={Karaoke} caption="Karaoke Night at Life360" />
          <div className="quote left">
            <div className="card">
              <div className="card-contents">
                I loved working with 1up Events. They are an amazing turn-key event planning company. Their attention to detail made the Arcade theme come to life. My team had a blast and won’t stop asking when we are going to do it again.
              </div>
            </div>
            <div className="picture">
              <img src={Laylee} alt="Laylee"/>
              <div className="name-title">
                <div className="name">Laylee</div>
                <div className="title">Office Manager at Segment</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Static>
  )
}

export default Testimonials


// <div className="section">
//   <Image src={Jungle} caption="Jungle Party at Segment" className="segment" />
//   <div className="quote left">
//     <div className="card">
//       <div className="contents">
//         <div>I wanted to formally thank you for helping make Redbubble's Loved Ones Day such a huge success! Everyone keeps talking about how much fun, memorable, and dynamic it was. We've already decided that we want to mirror this same location and experience next year! </div>
//
//         <p>Your team was absolutely amazing. The level of detail and mindfulness was truly remarkable. Everything was set up, executed, and cleaned up perfectly. Your staff was communicative, flexible, and very helpful. I cannot speak highly enough of the main point person who was on site. She was cheerful, kind, and extremely motivating during the relay races! She got everyone mobilized and participating. It was so much fun!</p>
//       </div>
//     </div>
//     <div className="picture">
//       <img src={Cecily} alt="Cecily"/>
//       <div className="name-title">
//         <div className="name">Cecily</div>
//         <div className="title">Workplace Experience Coordinator at Redbubble</div>
//       </div>
//     </div>
//   </div>
// </div>


// <div className="section">
//   <div className="quote">
//     <div className="card">
//       <div className="contents">
//         Best event to date, hands down!
//       </div>
//     </div>
//     <div className="picture">
//       <img src={Tim} alt="Tim"/>
//       <div className="name-title">
//         <div className="name">Tim</div>
//         <div className="title">CEO at Qadium</div>
//       </div>
//     </div>
//   </div>
// </div>
