import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import * as cartActions from './actions/'
import './App.css';
import Services from './components/Services/Services'
import Landing from './components/MobileLanding'
import Admin from './components/AdminDash/AdminDash'
import Analytics from './components/AdminDash/Analytics'
import EditPackage from './components/AdminDash/EditPackage'
import EditPowerup from './components/AdminDash/EditPowerup'
import NewPowerup from './components/AdminDash/NewPowerup'
import NewPackage from './components/AdminDash/NewPackage'
import NewPromotion from './components/AdminDash/NewPromotion'
import EditPromotion from './components/AdminDash/EditPromotion'
import NewLocation from './components/AdminDash/NewLocation'
import NewRegion from './components/AdminDash/NewRegion'
import NewSubRegion from './components/AdminDash/NewSubRegion'
import EditLocation from './components/AdminDash/EditLocation'
import Promotion from './components/StaticPages/Promotions'
import Upcoming from './components/AdminDash/Upcoming'
import Past from './components/AdminDash/Past'
import Login from './components/login/Login'
import VerifyEmail from './components/login/VerifyEmail'
import CheckEmail from './components/login/CheckEmail'
import moment from 'moment'
import Reset from './components/login/Reset'
import ForgotPassword from './components/login/ForgotPassword'
import NotFound from './components/NotFound'
import PackageBooked from './components/Packages/PackageBooked'
import EventRebooked from './components/Packages/EventRebooked'
import Overview from './components/UserDash/Overview'
import EventView from './components/UserDash/EventView'
import Account from './components/UserDash/Account'
import Checkout from './components/Checkout'
import Booked from './components/StaticPages/Booked'
import PrivacyTerms from './components/StaticPages/PrivacyTerms'
import Contact from './components/StaticPages/ContactUs'
import request from './request'
import HowTo from './components/StaticPages/HowTo'
import Testimonials from './components/StaticPages/Testimonials'
import AboutUs from './components/StaticPages/AboutUs'
import EventHost from './components/StaticPages/EventHost'
import CustomEvent from './components/StaticPages/CustomEvent'
import ServiceMap from './components/StaticPages/ServiceMap'
import Vendor from './components/StaticPages/Vendor'
import Track from './components/Utils/Analytics'
import Alert from './components/Utils/Alert'
import Newsletter from './components/StaticPages/Newsletter'
import ReferredSignedIn from './components/login/ReferredSignedIn'
import CartPage from './components/CartPage'
import Wishlists from './components/UserDash/WishlistList'
import WishlistView from './components/UserDash/Wishlist'
import WishlistPublicView from './components/UserDash/WishlistPublic'
import VendorDash from './components/VendorDash/Dashboard'
import VendorApplication from './components/VendorDash/VendorApplication'
import ServiceResponse from './components/VendorDash/ServiceResponse'
import EditService from './components/VendorDash/EditService'
import Browsing from './components/Browsing';

import FAQ from './components/StaticPages/FAQ'

function getBrowser(){
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  var raw =  ua.match(/Chrom(e|ium)\/([0-9]+)\./);
  // eslint-disable-next-line
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
    return "ie"
  }else{
    if(raw){
      var version = parseInt(raw[2], 10)
      if (version < 50){
        return "chrome"
      }
    }else{
      let v = (ua.includes("Safari") ? ua.match(/Version\/([0-9]+)\./) : null)
      if(v && parseInt(v[1], 10) < 6){
        return "safari"
      }
    }
  }
  return null;
}

function PrivateRoute ({component: Component, exact, authed, path, ...rest}) {
  console.log("Path", path)
  return (
    <Route exact={exact || false} path={path}
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/signin', state: {from: props.location.pathname}}} />}
    />
  )
}

function toTitleCase(str)
{
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

class App extends React.Component {

  constructor(props){
    super(props)
    var first =  !localStorage.getItem("not_first")
    this.state ={
      notSupported: getBrowser(),
      checked: false,
      first,
    }
  }

  async componentDidMount(){
    this.props.actions.setSession(true)
    this.setTitle(this.props)
    Track.pageview(this.props.location.pathname)
    if(moment(this.props.store.booking.date) < moment()){
      this.props.actions.changeParams("date", null)
    }
    if(this.props.location.pathname.includes("verify")){
      this.setState({checked: true})
      return
    }
    var login = await request('/api/verify/', {method: "POST"})
    this.setState({checked: true})
    console.log("login", login)
    if(!login || login.err){
      this.props.actions.setLogin({loggedIn: false, admin: false})
    }else{
      this.props.actions.setLogin({loggedIn: true, admin: login.is_admin, email: login.email, wishlists: login.wishlists, vendor_id: login.vendor_id, name: login.name})
      this.setEvents()
    }
    var locations = await request('/api/locations')
    if(!locations.err){
      this.props.actions.changeParams("all_locations", locations)
    }
  }

  async setEvents(){
    var upcoming = await request('/api/events/upcoming')
    if(upcoming.err){
      upcoming = []
    }
    this.props.actions.setEvents(upcoming)
  }

  getAlertText(){
    switch(this.state.notSupported){
      case 'chrome':
        return "Please update to a newer version of Chrome or give us a call at (415) 915-5184 if you'd like to discuss options or book an event. Thanks, and apologies for the inconvenience."
      case 'ie':
        return "If possible, please use Microsoft Edge or Google Chrome. If that's not possible, feel free to call us at (415) 915-5184 if you'd like to discuss options or book an event. Thanks, and apologies for the inconvenience."
      case 'safari':
        return "Please update to a newer version of Safari or give us a call at (415) 915-5184 if you'd like to discuss options or book an event. Thanks, and apologies for the inconvenience."
      default:
        if(!this.state.notSupported){
          return "supported"
        }
        return "Not supported"
    }
  }

  getAlertTitle(){
    switch(this.state.notSupported){
      case 'chrome':
        return "Unfortunately, this version of Chrome hasn't been updated in a while and is not supported."
      case 'ie':
        return "Unfortunately, Internet Explorer is no longer supported."
      case 'safari':
        return "Unfortunately, this version of Safari hasn't been updated in a while and is not supported."
      default:
        if(!this.state.notSupported){
          return "supported"
        }
        return "Not supported"
    }
  }

  setTitle(nextProps){
    if(nextProps.location.pathname === "/"){
      document.title = "1up Events"
      return
    }
    let string = nextProps.location.pathname.replace("/", "").replace(/-/g, " ");
    string = toTitleCase(string)
    document.title = string + " - 1up Events"
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.pathname !== nextProps.location.pathname && this.props.location.pathname !== "/events"){
      this.setTitle(nextProps)
      Track.pageview(nextProps.location.pathname)
    }
  }

  render(){
    var {store, actions} = this.props
    var powerupsWithProps = (props) => <Services {...props} {...store} {...actions} />
    if(!this.state.checked){
      console.log("Not logged in yet")
      return null
    }
    return (
      <div className="App min-w-[280px]">
        <Browsing />
        <div>
        <Switch >
          <Route exact path="/" component={Landing} />
          <Route exact path="/events" render={() => <Redirect to={{pathname: '/events/food'}} /> } />
          <Route path="/events/:category/:filter?" render={powerupsWithProps} />
          <Route path="/package-booked" component={PackageBooked} />
          <Route path="/event-rebooked" component={EventRebooked} />
          <Route path="/promo/:name" component={Promotion} />

          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/package/:id" component={EditPackage} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/powerup/:id" component={EditPowerup} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-powerup" component={NewPowerup} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-package" component={NewPackage} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-location" component={NewLocation} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-region" component={NewRegion} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-sub-region" component={NewSubRegion} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/upcoming-events" component={Upcoming} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/past-events" component={Past} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-promo" component={NewPromotion} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/promo/:id" component={EditPromotion} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/new-location" component={NewLocation} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/locations/:id" component={EditLocation} />

          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/:user_id/event/:id" component={EventView} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/event/:id" component={EventView} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/service/:id" component={EditService} />

          <PrivateRoute exact authed={store.login.admin} path="/admin_panel" component={Admin} />
          <PrivateRoute exact authed={store.login.admin} path="/admin_panel/analytics" component={Analytics} />

          <Route exact path="/vendors/accept/:token" render={() => <ServiceResponse accept={true} />} />
          <Route exact path="/vendors/decline/:token" render={() => <ServiceResponse decline={true} />}/>
          <PrivateRoute authed={!!store.login.vendor_id} path="/vendors/:tab/:id?/:index?" component={VendorDash} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/your-events/:id" component={EventView} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/your-events" component={Overview} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/wishlists/:id/:name" component={WishlistView} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/wishlists" component={Wishlists} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/account" component={Account} />
          <PrivateRoute exact authed={store.login.loggedIn} path="/checkout" component={Checkout} />
          <Route exact path="/lists/:url" component={WishlistPublicView}  />
          <Route exact path="/booked" component={Booked}  />
          <Route exact path="/signin" component={Login}  />
          <Route exact path="/ref/:id"  render={(props) => store.login.loggedIn ? <ReferredSignedIn /> : <Redirect to={{pathname: '/signin', state: {referral: props.match.params.id} }}/> }/>
          <Route exact path="/verify" component={CheckEmail}/>
          <Route path="/verify/:token/:checkout?/:parameter?" component={VerifyEmail} />
          <Route path="/signin/reset/:token" component={Reset} />
          <Route path="/signin/forgotpassword/" component={ForgotPassword} />
          <Route exact path="/privacy-policy" component={PrivacyTerms}/>
          <Route exact path="/terms-of-use" component={PrivacyTerms}/>
          <Route exact path="/contact-us" component={Contact}/>
          <Route exact path="/vendor" component={Vendor}/>
          <PrivateRoute exact authed={store.login.loggedIn} path="/vendor/application/:index?" component={VendorApplication}/>
          <Route exact path="/how-it-works" component={HowTo}/>
          <Route exact path="/testimonials" component={Testimonials}/>
          <Route exact path="/about-us" component={AboutUs}/>
          <Route exact path="/event-host" component={EventHost}/>
          <Route exact path="/cart" component={CartPage}/>
          <Route exact path="/custom-event" component={CustomEvent}/>
          <Route exact path="/locations" component={ServiceMap}/>
          <Route exact path="/newsletter-signup" component={Newsletter}/>
          <Route exact path="/faq" component={FAQ}/>

          <Route path="/powerups/fullevent.html" render={(props) => <Redirect to='/events/packages' />}/>
          <Route exact path="/powerups/powerup-pairings.html" render={(props) => <Redirect to='/events/packages' />}/>
          <Route exact path="/powerups/index.html" render={(props) => <Redirect to='/events/food' />}/>
          <Route exact path="/powerups/" render={(props) => <Redirect to='/events/food' />}/>
          <Route exact path="/builder/" render={(props) => <Redirect to='/events/food' />}/>
          <Route exact path="/builder/food" render={(props) => <Redirect to='/events/food' />}/>
          <Route exact path="/builder/:comp" render={(props) => <Redirect to='/events/food' />}/>
          <Route path="/builder/:category/:filter?" render={(props) => <Redirect to={props.location.pathname.replace("/builder", "/events")} />}/>
          <Route path="/packages/:category?" render={(props) => <Redirect to={"/events" + props.location.pathname} />}/>
          <Route exact path="/packages" render={(props) => <Redirect to='/events/packages' />}/>
          <Route path="/powerups/single-powerup.html" render={(props) => <Redirect to='/events/food' />}/>
          <Route exact path="/contact" render={(props) => <Redirect to='/contact-us' />}/>

          <Route component={NotFound} />
        </Switch>
        <Alert zIndex={9999} show={this.state.notSupported} onHide={() => this.setState({notSupported: false})} title={()=> this.getAlertTitle()} text={() => this.getAlertText()} buttonText="Got It"/>
        </div>
      </div>
    )
//<PrivateRoute exact authed={store.login.loggedIn} path="/vendors/:tab/:id" component={VendorDash} />

  }

}


const mapStateToProps = state => ({
  store: state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))




// export default App;
