

export default async function(path, params = {}){
  if(!params.headers) params.headers = {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
  if (process.env.SERVER === 'local'){
    params.credentials = 'include'
  } else if(!params.credentials){
    params.credentials = 'same-origin'
  }
  console.log("path", path, params)
  try{
    const result = await fetch(path, params)
    var res = await result.json()
    return res
  }catch(err){
    console.log("request error", err)
    return {err: "Server did not respond."}
  }

}
