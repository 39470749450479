import React from 'react'
import Static from './StaticWrapper'
import './EventHost.css'
import Paid from '../../img/EventHostPaid.svg'
import Fun from '../../img/EventHostFun.svg'
import NoExperience from '../../img/EventHostNoExperience.svg'
import Title from '../Title'
import Alert from '../Utils/Alert'
import Image from '../Utils/SpecialImage'
import EH from '../../img/EventHost.jpg'

async function suchFetch(path, fetchOpts, params){
  var url = new URL(`${path}`)
  if (params != null) Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  var result;
  try{
    result = await fetch(url, fetchOpts)
  }catch(err){
    console.log(err)
  }
  return result
};

class EventHost extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      located: "",
      available: "",
      excited: "",
      submitted: false,
    }

  }

  getMissingText(){
    if(!this.state.name){
      return "Please give us your name"
    }
    if(!this.state.email){
      return "Please give us your email"
    }
    if(!this.state.phone){
      return "Please give us your phone number"
    }
    if(!this.state.available){
      return "Please tell us when you're available"
    }
    if(!this.state.located){
      return "Please tell us where you're located"
    }
    return null
  }

  async submit(){
    var missing = this.getMissingText()
    if(!missing){
      var data = {firstname: this.state.name.split(" ")[0], lastname: this.state.name.split(" ").slice(1).join(" "), email: this.state.email, phone: this.state.phone, eventpref: this.state.excited, location: this.state.located, daysavailable: this.state.available}
      var response  = await suchFetch ('https://script.google.com/a/1upevents.com/macros/s/AKfycbyMaFsOM_-keiW3fiMEWG41bS4nghhnhFA0s1-8_4IH7cooqWHA/exec', {method: "GET"}, data)
      if(response.ok){
        this.setState({
          name: "",
          email: "",
          phone: "",
          located: "",
          available: "",
          excited: "",
          submitted: true,
          missing: null
        })
      }
    }else{
      this.setState({missing})
    }
  }

  render(){

    var missing = this.getMissingText()

    return (
      <Static className="event-host max-w-full lg:mt-[115px] mt-8">
        <div className="static-page-header  w-[1028px] max-w-full px-4 text-wrap md:text-[44px] text-[36px] ">Become an Event Host</div>
        <div className="main-section max-w-full w-[1028px] px-4 mx-auto flex grid gap-x-8 gap-y-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <div className="sect mx-0 max-w-full flex-none mx-auto md:mx-0">
            <img src={Paid} alt="get paid"/>
            <div className="title">Get Paid</div>
            <div className="text">Flexible work schedule as an independent contractor. Average shift is ~4 hrs.</div>
          </div>
          <div className="sect mx-0 max-w-full flex-none mx-auto md:mx-0">
            <img src={NoExperience} alt="no experience needed"/>
            <div className="title">No Experience Needed</div>
            <div className="text">Training is provided along with opportunity for growth.</div>
          </div>
          <div className="sect mx-0 max-w-full flex-none sm:col-span-2 md:col-span-1 sm:max-w-[50%] md:max-w-full mx-auto md:mx-0">
            <img src={Fun} alt="Fun!"/>
            <div className="title">Have Fun!</div>
            <div className="text">We're all smiles here. You'll love being a part of these events!</div>
          </div>
        </div>
        <div className="section-wrapper max-w-full px-4">
          <div className="party max-w-full">
            <div className="long-text  max-w-full pb-10">
              <Title title="Get the Party Started" />
              <div className="text max-w-full mb-2 mr-0 md:mr-10">
                <p>As a 1up Event Host, you are the party liaison for our clients.</p>
                <p>We'll match you up with events based on your location, availablity, and preferences. The day of, you just show up! All of the necessary event materials (e.g. food, drink, activity components), will be either managed by other vendors, or delivered to you at the event location with specific instructions for setup.</p>
                <p>Logistically, you'll manage the setup of the space, potentially moving some furniture around (feng shui, anyone?). You'll also manage vendor arrival and setup and ensure that the event starts on time.</p>
                <p>From there, everything should be on autopilot! Make sure everyone is having a great time! And just enjoy!</p>
                <p>When it's over, clean up and pack up (typical camping "leave no trace" policy). The remaining event materials will be picked up.</p>
                <p>TL;DR Show up. Get the party started. Have fun. Wrap it up. Get paid. Repeat.</p>
              </div>
              <Image className="max-w-full" src={EH} caption="Google's Holiday Party 2017"/>
            </div>
            <div className="requirements mx-auto flex grid gap-x-8 gap-y-6 grid-cols-1 md:grid-cols-3">
              <div className="sect flex-none max-w-full mx-0">
                <div className="section-header">Responsibilities</div>
                <ul className="description">
                  <li>• Show up at the pre-scheduled event.</li>
                  <li>• Setup (including food, drinks, activity) - This may also involve moving some furniture depending on the event.</li>
                  <li>• Manage vendors (arrival and setup)</li>
                  <li>• Host the event! Make sure everything runs smoothly during the event.</li>
                  <li>• Takedown and cleanup - After the event is over, clean up and pack everything</li>
                </ul>
              </div>
              <div className="sect flex-none max-w-full mx-0">
                <div className="section-header">Requirements</div>
                <ul className="description">
                  <li>• Must be 18 or older.</li>
                  <li>• Be available for at least 1 event a week (events usually take about 4 hrs including setup and takedown)</li>
                  <li>• Timeliness - please show up promptly for the shift</li>
                  <li>• Able to travel within the Bay Area</li>
                  <li>• Must like fun and awesome events!</li>
                </ul>
              </div>
              <div className="sect flex-none max-w-full mx-0">
                <div className="section-header">Nice to Have</div>
                <ul className="description">
                  <li>• Event, restaurant, catering, or hospitality experience</li>
                  <li>• Customer service experience</li>
                  <li>• Your own transportation</li>
                  <li>• Passionate about company culture</li>
                </ul>
              </div>
            </div>
            <div className="card my-form max-w-full" style={{padding: 20}}>
              <Title title="Apply Now to Become an Event Host" subtitle="We'll be in touch shortly after you submit." />
              <div className="group" >
                <div className="question">Full Name:</div>
                <input className="text-field" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
              </div>
              <div className="group" >
                <div className="question">Email:</div>
                <input type="email" className="text-field" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
              </div>
              <div className="group" >
                <div className="question">Phone:</div>
                <input type="tel" className="text-field" value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} />
              </div>
              <div className="question">What days are you usually available?</div>
              <textarea className="text-field" value={this.state.available} onChange={(e) => this.setState({available: e.target.value})} />
              <div className="question">Where in the Bay Area are you located? How far are you willing to travel?</div>
              <textarea className="text-field" value={this.state.located} onChange={(e) => this.setState({located: e.target.value})}/>
              <div className="question">What event are you most excited about?</div>
              <textarea className="text-field" value={this.state.excited} onChange={(e) => this.setState({excited: e.target.value})} />
              <div className="custom-button" onClick={()=> this.submit()} > Apply Now </div>
              { this.state.missing && missing ? <div style={{color: "red"}}>{missing}</div> : null}
            </div>
          </div>
        </div>
        <Alert show={this.state.submitted} title="Thank you for your submission!" text="We'll reach out to you as soon as we can." onHide={()=>this.setState({submitted: false})}/>
      </Static>
    )
  }
}

export default EventHost
