import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Static from '../StaticPages/StaticWrapper'
import Title from '../Title'
import ItemList from '../Utils/ItemList'
import CheckmarkCircle from '../../img/CheckmarkCircle.svg'
// import request from '../../request'
import moment from 'moment-timezone'
import currencyFormatter from 'currency-formatter'
import { finalTotal, getCouponValue, getBookedTax, getBookedPrice, getPostTax } from '../Utils/math'
import './EventView.css'
import Chevron from '../../img/Chevron.svg'
import BookAgainIcon from '../../img/BookAgainIconWhite.svg'
import { getTimezoneShortName } from '../Utils/Helpers'
import VendorList from './VendorList'
import request from '../../request'

export default class PastEvent extends Component {

  constructor(props){
    super(props)

    this.state = {
      event: props.event,
      vendors: []
    }
  }

  async componentDidMount(){
    var contact_info;
    if(this.props.location.pathname.includes("admin_panel")){
      contact_info = await request('/api/admin/contactInfo/' + this.props.event.event.user_id)
      var vendors = await request('/api/admin/events/' + this.props.event.event.id + "/vendors")
      if (contact_info.err){
        console.log(contact_info)
      }else{
        this.setState({name: contact_info.contact_name, phone: contact_info.phone || "", email: contact_info.contact_email || contact_info.email, company_name: contact_info.company_name || ""})
      }
      if(vendors.err){
        console.log(vendors)
      }else{
        console.log(vendors)
        this.setState({vendors})
      }
    }
  }

  render(){
    console.log(this.props)
    if(!this.props.event.event){
      return null
    }
    let e = this.state.event.event

    var booking = {duration: e.hours_needed, people: e.num_people, date: e.start_time, start_time: e.start_time, zipcode: e.zipcode, timezone: e.timezone}
    let total = getBookedPrice(this.state.event, booking)
    var coupon = this.state.event.event.coupon
    var fee = (this.state.event.venue ? 500: 0)
    var coupon_value = getCouponValue(coupon, total, this.props.cartify(this.state.event.package, this.state.event.components, this.state.event.event.num_people), booking)
    let start_time = moment.tz(e.start_time, e.timezone)
    let end_time = moment.tz(e.end_time, e.timezone)
    let final_total = finalTotal(total, coupon_value, fee, this.state.event.event.credited)
    let tax = this.state.event.event.tax
    let tax_amount = getBookedTax(this.state.event, booking, tax, coupon, total)
    return (
      <Static className="events wide">
        <div className="nav-links"> <Link to="/account" className="link">Account</Link> <img src={Chevron} alt=""/> <Link className="link" to="/your-events">Your Events</Link> <img src={Chevron} alt=""/></div>
        <div className="static-page-header">{e.canceled ? <span style={{color: "#FF5200", fontSize: 34}}>(Canceled) </span> : null}{start_time.format("ddd, MMM D, YYYY")} from {start_time.format("LT").toLowerCase().replace(" ", "")}-{end_time.format("LT").toLowerCase().replace(" ", "")}{e.canceled ? "": " " + getTimezoneShortName(start_time, e.timezone)}</div>
        {e.canceled ?  <div className="static-page-subheader"> </div>: <div className="static-page-subheader">We hope you enjoyed your event!</div>}
        <div className="section-wrapper">
        <div className="checkout-container past-event">
          <div className=" checkout-details">
            { false ?
              <div>
                <Title title="Post-Event Survey" subtitle="Let us know what we did well, and what we could improve."/>
                <div className="card section" >
                  {true ?
                    <div className="survey-thanks">
                      <img src={CheckmarkCircle} alt=""/>
                      <div className="title"> Thanks for filling out our post-event survey!</div>
                      <div className="text">We’ll use your feedback to improve future events, and will reach out to you if more information is needed.</div>
                    </div>
                    :
                    <div className="survey">

                    </div>
                  }
                </div>
              </div>
                : null
            }
            { this.state.event.event.photos ?
              <div className="photos">
                <Title title="Photos From Your Event" subtitle="All taken from your event."/>
                <div className="card section">
                  <a href={this.state.event.event.photos} className="secondary-button">View & Download Photos</a>
                </div>
              </div>
              : null
            }
            <Title title="Items & Instructions" subtitle="Here's everything your event had included."/>
            <div className="card section items" style={{paddingTop: 4}}>
              <ItemList booking={booking} disableStartTime={true} added={this.state.event.components} dash={true} noChange={true} cart={this.props.cartify(this.state.event.package, this.state.event.components, this.state.event.event.num_people)} booked={true} />
              <div className="rebook">
                <span className="custom-button with-icon" onClick={this.props.rebook} >Add All Items to Cart</span>
                <img className="rebook-icon" src={BookAgainIcon} alt="" />
              </div>
            </div>
            {this.props.login.admin && this.state.vendors.length > 0 ?
              <div>
              <Title title={"Vendors"} />
              <div className="card section items" style={{paddingTop: 4}}>
                <VendorList vendors={this.state.vendors} booking={booking} start_time={e.start_time} timezone={e.timezone}/>
              </div>
              </div>
            : null}
          </div>
          <div className="order">
            <div className="wrap">
              <div className="summary">
                <div className="summary-header">Event Summary</div>
                <div className="lineItem" ><span>Items:</span><span className="price">{currencyFormatter.format(total, { code: 'USD', precision: 0})}</span></div>
                {coupon ? <div className="lineItem" ><span>Coupon:</span><span className="price">{currencyFormatter.format(-coupon_value, { code: 'USD', precision: 0})}</span></div> : null}
                {this.state.event.venue ? <div className="lineItem" ><span>Venue Finding Fee:</span><span className="price">{currencyFormatter.format(500, { code: 'USD', precision: 0})}</span></div> : null}
                {this.state.event.event.credited > 0 ? <div className="lineItem" ><span>Credit:</span><span className="price">{currencyFormatter.format(-this.state.event.event.credited, { code: 'USD', precision: 0})}</span></div> : null}
                <div className="lineItem"><span>Tax:</span><span className="price">{currencyFormatter.format( tax_amount, { code: 'USD', precision: 0})}</span> </div>
              </div>
              <div className="total">
                <div><span className="cost">Total Cost:</span> <span className="price">{currencyFormatter.format( final_total + tax_amount, { code: 'USD', precision: 0})}</span></div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </Static>
    )
  }

}
