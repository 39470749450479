import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/Footer.css'
import Logo from '../../img/1upLargeGrey.svg'

const Footer = () => {
  return (
    <div className="footer p-4 flex items-center">
      <div className="content ">
        <div className="simple flex flex-col justify-center items-center sm:flex-row gap-4">
          <div className='flex items-center'>
            <img src={Logo} className="logo inline-flex mt-[7px]" alt=""/>
            <span className="copyright">{`Copyright © ${new Date().getFullYear()} 1up Events, Inc. All Rights Reserved.`}</span>
          </div>
          <div className="others flex mx-auto sm:mr-0 relative">
            <Link to="/terms-of-use">Privacy & Terms of Use</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
