import React from 'react'
import Static from '../StaticPages/StaticWrapper'
import '../StaticPages/EventHost.css'
import './Dashboard.css'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import Title from '../Title'
import request from '../../request'
import Alert from '../Utils/Alert'
import Image from '../Utils/SpecialImage'
import EH from '../../img/Vendor.jpg'
import {Dropdown, MenuItem} from 'react-bootstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Overview from './Overview'
import Events from './Events'
import Ratings from './Ratings'
import Earnings from './Earnings'
import Services from './Services'
import AddService from './AddService'
import EditService from './EditService'
import EventView from './EventView'
import GoogleCalendar from './GoogleCalendarIntegration'
import Profile from './Profile'

const Tabs = ({active, request_count, onClick, payments}) => {
  return (
    <div className="vendor-tabs">
      <div onClick={() => onClick("dashboard")} className={"dash-tab first" + (active === "dashboard" || !active ? " selected" : "")}>Dashboard {request_count > 0 ? <div className={ "notification-dot" + (active === "dashboard" || !active ? " selected" : "")} ><div className="notification-count">{request_count}</div></div> : null}</div>
      <div onClick={() => onClick("events")} className={"dash-tab" + (active === "events" ? " selected" : "")}>Events</div>
      <div onClick={() => onClick("services")} className={"dash-tab" + (active === "services" ? " selected" : "")}>Services</div>
      <div onClick={() => onClick("profile")} className={"dash-tab" + (active === "profile" ? " selected" : "")}>Profile</div>
      {payments ? <Link to="/api/stripe/transfers" target="_blank" className={"dash-tab"}>Payments</Link> : null}

    </div>
  )
}


// <div onClick={() => onClick("profile")} className={"dash-tab" + (active === "profile" ? " selected" : "")}>Profile</div>
// <div onClick={() => onClick("ratings")} className={"dash-tab" + (active === "ratings" ? " selected" : "")}>Ratings</div>
// <div onClick={() => onClick("earnings")} className={"dash-tab" + (active === "earnings" ? " selected" : "")}>Earnings</div>

class Vendor extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      located: "",
      company: "",
      offer: "",
      submitted: false,
      source: "",
      referral: "",
      credit: 0,
      payments: false,
      requests: [1,2],
      request_count: 0,
      vendor_timezone: "America/New_York",
    }

  }

  async componentDidMount(){
    var result =  await request("/api/vendor/request_count")
    var vendor =  await request("/api/vendor")
    var payments =  !!vendor.vendor.stripe_account_id
    if(result.err){
      console.log("getting count", result)
    }else{
      console.log("got timzone", vendor.vendor.timezone)
      this.setState({request_count: Number(result.count), payments, vendor_timezone: vendor.vendor.timezone})
    }
  }

  async accept(token){
    var result = await request('/api/vendor/accept/' + token, {method: "PUT"})
    if(!result.err){
      this.props.setRequestCount(this.state.requests.length - 1)
    }
  }

  async decline(token){
    var result = await request('/api/vendor/decline/' + token, {method: "PUT"})
  }

  navigate(tab){
    this.props.history.push('/vendors/' + tab)
  }

  setRequestCount(count){
    this.setState({request_count: count})
  }

  renderTab(tab){
    switch(tab){
      case "profile":
        return <Profile />
      case "events":
        return <Events vendor_timezone={this.state.vendor_timezone} />
      case "ratings":
        return <Ratings />
      case "earnings":
        return <Earnings />
      case "services":
        if(this.props.match.params.id === "add"){
          return <AddService />
        }
        if(this.props.match.params.id){
          return <EditService />
        }
        return <Services />
      case "gc":
        return <GoogleCalendar {...this.props} />
      case "event":
        return <EventView vendor_timezone={this.state.vendor_timezone} id={this.props.match.params.id}/>
      default:
        return <Overview setRequestCount={(count) => this.setRequestCount(count)} />
    }
  }

  render(){
    var v = {company_name: "1up Events"}
    return (
      <Static vendor={true} className="wide vendor-dash">
        <div className="static-page-header">{v.company_name}</div>
        <Tabs active={this.props.match.params.tab} request_count={this.state.request_count} onClick={(tab) => this.navigate(tab)} payments={this.state.payments}/>
        <div className="section-wrapper">
          <div className="checkout-container account-page">
            {this.renderTab(this.props.match.params.tab)}
          </div>
        </div>
      </Static>
    )
  }

}

export default withRouter(Vendor)
