import 'rc-time-picker/assets/index.css';

import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../actions/'
import moment from 'moment-timezone'
import TimePicker from 'rc-time-picker';
import Duration from '../img/BookBarDuration.svg'
import Date from '../img/BookBarDate.svg'
import Location from '../img/BookBarLocation.svg'
import People from '../img/BookBarPeople.svg'
import '../css/Checkout.css'
import PlainHeader from './Header/PlainHeader'
import {CardElement, StripeProvider, Elements, injectStripe} from 'react-stripe-elements'
import {getTotal, getPackagePrice, getLateFee} from './Utils/math'
import Title from './Title'
import currencyFormatter from 'currency-formatter'
import Tldr from './Utils/Tldr'
import ItemList from './Utils/ItemList'
import Footer from './Utils/Footer'
import request from '../request'
import CheckboxControl from './Utils/CheckboxControl'
import { BeatLoader } from 'react-spinners';
import Amex from '../img/creditcards/amex.png'
import Visa from '../img/creditcards/visa.png'
import Master from '../img/creditcards/master.png'
import JCB from '../img/creditcards/jcb.png'
import Diners from '../img/creditcards/diners.png'
import Discover from '../img/creditcards/discover.png'
import Credit from '../img/creditcards/credit.png'
import ConfirmationModal from './Utils/ConfirmationModal'
import Alert from './Utils/Alert'
import Terms from './Utils/TermsAndConditions'
import Track from './Utils/Analytics'
import { getComponentPrice, finalTotal, getCouponValue, getTax, getStartTime, getPriceForMultipleOccurrences } from './Utils/math'
import PlacesAutocomplete from 'react-places-autocomplete'
import TimezonePicker from './Utils/TimezonePicker'
import {getTimezoneShortName, cartsContainSameItems, cartToLists } from './Utils/Helpers'
import BookBarModal from './Utils/BookBarModal'
import BookingModal from './Utils/BookingModal'

import ValueSelector from './Utils/ValueSelector'

import pluralize from 'pluralize'

var stripe_key = "pk_live_4IAFeRo1czDhMwEzjo2iH1We";

if (  window.location.hostname.includes("1upevents")){
  console.log("production")
}else{
  stripe_key = "pk_test_UXa8gJrBB1hXxScBLHVyTGSm"
  console.log(window.location.hostname, "pk_test_UXa8gJrBB1hXxScBLHVyTGSm")
}

// async function hasAccount(email){
//   var params = { method: 'POST',
//                 headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({email})
//             };
//   const result = await fetch('api/hasAccount', params)
//   var res = await result.json()
//   if(res.err){
//     console.log("Server error", res.err)
//     return false
//   }
//   return res.exists
// }

function getWhichWeek(date){
  var day_of_month = moment(date).date()
  var week = Math.ceil(day_of_month/7)
  switch(week){
    case 1:
      return "first"
    case 2:
      return "second"
    case 3:
      return "third"
    case 4:
      return "fourth"
    default:
      return "last"
  }
}

async function suchFetch(path, fetchOpts, params){
  var url = new URL(`${path}`)
  if (params != null) Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  var result;
  try{
    result = await fetch(url, fetchOpts)
  }catch(err){
    console.log(err)
  }
  return result
};

function getEventDescription(pack, components){
  if(pack && (!components || components.length === 0)){
    return pack.name
  }
  if(pack && components.length > 0){
    return pack.name + " and " + (components.length > 1 ? String(components.length) + " more items" : components[0].name)
  }
  if(components.length === 1){
    return components[0].name
  }
  if(components.length === 2){
    return components[0].name + " and " + components[1].name
  }
  if(components.length > 0){
    return components[0].name + " and " + String(components.length - 1) + " more items"
  }
  return "Nothing"
}

function eventSize(revenue){
  return revenue >= 10000 ? "extra big" : (revenue >= 3000 ? "big" : "small")
}


const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = change => {
  console.log('[change]', change);
};
// const handleClick = () => {
//   console.log('[click]');
// };
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, Menlo, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class CardForm extends Component {


  render() {
    return (
        <CardElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(this.props.fontSize)}
        />
    );
  }
}

function getQty(component, booking){
  if(component.qty) return component.qty
  if(component.unit === "person") return booking.people
  if(component.unit === "hour") return booking.duration
  if(component.unit === "kit") return 1
  return booking.people
}

class _Checkout extends Component{

  constructor(props){
    super(props)
    console.log(props)
    var savedState = JSON.parse(localStorage.getItem("checkout"))
    var state = {
      name: "",
      new_name: "",
      email: "",
      new_email: "",
      company_name: "",
      new_company_name: "",
      address: "",
      start_time: null,
      time_open: false,
      type: "",
      budget: "",
      location:"",
      instructions: "",
      parking_sit: "",
      elevator: "",
      coi: "",
      coi_req: "",
      phone: "",
      new_phone: "",
      tables_chairs: false,
      classroom_seating: false,
      theater_seating: false,
      wifi_internet: false,
      av_setup: false,
      parking: false,
      disabled: false,
      anything_else: "",
      coupon: "",
      invalid_coupon: "",
      coupon_details: null,
      error: "",
      card_details: null,
      change_creditcard: false,
      change_contact_info: false,
      goBack: false,
      showAlert: false,
      showTerms: false,
      credit: "",
      user_id: "",
      send_email: true,
      payment_method: "cc",
      check_time: false,
      bookbar: false,
      timezone: this.props.params.timezone || "America/Los_Angeles",
      pack_unavailable: [],
      unavailable: false,
      notify: false,
      recurring: "no",
      recur: false,
      occurrences: "",
      changed_occurrences: false
    }
    if(savedState){
      savedState.start_time = savedState.start_time ? moment(savedState.start_time) : moment()
      savedState.goBack = false
      this.state = Object.assign(state, savedState)
    }else{
      this.state = state
    }
  }

  async componentDidUpdate(prevProps, prevState){
    for(var key in prevState){
      if (prevState[key] !== this.state[key]){
        localStorage.setItem("checkout", JSON.stringify(this.state))
        break
      }
    }
    var old_start = getStartTime(prevProps.params.date, prevProps.params.start_time, prevProps.params.timezone)
    var start_time = getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone)
    var clone = JSON.parse(JSON.stringify(this.props.cart))
    if(moment(start_time).isSame(old_start, "minute") && Number(prevProps.params.duration) === Number(this.props.params.duration) && (this.props.params.zipcode.length < 5 || prevProps.params.zipcode === this.props.params.zipcode)){
      if(!cartsContainSameItems(this.props.cart, prevProps.cart)){
        await this.getComponentsWithAvailability(prevProps, clone, start_time, true)
      }
      console.log(cartsContainSameItems(this.props.cart, prevProps.cart), this.props.cart, prevProps.cart)
      return
    }else{
      await this.getComponentsWithAvailability(prevProps, clone, start_time)
    }
  }


  async componentDidMount(){
    if(this.cartIsEmpty(this.props.cart)){
      this.props.history.push("/events")
      return
    }
    Track.checkout()
    var contact_info = await request('/api/contactInfo')

    if (contact_info.err){
      console.log(contact_info)
    }else{
      var new_state = {name: contact_info.contact_name, phone: contact_info.phone || "", email: contact_info.contact_email || contact_info.email, company_name: contact_info.company_name || "", credit: contact_info.credit}
      if(this.props.params.location === "venue"){
        new_state= Object.assign(new_state, {
          city: contact_info.city || "",
          address: contact_info.address || "",
          coi: contact_info.coi ? "yes" : "no",
          instructions: contact_info.day_notes || "",
          parking_sit: contact_info.parking || "",
          elevator: contact_info.elevator || ""
        })
      }
      this.setState(new_state)
    }
    var stripe = await request('/api/stripe_id')
    console.log("stripe! ", stripe)
    if(stripe && !stripe.err){
      this.setState({card_details: stripe})
    }
    var start = moment(this.props.params.start_time)
    if(start.hour() === moment().hour() ||  start.hour() > 21 || start.hour() < 8){
      this.setState({check_time: true})
    }

  }

  async getComponentsWithAvailability(prevProps, clone, start_time, cartChanged){
    var end_time = moment(start_time).add(this.props.params.duration, "hours")
    var unavailable = false
    var notify = false
    var ids = []
    var pack = clone.package && clone.package.length > 0 ? clone.package[0].component : null
    if(pack){
        ids = ids.concat(pack.components.map(c => Number(c.id)))
    }
    for (var cat in clone){
      if(cat !== "package"){
        for(var c of clone[cat]){
          ids = ids.concat(c.id)
        }
      }
    }
    let result = await request("/api/availability/components", {method: "POST", body: JSON.stringify({start_time, end_time, ids, zipcode: this.props.params.zipcode})})

    if(!result || result.err){
      console.log("Setting Cart", result)
      return
    }
    var s = new Set(result.map(c => c.id ))
    let pack_unavailable = []
    for (var cat in clone){
      if(cat === "package" && pack){
        let pack_ids = clone.package[0].component.components.map(c => Number(c.id))
        let avail =  true
        for (let c of pack_ids){
          if(!s.has(c)){
            clone.package[0].component.is_available = false
            avail = false
            unavailable = true
            notify =  true
            pack_unavailable.push(result.find(p => Number(p.id) === Number(c)))
          }
        }
        if(avail || clone.package.canceled){
          clone.package[0].component.is_available = true
          unavailable = false
          notify =  false
        }
      }else{
        for (var cc of clone[cat]){
          var c = cc.component
          // eslint-disable-next-line
          var updated = result.find(u => u.id === c.id)
          c.start_time = moment(start_time).add(c.start_offset ? c.start_offset.value : 0, "hours")
          c.end_time = moment(c.start_time).add(c.unit === "hour" ? c.qty : this.props.params.duration, "hours")
          c.start_offset = {name: moment(c.start_time).format("h:mma"), value: c.start_offset ? c.start_offset.value : 0 }
          c.remaining = updated ? updated.remaining : null
          if((!s.has(c.id) || (updated.remaining !== null && Number(c.num_staff) > Number(updated.remaining))) && !c.canceled ){
            c.is_available = false
            unavailable = true
            notify =  true
          }else{
            c.is_available = true
          }
        }
      }
    }
    console.log("Checked availabilty")
    this.props.actions.setCart(clone)
    if(cartChanged) notify = false
    this.setState({unavailable, notify, pack_unavailable})
  }

  getUnavailableText(){
    var event = cartToLists(this.props.cart)
    var comp_count = event.components.length
    var unavailable = []
    for (var c of event.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length
    var un, untext;
    if(event.package && this.state.pack_unavailable.length > 0){
      if(!event.package.is_available  && !event.package.canceled){
        if(unavailable_count === 0){
          // if(this.state.pack_unavailable.length > 1){
          //   un = this.state.pack_unavailable.map(c => c.name)
          //   untext = un.slice(0, un.length-1).join(", ").trim()
          //   untext += " and " + un[un.length]
          //   return "The " + untext + " parts of your package are unavailable. Please choose another date, time or place."
          // }else{
          //   return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event."
          // }
          return "Your package is unavailable. Please choose another date, time or place."
        }else{
          return "Your package and some of the items are unavailable. Please choose another date, time or place."
          // if(this.state.pack_unavailable.length > 1){
          //   un = this.state.pack_unavailable.map(c => c.name)
          //   untext = un.slice(0, un.length-1).join(", ").trim()
          //   untext += " and " + un[un.length-1]
          //   return "The " + untext + " parts of your package are unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + untext + " will not be at your event. You will need to remove any and all addons that are not available."
          // }else{
          //   return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event. You will need to remove any and all addons that are not available."
          // }
        }
      }
    }
    if(comp_count === unavailable_count){
      return "None of the items in your cart are available. Please check another event date, time or location."
    }else{
      if(unavailable_count > 1){
        un = unavailable.map(c => c.name)
        untext = un.slice(0, un.length-1).join(", ").trim()
        untext += " and " + un[un.length-1]
        return untext + " are unavailable. Please check another event date, time or location. Or remove the unavailable items."
      }else if(unavailable_count === 1){
        return unavailable[0].name + " is unavailable. Please check another event date, time or location. Or remove the unavailable items."
      }else{
        return "All good"
      }
    }
  }

  getUnavailableTitle(){
    var event = cartToLists(this.props.cart)
    var comp_count = event.components.length
    var unavailable = []
    for (var c of event.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length
    if(this.state.pack_unavailable.length > 0){
      if(unavailable_count > 0){
        return "Package and Some Items Not Available"
      }
      return "Package Not Available"
    }
    if(unavailable_count > 0){
      if(comp_count === unavailable_count){
        return "Items Not Available"
      }
      return "Some Items Not Available"
    }
    return "Item Not Available"
  }

  cartIsEmpty(store){
    var cart_count = 0;
    for (var key in store){
      cart_count += store[key].length
    }
    if(this.props.params.location === "find") cart_count += 1
    return cart_count === 0
  }

  hasItems(store){
    console.log("hasItems", store)
    var cart_count = 0;
    for (var key in store){
      cart_count += store[key].length
    }
    return cart_count !== 0
  }

  hasHost(){
    for (var cat in this.props.cart){
      if(cat === "package" && this.props.cart.package && this.props.cart.package.length >= 1){
        return false
      }else{
        if(this.props.cart[cat]){
          for (let c of this.props.cart[cat]){
            if(c.component.event_host) return true
          }
        }
      }
    }
    return false
  }

  getText(){
    if(this.props.params.location === "find"){
      if(this.hasHost()){
        return "Price includes event host, setup, takedown and $500 venue finding fee."
      }else{
        return "Price includes $500 venue finding fee."
      }
    }else{
      if(this.hasHost()){
        return "Includes event host, setup and takedown."
      }else{
        return ""
      }
    }
  }

  getCartWithQty(cart, params, late_fee){
    var clone = JSON.parse(JSON.stringify(cart))
    for (let cat in clone){
      if(cat === "package" && clone[cat].length > 0){
        let c = clone[cat][0].component
        c.price = currencyFormatter.format(getPackagePrice(c.component_details, params, c.discount, c.setup_time, c.takedown_time, late_fee), { code: 'USD', precision: 0})
        c.qty = params.people
        continue
      }
      if(clone[cat]){
        for (var comp of clone[cat]){
          let c = comp.component
          var qty = getQty(c, this.props.params)
          c.qty = qty
          c.price = currencyFormatter.format(getComponentPrice(c, params, null, null, null, late_fee), { code: 'USD', precision: 0})
          c.options = c.option_values
        }
      }else{
        delete clone[cat]
      }
    }
    return clone

  }



  async sendReceipt(start_time, event_id, image){
    var body = {}
    var late_fee = getLateFee(moment(), getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone))
    let total = getTotal(this.props.cart, this.props.params, late_fee)
    let venue_fee = (this.props.params.location === "find" ? 500: 0)
    // let coupon_value = getCouponValue(this.state.coupon_details, total, this.props.cart, this.props.params)
    let coupon_details = this.state.coupon_details || {}
    // coupon_details = {value: 100, repeatable: false}
    let coupon_value = getCouponValue(coupon_details, total, this.props.cart, this.props.params, late_fee)
    let next_coupon_value = coupon_details.repeatable ? coupon_value : 0
    let pretax = finalTotal(total, coupon_value, venue_fee)
    let credit = 0
    let multiplier = this.state.occurrences || this.getDefaultOccurrences()
    multiplier = Number(multiplier) || 1
    let grand_total = getPriceForMultipleOccurrences(this.props.cart, this.props.params, venue_fee, multiplier, this.state.recurring)
    if(multiplier > 1){
      pretax = grand_total - coupon_value - next_coupon_value * (multiplier - 1)
    }
    if(this.state.credit) credit = pretax < this.state.credit ? pretax : this.state.credit
    credit = pretax < credit ? pretax: credit
    credit = Number(credit)
    var final_total = finalTotal(total, this.state.recurring === "no" ? coupon_value : 0, venue_fee, this.state.recurring === "no"? credit : 0)
    let total_costs = finalTotal(grand_total, coupon_value + next_coupon_value * (multiplier - 1), 0,  credit)
    let cost =  this.state.recurring === "no" ? final_total : total_costs
    body.event_id = event_id
    body.image = image
    body.email = this.state.new_email || this.state.email
    body.name = this.state.new_name || this.state.name
    body.phone = this.state.new_phone || this.state.phone
    body.duration =  this.props.params.duration
    body.location = this.state.address || "To be determined"
    body.num_people = this.props.params.people
    body.date = start_time.format("ddd, MMMM Do YYYY") + " at " + start_time.format("LT").toLowerCase() + " " + getTimezoneShortName(start_time, this.props.params.timezone)
    body.start_time = start_time
    body.timezone = this.props.params.timezone
    body.cart = this.getCartWithQty(this.props.cart, this.props.params, late_fee)
    console.log(body.cart)
    var listedCart = cartToLists(body.cart)
    let subtotal = currencyFormatter.format(total, { code: 'USD', precision: 0})
    let coupon = !this.state.coupon_details ? null : currencyFormatter.format(-coupon_value , { code: 'USD', precision: 0})
    let repeatable_coupon = !this.state.coupon_details ? false : this.state.coupon_details.repeatable
    let code = !this.state.coupon_details ? null : this.state.coupon_details.code
    // // let pretax = finalTotal(total, coupon_value, venue_fee)
    // var credit = 0
    // if(this.state.credit) credit = pretax < this.state.credit ? pretax : this.state.credit
    // console.log(this.state.credit, credit)
    // credit = Number(credit)
    let credited = credit ? currencyFormatter.format(-credit , { code: 'USD', precision: 0}) : null
    console.log("Credited: ", credited)
    // let final_total = finalTotal(total, coupon_value, venue_fee, credit)
    let tax_amount = getTax(this.props.cart, this.props.params, late_fee, this.props.params.tax, this.state.coupon_details, total)
    let tax = currencyFormatter.format( tax_amount, { code: 'USD', precision: 0})
    let totality = currencyFormatter.format(cost + tax_amount, { code: 'USD', precision: 0})
    let includes = this.getText()
    Track.ecommerce(cost, tax, body.cart, event_id)
    body.pricing = {subtotal, coupon, repeatable_coupon, finding: this.props.params.location === "find" , credited, tax, total: totality, includes, code, recurring: this.state.recurring, multiplier}
    var company_name = this.state.new_company_name || this.state.company_name
    request('/api/send_email', {method: "POST", body: JSON.stringify(body)})
    var sheet_url;
    if (window.location.hostname.includes("1upevents")){
      sheet_url = "production"
    }else{
      sheet_url = "test"
    }
    suchFetch('https://script.google.com/a/1upevents.com/macros/s/AKfycbwBMY2_OsDOTe9UtLua78LMd4ah_-mHYukzuhDzXgJfIufmb8hI/exec' ,{method: "GET", mode: 'no-cors'}, {
      "Month": moment(start_time).format("MM - MMM"),
      "Day of Week": moment(start_time).format("ddd"),
    	"Date": moment(start_time).format('MM/DD/YYYY'),
      "Event": getEventDescription(listedCart.package, listedCart.components),
      "Customer": company_name || body.email,
      "Package": listedCart.package ? "yes" : "no",
      "Booking Value": totality,
      "Revenue": final_total,
      "Profit": final_total*.2,
      "% Profit": .2,
      "Event Fully Paid?" : "unpaid",
      "Deposit" : "",
      "Charged": "",
      "Remainingt Balance to Charge": "",
      "# of Vendors": "",
      "Big or Small Event?" : eventSize(final_total),
      "Booking Month": moment().format('MM-MMMM'),
      "Booking Method": "new website",
      "New Customer?": "",
      "Event Host": this.hasHost() ? "yes" : "no",
      "Notes": "",
      "Event ID": event_id,
      "sheet_url": sheet_url
    })
  }

  getFindVenue(){
    var venue = {tables_chairs: this.state.tables_chairs, classroom_seating: this.state.classroom_seating, theater_seating: this.state.theater_seating,
      wifi_internet: this.state.wifi_internet, av_setup: this.state.av_setup, parking: this.state.parking,
      budget: this.state.budget, location: this.state.location, type: this.state.type, notes: this.state.anything_else
    }
    return venue
  }

  mergeAddress(address, city){
    return address + (address.toLowerCase().includes(city.toLowerCase()) ? "" : (address[address.length -1] === "," ? " " :  ", ") + city)
  }

  getHasVenue(event){
    event.day_notes = this.state.instructions
    event.parking = this.state.parking_sit
    event.elevator = this.state.elevator
    event.coi = this.state.coi === "yes" ? true : false
    event.location = this.mergeAddress(this.state.address, this.state.city)
    return event
  }

  async saveContactInfo() {
    var contact_info = {}
    if(this.state.new_name || this.state.new_name || this.state.new_phone || this.state.new_company_name){
      if(!this.state.user_id){
        contact_info.contact_name = this.state.new_name || this.state.name
        contact_info.contact_email = this.state.new_email || this.state.email
        contact_info.email = this.state.email
        contact_info.phone = this.state.new_phone || this.state.phone
        contact_info.company_name = this.state.new_company_name || this.state.company_name
        // var contact_info = await request('/api/contactInfo', {method: "PUT", body: JSON.stringify({contact_info: contact})})
      }
    }
    if(this.props.params.location === "venue"){
      contact_info.city = this.state.city || null
      contact_info.address = this.mergeAddress(this.state.address, this.state.city) || null
      contact_info.coi = this.state.coi === "yes" ? true: false
      contact_info.day_notes = this.state.instructions || null
      contact_info.parking = this.state.parking_sit || null
      contact_info.elevator = this.state.elevator || null
    }
    if(!this.state.user_id && Object.keys(contact_info).length > 0){
      await request('/api/contactInfo', {method: "PUT", body: JSON.stringify({contact_info})})
    }
  }

  checkIfRemote(cart){
    let remote = true
    for(var cat in cart){
      if(cat === "package" && cart.package.length !== 0 ) return false
      for (var i of cart[cat]){
        if(i.component.remote !== true){
          return false
        }
      }
    }
    return true
  }

  async onSubmit(payload){
    var endpoint = 'api/event/loggedIn'
    if(this.state.user_id){
      endpoint = 'api/admin/event'
    }
    var ol_pack;
    var components = []
    for (var cat in this.props.cart){
      if(cat === "package"){
        ol_pack = this.props.cart[cat] && this.props.cart[cat][0] ? this.props.cart[cat][0].component : null;
      }else{
        components = components.concat(this.props.cart[cat].map(comp => comp.component))
      }
    }
    var start_time = getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone)
    var new_comps = []
    for (var comp of components){
      var c = Object.assign({}, comp)
      var qty = getQty(c, this.props.params)
      c.qty = qty
      c.options = c.option_values
      c.start_offset = c.start_offset ? c.start_offset.value : 0
      c.start_time = moment(start_time).add(c.start_offset, "hours")
      c.end_time = moment(c.start_time).add( c.unit === "hour" ? qty : this.props.params.duration, "hours")
      new_comps.push(c)
    }
    var pack = null
    var image =  null
    if(ol_pack){
      image = ol_pack.images && ol_pack.images.length > 0 ? ol_pack.images[0] : null
      pack = Object.assign({}, ol_pack)
      qty = this.props.params.people
      if(pack.minimum && qty < Number(pack.minimum)){
        console.log("minimum not met")
        qty = Number(pack.minimum)
      }
      if(pack.maximum && qty > Number(pack.maximum)){
        qty = Number(pack.maximum)
      }
      pack.qty = qty
      pack.options = pack.option_values
    }
    if(!image){
      if(components.length > 0){
        image = components[0].images && components[0].images.length > 0 ? components[0].images[0] : null
      }else{
        image = "packages/jungle04.jpg"
      }
    }

    var params = this.props.params
    // var start_time = moment(this.props.params.start_time).format("HH:mm")
    // start_time = moment(this.props.params.date).subtract(12, "hours").add(start_time.split(":")[0], 'hours').add(start_time.split(":")[1], 'minutes')
    // start_time = moment.tz(start_time.format("YYYY-MM-DDTHH:mm"), this.props.params.timezone)
    var end_time = moment(start_time).add(Number(this.props.params.duration), 'hours')
    var event_details = {title: "", num_people: params.people, hours_needed: params.duration, start_time, end_time, timezone: this.props.params.timezone, date: moment(this.props.params.date), coupon_code: this.state.coupon || null, email: this.state.new_email, timezone: this.props.params.timezone, zipcode: this.props.params.zipcode, tax: this.props.params.tax, payment_method: this.state.payment_method, recurring: this.state.recurring, occurrences: this.state.occurrences || this.getDefaultOccurrences()}
    var venue = null
    if(this.props.params.location === "find"){
      venue = this.getFindVenue()
    }else{
      event_details = this.getHasVenue(event_details)
    }
    var req_params = { method: 'POST',
                  body: JSON.stringify({event: event_details, package: pack, components: new_comps, venue, user_id: this.state.user_id})
              };

    if(payload && !this.state.user_id){
      var stripe;
      if(this.state.change_creditcard){
        stripe = await request('/api/update_customer/', {method: "POST", body: JSON.stringify({contactname: this.state.name, email: this.state.email, stripeToken: payload.token.id})})
      }else{
        stripe = await request('/api/add_customer', {method: "POST", body: JSON.stringify({contactname: this.state.name, email: this.state.email, stripeToken: payload.token.id})})
      }
    }
    if(payload && (!stripe || stripe.err)){
      if(stripe){
        if(stripe.err === "Token has expired"){
          this.displayAlert("Sorry, it seems your session expired. Please log back in.")
          return
        }
      }
      this.displayAlert("Could not process card. Please refresh the page and try again.")
      return
    }
    await this.saveContactInfo()
    var result = await request(endpoint, req_params)
    console.log("Result", result, (result.err))


    if(result && !result.err){
      Track.booked()
      if(this.state.send_email) this.sendReceipt(start_time, result.event_id, image)
      localStorage.setItem("checkout", null)
      this.props.actions.clearCart()
      this.props.actions.clearParams()
      this.props.history.push({pathname:'/booked', state: {image, result}})

    }else{
      this.displayAlert("Could not submit event. Please try again.")
      console.log("ERROR")
    }

  }

  async addToSheet(name, contact_email, email, company_name, phone){
    console.log("addToSheet")
    name =  name.split(" ")
    let firstname = name[0]
    let lastname = name.length > 1 ?  name[name.length - 1] : ""
    var data = {firstname, lastname, contact_email, email, phone, company_name}
    var response  = await suchFetch ('https://script.google.com/a/1upevents.com/macros/s/AKfycbwMfX0GT1jYkLburAvA1rxpFiu4DxAjHq74mt8Rz9tvpzPhq8vB/exec', {method: "GET"}, data)
    console.log("Google Response", response)
  }

  handleSubmit = (ev, cost) => {
    ev.preventDefault();
    if(this.state.unavailable){
      this.setState({notify: true})
      return
    }
    var required = []
    if(!this.state.phone) required.push("new_phone")
    if(this.props.params.location === "venue"){
      required= required.concat(["address", "city"])
    }else{
      required= required.concat(["type", "location", "budget"])
    }
    for(let r of required){
      if(!this.state[r]){
        console.log(r)
        document.getElementById(r).focus()
        return
      }
    }

    this.setState({disabled: true})
    if(!this.state.card_details || this.state.change_creditcard){
      this.props.stripe.createToken().then(payload => {
        if(payload.error){
          this.displayAlert(payload.error.message)
        }else{
          this.onSubmit(payload)
        }
      }).catch(err => this.displayAlert(err))
    }else{
      this.onSubmit(null)

    }
  }

  displayAlert(error){
    this.setState({error, disabled: false, showAlert: true})
  }

  isInCart(c_id, cart){
    for (var cat in cart){
      if(cat !== "package"){
        for(var c of cart[cat]){
          if(c.id === c_id){
            return true
          }
        }
      }
    }
    return false
  }

  async checkCoupon(){
    this.addToSheet(this.state.new_name || this.state.name, this.state.new_email || this.state.email, this.state.email,  this.state.new_company_name || this.state.company_name, this.state.new_phone || this.state.phone )
    if(this.state.email){
      var coupon = await request("/api/coupon/code-email/"+this.state.coupon+"/"+this.state.email)
      console.log("Coupon:", coupon)
      if(!coupon){
        this.setState({invalid_coupon: "Could not reach server."})
        return
      }
      if(coupon.err === "used"){
        this.setState({invalid_coupon: "Code cannot be used twice."})
      }else if(coupon.err){
        this.setState({invalid_coupon: "Not a valid code."})
      }else{
        if(coupon.component_id){
          if(this.isInCart(coupon.component_id, this.props.cart)){
            this.setState({invalid_coupon: "", coupon_details: coupon})
          }else{
            this.setState({invalid_coupon: "Code does not apply to any of the items in your cart."})
          }
          return
        }
        if(coupon.package_id){
          if(this.props.cart && this.props.cart.package && this.props.cart.package.length > 0 && this.props.cart.package[0].id === coupon.package_id){
            this.setState({invalid_coupon: "", coupon_details: coupon})
          }else{
            this.setState({invalid_coupon: "Code does not apply to any of the items in your cart."})
          }
          return
        }
        this.setState({invalid_coupon: "", coupon_details: coupon})
      }
    }else{
      var valid = await request("/api/coupon/code/"+this.state.coupon)
      console.log("Coupon:", valid)
      if(!valid.coupon){
        this.setState({invalid_coupon: "Not a valid code."})
      }else{
        coupon = valid.coupon
        if(coupon.component_id){
          if(this.isInCart(coupon.component_id, this.props.cart)){
            this.setState({invalid_coupon: "", coupon_details: coupon})
          }else{
            this.setState({invalid_coupon: "Code does not apply to any of the items in your cart."})
          }
          return
        }
        this.setState({invalid_coupon: "", coupon_details: coupon})
      }
    }
  }


  ziptext(){
    switch (this.props.params.location){
      case "venue":
        return "Your venue"
      case "find":
        return "To Be Determined"
      default:
        return this.props.params.zipcode
    }
  }

  checkMarkLabel(label){
    this.setState({[label]: !this.state[label]})
  }

  calcTotal(pre, coupon, fee){
    if (coupon){
      if(coupon.value){
        pre -= coupon.value
      }else if(coupon.percent){
        pre = pre - pre * Number(coupon.percent)
      }
    }
    pre += fee || 0
    return pre
  }

  getCard(){
    switch(this.state.card_details.brand.toLowerCase()){
      case "american express":
        return Amex
      case "visa":
        return Visa
      case "discover":
        return Discover
      case "mastercard":
        return Master
      case "jcb":
        return JCB
      case "diners club":
        return Diners
      default:
        return Credit
    }
  }

  getSetupTime(cart){
    var setup_time = 0
    for (var cat in cart){
      for(var c of cart[cat]){
        c = c.component
        if(c.setup_time){
            setup_time = (c.setup_time > setup_time) ? c.setup_time : setup_time
        }
      }
    }
    return setup_time
  }

  getExpirey(){
    var month = this.state.card_details.exp_month.toString()
    if (month.length < 2) month = "0"+ month

    return month + "/" + this.state.card_details.exp_year.toString().slice(2)
  }

  onKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13) {
          event.preventDefault();
    }
  }

  onEnterCoupon(event){
    if(event.which === 13) this.checkCoupon()
  }

  changeNotes(value, component, cat){
    this.props.actions.editCartItem(component.id, cat, "notes", value)
  }

  changeOffset(value, component, cat){
    console.log(value, component, cat)
    this.props.actions.editCartItem(component.id, cat, "start_offset", value)
  }

  getOptions(date){
    return [{name:"Does not repeat", value: "no"},
    {name: "Weekly on " + pluralize(moment(date).format("dddd")), value: "weekly"},
    {name: "Biweekly on " + pluralize(moment(date).format("dddd")), value: "biweekly"},
    {name: "Monthly on the " + getWhichWeek(date) + moment(date).format(" dddd"), value: "monthly"}]
  }

  getDefaultOccurrences(){
    switch(this.state.recurring){
      case "weekly":
        return 52
      case "biweekly":
        return 26
      case "monthly":
        return 12
      default:
        return 1
    }
  }

  changeOccurrences(occurrences){
    this.setState({occurrences, changed_occurrences: true})
  }

  setAddress(address){
    var a = address.split(",")
    var city = this.state.city
    if(a[a.length-1] === " USA"){
      city = a[a.length-3]
    }
    var address = address
    this.setState({address, city})
  }

  render(){
    const cssClasses = {
      input: 'address text-field',
      autocompleteContainer: 'my-autocomplete-container'
    }

    let remote = this.checkIfRemote(this.props.cart)
    var has_items = this.hasItems(this.props.cart)
    console.log("has_items", has_items)
    var section_index = 1
    if(has_items) section_index = 2
    var late_fee = getLateFee(moment(), getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone))
    var total = getTotal(this.props.cart, this.props.params, late_fee)
    var venue_fee = (this.props.params.location === "find" ? 500: 0)
    let findVenue = this.props.params.location === "find"
    let setup_time = this.getSetupTime(this.props.cart)
    let coupon_details = this.state.coupon_details || {}
    // coupon_details = {value: 100, repeatable: false}
    var coupon_value = getCouponValue(coupon_details, total, this.props.cart, this.props.params, late_fee)
    let next_coupon_value = coupon_details.repeatable ? coupon_value : 0
    var pretax = finalTotal(total, coupon_value, venue_fee)
    var credit = 0
    var tax =  this.props.params.tax
    var tax_amount = getTax(this.props.cart, this.props.params, late_fee, tax, coupon_details, total)
    let multiplier = this.state.occurrences || this.getDefaultOccurrences()
    multiplier = Number(multiplier) || 1
    var grand_total = getPriceForMultipleOccurrences(this.props.cart, this.props.params, venue_fee, multiplier, this.state.recurring)
    if(multiplier > 1){
      pretax = grand_total - coupon_value - next_coupon_value * (multiplier - 1)
    }
    if(this.state.credit) credit = pretax < this.state.credit ? pretax : this.state.credit
    credit = pretax < credit ? pretax: credit
    credit = Number(credit)
    var final_total = finalTotal(total, this.state.recurring === "no" ? coupon_value : 0, venue_fee, this.state.recurring === "no"? credit : 0)
    let total_costs = finalTotal(grand_total, coupon_value + next_coupon_value * (multiplier - 1), 0,  credit)
    let cost =  this.state.recurring === "no" ? final_total : total_costs
    let recurring_options = this.getOptions(this.props.params.date)

    console.log("CARD stripe", this.state.card_details, this.state.change_creditcard); 
    return (
      <div>
        <PlainHeader title={"Review & Book"} warn={true} onConfirm={() => this.props.history.push("/")}/>
        <form onKeyPress={(event) => this.onKeyPress(event)} onSubmit={(ev) => this.handleSubmit(ev, cost)}>
          <div className="checkout-container new">
            <div className="checkout-details">
              <Title title={"1. Fill in the Basics"} subtitle={"Here are the high level details for your event."} />
              <div className="card section" >
                <div className="booking">
                  <div className="subheader">Event information:</div>
                  <div><img src={Date} alt=""/><span className="text">{moment(this.props.params.date).format("ddd, MMM D, YYYY")}</span></div>
                  <div><img src={People} alt=""/><span className="text">{this.props.params.people || 0} people</span></div>
                  <div><img src={Location} alt=""/><span className="text">{this.ziptext()}</span></div>
                  <div><img src={Duration} alt=""/><span className="text">{moment(this.props.params.start_time).format("h:mma") || 0} for {this.props.params.duration || 0} hours</span></div>
                  {setup_time ? <div className="disclaimer" style={{textAlign: "left", marginBottom: 0}}><i>{"We will arrive ~" + setup_time + " " + (setup_time > 1 ? " hours" : " hour") + " before to setup." }</i></div> : <div className="disclaimer" ></div>}
                  <div onClick={()=> this.setState({bookbar: true})} className="change">Change Event Information</div>
                </div>
                <div className="contact">
                  <div className="subheader">Point of contact for this event:</div>
                  {this.state.change_contact_info ?
                    <div>
                      {this.props.login.admin ? <input className="text-field" type="number" value={this.state.user_id} placeholder="User ID" onChange={(e) => this.setState({user_id: e.target.value})} style={{width: 240, marginTop: 10}} /> : null}
                      <input className="text-field" type="text" value={this.state.new_name} onChange={(e) => this.setState({new_name: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Contact Name" />
                      <input className="text-field" type="email" value={this.state.new_email} onChange={(e) => this.setState({new_email: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Email Address"/>
                      {this.state.phone ? <input className="text-field" type="tel" value={this.state.new_phone} onChange={(e) => this.setState({new_phone: e.target.value})} placeholder="E.g. (650) 888-5252" style={{width: 240, marginTop: 10}} /> : null}
                      {this.state.company_name ? <input className="text-field" type="text" value={this.state.new_company_name} onChange={(e) => this.setState({new_company_name: e.target.value})} placeholder="Company Name" style={{width: 240, marginTop: 10}} /> : null}
                      {this.props.login.admin ?
                        <div>
                          <CheckboxControl checked={this.state.send_email} onChange={ (send_email) => this.setState({send_email})} />
                          <label onClick={() => this.checkMarkLabel("send_email")}>Send Email</label>
                        </div>
                        : null
                      }
                    </div>
                    :
                    <div>
                      <div>{this.state.name}</div>
                      {this.state.company_name ? <div>{this.state.company_name}</div> : null}
                      <div>{this.state.email}</div>
                      {this.state.phone ? <div>{this.state.phone}</div> : null}
                      <div onClick={()=> this.setState({change_contact_info: true, new_name: this.state.name, new_email: this.state.email, new_phone: this.state.phone, new_company_name: this.state.company_name})} className="change">Change Contact</div>
                    </div>
                  }
                </div>
                <div className="other">
                  {!findVenue ?
                    <div>
                      <div className="questions">{remote ? "What's your address?*" : "What's the address of your event?*"} </div>
                      {remote ? <div style={{marginTop: -4, textAlign: 'left' }}className="disclaimer">Don't worry, this event is remote!</div> : null}
                      <PlacesAutocomplete classNames={cssClasses} inputProps={{id: "address", value: this.state.address, onChange: (address) => this.setAddress(address), placeholder: "E.g. 123 Main St, San Francisco" }}   />
                      <input className="text-field" id="city" type="text" value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} placeholder="City" style={{width: 220, marginTop: 20}} />
                      <TimezonePicker style={{marginTop: 20}} value={this.props.params.timezone} update={(timezone) => this.props.actions.changeParams("timezone", timezone)} />
                    </div>
                    : null
                  }
                  {!this.state.phone ?
                    <div>
                      <div className="questions" style={{marginTop: 20}}>{"What's your phone number?*"} </div>
                      <input className="text-field" id="new_phone" type="tel" value={this.state.new_phone} onChange={(e) => this.setState({new_phone: e.target.value})} placeholder="E.g. (650) 888-5252" style={{width: 220}} />
                    </div>
                    : null
                  }
                  {!this.state.company_name ?
                    <div>
                      <div className="questions" style={{marginTop: 20}}>{"What's your company's name? (If applicable)"} </div>
                      <input className="text-field" type="text" value={this.state.new_company_name} onChange={(e) => this.setState({new_company_name: e.target.value})} placeholder="" style={{width: 220}} />
                    </div>
                    : null
                  }
                  <div>
                    <div className="questions" style={{marginTop: 20}}>{"Would you like this event to occur regularly?"} </div>
                      <ValueSelector value={this.state.recurring} name={recurring_options.find(i => i.value === this.state.recurring).name} options={recurring_options} update={(val) => this.setState({recurring: val})} style={{marginTop: 8}}/>
                      {this.state.recurring !== "no" ?
                        <div style={{marginTop: 8, color: "var(--primary-content-color)"}}> Number of Occurrences:
                          <input type="number" className="text-field" value={this.state.changed_occurrences ? this.state.occurrences :  this.getDefaultOccurrences()} style={{width: 50, marginLeft: 12}} onChange={(e) => this.changeOccurrences(e.target.value)} />
                        </div>
                        :null
                      }
                  </div>
                </div>
              </div>
              {has_items ?
                <div>
                  <Title title={"2. Items & Instructions"} subtitle={"Let us know about any food allergies, special needs, etc here."} />
                  <div className="card section" style={{paddingTop: 4}}>
                    <ItemList cart={this.props.cart} booking={this.props.params} onChange={this.changeNotes.bind(this)} changeOffset={(value, component, cat) => this.changeOffset(value, component, cat)} lateFee={true}/>
                  </div>
                </div>
              : null}
              <Title title={ findVenue ?  section_index + 1 + ". Find a Venue"  : section_index + 1 + ". Your Venue"} subtitle={findVenue ? "Let us know about what kind of venue you'd like. We'll contact you shortly after you book to confirm details and give you venue options. You'll be charged a $500 venue finding fee, plus the cost of the venue itself.": ""} />
              <div className="card section">
                {this.props.params.location === "find" ?
                <div className="venue-questions" style={{marginTop: -20}}>
                  <div className="questions" >Type of event:*</div>
                  <input className="text-field" id="type" placeholder="party, offsite, meeting, etc" value={this.state.type} onChange={(e) => this.setState({type: e.target.value})} style={{width: 220}} />
                  <div className="questions">Amenities required:</div>
                  <div className="checkbox-section">
                    <CheckboxControl checked={this.state.tables_chairs} onChange={ (tables_chairs) => this.setState({tables_chairs})} />
                    <label onClick={() => this.checkMarkLabel("tables_chairs")}>Tables and Chairs</label>
                    <CheckboxControl checked={this.state.classroom_seating} onChange={ (classroom_seating) => this.setState({classroom_seating})} />
                    <label onClick={() => this.checkMarkLabel("classroom_seating")}>Classroom Seating</label>
                    <CheckboxControl checked={this.state.theater_seating} onChange={ (theater_seating) => this.setState({theater_seating})} />
                    <label onClick={() => this.checkMarkLabel("theater_seating")}>Theater Seating</label>
                    <div style={{marginTop: 10}}>
                      <CheckboxControl checked={this.state.wifi_internet} onChange={ (wifi_internet) => this.setState({wifi_internet})} />
                      <label onClick={() => this.checkMarkLabel("wifi_internet")}>Wifi/Internet</label>
                      <CheckboxControl checked={this.state.av_setup} onChange={ (av_setup) => this.setState({av_setup})} />
                      <label onClick={() => this.checkMarkLabel("av_setup")}>A/V Setup</label>
                      <CheckboxControl checked={this.state.parking} onChange={ (parking) => this.setState({parking})} />
                      <label onClick={() => this.checkMarkLabel("parking")}>Parking</label>
                    </div>
                  </div>
                  <div className="questions">Location:*</div>
                  <input className="text-field" id="location" placeholder="E.g. Within 5 miles of San Francisco" value={this.state.location} onChange={(e) => this.setState({location: e.target.value})} style={{width: 220}} />
                  <div className="questions">Venue budget:*</div>
                  <input className="text-field" id="budget" placeholder="E.g. Less than $8,000" value={this.state.budget} onChange={(e) => this.setState({budget: e.target.value})} style={{width: 220}} />
                  <div className="questions">Anything else?</div>
                  <textarea className="text-field" value={this.state.anything_else} onChange={(e) => this.setState({anything_else: e.target.value})} style={{width: 420}} />
                </div>

                :
                <div className="venue-questions" style={{marginTop: -20}}>
                 <div className="questions">Load in/load out instructions:</div>
                 <textarea className="text-field" placeholder="How do we get into the building? Anything else we should know?" value={this.state.instructions} onChange={(e) => this.setState({instructions: e.target.value})} style={{width: 420}} />
                 <div className="questions">What is the parking situation?</div>
                 <input className="text-field" placeholder="Limited, plentiful, non-existant, etc" value={this.state.parking_sit} onChange={(e) => this.setState({parking_sit: e.target.value})} style={{width: 240}} />
                 <div className="questions">What floor will your event be on?</div>
                 <div>
                   <input type="radio" id="first" value={"first"} name="first" onChange={(e) => this.setState({elevator: e.currentTarget.value})} checked={this.state.elevator === "first"} />
                   <label htmlFor="first">First floor</label>
                   <input type="radio" id="yes" value={"yes"} name="yes" onChange={(e) => this.setState({elevator: e.currentTarget.value})} checked={this.state.elevator === "yes"} />
                   <label htmlFor="yes">{"Other (elevator available)"}</label>
                   <input type="radio" id="no" value={"no"} name="no" onChange={(e) => this.setState({elevator: e.currentTarget.value})} checked={this.state.elevator === "no"} />
                   <label htmlFor="no">Other (no elevator)</label>
                 </div>
                 <div className="questions">Does your building require a Certificate of Insurance?</div>
                 <div>
                   <input type="radio" id="coi_yes" value={"yes"} name="coi_yes" onChange={(e) => this.setState({coi: e.currentTarget.value})} checked={this.state.coi === "yes"} />
                   <label htmlFor="coi_yes">{"Yes"}</label>
                   <input type="radio" id="coi_no" value={"no"} name="coi_no" onChange={(e) => this.setState({coi: e.currentTarget.value})} checked={this.state.coi === "no"} />
                   <label htmlFor="coi_no">No</label>
                 </div>
                 {this.state.coi === "yes" ? <div style={{textAlign: "left", marginBottom: 0}} className="disclaimer">Please email the COI requirements for your building to <a target="_blank" href= "mailto: info@1upevents.com">info@1upevents.com</a></div>: null}
                </div>
                }
              </div>
              <Title title={ section_index + 2 + ". Payment"}  />
              <div className="card section">
                <div className="questions">Enter your credit card information:</div>
                {this.state.card_details && !this.state.change_creditcard ? 
                  <div className="text-field credit-card flex gap-x-4 justify-between items-center py-1"> 
                    <div className='flex items-center'>
                      <div className="brand mr-0 inline-block">
                        <img src={this.getCard()} className="little-card mr-0 mt-0" alt={this.state.card_details.brand}/>
                      </div>
                      <div className="ending text pl-2 ml-0">{"ending in " + this.state.card_details.last4}</div>
                    </div>
                    <div className="text ml-0 max-w-[220px] text-ellipsis text-nowrap overflow-hidden">{this.state.name}</div>
                    <div className="text ml-0">Exp: {this.getExpirey()}</div>
                    <span className="change" onClick={()=> this.setState({change_creditcard: true})}>Change</span>
                  </div> 
                  : 
                  <CardForm fontSize={18} />
                  }
                  <div className="disclaimer cc-disclaimer">We’ll charge the full amount to this card after your event and keep it on file for future changes.</div>
                <input className="text-field" style={{width: 265}} onKeyPress={(event) => this.onEnterCoupon(event)} placeholder="Enter a coupon code (if you have one)" value={this.state.coupon} onChange={(e) => this.setState({coupon: e.target.value.toUpperCase()})} />
                {!this.state.coupon_details ? <div className="secondary-button" onClick={() => this.checkCoupon()} style={{float: "none", marginLeft: 10}}>Apply</div> : <div className="secondary-button accepted" style={{float: "none", marginLeft: 10}}>Applied</div>}
                {!this.state.coupon_details ? null : <span className="change" style={{float: "none", marginLeft: 10}} onClick={() => this.setState({coupon_details: null, coupon: ""})}>Remove</span>}
                {this.state.invalid_coupon ? <div style={{color: "red", marginTop: 3}}>{this.state.invalid_coupon}</div> : null}
                <div className="questions payment">Choose a payment method:</div>
                <div className="disclaimer cc-disclaimer">If you select ACH, your card will be kept on file but will not be charged.</div>
                <div className="venue-questions">
                  <div className="opt" key={"cc"} >
                    <input type="radio" id={"cc"} value={"cc"} name={"cc"}  onChange={(e) => this.setState({payment_method: e.currentTarget.value})} checked={this.state.payment_method === "cc"} />
                    <label htmlFor={"cc"}>{"Credit Card"}</label>
                  </div>
                  <div className="opt" key={"ach"} >
                    <input type="radio" id={"ach"} value={"ach"} name={"ach"} onChange={(e) => this.setState({payment_method: e.currentTarget.value})} checked={this.state.payment_method === "ach"} />
                    <label htmlFor={"ach"}>{"ACH"}</label>
                  </div>
                </div>
              </div>
              <div className="bottom-order">
                <button className={"custom-button" + (this.state.disabled ? " disabled" : "") }>
                  Book Now
                  {this.state.disabled ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <BeatLoader
                          color={'#FBB200'}
                          size={12}
                          loading={this.state.loading}
                        />
                      </div>
                    </div> : null
                  }
                  </button>
                  <span><span className="cost">Total Cost: </span>
                  <span className="price">{currencyFormatter.format(cost + tax_amount, { code: 'USD', precision: 0})}</span></span>
                <div className="disclaimer" style={{textAlign: 'left'}} >By booking, you agree to the <span onClick={()=> this.setState({showTerms: true})} style={{color: "var(--input-highlight)", cursor: "pointer"}}>terms and conditions.</span></div>
                <Tldr />
              </div>

            </div>

            <div className="order">
              <div className="wrap">
              <div className="summary" style={{border: "none"}}>
                <div className="summary-header" style={{marginTop: 0}}>Order Summary</div>
                <div className="lineItem" ><span>Items:</span><span className="price">{currencyFormatter.format(total, { code: 'USD', precision: 0})}</span></div>
                {this.props.params.location === "find" ? <div className="lineItem" ><span>Venue Finding Fee:</span><span className="price">{currencyFormatter.format(500, { code: 'USD', precision: 0})}</span></div> : null}
                {coupon_value && coupon_details.repeatable ? <div className="lineItem" ><span>Coupon:</span><span className="price">{currencyFormatter.format(-coupon_value, { code: 'USD', precision: 0})}</span></div> : null}
                { this.state.recurring !== "no" && multiplier > 1 ?
                  <div className="lineItem" style={{position: "relative", height: 20, marginTop: 6}} >
                    <span className="price" style={{position: "absolute", right: 0, color: "var(--strong-content-color)"}}>x{multiplier}</span>
                    </div>
                : null}
                {coupon_value && !coupon_details.repeatable ? <div className="lineItem" ><span>Coupon:</span><span className="price">{currencyFormatter.format(-coupon_value, { code: 'USD', precision: 0})}</span></div> : null}
                {credit ? <div className="lineItem" ><span>Credit:</span><span className="price">{currencyFormatter.format(-credit, { code: 'USD', precision: 0})}</span></div> : null}
                <div className="lineItem"><span>Tax:</span><span className="price">{currencyFormatter.format(tax_amount, { code: 'USD', precision: 0})}</span> </div>
              </div>
              <div className="total">
                <div><span className="cost">Total Cost:</span> <span className="price">{currencyFormatter.format(cost + tax_amount, { code: 'USD', precision: 0})}</span></div>
              </div>
              <div className="help">
                <div className="lineItem">Need help? Have questions?</div>
                <div className="lineItem">Call us at <a className="link" href="tel:+14159155184">(415) 915-5184</a></div>
              </div>
              </div>
            </div>
          </div>
        </form>
        <ConfirmationModal title="Are you sure you want to leave checkout?" text="This will take you back the previous view, where you will be able to edit the booking information." confirmationText="Leave Checkout" show={this.state.goBack} onHide={() => this.setState({goBack: false})} onConfirm={()=> this.props.history.goBack()}/>
        <ConfirmationModal title="Double check your start time" text={"Your start time is set to " + moment(this.props.params.start_time).format("h:mm a") + " please confirm this is the time you want your event to begin."} confirmationText="Confirm" secondaryText="Change Time" show={this.state.check_time} onHide={() => {this.setState({check_time: false}); this.setState({bookbar: true})}} onConfirm={() => this.setState({check_time: false})}/>
        <Alert title={() => this.state.error} text="" show={this.state.showAlert} onHide={()=> this.setState({showAlert: false})} />
        <Terms show={this.state.showTerms} onHide={()=> this.setState({showTerms: false})}/>
        <BookingModal callToAction={"Save"} show={this.state.bookbar} onHide={() => this.setState({bookbar: false})} />
        <Alert title={() => this.getUnavailableTitle()} text={() => this.getUnavailableText()} show={this.state.notify} onHide={()=> this.setState({notify: false})} buttonText="Got It"/>
        <Footer/>
      </div>
    )
  }

}

const Checkout = injectStripe(_Checkout);


class CheckoutWrapper extends Component{
  render(){
    return (
      <StripeProvider apiKey={stripe_key}>
        <Elements >
          <Checkout {...this.props} />
        </Elements>
      </StripeProvider>
    )
  }
}

// <input className="text-field" id="address" value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} placeholder="E.g. 123 Main St, San Francisco" style={{width: 100+"%"}} />


const mapStateToProps = state => ({
  cart: state.cart, params: state.booking, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutWrapper))
