import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ServicePage from '../Services/ServicePage'
import CheckboxControl  from '../Utils/CheckboxControl'
import Header from '../Header/Header'
import Title from '../Title'
import CheckboxTree from 'react-checkbox-tree';
import TravelFees from '../Utils/TravelFees'
import { getCurrentRegion, regionsToNodes, getLocationNames } from '../Utils/Locations'

var fields = {description: "textarea", daily_max: "number", price: "number", minimum: "number", maximum: "number", hour_max: "number",hour_min: "number",setup_time: "number", takedown_time: "number", discount: "number",  options: "textarea", add_ons: "textarea", is_active: "checkbox", staff: "checkbox", remote:"checkbox",
    sub_categories: "textarea", event_host: "checkbox", normal_duration: "number", extra_hours_fee: "number", booking_lead_time: "number", extra_hours_fee_scalar: "number", delivery: "checkbox", pickup: "checkbox", delivery_window_duration: "number", flat_fee: "number" }

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

var powerupFields = ["name", "description", "includes", "images", "price", "unit", "unit_name", "flat_fee",  "daily_max", "maximum", "minimum", "hour_min", "hour_max", "setup_time", "takedown_time", "categories", "sub_categories", "seasonal", "related", "keywords", "is_active", "remote", "options", "event_host", "normal_duration", "extra_hours_fee",  "extra_hours_fee_scalar",  "delivery", "pickup", "delivery_window_duration", "booking_lead_time"]



class NewPowerup extends Component{

  constructor(props){
    super(props)
    var powerup = {}
    if(this.props.location && this.props.location.state){
      powerup = this.props.location.state
    }else{
      for(var i of powerupFields){
        powerup[i] = null
      }
      powerup.is_active =  true
      powerup.event_host = false
      powerup.id = 1
      powerup.remote = false
    }


    this.state = {
      powerup,
      preview: false,
      id: null,
      new_id: "",
      error: "",
      regions: [],
      locations: [],
      expanded: [],
      sub_region_fees: {},
      region_fees: {},
      travel_fees: {}
    }
  }

  async componentDidMount(){
    var regions = await request('/api/regions')
    regions = regionsToNodes(regions)
    this.setState({regions})
  }

  componentWillReceiveProps(nextProps){
    if(this.props.match.params.id !== nextProps.match.params.id){
      this.getData(nextProps)
    }
  }

  handleChange(key, value){
    let clone = Object.assign({}, this.state.powerup)
    clone[key] = value
    if(this.state.error){
      this.setState({powerup: clone, error: ""})
      return
    }
    this.setState({powerup: clone})
  }

  preview(){
    var  powerup = Object.assign({}, this.state.powerup)
    powerup.options = JSON.parse(powerup.options)
    powerup.categories = JSON.parse(powerup.categories)
    powerup.sub_categories = JSON.parse(powerup.sub_categories)
    powerup.related = JSON.parse(powerup.related)
    powerup.keywords = JSON.parse(powerup.keywords)
    powerup.images = JSON.parse(powerup.images)
    powerup.seasonal = JSON.parse(powerup.seasonal)
    powerup.travel_fees = this.state.travel_fees
    return powerup
  }

  async validate(powerup){
    let jsonList = ["images", "options", "sub_categories", "seasonal", "keywords", "categories", "related"]
    try{
      for(var c of jsonList){
        if(powerup[c]){
          try{
            JSON.parse(powerup[c])
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      if(!powerup.name){
        document.getElementById("name").focus()
        this.setState({error: "Name can't be empty"})
        return false
      }
      if(!powerup.unit){
        document.getElementById("unit").focus()
        this.setState({error: "Unit can't be empty"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  async setPreview(){
    var validated = await this.validate(this.state.powerup)
    if(validated){
      this.setState({preview: true})
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate(this.state.powerup)
    if(validated){
      var clone = Object.assign({}, this.state.powerup)
      delete clone.id
      clone.locations = this.state.locations
      clone.travel_fees = this.state.travel_fees
      var res  = await request("/api/admin/component", {method: "POST", body: JSON.stringify({component: clone} )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err === "Invalid html"){
        let d = document.getElementById("description")
        console.log("Description:", d)
        d.focus()
        this.setState({error: res.err})
        return
      }else if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.props.history.push("/admin_panel/powerup/" + res.id)
      }
    }
  }

  render(){
    if (!this.state.powerup) return null
    return (
      <div>
      <Header />
      <div style={{marginTop: 40, marginLeft: 40}}>
        <Title title="New Powerup"/>
      </div>
      <div className="card form">
      <Form onSubmit={ (event) => this.handleSubmit(event)}>
        {powerupFields.map((key, i) => {
          if(fields[key] === "textarea"){
            return (
              <FormGroup key={i} controlId={key}>
                <ControlLabel>{key}</ControlLabel>
                <FormControl className="text-field" componentClass="textarea" style={{resize: "vertical"}} rows="8"  bsSize="sm" placeholder={key} value={this.state.powerup[key] || ""} onChange={(e)=> this.handleChange(key, e.target.value)}/>
              </FormGroup>
            )
          }if(fields[key] === "checkbox"){
            return (
              <div key={i}>
                <CheckboxControl checked={this.state.powerup[key]} onChange={(check) => this.handleChange(key, check)} />
                <label style={{marginLeft: 10}} onClick={() => this.handleChange(key, !this.state.powerup[key])}>{key}</label>
              </div>
            )
          }else{
            return (
              <FieldGroup
                   id={key}
                   key={i}
                   type={fields[key]}
                   label={key}
                   className="text-field"
                   placeholder={key}
                   value={this.state.powerup[key] || ""}
                   onChange={(e)=> this.handleChange(key, e.target.value)}
                   bsSize="sm"
                 />
            )
          }
        })}
        <div className="locations-section">
          <ControlLabel>{"Locations"}</ControlLabel>
          <CheckboxTree
              nodes={this.state.regions}
              checked={this.state.locations}
              expanded={this.state.expanded}
              onCheck={locations => this.setState({ locations })}
              onExpand={expanded => this.setState({ expanded })}
          />
        </div>
        <div className="locations-section">
          <ControlLabel>{"Travel Fees"}</ControlLabel>
          <TravelFees region_fees={this.state.region_fees} sub_region_fees={this.state.sub_region_fees} travel_fees={this.state.travel_fees} regions={this.state.regions} locations={this.state.locations}
              changeState={this.setState.bind(this)}
          />
        </div>
        <Button style={{marginRight: 20}} onClick={()=> this.setPreview()}>Preview</Button>
        <Button style={{marginRight: 20}} type="submit">
             Submit
        </Button>
        {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
      </Form>
      </div>
      {this.state.preview ? <ServicePage booking={this.props.booking} show={this.state.preview} inCart={false} component={this.preview()} onHide={()=> this.setState({preview: false})} addToCart={() => null } /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  booking: state.booking
})


export default withRouter(connect(
  mapStateToProps
)(NewPowerup))
