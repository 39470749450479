import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/Footer.css'
import Logo from '../../img/1upLargeGrey.svg'
import SimpleFooter from './Footer'

const Footer = ({className}) => {
  return (
    <div className='new mt-auto'> 
    <div className={" footer h-fit min-h-fit w-full justify-content flex relative flex gap-4 p-4 pt-16 " + className}>
      <div className="content min-h-full max-w-full">
        <div className="grid grid-cols-[repeat(auto-fit,_minmax(130px,_1fr))] gap-8 justify-items-start lg:gap-16 justify-center">
          <div className="list flex-none mr-0">
            <div className="list-header">Our Events</div>
            <ul>
              <li><Link to="/how-it-works">How It Works</Link></li>
              <li><Link to="/faq">FAQs</Link></li>
              <li><Link to="/testimonials">Testimonials</Link></li>
              <li><Link to="/events">Book an Event</Link></li>
            </ul>
          </div>
          <div className="list flex-none  mr-0">
            <div className="list-header">Work for 1up</div>
            <ul>
              <li><Link to="/event-host">Become an Event Host</Link></li>
              <li><Link to="/vendor">Become a Vendor</Link></li>
            </ul>
          </div>
          <div className="list flex-none  mr-0">
            <div className="list-header">Company</div>
            <ul>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link to="/locations">Locations</Link></li>
            </ul>
          </div>
          <div className="list flex-none  mr-0">
            <div className="list-header">Account</div>
            <ul>
              <li><Link to="/signin">Sign In</Link></li>
              <li><Link to="/signin">Register</Link></li>
              <li><Link to="/account">Manage Your Account</Link></li>
              <li><Link to="/your-events">Your Events</Link></li>

            </ul>
          </div>
          <div className="list flex-none " style={{marginRight: 0}}>
            <div className="list-header">Social</div>
            <ul>
              <li><a href="http://www.instagram.com/1up_events">Instagram</a></li>
              <li><a href="https://twitter.com/Events1up">Twitter</a></li>
              <li><a href="http://www.facebook.com/1upEventsLLC">Facebook</a></li>
              <li><a href="https://www.linkedin.com/company/1up-events/">LinkedIn</a></li>

            </ul>
          </div>
        </div>
        {/* <div className="simple">
          <img src={Logo} className="logo inline-flex" alt=""/>
          <span className="copyright">{`Copyright © ${new Date().getFullYear()} 1up Events, Inc. All Rights Reserved.`}</span>
          <div className="others">
          <Link to="/terms-of-use">Privacy & Terms of Use</Link>
          </div>
        </div> */}
      </div>
    </div>
    <SimpleFooter />
    </div>

  )
}

export default Footer
