import moment from 'moment-timezone'

const initialState = {date: null, people: "", zipcode: "", start_time: "", duration: "", location: "venue", servicable: true, all_locations: [], timezone:  moment.tz.guess(true), location_ids: null, tax: 0.085, allComponents: [], allPackages: []}

function getLocations(all_locations, zipcode){
  var locs = []
  if(all_locations){
    for(var l of all_locations){
      if(l.zipcodes.includes(zipcode)){
        locs.push(l)
      }
    }
  }
  return locs
}

export default function booking(state = initialState, action) {
  var clone;
  switch (action.type) {

    case 'CHANGE':
      clone = Object.assign({}, state);
      clone[action.field] = action.value
      if(action.field === "zipcode"){
        var locations = getLocations(clone.all_locations, action.value)
        clone.timezone = locations.length > 0 ? locations[0].timezone : moment.tz.guess(true)
        clone.location_ids = locations.map(l => l.id)
        clone.tax = locations.length > 0 ? Number(locations[0].tax) : 0.085
      }
      if(action.field === "all_locations"){
        var locations = getLocations(clone.all_locations, clone.zipcode)
        if(!clone.timezone) clone.timezone = locations.length > 0 ? locations[0].timezone : moment.tz.guess(true)
        clone.location_ids = locations.map(l => l.id)
        clone.tax = locations.length > 0 ? Number(locations[0].tax) : 0.085
      }
      console.log(clone)
      return clone

    case "CLEAR":
      clone = Object.assign({}, state);
      clone.date = null
      clone.people = ""
      clone.duration = ""
      clone.start_time = ""
      return clone

    case "SET_ALL_COMPONENTS":
      clone = Object.assign({}, state);
      clone.allComponents = action.components
      return clone

    case "SET_ALL_PACKAGES":   
      clone = Object.assign({}, state);
      clone.allPackages = action.packages
      return clone

    case "SET_LOADING":
      clone = Object.assign({}, state);
      clone.loading = action.isLoading
      return clone
      
    default:
      return state
  }
}
