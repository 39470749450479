import React, { Component } from 'react'
import Modal from '../Modal'
import request from '../../request'
import Search from '../Search'
import AddToCartButton from '../../img/AddToCartButton.svg'
import InCartButton from '../../img/InCartButton.svg'
import moment from 'moment'
import ListItem from '../Powerups/ListItem'
import '../UserDash/AdderStyles.css'
import ServicePage from "../Services/ServicePage"
var categories = ["remote", "food", "drink", "animals", "entertainment", "decor", "logistics"]


const SideBar = ({categories, onSelect, selected}) => {
  return (
    <div className="adder-sidebar" >
      {categories.map(cat =>
        <div className={"category" + (cat === selected ? " selected" : "") } onClick={() => onSelect(cat)}>{cat}</div>
      )}
    </div>
  )
}

function getDetails(data){
  switch(data.unit){
    case "person":
      return (
        <div className="details">
          <div className="sec"><b>{data.qty}</b> people</div>
        </div>
      )
    case "hour":
      return (
        <div className="details">
          <div className="sec"><b>{data.qty}</b> hours</div>
          {data.num_staff  && Number(data.num_staff) > 1 ? <div className="sec"><b>{data.qty}</b> hours</div> : null}
        </div>
      )
    default:
      if(Number(data.qty) > 1){
        return (
          <div className="details">
            <div className="sec"><b>{data.qty}</b> kits</div>
          </div>
        )
      }
  }
}


class Service extends React.Component {

  constructor(props){
    super(props)
    this.state = {view: false}
  }

 render(){
   var {component, key, length, onClick, showModal, viewDetails, selected, onSelect} = this.props

    var image = ""
    if(component.images) image = "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]
    var sel = selected ? " service-selected" : ""
    return (
      <div className={sel} style={{paddingLeft: 12}}>
        <div style={{width: 280, display: 'inline-block'}} key={key} className={"powerup"} onClick={() => onSelect(component)}>
          <div className="powerup-image-container" >
            <img className="powerup-image" src={image} alt={component.name}/>
          </div>
          <div className="powerup-wrapper">
            <div className={"powerup-details"}>
              <div className={"powerup-name" + sel } onClick={() => onSelect(component)}> {component.name} </div>
            </div>
          </div>
          {this.state.view ? <ServicePage readOnly={true} hideActions={true} hidePrice={true} setBooking={null} included={false} booking={{}} add={null} edit={null} fromCart={false} show={this.state.view} inCart={false} component={component} onHide={()=> this.setState({view: false})} category={"food"} />  : null}
        </div>
        {selected && component.id === selected.id ? null : <div className={"view-button" + sel} onClick={() => this.setState({view: true})}>View</div>}
      </div>
    )
  }
}

// <div className="overview-container">
//   <div className="powerup-image-container">
//     <img className="powerup-image" src={image} alt={data.name}/>
//   </div>
//   <div className="name-date">
//     <div className="name">{data.name}</div>
//   </div>
// </div>

const ItemList =({items, booking, actions, onChange, add, remove, added, onSelect, selected, showModal}) => {
  var added_ids = []
  var added_comps = []
  for (let a of added){
    if(a.change !== "remove" && !a.canceled){
      added_ids.push(Number(a.id))
      added_comps.push(a)
    }
  }

  return (
    <div className="item-adder cart-contents">
      {items.map(c => {
        return (
          <div style={{position: 'relative'}} key={c.id} >
              <Service component={c} selected={ selected ? c.id === selected.id : false} onSelect={onSelect}/>
          </div>
        )
      })}
    </div>
  )
}
//            <div style={{width: 280, display: 'inline-block'}}>
//</div>


export default class ItemAdder extends Component{

  constructor(props){
    super(props)
    this.state = {
      items: [],
      category: "food",
      search: "",
      selected: null
    }
  }

  async componentDidMount(){
    var items = await request('/api/vendor/getServicableComponents', {method: "POST", body: JSON.stringify({locations: this.props.locations})})
    if(items.err){
      console.log(items.err)
      return
    }else{
      for(let c of items){
        c.is_available = c.remaining !== 0
        if (c.keywords){
          c.keywords.push(c.name.toLowerCase())
          if(c.options) c.keywords = c.keywords.concat(this.getOptionNames(c.options))
        }else{
          c.keywords = [c.name.toLowerCase()]
          if(c.options) c.keywords = c.keywords.concat(this.getOptionNames(c.options))
        }
        console.log("keywords", c.name, c.keywords)
      }
      this.setState({items})
    }
  }

  select(selected){
    this.props.onSelect(selected)
    this.props.onHide()
  }

  search(comps, search){
    search =  search.toLowerCase();
    console.log("searching", search)
    return comps.filter( c => {
      if (c.keywords){
        for (var k of c.keywords){
          if(k.includes(search)){
            return true
          }
        }
        return false
      }
      return false
    })
  }


  getOptionNames(options){
    var keys = []
    for (let t of options.types){
      keys = keys.concat(t.options.map(opt => opt.name.toLowerCase()))
    }
    return keys
  }

  filter(items, cat, search){
    if(search){
      return this.search(items, search)
    }else{
      return items.filter( c => c.categories && c.categories.includes(cat))
    }
  }

  render(){

    var items = this.filter(this.state.items, this.state.category, this.state.search)
    console.log(this.state.items, items)
    return (
      <Modal show={true} onHide={() => this.props.onHide()} zIndex={2000} customClassName="adder-modal" backdropClassName="custom-backdrop">
        <div className="adder-header">
          <span className="text">Select a Service</span>
          <Search value={this.state.search} category="" updateSearch={(e) => this.setState({search: e.target.value})}/>
        </div>
        <div className="selection-modal">
          <SideBar categories={categories} onSelect={(category) => this.setState({category})}  selected={this.state.category}/>
          <ItemList onSelect={(selected) => this.setState({selected})} selected={this.state.selected} items={items} booking={this.props.booking} remove={this.props.remove} onChange={this.props.edit} add={this.props.add} added={[]} />
        </div>
        <div className="adder-footer">
          <div className={"custom-button vendor" + (!this.state.selected ? " disabled": "" )} onClick={() => this.select(this.state.selected)}> Select Service </div>
          <div className="secondary-button" onClick={() => this.props.newService()}> Submit New Service </div>

        </div>
      </Modal>
    )
  }
}
