import React from 'react'
import ConfirmationModal from './ConfirmationModal'
import pluralize from 'pluralize'

function limitationTitle(component, store, limitation){
  if(limitation === "min"){
    switch(component.unit){
      case "hour":
        return component.name + " requires booking for a minimum of " + component.minimum + (component.minimum === 1 ? " hour." : " hours.")
      case "kit":
        return component.name + " requires booking a minimum of " + component.minimum + pluralize(component.unit_name || " kit") + "."
      default:
        return component.name + " requires booking for a minimum of " + component.minimum + " people."
    }
  }
  switch(component.unit){
    case "hour":
      return component.name + " has a maximum booking of " + component.maximum + (component.minimum === 1 ? " hour" : " hours") + "."
    case "kit":
      return component.name + " has a maximum booking of " + component.maximum + " " + pluralize(component.unit_name || "kit") + "."
    default:
      return component.name + " has a maximum booking of " + component.maximum + " people."

  }
}

function limitationText(component, store, limitation){
  var billedFor = ""
  var current = ""
  if(limitation === "min"){
    switch(component.unit){
      case "hour":
        billedFor = component.minimum + (component.minimum === 1 ? " hour" : " hours")
        current = store.duration +  (store.duration === 1 ? " hour" : " hours")
        return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b> even though your event is currently set for only {current}.</span>
      case "kit":
        billedFor = component.minimum + " " + pluralize(component.unit_name || "kit")
        return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b>.</span>
      default:
        billedFor = component.minimum + " people"
        current = store.people +  (store.people === 1 ? " person" : " people")
        return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b> even though your event is currently set for only {current}.</span>

    }
  }

  return <span> You can add {component.name} to your cart, but you may not have enough at your event.</span>
}

const LimitationModal = ({component, store, limitation, onConfirm, onHide, show}) => {
  return (
    <ConfirmationModal title={() => limitationTitle(component, store, limitation)} text={() => limitationText(component, store, limitation)} show={show} onHide={onHide} onConfirm={onConfirm}/>
  )
}


export default LimitationModal
