import React, { Component } from 'react'
import './Promotions.css'
import '../../css/PackageBooked.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../actions/'
import { withRouter, Link } from 'react-router-dom'
import ServiceItem from '../Services/ServiceItem'
import request from '../../request'
import Header from '../Header/Header'
import Footer from '../Utils/Footer'
import Alert from '../Utils/Alert'
import Title from '../Title'
import { getStartTime } from '../Utils/math'
import moment from 'moment-timezone'


class PackageBooked extends Component {

  constructor(props){
    super(props)
    this.state = {
      id: "",
      name: "",
      description: "",
      powerups: [],
      packages: [],
      missing: false,
      componentsRemoved: props.location && props.location.state && props.location.state.componentsRemoved.length > 0 ? props.location.state.componentsRemoved : null,
    }
  }

  async componentDidMount(){
    var start_time;
    var end_time;
    let timezone;
    if(this.props.booking.date && this.props.booking.start_time){
      start_time = getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)
      end_time = this.props.booking.duration ? moment(start_time).add(this.props.booking.duration, "hours") : start_time.add(1, "hours")
      timezone = this.props.booking.timezone
    }
    this.getContents(start_time, end_time, timezone)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.booking.date !== this.props.booking.date || this.props.booking.location_id !== nextProps.booking_id || nextProps.booking.start_time !== this.props.booking.start_time || nextProps.booking.duration !== this.props.booking.duration) {
      var start_time = getStartTime(nextProps.booking.date, nextProps.booking.start_time, nextProps.booking.timezone)
      var end_time = nextProps.booking.duration ? moment(start_time).add(nextProps.booking.duration, "hours") : start_time.add(1, "hours")
      var timezone = this.props.booking.timezone
      this.getContents(start_time, end_time, timezone);
    }
  }

  async getContents(start_time, end_time, timezone){
    var res;
    var name;
    if(this.props.match.params.name) name = this.props.match.params.name.toLowerCase()
    if(start_time && end_time){
      res = await request('/api/promo/'+ name, {method: "POST", body: JSON.stringify({start_time, end_time, timezone})})
    }else{
      res = await request('/api/promo/'+ name)
    }
    if (res.err){
      console.log(res.err)
      return
    }
    this.setState({powerups: res.components, packages: res.packages, name: res.name, description: res.description, id: res.id})
  }

  missing(params){
    var missingParams = false;
    for(var key in params){
      if(!params[key]){
        missingParams = true
        break
      }
    }
    return missingParams
  }

  missingClicked(){
    window.scrollTo(0, 0)
    this.setState({missing: true})
    this.props.actions.assignMissing("missingAlert")
  }

  missingDismissed(){
    this.setState({missing: false})
    this.props.actions.assignMissing("missing")
  }

  getAlertBody(name){
    let list = ""
    if(!this.state.componentsRemoved) return list
    if(this.state.componentsRemoved.length > 1){
      let last = this.state.componentsRemoved[this.state.componentsRemoved.length - 1]
      list = this.state.componentsRemoved.slice(0, this.state.componentsRemoved.length - 1).join(", ") + " and " + last
    }else{
      list = this.state.componentsRemoved[0]
    }

    return "Because "+name+" already includes "+ list +", we've removed "+(this.state.componentsRemoved.length > 1 ? "them": "it")+ " from your cart."

  }


  render(){
    var cartMargin = (this.props.app.cart) ? {marginRight: 320} : {marginRight: 0}
    cartMargin.paddingLeft = 100
    cartMargin.paddingBottom = 100

    return (
    <div className="promotions">
      <Header />
        <div >
          <div className="powerup_list mx-auto max-w-full relative px-0 mb-12" id="powerup-list" style={cartMargin}>
            {this.props.login.admin ? <Link to={{pathname:"/admin_panel/promo/"+ this.state.id}} className="edit"><i style={{color: "var(--primary-content-color)", fontSize: 20, marginRight: -20, verticalAlign: "top"}}  className="fa fa-pencil-square-o"></i></Link> : null }
            <Title title={this.state.name} wrapperClassName="pl-4 sm:pl-[50px] pr-4" subtitle={this.state.description} />
            <ul className="sublist mt-8 pl-4 sm:pl-[50px] flex gap-x-12 gap-y-8 sm:gap-y-12 flex-wrap m-0 p-0">
              {this.state.powerups.map( (component, i) =>
                <li key={i} className='ml-0'>
                  <ServiceItem addOn={true} component={component} path={"/builder/" + component.categories[0] + "/" + component.id + "/" + component.name.replace(/\//g,"-")} />
                </li>
              )}
            </ul>
          </div>
        </div>
        <Footer style={{paddingRight: cartMargin.marginRight + 67}}/>
      <Alert show={this.state.missing} buttonText="Got It!" zIndex={9980} backdropClassName=" high-z" onHide={()=> this.missingDismissed()} title="Enter all your event information into the Book Bar." text="Once entered, we can show you pricing as you add items and let you Review & Book."/>
    </div>
    )
  }
}

const mapStateToProps = state => ({
  pack: state.cart.package, app: state.app, booking: state.booking, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageBooked))
