import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header'
import Title from '../Title'
import ComponentSearch from './ComponentSearch'

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class EditPromotion extends Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      description: "",
      components: "",
      packages: "",
      error: ""
    }
  }

  async validate(){
    let jsonList = ["packages", "powerups"]
    try{
      for(var c of jsonList){
        if(this.state[c]){
          try{
            JSON.parse(this.state[c])
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      if(!this.state.name){
        document.getElementById("name").focus()
        this.setState({error: "Name can't be empty"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  componentDidMount(){
    this.getData(this.props)
  }

  async getData(props){
    var promo = await request('/api/admin/promo/'+ props.match.params.id)
    this.setState({name: promo.name, description: promo.description, components: JSON.stringify(promo.components), packages: JSON.stringify(promo.packages) })
  }


  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate()
    if(validated){
      var promo = {name: this.state.name, description: this.state.description, components: this.state.components, packages: this.state.packages}
      var res  = await request("/api/admin/promo", {method: "PUT", body: JSON.stringify({promo, id: this.props.match.params.id } )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.setState({name: res.name, description: res.description, components: JSON.stringify(res.components), packages: JSON.stringify(res.packages), error: "Updated" })

      }
    }
  }

  render(){
    let url = this.state.name.trim()
    url = url.replace(/\//g,"-")
    url = url.replace(/ /g,"-")
    url = url.toLowerCase()

    return (
      <div>
        <Header />
        <div style={{marginTop: 40, marginLeft: 40}}>
          <Title title="Edit Promotion"/>
        </div>
        <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          <FieldGroup
               id={"name"}
               key={"name"}
               type="text"
               label={"name"}
               className="text-field"
               placeholder={"name"}
               value={this.state.name}
               onChange={(e)=> this.setState({name: e.target.value})}
               bsSize="sm"
             />
         <FieldGroup
              id={"url"}
              key={"url"}
              type="text"
              label={"url"}
              className="text-field"
              placeholder={"url"}
              value={url}
              disabled={true}
              bsSize="sm"
            />
          <FormGroup key={"description"} controlId={"description"}>
            <ControlLabel>{"description"}</ControlLabel>
            <FormControl className="text-field" componentClass="textarea" style={{resize: "vertical"}} rows="8"  bsSize="sm" placeholder={"description"} value={this.state.description} onChange={(e)=> this.setState({description: e.target.value})}/>
          </FormGroup>
          <FieldGroup
               id={"packages"}
               key={"packages"}
               type="text"
               label={"packages (comma separated)"}
               className="text-field"
               placeholder={"1,2,3"}
               value={this.state.packages}
               onChange={(e)=> this.setState({packages: e.target.value})}
               bsSize="sm"
             />
         <FieldGroup
              id={"powerups"}
              key={"powerups"}
              type="text"
              label={"powerups (comma separated)"}
              className="text-field"
              placeholder={"1,2,3"}
              value={this.state.components}
              onChange={(e)=> this.setState({components: e.target.value})}
              bsSize="sm"
            />
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
        </div>
        <ComponentSearch />
      </div>
    )
  }
}

export default withRouter(EditPromotion)
