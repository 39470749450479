import React from 'react'
import './SurveyFlow.css'
import Title from '../Title'
import CheckIcon from '../../img/CheckmarkCircle.svg'

function getOffset(el) {
  el = el.getBoundingClientRect();
  return {
    left: el.left + window.scrollX,
    top: el.top + window.scrollY
  }
}

function dots(index, length, setIndex){
  var ds = []
  var indexes = [...Array(length).keys()]
  ds = indexes.map(i => {
    if(i < index){
        return (<div key={"dot"+i}><img onClick={() => setIndex(i)} src={CheckIcon} alt="complete" className={"dot check"} />{(i !== length -1 ? <div className="line"/> : null)}</div>)
      }else{
        return (<div key={"dot"+i}><div className={"dot empty"} />{(i !== length -1 ? <div className="line"/> : null)}</div>)
      }
  })
  if(index < length){
    ds.push(<div key={"fulldot"} className={"dot full"} style={{left: index*88}} />)
  }
  return ds
}

const SurveyFlow = ({children, back, next, length, index, submit, canProgress, setIndex}) => {
  console.log("canProgress", canProgress, children)
  return (
    <div className="survey" id="survey">
      <div className="dots flex h-[20px] items-center">
        {dots(index, length, setIndex)}
      </div>
      <div className="card">
        {children}
        {index < length ?
          <div className="bottom">
            <button className={"secondary-button" + (index === 0 ? " disabled" : "")} onClick={() => {if(index !== 0) back(); window.scrollTo(0, getOffset(document.getElementById("survey")).top - 20)}}>Back</button>
            {index === length - 1 ? <button className={"custom-button" + (canProgress ? "" : " disabled")} onClick={() => {submit(); window.scrollTo(0, getOffset(document.getElementById("survey")).top - 20)}}>Finish</button> : <button className={"custom-button" + (canProgress ? "" : " disabled" )} onClick={() => {if(canProgress) next(); window.scrollTo(0, getOffset(document.getElementById("survey")).top - 20)}}>Continue</button>}
          </div>
        : null}
      </div>
    </div>
  )
}

export default SurveyFlow
