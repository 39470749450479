import React , { Component } from 'react'
import RegularPackage from '../Services/RegularPackage'
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from '../Utils/GetWindowDimensions';

function PrevArrow({ className, style, onClick }) {
  return (
    <div
      className={'absolute z-[1] shadow-lg left-4 top-1/2 bg-black/50 transform -translate-y-1/2 rounded-full cursor-pointer'}
      onClick={onClick}
    >
      <ChevronLeft color="white" className='w-8 h-8'/>
    </div>
  );
}

function NextArrow({ className, style, onClick }) {
  return (
    <div
      className={'absolute shadow-lg right-4 top-1/2 transform -translate-y-1/2 bg-black/50 rounded-full  cursor-pointer'}
      onClick={onClick}
    >
      <ChevronRight color="white" className='w-8 h-8'/>
    </div>
  );
}

function PackageCarousel({packages}) {
  const {width, height} = useWindowDimensions()
  
  var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    draggable: true,
    // dotsClass: "bottom-6 shadow-lg bg-black/50 p-2 w-fit left-1/2 transform -translate-x-1/2 rounded-full flex absolute",
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // appendDots: dots => (
    //   <div className=''>
    //     <ul className='flex space-x-5'> {dots} </ul>
    //   </div>
    // ),
    customPaging: i => (
      <div className='h-2 w-2 rounded-full bg-white/50 '/>
    ),
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
    {
      breakpoint: 400,
      settings: {
        className: "smallcenter"
      }
    },
  ]
  };

  return (
    <div className='w-full flex justify-center relative'>
    <div className={"w-full max-w-[1200px] flex justify-center h-full overflow-hidden carousel " + (width > 590 ? " cmask" : "")}>
      <div className="w-[1200px] h-full">
      <Slider {...settings}>
        {packages.map( (p, i) => {
            var angle = i%2 ? "jaunty" : "unjaunty"
            return <div className={"packageListItem " + angle} key={i}><RegularPackage noIcon={true} component={p} path={"/events/packages/all/"+ p.id + "/" + p.name.replace(/\//g,"-")} /></div>
          })
        }
      </Slider>
      </div>
    </div>
    </div>
  );

}


export default class PackageList extends Component{

  constructor(props){
    super(props)
    this.state = {
      packages: []
    }
  }

  filterFeatured(packages, num){
    var d = new Date();
    var month = d.getMonth();
    var featured = packages.filter(c => {
      if(c.seasonal){
        return c.seasonal.includes(month)
      }
      return false
    })
    featured = featured.slice(0, num)
    if(featured.length < num) featured = featured.concat(packages.slice(0, num - featured.length))
    return featured
  }

  getData(){
    fetch('/api/packages', {method: "POST", headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                } } )
      .then(res => res.json())
      .then(packages =>
        {
          if(packages.err){
            console.log(packages.err)
          }else{
            packages = this.filterFeatured(packages, 5)
            console.log(packages)
            this.setState({packages})
          }
        })
      .catch(function(reason) {
        console.log(reason)
      });
  }

  componentDidMount(){
    this.getData()
  }

  render(){
    return (
      <div className={this.props.className}>
        {this.props.children}
        <PackageCarousel packages={this.state.packages} />
      </div>
    )
  }
}
