import React from 'react'
import Static from './StaticWrapper'
import './EventHost.css'
import Title from '../Title'
import request from '../../request'
import Image from '../Utils/SpecialImage'
import EH from '../../img/Vendor.jpg'
import {Dropdown, MenuItem} from 'react-bootstrap'

const Vendor = (props) => {

  const submit = async () => {
    window.scrollTo(0,0)
    props.history.push('/vendor/application')
  }

  return (
    <Static className="event-host mx-auto max-w-full relative lg:mt-[115px] mt-8">
      <div className="static-page-header px-4 w-[1028px]  max-w-full md:text-[44px] text-[36px]" style={{marginBottom: 40}}>Be a 1up Vendor</div>
      <div className="section-wrapper px-4 max-w-full">
        <div className="party max-w-full">
          <div className="long-text max-w-full pb-10">
            <Title title="Bring the Goods." />
            <div className="text max-w-full mb-2 mr-0 md:mr-10">
              <p>As a 1up Vendor, you are the backbone of our company.</p>
              <p>It’s all about letting your product/service take center stage and providing an incredible experience to our amazing clients.</p>
              <p>We exclusively work with the top vendors in the area. So, if you feel you have a great product/service to offer and a fantastic record of customer service, we definitely want to hear from you!</p>
              <p>As our client base continues to grow, we continually need to add more amazing vendors who provide the same services we offer so we can ensure availability in all cases. So, even if you already see a similar service on our site to what you provide, we definitely still want to hear from you!</p>
              <p>We’re regularly expanding our offerings. In addition to providing all the basics, we’re excited to offer creative/out-there niches. If you have a fun/unique/random/silly skill -- think plate-spinning or sand castle artistry, we’d love to hear from you! The joy of discovery and the originality of our offerings is just one of the many reasons our clients love working with us.</p>
              <p>On the logistics side, we'll match you up with events based on your services, location, and availability. The day of, you just show up on time and wow our clients! When it's over, clean up and pack up (typical camping "leave no trace" policy). We’ll then share all client feedback with you following the event.</p>
            </div>
            <Image src={EH} className={"max-w-full"} caption="One of our awesome DJ's at Segment's Jungle Party"/>
          </div>
          <div className="requirements  mx-auto flex grid gap-x-8 gap-y-6 grid-cols-1 md:grid-cols-3">
            <div className="sect flex-none max-w-full  mx-0">
              <div className="section-header">How 1up Works</div>
              <ul className="description">
                <li>• We work with the client directly so you only have 1 point of contact: us.</li>
                <li>• There’s no back and forth. We’ll send you the booking information with all the information you need.</li>
                <li>• Easy, timely payment processing.</li>
              </ul>
            </div>
            <div className="sect flex-none max-w-full  mx-0">
              <div className="section-header">Advantages of Working with Us</div>
              <ul className="description">
                <li>• More business! Access to top clients.</li>
                <li>• Easy revenue! We bring the business to you.</li>
                <li>• Great photos of your product/service.</li>
                <li>• Publicity: We send out regular newsletters featuring different services</li>
              </ul>
            </div>
            <div className="sect flex-none max-w-full  mx-0">
              <div className="section-header">Requirements</div>
              <ul className="description">
                <li>• A service or skill you’re an expert in.</li>
                <li>• A proven track record of excellent customer service.</li>
                <li>• Reliable and Timely: must show up on time.</li>
                <li>• Competitive pricing.</li>
                <li>• Responsiveness: we need to know immediately whether or not you’re available for a booking.</li>
              </ul>
            </div>
          </div>
          <div className="card my-form max-w-full" style={{padding: 20}}>
            <Title title="Apply Now to be a 1up Vendor" subtitle="We'll be in touch shortly after you submit." />
            <div className="custom-button" onClick={()=> submit()} > Apply Now </div>
          </div>
        </div>
      </div>
    </Static>
  )
}

export default Vendor
