import React from 'react'
import {Link} from 'react-router-dom'
import '../../css/StaticPages/Booked.css'
import Logo from '../../img/1upLogoHeart.svg'
import Static from './StaticWrapper'
import Image from '../Utils/SpecialImage'

const Booked =  ({location}) => {
  var event_id = 0
  var image = null
  if(location && location.state && location.state.result) event_id = location.state.result.event_id
  if(location && location.state && location.state.image) image = "https://d2c831se9gn8j1.cloudfront.net/" + location.state.image

  return (
    <Static className="booked-wrapper">
      <div className="booked card">
        <Image src={image || "https://d2c831se9gn8j1.cloudfront.net/packages/jungle04.jpg"} caption={image ? "" : "Jungle Party at Segment"} className="booked-image" />
        <div className="content">
          <div className="title">Your event has been booked!</div>
          <div className="text">
            <p>Your booking confirmation code is <b>{event_id}</b>.</p>
            <p>You can see <Link to={"/your-events/"+ event_id } className="link"> Your Event </Link> to make changes to before it starts or to change the contact information.</p>

            <p><Link className="visit-dash" to="/your-events">See All Your Events.</Link></p>

            <p>We’ll be in touch around a week before your event to make sure all the details are correct.</p>

            <p><Link className="visit-dash" to="/how-it-works">Learn about each step of the event process.</Link></p>

            <p>Thank you so much for booking with us. We can’t wait to throw an awesome event for you.</p>

            <p>See you soon!</p>
          </div>
          <img src={Logo} height="39px" style={{marginTop: 15}} alt=""/>
        </div>
      </div>
    </Static>
  )
}

export default Booked
