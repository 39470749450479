import React from 'react'

class Events extends React.Component{


  render(){
    return (
      <div> TODO </div>
    )
  }
}

export default Events
