import React from 'react'
import { getServicePrice } from '../Utils/math'
import CheckboxControl from '../Utils/CheckboxControl'
import request from '../../request'

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}

class VendorList extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      vendors: [],
      edited: false
    }
  }

  componentDidMount(){
    this.getVendors()
  }

  async getVendors(){
    var vendors = await request('/api/admin/events/' + this.props.event.event.id + "/vendors")
    if(vendors.err){
      console.log(vendors)
    }else{
      this.setState({vendors})
    }
    return
  }

  updatePayment(id, value, key ){
    var vendors = cloneObj(this.state.vendors)
    var v = vendors.find(v => v.id === id)
    v[key] = value
    this.setState({vendors, edited: true})
  }

  async save(){
    var vendors = this.state.vendors.map(v => {return {deposit_paid: v.deposit_paid, remainder_paid: v.remainder_paid, id: v.id}})

    var res = await request('/api/admin/vendors/bookings', {method: "PUT", body: JSON.stringify(vendors)})
    if(res.err){
      console.log(res.err)
    }else{
      this.getVendors()
      this.setState({edited: false})
    }
  }

  cancel(){
    this.setState({
      vendors: this.props.vendors,
      edited: false
    })
  }

  render(){
    var {booking, start_time, timezone} = this.props
    return (
      <div className="vendors">
        {this.state.vendors.map( (v, i) =>
          <div className="vendor" key={i}>
            <div className="service-name">{v.service_name}</div>
            {v.canceled &&
              <div className="canceled-service">
                CANCELLED
              </div>
            }
            <div>{v.name}</div>
            <div>{v.company_name}</div>
            <div>{v.phone}</div>
            <div>{v.email}</div>
            <div>Cost: ${getServicePrice(v, booking, start_time, timezone)} </div>
            <div>Deposit: ${ getServicePrice(v, booking, start_time, timezone) * (Number(v.deposit || 0)/100)} </div>
            <div>Paid: ${Number(v.deposit_amount + v.remainder_amount)/100} </div>
            <div>
              <CheckboxControl style={{marginBottom: 8}} checked={v.deposit_paid}  onChange={(value) => this.updatePayment(v.id, value, "deposit_paid")}/>
              <label style={{marginLeft: 10}} onClick={() => this.updatePayment(v.id, !v.deposit_paid, "deposit_paid")}>Deposit Paid</label>
            </div>
            <div>
              <CheckboxControl checked={v.remainder_paid} onChange={(value) => this.updatePayment(v.id, value, "remainder_paid")}/>
              <label style={{marginLeft: 10}} onClick={() => this.updatePayment(v.id, !v.remainder_paid, "remainder_paid")}>Remainder Paid</label>
            </div>
          </div>
        )}
        {this.state.edited ?
          <div>
            <div className="secondary-button" style={{float: "none"}} onClick={() => this.cancel()}>Cancel</div>
            <div className={"custom-button"} style={{float: "none", marginLeft: 9}} onClick={() => this.save()}>Save Changes</div>

          </div>
          :null
         }
      </div>
    )
  }
}

// const VendorList = ({vendors, booking, start_time, timezone, updatePayment}) => {
//
// }

export default VendorList
