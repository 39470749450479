import React from 'react'
import '../../css/Utils/SectionHeader.css'

const SectionHeader = ({name, pack}) => {
  var addon = ""
  if(pack && name !== "package") addon = "ADD-ON "
  return (
    <div className="section-header">
      <div className="header-name" > {addon + name.toUpperCase()} </div>
      <div className="line" />
    </div>
  )
}

export default SectionHeader
