import React, { Component } from 'react'
import Checkmark from '../../img/CheckmarkCheck.svg'
import './CheckboxControl.css'


class CheckboxControl extends Component{

  render(){
    return (
      <div style={this.props.style} className={ "custom-checkbox" + (this.props.checked ? " checked" : " unchecked") + (this.props.className || "")} onClick={() => this.props.onChange(!this.props.checked)} >
        <img className={this.props.checkMarkStyle || "checkmark"} src={Checkmark} alt="" />
      </div>
    )
  }
}

export default CheckboxControl
