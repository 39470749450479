import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import BookBar from '../Header/BookBar'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { getStartTime } from './math'
import { Dropdown, MenuItem } from 'react-bootstrap'
import './Reschedule.css'
import Title from '../Title'

class BookBarModal extends Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }



  render(){
    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="generic-modal booking-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")} onHide={() => this.props.onHide()}>
        <Title title="Change Event Details"/>
        <div style={{display: "block", width: "100%", marginTop: -12}}>
          <BookBar />
          <div className={"custom-button"} style={{float: "right", marginLeft: 9}} onClick={this.props.onHide}>Done</div>
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBarModal))
// export default CreateWishlist
