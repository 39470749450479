import React from "react"
import request from '../../request'
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, Table} from 'react-bootstrap'

function makeLowerCase(a){
  if(a){
    return a.toLowerCase()
  }
  return ""
}

export default class Services extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      search: "",
      search_id: "",
      service_id: "",
      rating: "",
      services: []
    }
  }

  async getServices(){
    var services = await request('/api/admin/services')
    if(services.err){
      console.log(services)
    }else{
      this.setState({services})
    }
  }

  componentDidMount(){
    this.getServices()
  }

  search(e){
    if(this.state.search){
      return (makeLowerCase(e.vendor_name).includes(this.state.search.toLowerCase()) || makeLowerCase(e.service_name).includes(this.state.search.toLowerCase()) || makeLowerCase(e.company_name).includes(this.state.search.toLowerCase()) )
    }else if(this.state.search_id){
      return this.state.search_id && Number(e.id) === Number(this.state.search_id)
    }
    return true
  }

  async setRating(){
    var services = await request('/api/admin/services/' + this.state.service_id + "/rating", {method: "PUT", body: JSON.stringify({rating: this.state.rating})})
    this.getServices()
  }

  async enableAutopay(v_id, s_id){
    var services = await request('/api/admin/vendor/' + v_id + '/service/' + s_id + "/enableAutopay", {method: "PUT"})
    this.getServices()
  }

  render(){
    return (
      <div className="card" style={{marginTop: 20, padding: 20}}>
        <div>
          <span>Search:  </span>
          <input type="text" className="text-field" placeholder="Search by any name" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
          <input type="number"className="text-field" style={{marginLeft:20}} placeholder="Search by id" value={this.state.search_id} onChange={(e) => this.setState({search_id: e.target.value})}/>
        </div>
        {this.state.edit_rating ?
          <div>
            <span>Edit Rating: </span>
            <input type="number" className="text-field" placeholder="Service ID" style={{marginRight:20}} value={this.state.service_id} onChange={(e) => this.setState({service_id: e.target.value})}/>
            <input type="number"className="text-field" style={{marginRight:20}} placeholder="Rating" value={this.state.rating} onChange={(e) => this.setState({rating: e.target.value})}/>
            <button onClick={() => this.setRating()} style={{float: "none"}} className="secondary-button">Set</button>
          </div>
        : <button onClick={() => this.setState({edit_rating: true})} style={{float: "none"}} className="secondary-button">Edit Rating</button>}
        <Table>
          <thead>
            <tr>
              <th>Edit</th>
              <th>AutoPay</th>
              <th>AP Enabled</th>
              <th>Service ID</th>
              <th>Service Name</th>
              <th>Vendor Name</th>
              <th>Company Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Vendor ID</th>
              <th>Component ID</th>
              <th>Payment Method</th>
              <th>Discount</th>
              <th>1up Rating</th>
              <th>Active</th>
              <th>Description</th>
              <th>Price</th>
              <th>Unit</th>
              <th>Minumim</th>
              <th>Setup</th>
              <th>Booking Lead Time</th>
              <th>Requirements</th>
              <th>Max Events</th>
              <th>Deposit</th>
              <th>Flat Fee</th>
              <th>Late Booking Percentage</th>
              <th>Late Booking Threshold</th>
              <th>Normal Duration</th>
              <th>Extra Hours fee</th>
              <th>Extra Hours Fee Scalar</th>
              <th>Extra Hours Fee Scalar Unit</th>
              <th>Typical # of Staff</th>
              <th>Attendees per Staff</th>
            </tr>
          </thead>
          <tbody>
            {this.state.services.map((e, i) => (this.search(e) ?
                <tr key={i}>
                  <td> <Button type="button" onClick={()=> this.props.history.push('/admin_panel/service/' + e.id)}>Edit</Button></td>
                  <td>{e.autopay && e.autocharge_enabled ? "Allowed" : <Button type="button" onClick={()=> this.enableAutopay(e.vendor_id, e.id)}>Allow</Button>}</td>
                  <td>{e.autopay && e.autocharge_enabled && e.stripe_account_id ? "true" : "false"}</td>
                  <td>{e.id}</td>
                  <td>{e.service_name || e.name}</td>
                  <td>{e.vendor_name}</td>
                  <td>{e.company_name}</td>
                  <td>{e.phone}</td>
                  <td>{e.email}</td>
                  <td>{e.vendor_id}</td>
                  <td>{e.component_id}</td>
                  <td>{e.payment_method}</td>
                  <td>{e.discount}</td>
                  <td>{e.oneup_rating}</td>
                  <td>{e.is_active === null ? "Awaiting review" : e.is_active.toString()}</td>
                  <td>{e.description}</td>
                  <td>{e.price}</td>
                  <td>{e.unit}</td>
                  <td>{e.minimum}</td>
                  <td>{e.setup_time}</td>
                  <td>{e.booking_lead_time}</td>
                  <td>{JSON.stringify(e.requirements)}</td>
                  <td>{e.max_events}</td>
                  <td>{e.deposit}</td>
                  <td>{e.flat_fee}</td>
                  <td>{Number(e.late_booking_percentage || 0) * 100}%</td>
                  <td>{e.late_booking_threshold} days</td>
                  <td>{e.normal_duration}</td>
                  <td>{e.extra_hours_fee}</td>
                  <td>{e.extra_hours_fee_scalar}</td>
                  <td>{e.extra_hours_fee_scalar_unit}</td>
                  <td>{e.typical_num_staff}</td>
                  <td>{e.attendees_per_staff}</td>
                </tr> : null)
              )}
          </tbody>
        </Table>
      </div>
    )
  }


}
