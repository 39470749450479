import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import currencyFormatter from 'currency-formatter';
import Card from '../Card'
import AddToCartButton from '../../img/AddToCartButtonWhite.svg'
import AvailabilityIcon from '../../img/SoldOutViewAvailabilityIcon.svg'
import InCartButton from '../../img/InCartButton.svg'
import moment from 'moment'
import request from '../../request'
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import LimitationModal from '../Utils/LimitationModal'
import { getPackagePrice, getStartTime, getLateFee } from '../Utils/math'
import Hearting from '../Utils/Hearting'
import AvailabilityViewer from '../Utils/AvailabilityViewer'

class RegularPackage extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      calendar: false,
      unavailable: [],
      limitation: false,
      scroll: null,
      right: null,
      availability: []
    }

  }

  // componentWillReceiveProps(nextProps) {
  //
  //   if (nextProps.component.id !== this.props.component.id) {
  //     console.log("getting revised data")
  //
  //     this.getAvailability(nextProps, "recevied")
  //   }
  // }

  componentDidUpdate(prevProps){

    let start = getStartTime(this.props.store.date, this.props.store.start_time, this.props.store.timezone)
    let old_start = getStartTime(prevProps.store.date, prevProps.store.start_time, prevProps.store.timezone)
    if((!this.props.component.is_available && !start.isSame(old_start, "minute")) || (!this.props.component.is_available && prevProps.component.is_available) ){
      if(this.props.component.booking_lead_time && Number(this.props.component.booking_lead_time) > start.diff(moment(), "days")){
        this.setState({availability: []})
      }else{
        this.getAvailability(this.props)
      }
    }
  }

  async getAvailability(props){
    console.log("gettingA", props.component.name, this.state.availabilty, props.store)
    if(!props.component.is_available){
      var start_time = moment(props.store.date).set('hour', 0).set('minute', 0).set('second', 0);
      var end_time = moment(start_time).add(1, "day").add(props.store.duration, "hours")
      let timezone = props.store.timezone || ""
      timezone = timezone.replace("/", "%2F")
      var availability = await request('/api/availability/package/' + props.component.id + "/" + start_time + "/" + end_time + "/" + timezone)
      // console.log("gettingA", props.component.name, availability)
      if(!availability.err){
        this.setState({availability})
      }
    }

  }


  async componentDidMount(){
    if(this.props.component){
      console.log("GETTING Availability")
      this.getAvailability(this.props)
    }
  }

  past(date){
    return date < moment().add(3, 'days')
  }

  blockOut(date){
    if(date < moment().add(this.props.component.booking_lead_time || 2, 'days')){
      return true
    }
    for (var d of this.state.unavailable){
      if(moment(d).isSame(date, 'day')){
        return true
      }
    }
    return false
  }

  addToCart(component){
    if(component.maximum && component.maximum > this.props.store.people){
      this.setState({limitation: "max"})
    }else{
      this.props.addToCart()
    }
  }


  confirmedAddToCart(){
    this.setState({limitation: false})
    this.props.addToCart()
  }


  getPrice(component, store){
    if(component.calculated_price){
      return <span>{currencyFormatter.format(component.calculated_price, { code: 'USD', precision: 0})}</span>
    }
    var late_fee = getLateFee(moment(), getStartTime(store.date, store.start_time, store.timezone))
    var price = getPackagePrice((component.component_details || []), store, component.discount, component.setup_time, component.takedown_time, late_fee)
    if(!store.people || !store.duration){
      return <span><span style={{fontWeight: "normal"}} className="faded">Starting at </span>{currencyFormatter.format(price, { code: 'USD', precision: 0})}</span>
    }
    return <span>{currencyFormatter.format(price, { code: 'USD', precision: 0})}</span>
  }

  render(){
    var {component, inCart, store, path, filter} = this.props
    var name = component.name.replace(/\//g,"-")
    name = component.name.replace(/ /g,"-")
    var id =  component.id
    path = path || "/events/packages/" + (filter ? filter + "/" : "" ) + id + "/" + (name || "")
    var c = () => {
      if(this.props.noIcon){
        return null
      }
      if(!component.is_available){
        // <div className="my-small-caret"/>
        return (
          <div style={{width: 41, height: 32}} >
              <AvailabilityViewer name={component.name} lead_time={component.booking_lead_time} start={getStartTime(store.date, store.start_time, store.timezone)} right={right} availability={this.state.availability} />
            </div>
          )
      }
      return inCart ?               
      <div style={{width: 36, height: 36, alignContent: 'center'}}>
        <img alt="" src={InCartButton} />
      </div>
      : <div className="bg-primary-action-color add rounded float-right hover:bg-primary-action-color-hover active:bg-primary-action-color-hover" style={{width: 36, height: 36, alignContent: 'center', paddingLeft: 1}}>
          <img alt="" className="i" src={AddToCartButton}  onClick={() => this.addToCart(component)} />
        </div>
    }
    var image = ""
    if(component.images && component.images[0]) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]
    var image2 = image
    if(component.images && component.images[1]) image2 =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[1]
    var right = false
    if(document.getElementById("powerup-list")){
      setTimeout(() => {if(this.state.scroll !== document.getElementById("powerup-list").scrollWidth && document.getElementById(component.id)) this.setState({right: document.getElementById(component.id).getBoundingClientRect().right, scroll: document.getElementById("powerup-list").scrollWidth })}, 400)
      if(this.state.scroll){
        right = this.state.scroll - this.state.right < 320
      }
    }
    return (
    <div className="reg-package-container" id={component.id} key={this.props.key}>
      <Card className="package-regular" >
        <div className="flex" style={{overflow: 'hidden', height: 167}}>
          <Link to={path} className="h-[167px] w-[168px]" style={{borderRight: "1px solid white", display: "inline-block"}}>
            <img className="h-full w-auto object-cover " src={image} alt={component.name}/>
          </Link>
          <Link to={path} className="h-[167px] w-[168px]" style={{display: "inline-block"}}>
            <img className="h-full w-auto object-cover self-center" src={image2} alt={component.name}/>
          </Link>
        </div>
        <Link to={path} className="name">{component.name}</Link>
        <div className="description">{component.short_description}</div>
        {/* {this.props.login.admin ? <Link to={{pathname:"/admin_panel/package/"+ component.id, state: component }} className="edit"><i style={{color: "var(--primary-content-color)"}} className="fa fa-pencil-square-o"></i></Link> : null } */}
      </Card>
      <div className='absolute bottom-0 w-full h-fit p-2 pl-[11px] flex justify-between items-center'>
            <div className='flex'>
              <div className="price float-left">{this.getPrice(component, store)}</div>
            </div>
            <div className='flex space-x-2 items-center'>
              {this.props.login.admin ? <Link to={{pathname:"/admin_panel/powerup/"+ component.id, state: component }} className="edit"><i style={{color: "var(--primary-content-color)"}} className="fa fa-pencil-square-o w-4"></i></Link> : null }
              {this.props.noIcon ? null :  <Hearting right={true} lists={this.props.login.wishlists || []} component={component} isPackage={true} />}
              {c()}
            </div>
          </div>

      {/* {this.props.noIcon ? null :  <Hearting right={right} lists={this.props.login.wishlists || []} right={right} component={component} isPackage={true} />}
      {this.props.noIcon ? null:
        c()
      } */}
      {this.state.calendar ?
        <div className={"calendar-wrapper" + (right ? " right" : "")}>
        <DayPickerSingleDateController
          date={this.props.store.date ? moment(this.props.store.date) : null}
          focused={this.state.calendar}
          onDateChange={(date) => this.props.actions.changeParams("date", date)}
          onFocusChange={({ focused }) => {console.log("focus change", focused); this.setState({calendar: focused})} }
          isDayBlocked={this.blockOut.bind(this)}
          isOutsideRange={(date) => this.past(date)}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={1}/>
        </div>
        : null
        }
        <LimitationModal component={component} limitation={this.state.limitation} store={this.props.store} show={!!this.state.limitation} onHide={() => this.setState({limitation: false})} onConfirm={() => this.confirmedAddToCart()}/>

    </div>
    )
  }

}


const mapStateToProps = state => ({
  store: state.booking, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RegularPackage))
