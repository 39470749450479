import React from 'react'
import Modal from '../Modal'

class VerifyModal extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      resent: false
    }
  }

  render(){
    var {show, resend, onHide, email} = this.props
    return (
      <Modal show={show} zIndex={2050} onHide={onHide} customClassName="customize-modal verify" backdropClassName="customize-backdrop">
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Verify Your Email Address</div>
          <div className="content">
            <p>We’ve sent an email to <b>{email}</b> with a verification link. Please check your email and click on the link to continue.</p>
            <p>Didn’t receive the verification email? Choose Send Verification Email Again and we’ll send you a new one.</p>
          </div>
          <div className="cancel" onClick={onHide}>Cancel</div>
          {!this.state.resent ? <div className="secondary-button" onClick={ () => {resend(); this.setState({resent: true}) }}>Send Verification Email Again</div>
            : <div className="secondary-button" style={{cursor: "pointer"}} onClick={() => {resend(); this.setState({resent: false}) }}> Resent! Click to Send Again</div>
          }
        </div>
      </Modal>
    )
  }

}

export default VerifyModal
