import React, { Component } from 'react';
import '../../css/Header.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as cartActions from '../../actions/'
import { Link, withRouter } from 'react-router-dom'
import {Dropdown, MenuItem} from 'react-bootstrap'
import logo from '../../img/1upLarge.svg'
import logoWhite from '../../img/1upLargeWhite.svg'
import logoPurple from '../../img/1upVendor.svg'
import CartIcon from '../../img/NavBarCart.svg'
import request from '../../request'
import { LinkContainer } from 'react-router-bootstrap'

class Header extends Component{


  signOut(){
    this.props.actions.logOut()
    request('/api/signout/', {method: "POST"})
  }

  viewCart(){
    this.props.actions.openCart()
    this.props.history.push('/events')
  }


  account(){
    if(this.props.login.loggedIn){
      return (
        <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-1" className="account">
          <Dropdown.Toggle noCaret >
            <div className="text" >
              <span>Account</span>
              <span className="my-caret"/>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            {this.props.login.admin ? <LinkContainer to="/admin_panel"><MenuItem eventKey="3">Admin Dashboard</MenuItem></LinkContainer>: null}
            {this.props.login.vendor_id ? <LinkContainer to="/vendors/dashboard"><MenuItem eventKey="3">Vendor Dashboard</MenuItem></LinkContainer>: null}
            <LinkContainer to="/account"><MenuItem eventKey="1" active={ window.location.pathname.includes("account")}>Account</MenuItem></LinkContainer>
            <LinkContainer to="/your-events"><MenuItem eventKey="2" active={ window.location.pathname.includes("your-events")}>Your Events</MenuItem></LinkContainer>
            <LinkContainer to="/wishlists"><MenuItem eventKey="3" active={ window.location.pathname.includes("wishlists")}>Wish Lists</MenuItem></LinkContainer>
            <MenuItem divider />
            <MenuItem eventKey="4" onClick={() => this.signOut()}>Sign Out</MenuItem>
          </Dropdown.Menu>
        </Dropdown>


      )
    }
    return <Link className="sign-in" to={{pathname: '/signin', state: {from: this.props.location.pathname}}}  >Sign In</Link>
  }


  render(){
    // var packages_sel = window.location.pathname.includes("package") ? " selected" : ""
    var powerups_sel = window.location.pathname.includes("builder") ? " selected" : ""
    var cart_count = 0;
    for (var key in this.props.cart){
      cart_count += this.props.cart[key].length
    }
    return (
      <div className={"header simple "+ (this.props.className || "")}>
        <div className="content">
          <Link to="/"><img src={ this.props.vendor ? logoPurple : (this.props.className === "white" ? logoWhite : logo)} className={this.props.vendor ? "logo vendor" :"simple-logo"} alt="1up Events" /></Link>
          {this.props.vendor ? <span className="vendor-header-text">Vendor</span> : null}
          <div style={{position: "absolute", right: 0}}>
            <Link className={"nav-text" + powerups_sel} to="/events"> Book an Event</Link>
            <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" className="more">
              <Dropdown.Toggle noCaret >
                <div className="text">
                  <span>Contact & More</span>
                  <span className="my-caret"/>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <LinkContainer to="/contact-us"><MenuItem eventKey="1" >Contact Us</MenuItem></LinkContainer>
                <LinkContainer to="/how-it-works"><MenuItem eventKey="5" >How It Works</MenuItem></LinkContainer>
                <LinkContainer to="/faq"><MenuItem eventKey="6" >FAQs</MenuItem></LinkContainer>
                <LinkContainer to="/about-us"><MenuItem eventKey="4" >About Us</MenuItem></LinkContainer>
                <MenuItem divider />
                <LinkContainer to="/event-host"><MenuItem eventKey="2" >Be an Event Host</MenuItem></LinkContainer>
                <LinkContainer to="/vendor"><MenuItem eventKey="3" >Be a Vendor</MenuItem></LinkContainer>
              </Dropdown.Menu>
            </Dropdown>
            <div className="card user">
              {this.account()}
              <div className="header-divider" style={{height: 32, marginTop: 6}}/>
              <div className="cart-button" tab="0" onClick={() => this.viewCart()} onFocus={() => console.log("focus")}  >
                <div className="cart-text">{cart_count ? cart_count : ""}</div>
                <img src={CartIcon}  alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header))

// <Link className="navbar-brand" style={{padding:0, paddingLeft:15}} to='/'><img alt="Brand" src={logo} width="100px"/></Link>
