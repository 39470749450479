import React, { Component } from 'react';
import {Form, FormGroup, Button, FormControl, Table} from 'react-bootstrap'
import '../../css/AdminDash.css'
import { withRouter } from 'react-router-dom'
import request from '../../request'
import moment from 'moment'
import CheckboxControl from '../Utils/CheckboxControl'
import Services from './Services'
import FakeEvents from './FakeEvents'

class AdminDash extends Component{

  constructor(props){
    super(props)
    this.state = {
      data: [],
      locations: [],
      credit_id: "",
      credit_email: "",
      credit: "",
      credit_user: false,
      approve_vendor: false,
      vendor_email: "",
      error: "",
      approve_service: false,
      service_id: "",
      vendor_rejection_note: "",
      service_rejection_note: "",
      deadWeights: [],
      users: [],
      component_id: "",
      event_id: "",
      venue_name: "",
      venue_address: "",
      venue_price: "",
      service_discount: "",
      set_service_discount: false,
      search: "",
      search_id: "",
      coupons: [],
      show_coupons: false,
      repeatable: false,
      code: "",
      coupon_value: "",
      coupon_percent: "",
      coupon_comp_id: "",
      add_coupon: false,
      tab: "users",
      show_services: false,
      show_fake_bookings: false
    }
  }

  showComponentForm(){
    this.setState({showForm: true})
  }

  async showNewsletter(){
    var subs = await request('/api/admin/newsletter')
    this.setState({data: subs || []})
  }

  async showSubscribers(){
    var subs = await request('/api/admin/subscribers')
    this.setState({data: subs || []})
  }

  async showLocations(){
    var subs = await request('/api/admin/locations')
    this.setState({locations: subs || []})
  }

  async getUsers(){
    var subs = await request('/api/admin/users')
    this.setState({users: subs || []})
  }

  async getCoupons(){
    var subs = []
    console.log("get coupons")
    if(!this.state.show_coupons) subs = await request('/api/admin/coupons')
    this.setState({coupons: subs || [], show_coupons: !this.state.show_coupons})
  }

  async addCoupon(){
    var subs = await request('/api/admin/coupons', {method: "POST", body: JSON.stringify({coupon: {
      code: this.state.code,
      value: this.state.coupon_value || null,
      percent: this.state.coupon_percent || null,
      component_id: this.state.coupon_comp_id || null,
      repeatable: this.state.repeatable
    }}) })
    this.setState({
      code: "",
      coupon_value: "",
      coupon_percent: "",
      coupon_comp_id: "",
      repeatable: false,
      add_coupon: false
    })
    await this.getCoupons()
  }

  async getDeadWeights(){
    var res = await request('/api/admin/deadWeights')
    if(res.err){
      this.setState({error: JSON.stringify(res)})
    }else{
      this.setState({deadWeights: res || []})
    }
  }

  handleChange(key, value){
    this.setState({[key]: value})
  }

  showTable(){
    return (
      <div className="table card">
        <div className="table-row">
          {Object.keys(this.state.data[0]).map(k =>
            <div className="table-header">{k}</div>
          )}
        </div>
        {this.state.data.map(row =>
          <div className="table-row">
            {Object.values(row).map(r =>
              <div className="table-item">{r}</div>
            )}
          </div>
        )}
      </div>
    )
  }

  showLocationTable(){
    return (
      <div className="table card">
      <table>
      <tr>
        {this.state.locations.map( (k, i ) =>
          <td style={{verticalAlign: "top", paddingLeft: 6, paddingRight: 6, borderRight: "1px solid black" }}>
              <table >
                  <thead>
                      <tr style={{borderBottom: "1px solid black"}}>
                          <td>{k.name}</td>
                      </tr>
                  </thead>
                  <tbody>
                    {k.zipcodes.map((row, i) =>
                      <tr>
                          <td>{row}</td>
                      </tr>
                    )}
                  </tbody>
              </table>
          </td>
        )}
      </tr>
      </table>
      </div>

    )
  }

  async submitCredit(){
    var res = await request('/api/admin/credit', {method: "POST", body: JSON.stringify({user_id: this.state.credit_id, email: this.state.credit_email, credit: this.state.credit})})
    if(res.err){
      this.setState({error: JSON.stringify(res.err) })
      console.log("Couldn't find user")
    }else{
      this.setState({credit_id: "", credit_email: "", credit: "", credit_user: false})
    }
  }

  async approveVendor(){
    var res = await request('/api/admin/approveVendor', {method: "PUT", body: JSON.stringify({email: this.state.vendor_email})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't find vendor")
    }else{
      this.setState({vendor_email: "", approve_vendor: false, error: ""})
    }
  }

  async rejectVendor(){
    var res = await request('/api/admin/rejectVendor', {method: "PUT", body: JSON.stringify({email: this.state.vendor_email, note: this.state.vendor_rejection_note})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't find vendor")
    }else{
      this.setState({vendor_email: "", approve_vendor: false, error: ""})
    }
  }

  async approveService(){
    var res = await request('/api/admin/approveService', {method: "PUT", body: JSON.stringify({service_id: this.state.service_id, component_id: this.state.component_id})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't find vendor")
    }else{
      this.setState({service_id: "", approve_service: false, error: ""})
    }
  }

  async setServiceDiscount(){
    var res = await request('/api/admin/services/' + this.state.service_id + "/discount", {method: "PUT", body: JSON.stringify({discount: this.state.service_discount})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't find service")
    }else{
      this.setState({set_service_discount: false, service_id: "", service_discount: "", error: ""})
    }
  }

  async rejectService(){
    var res = await request('/api/admin/rejectService', {method: "PUT", body: JSON.stringify({service_id: this.state.service_id, note: this.state.service_rejection_note})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't find vendor")
    }else{
      this.setState({service_id: "", approve_service: false, error: ""})
    }
  }

  async assignVenue(){
    var res = await request('/api/admin/events/' + this.state.event_id + "/venue" , {method: "PUT", body: JSON.stringify({venue_name: this.state.venue_name, venue_address: this.state.venue_address, venue_price: this.state.venue_price})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't save venue")
    }else{
      this.setState({event_id: "", venue_name: "", venue_price: "", venue_address: "",  error: ""})
    }
  }

  showCredit(){
    return (
      <div className="card" style={{padding: 20, width: 520, marginTop: 20}}>
        <div> Put in user id or email associated with the account </div>
        <input type="number" value={this.state.credit_id} onChange={(e) => this.setState({credit_id: e.target.value}) } className="text-field" style={{width: 50, marginRight: 12}} placeholder="ID"/>
        <input type="email" value={this.state.credit_email} onChange={(e) => this.setState({credit_email: e.target.value}) } style={{marginRight: 12}} className="text-field" placeholder="email"/>
        <input type="number" value={this.state.credit} onChange={(e) => this.setState({credit: e.target.value}) } style={{marginRight: 12}} className="text-field" placeholder="credit"/>
        <button onClick={() => this.submitCredit()} style={{float: "none"}} className="secondary-button">Add</button>
      </div>

    )
  }

  approveVendorUI(){
    return (
      <div className="card" style={{padding: 20, width: 520, marginTop: 20}}>
        <div> Put email associated with the vendor </div>
        <input type="email" value={this.state.vendor_email} onChange={(e) => this.setState({vendor_email: e.target.value}) } style={{marginRight: 12}} className="text-field" placeholder="email"/>
        <button onClick={() => this.approveVendor()} style={{float: "none"}} className="secondary-button">Approve</button>
        <button onClick={() => this.rejectVendor()} style={{float: "none", marginLeft: 12}} className="secondary-button">Reject</button>
        <div><input type="text" value={this.state.vendor_rejection_note} onChange={(e) => this.setState({vendor_rejection_note: e.target.value}) } style={{marginRight: 12, width: 480, marginTop: 20}} className="text-field" placeholder="(Optional): Reasons for rejection"/></div>
      </div>

    )
  }

  approveServiceUI(){
    return (
      <div className="card" style={{padding: 20, width: 620, marginTop: 20}}>
        <div>Put in the service id and, for new services, the component id</div>
        <input type="number" placeholder="Service ID" value={this.state.service_id} onChange={(e) => this.setState({service_id: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <input type="number" placeholder="Component ID" value={this.state.component_id} onChange={(e) => this.setState({component_id: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <button onClick={() => this.approveService()} style={{float: "none"}} className="secondary-button">Approve</button>
        <button onClick={() => this.rejectService()} style={{float: "none", marginLeft: 12}} className="secondary-button">Reject</button>
        <div><input type="text" value={this.state.service_rejection_note} onChange={(e) => this.setState({service_rejection_note: e.target.value}) } style={{marginRight: 12, width: 480, marginTop: 20}} className="text-field" placeholder="(Optional): Reasons for rejection"/></div>
      </div>

    )
  }

  setServiceDiscountUI(){
    return (
      <div className="card" style={{padding: 20, width: 620, marginTop: 20}}>
        <div>Put in the service id and the discount as a decimal</div>
        <input type="number" placeholder="Service ID" value={this.state.service_id} onChange={(e) => this.setState({service_id: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <input type="number" placeholder="Discount: e.g. 0.1" value={this.state.service_discount} onChange={(e) => this.setState({service_discount: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <button onClick={() => this.setServiceDiscount()} style={{float: "none"}} className="secondary-button">Save</button>
      </div>
    )
  }


  assignVenueUI(){
    return (
      <div className="card" style={{padding: 20, width: 620, marginTop: 20}}>
        <div>Put in the event id (confirmation code).</div>
        <input type="text" placeholder="Event ID" value={this.state.event_id} onChange={(e) => this.setState({event_id: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <input type="text" placeholder="Venue Address" value={this.state.venue_address} onChange={(e) => this.setState({venue_address: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <input type="text" placeholder="Venue Name" value={this.state.venue_name} onChange={(e) => this.setState({venue_name: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <input type="number" placeholder="Price" value={this.state.venue_price} onChange={(e) => this.setState({venue_price: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
        <button onClick={() => this.assignVenue()} style={{float: "none"}} className="secondary-button">Assign</button>
      </div>

    )
  }

  couponsUI(){
    return (
      <div className="card" style={{marginTop: 20, padding: 20}}>
        <div>
          <span>Search:  </span>
          <input type="text" className="text-field" placeholder="Search by code" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
          <input type="number"className="text-field" style={{marginLeft:20}} placeholder="Search by id" value={this.state.search_id} onChange={(e) => this.setState({search_id: e.target.value})}/>
        </div>
        {this.state.add_coupon ?
          <div>
            <span> Add New Coupon: </span>
            <input type="text" className="text-field" placeholder="Code" style={{marginRight:20}} value={this.state.code} onChange={(e) => this.setState({code: e.target.value})}/>
            <input type="number"className="text-field" style={{marginRight:20}} placeholder="Value" value={this.state.coupon_value} onChange={(e) => this.setState({coupon_value: e.target.value})}/>
            <input type="number"className="text-field" style={{marginRight:20}} placeholder="Percent e.g. 0.1" value={this.state.coupon_percent} onChange={(e) => this.setState({coupon_percent: e.target.value})}/>
            <input type="number"className="text-field" style={{marginRight:20}} placeholder="Component_id" value={this.state.coupon_comp_id} onChange={(e) => this.setState({coupon_comp_id: e.target.value})}/>
            <span>Repeatable: </span><CheckboxControl checked={this.state.repeatable} onChange={ (check) => this.setState({repeatable: check}) } />
            <button onClick={() => this.addCoupon()} style={{float: "none"}} className="secondary-button">Add</button>
          </div>
        : <button onClick={() => this.setState({add_coupon: true})} style={{float: "none"}} className="secondary-button">New Coupon</button>}
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Value</th>
              <th>Percentage</th>
              <th>Component ID</th>
              <th>Repeatable</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {this.state.coupons.map((e, i) => (this.searchCoupons(e) ?
                <tr key={i}>
                  <td>{e.id}</td>
                  <td>{e.code}</td>
                  <td>{e.value}</td>
                  <td>{e.percent}</td>
                  <td>{e.component_id}</td>
                  <td>{e.repeatable.toString()}</td>
                  <td>{e.active.toString()}</td>
                </tr> : null)
              )}
          </tbody>
        </Table>
      </div>
    )
  }

  searchedFor(e){
    if(this.state.search){
      var name = e.name || ""
      var email = e.email || ""
      var company_name = e.company_name || ""
      return (name.toLowerCase().includes(this.state.search.toLowerCase()) || email.toLowerCase().includes(this.state.search.toLowerCase()) || company_name.toLowerCase().includes(this.state.search.toLowerCase()) )
    }else if(this.state.search_id){
      return this.state.search_id && Number(e.id) === Number(this.state.search_id)
    }
    return true
  }

  searchCoupons(e){
    if(this.state.search){
      return (e.code.toLowerCase().includes(this.state.search.toLowerCase()))
    }else if(this.state.search_id){
      return this.state.search_id && Number(e.id) === Number(this.state.search_id)
    }
    return true
  }

  tab(){
    switch(this.state.tab){
      case "users":
        return (
          <div>
            <div className="break">
              <Button type="button" onClick={()=> this.getUsers()}>View Users</Button>
              <Button type="button" onClick={()=> this.setState({credit_user: !this.state.credit_user})}>Credit User</Button>
              <Button type="button" onClick={()=> this.getCoupons()}>Coupons</Button>
            </div>
            <div className="break">
              <Button type="button" onClick={()=> this.showSubscribers()}>View Location Subscribers</Button>
              <Button type="button" onClick={()=> this.showNewsletter()}>View Newsletter Subscribers</Button>
              <Button type="button" onClick={()=> this.getDeadWeights()}>Get Dead Weights</Button>
            </div>
          </div>
        )
      case "items":
        return (
          <div>
            <div className="break">
              <Button type="button" onClick={()=> this.props.history.push('/events')}>Edit Items</Button>
            </div>
            <div className="break">
              <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-powerup')}>New Powerup</Button>
              <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-package')}>New Package</Button>
              <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-promo')}>New Promotion</Button>
              <Button type="button" onClick={()=> this.setState({show_fake_bookings: !this.state.show_fake_bookings})}>Fake Bookings</Button>
            </div>
          </div>
        )
      case "locations":
        return (
          <div className="break">
            <Button type="button" onClick={()=> this.showLocations()}>View Locations</Button>
            <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-location')}>New Location</Button>
            <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-region')}>New Region</Button>
            <Button type="button" onClick={()=> this.props.history.push('/admin_panel/new-sub-region')}>New Sub-Region</Button>
          </div>
        )
      case "events":
        return (
          <div>
            <div className="break">
              <Button type="button" onClick={()=> this.props.history.push('/admin_panel/upcoming-events')}>View Upcoming Events</Button>
              <Button type="button" onClick={()=> this.props.history.push('/admin_panel/past-events')}>View Past Events</Button>
              <Button type="button" onClick={()=> this.setState({assign_venue: !this.state.assign_venue})}>Assign Venue</Button>
              <Button type="button" onClick={()=> this.setState({show_fake_bookings: !this.state.assign_venue})}>Fake Bookings</Button>
            </div>
          </div>
        )
      case "vendors":
        return (
          <div className="break">
            <Button type="button" onClick={()=> this.setState({approve_vendor: !this.state.approve_vendor})}>Approve/Reject Vendor</Button>
            <Button type="button" onClick={()=> this.setState({approve_service: !this.state.approve_service})}>Approve/Reject Service</Button>
            <Button type="button" onClick={()=> this.setState({set_service_discount: !this.state.set_service_discount})}>Set Service Discount</Button>
            <Button type="button" onClick={()=> this.setState({show_services: !this.state.show_services})}>View Services</Button>
          </div>
        )

    }
  }

  render(){
    var tab = this.state.tab
    return (
      <div className="admin-dash" style={{margin: 25}}>
        <div className="tabs">
          <div className={"tab" + (tab === "users" ? " selected": "")}  onClick={() => this.setState({tab: "users"})}>Users</div>
          <div className={"tab" + (tab === "items" ? " selected": "")} onClick={() => this.setState({tab: "items"})}>Items/Packages</div>
          <div className={"tab" + (tab === "events" ? " selected": "")} onClick={() => this.setState({tab: "events"})}>Events</div>
          <div className={"tab" + (tab === "vendors" ? " selected": "")} onClick={() => this.setState({tab: "vendors"})}>Vendors</div>
          <div className={"tab" + (tab === "locations" ? " selected": "")} onClick={() => this.setState({tab: "locations"})}>Locations</div>
          <a className={"tab" + (tab === "analytics" ? " selected": "")} href="admin_panel/analytics">Analytics </a>

        </div>
        {this.tab()}
        {this.state.data.length > 0 ? this.showTable()  : null}
        {this.state.locations.length > 0 ? this.showLocationTable() : null}
        {this.state.credit_user ?  this.showCredit() : null}
        {this.state.approve_vendor ?  this.approveVendorUI() : null}
        {this.state.approve_service ?  this.approveServiceUI() : null}
        {this.state.assign_venue ?  this.assignVenueUI() : null}
        {this.state.set_service_discount ?  this.setServiceDiscountUI() : null}
        {this.state.show_services ?  <Services history={this.props.history}/> : null}
        {this.state.show_fake_bookings ?  <FakeEvents /> : null}
        {this.state.show_coupons ?  this.couponsUI() : null}
        {this.state.error ? <div>{this.state.error} </div> : null }
        {this.state.deadWeights.length > 0 ?
          <div className="table card">
            <div className="table-row">
              <div className="table-header">ID</div>
              <div className="table-header">Email</div>
              <div className="table-header">Company Name</div>
              <div className="table-header">Days since sign up</div>
            </div>
            {this.state.deadWeights.map( (row, i) =>
              <div key={i} className="table-row">
                <div className="table-item">{row.id}</div>
                <div className="table-item">{row.email}</div>
                <div className="table-item">{row.company_name}</div>
                <div className="table-item">{moment().diff(moment(Number(row.created_at)), 'days')}</div>
              </div>
            )}
          </div>
        :null}
        {this.state.users.length > 0 ?
          <div className="card" style={{marginTop: 20, padding: 20}}>
            <input type="text" className="text-field" placeholder="Search name/email/company" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
            <input type="number"className="text-field" style={{marginLeft:20}} placeholder="Search by id" value={this.state.search_id} onChange={(e) => this.setState({search_id: e.target.value})}/>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((e, i) => (this.searchedFor(e) ?
                    <tr key={i}>
                      <td>{e.id}</td>
                      <td>{e.name}</td>
                      <td>{e.company_name}</td>
                      <td>{e.email}</td>
                      <td>{e.phone}</td>
                      <td>${e.credit || 0}</td>
                    </tr> : null)
                  )}
              </tbody>
            </Table>
          </div>
          : null}
      </div>
    )

  }
}

export default withRouter(AdminDash)
