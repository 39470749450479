
import currencyFormatter from 'currency-formatter'


function trackConv(google_conversion_id, google_conversion_label) {
    var image = new Image(1, 1);
    image.src = "//www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?label=" + google_conversion_label + "&amp;guid=ON";
}

export default class Track {

  static booked(){
    if (  window.location.hostname.includes("1upevents")){
      window.ga('send', {hitType: 'event',eventCategory: 'Booked',eventAction: 'click',eventLabel: "Booked an event"})
      trackConv(1015255539, "LaN7CPLbo3MQ86OO5AM")
    }
  }

  static createdAccount(){

    if (window.location.hostname.includes("1upevents")){
      window.ga('send', {hitType: 'event',eventCategory: 'AccountCreation',eventAction: 'click',eventLabel: "Created an account"})
    }
  }

  static addToCart(name){
    if (window.location.hostname.includes("1upevents")){
      window.fbq('track', 'AddToCart')
      window.ga('send', {hitType: 'event',eventCategory: 'Add to Cart',eventAction: 'click',eventLabel: name})
    }
  }

  static addToWishlist(name){
    if (window.location.hostname.includes("1upevents")){
      window.fbq('track', 'AddToWishlist')
      window.ga('send', {hitType: 'event',eventCategory: 'Add to Wishlist',eventAction: 'click',eventLabel: name})
    }
  }

  static pageview(location){
    if (window.location.hostname.includes("1upevents")){
      window.ga('set', 'page', location);
      window.ga('send', 'pageview');
      window.fbq('track', 'PageView')
    }
  }

  static ecommerce(revenue, tax, cart, id ){
    if (window.location.hostname.includes("1upevents")){
      window.ga('ecommerce:addTransaction', {
        'id': id,                     // Transaction ID. Required.
        'affiliation': '1up Events',   // Affiliation or store name.
        'revenue': revenue.toString(),               // Grand Total.
        'shipping': '0',                  // Shipping.
        'tax': tax.toString()                     // Tax.
      });
      for (var cat in cart){
        for (var comp of cart[cat]){
          let c = comp.component
          window.ga('ecommerce:addItem', {
            'id': id,
            'sku': c.id.toString(),                // Transaction ID. Required.
            'name': c.name,    // Product name. Required.
            'category': cat,         // Category or variation.
            'price': currencyFormatter.unformat(c.price, { code: 'USD' }),                 // Unit price.
            'quantity': '1'                   // Quantity.
          });
        }
      }
      window.fbq('track', 'Purchase', {value: revenue.toString(), currency: 'USD'})
      window.ga('ecommerce:send');
      window.ga('ecommerce:clear');
    }
  }

  static checkout(){
    if (window.location.hostname.includes("1upevents")){
      window.fbq('track', 'InitiateCheckout')
      window.ga('send', {hitType: 'event',eventCategory: 'Checkout',eventAction: 'click',eventLabel: "Checkout"})
      trackConv(1015255539, "uD-_CKbOp28Q86OO5AM")
    }
  }

  static test(){
    window.ga('send', {hitType: 'event',eventCategory: 'test',eventAction: 'click',eventLabel: "test event"})
  }



}
