import React, { Component } from 'react'
import '../../css/Utils/Button.css'
import '../../css/PackageBooked.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../actions/'
import { withRouter, Link} from 'react-router-dom'
import Checkmark from '../../img/CheckmarkCircle.svg'
import ServiceItem from '../Services/ServiceItem'
import request from '../../request'
import Header from '../Header/Header'
import Footer from '../Utils/SimpleFooter'
import Alert from '../Utils/Alert'
import moment from 'moment'


function getContents(pack, components){
  if(pack && (!components || components.length === 0)){
    return <span><b>{pack.name} Package</b> </span>
  }
  if(pack && components.length > 0){
    return <span><b>{pack.name} Package</b> and {components.length > 1 ? String(components.length) + " more items" : components[0].name}</span>
  }
  if(components.length === 1){
    return <span>{components[0].name}</span>
  }
  if(components.length === 2){
    return <span>{components[0].name + " and " + components[1].name}</span>
  }
  return <span>{components[0].name} and {String(components.length) + " more items"}</span>
}


class EventRebooked extends Component {

  constructor(props){
    super(props)
    this.state = {
      addons: [],
      event: null,
      missing: false,
      merge: false,
      componentsRemoved: props.location && props.location.state && props.location.state.componentsRemoved.length > 0 ? props.location.state.componentsRemoved : null,
    }
  }

  getCartCategory(id){
    for(var cat in this.props.cart){
      if(cat !== "package"){
        var res = this.props.cart[cat].find((c) => Number(c.id) === Number(id) )
        if(res){
          return cat
        }
      }
    }
    return null
  }

  mergeCart(event, empty){
    console.log("event", event)
    var clone = empty ? {} : JSON.parse(JSON.stringify(this.props.cart))
    for(var ec of event.components){
      var category = this.getCartCategory(ec.id)
      console.log(category, ec.name)
      if(category && !empty){
        console.log(clone, clone[category])
        // eslint-disable-next-line
        clone[category] = clone[category].filter(item =>
          item.id !== ec.id
        )
      }
    }
    if(event.package){
      clone.package = [{id: event.package.id, component: event.package}]
    }

    for (var c of event.components){
      clone[c.categories[0]] = clone[c.categories[0]] || []
      c.is_available = true
      var comp = {
        id: c.id,
        component: c
      }
      clone[c.categories[0]].push(comp)
    }
    console.log("clone", clone)
    this.props.actions.setCart(clone, this.props.login.loggedIn )

  }

  async componentDidMount(){
    console.log("view mounted!")
    this.props.actions.openCart()
    var event_id = this.props.location.state ? this.props.location.state.event_id : null
    if(event_id){
      var event =  await request(`/api/events/${event_id}`)
      if(event && !event.err){
        this.mergeCart(event, this.props.location.state.empty)
        if(event.package && event.package.add_ons){
          var params = { method: 'POST',
                        body: JSON.stringify({ids: Object.keys(event.package.add_ons) })
                    };
          var res =  await request('/api/getComponentsByIDs', params)
          if (res.err){
            console.log(res.err)
            return
          }
          console.log("addons:", res)
          this.setState({addons: res, event})
        }else{
          this.setState({event})
        }
      }
    }else{
      this.props.history.push("/events")
    }

  }

  missing(params){
    var missingParams = false;
    for(var key in params){
      if(!params[key]){
        missingParams = true
        break
      }
    }
    return missingParams
  }

  missingClicked(){
    window.scrollTo(0, 0)
    this.setState({missing: true})
    this.props.actions.assignMissing("missingAlert")
  }

  missingDismissed(){
    this.setState({missing: false})
    this.props.actions.assignMissing("missing")
  }

  getAlertBody(name){
    let list = ""
    if(!this.state.componentsRemoved) return list
    if(this.state.componentsRemoved.length > 1){
      let last = this.state.componentsRemoved[this.state.componentsRemoved.length - 1]
      list = this.state.componentsRemoved.slice(0, this.state.componentsRemoved.length - 1).join(", ") + " and " + last
    }else{
      list = this.state.componentsRemoved[0]
    }

    return "Because "+name+" already includes "+ list +", we've removed "+(this.state.componentsRemoved.length > 1 ? "them": "it")+ " from your cart."

  }


  render(){

    if(!this.state.event) return null
    var pack = this.state.event.package
    var cartMargin = (this.props.app.cart) ? {marginRight: 320} : {marginRight: 0}

    var missing =  this.missing(this.props.booking)

    return (
    <div>
      <Header />
      <div className="package-booked" style={cartMargin}>
        <div className="block-header">
          <img src={Checkmark} className="icon" alt=""/>
          <div className="title">{this.state.event ? getContents(this.state.event.package, this.state.event.components) : ""} from your <span className="emphasis">{this.state.event ? moment(this.state.event.start_time).format("MMM D") : ""} event</span> have been added!</div>
          {missing ?
            <div>
              <div className="subtitle">Huzzah! Enter missing information on your event in the Book Bar, then choose Review & Book.</div>
              <div>
                <div className="custom-button" onClick={()=> this.missingClicked()} style={{float: 'none', marginBottom: 36, width: 221}}>Enter Missing Info.</div>
              </div>
            </div>
            : (pack ? <div>
              <div className="subtitle">Huzzah! Go to Review & Book to get the party started, or consider adding a few of these popular add-ons.</div>
              <div>
                <Link to="/checkout" className="custom-button" style={{float: 'none', marginBottom: 36, width: 221}}>Review & Book</Link>
              </div>
            </div>
            :
            <div>
              <div className="subtitle">Huzzah! Go to Review & Book to get the party started.</div>
              <div>
                <Link to="/checkout" className="custom-button" style={{float: 'none', marginBottom: 36, width: 221}}>Review & Book</Link>
              </div>
            </div>
            )
          }

        </div>
        {pack ?
        <div className="addons">
          <div className="title">Add-Ons for {pack.name}</div>
          <div className="subtitle">Consider adding these popular items to your event to make it even more awesome.</div>
          <div className="powerup_list" id="powerup-list">
            <ul className="sublist">
              {this.state.addons.map( (component, i) =>
                <li key={i} style={(i+1)%3 === 0 ? {marginRight: 0} : null}>
                  <ServiceItem addOn={true} component={component} path={"/builder/" + component.categories[0] + "/" + component.id + "/" + component.name.replace(/\//g,"-")} />
                </li>
              )}
            </ul>
          </div>
          <Footer/>
        </div>
        :
        <div className="addons">
          <Footer/>
        </div>
        }
      </div>
      {pack ? <Alert show={!!this.state.componentsRemoved} onHide={()=> this.setState({componentsRemoved: null})} title={"Some items in your cart are included in "+pack.name+"!"} text={ ()=> this.getAlertBody(pack.name)} /> : null}
      <Alert show={this.state.missing} buttonText="Got It!" zIndex={9980} backdropClassName=" high-z" onHide={()=> this.missingDismissed()} title="Enter all your event information into the Book Bar." text="Once entered, we can show you pricing as you add items and let you Review & Book."/>
    </div>
    )
  }
}

const mapStateToProps = state => ({
  pack: state.cart.package, cart: state.cart, app: state.app, booking: state.booking, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRebooked))
