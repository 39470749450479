import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl} from 'react-bootstrap'
import * as cartActions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import request from '../../request'
import Header from '../Header/SimpleHeader'
import Warning from '../../img/WarningIcon.svg'
class Reset extends Component {
  constructor (props) {
    super(props)
    console.log(props)

    this.state = { email: '', password: '', re_password: '', error: '' }
  }

  handleChange (key, value) {
    this.setState({ [key]: value })
  }

  async handleSubmit (event) {
    event.preventDefault()

    // to send to server
    if (!this.state.password) {
      this.setState({ error: 'Must provide new password.' })
      return
    }
    if (!this.state.re_password) {
      this.setState({ error: 'Must re-enter new password.' })
      return
    }
    if (this.state.password !== this.state.re_password) {
      this.setState({ error: 'Passwords do not match.' })
      return
    }
    var token = this.props.match.params.token
    var params = { method: 'POST',
      body: JSON.stringify({ password: this.state.password }) }
    var res = await request('/api/reset/' + token, params)
    if (res.err) {
      console.log(res.err)
      if (res.err === 'reset_token_expired') this.setState({ error: 'Your password reset link has expired. Please request it again.' })
      return
    }

    params = { method: 'POST',
      body: JSON.stringify({ email: res.email, password: this.state.password }) }
    console.log('Email', this.state.email)
    res = await request('/api/login', params)
    console.log(res)
    if (!res) {
      this.setState({ error: 'Server could not be reached' })
      return
    }
    if (res.err) {
      console.log(res.err)
      this.setState({ error: res.err })
      return
    }
    this.props.actions.setLogin({ loggedIn: true, admin: res.is_admin, wishlists: res.wishlists, vendor_id: res.vendor_id, name: res.name })
    this.props.history.push('/')
  }

  render () {
    return (
      <div>
      <Header />
      <div className="card form" style={{marginTop: 120, marginRight: "auto", marginLeft: "auto"}}>
      <Form onSubmit={this.handleSubmit.bind(this)} >
        <FormGroup controlId="password">
          <ControlLabel>Password</ControlLabel>
          <FormControl type="password" className="text-field" value={this.state.password} onChange={(e)=> this.handleChange("password", e.target.value)}/>
        </FormGroup>
        <FormGroup controlId="re-password">
          <ControlLabel>Re-Enter Password</ControlLabel>
          <FormControl type="password" className="text-field" value={this.state.re_password} onChange={(e)=> this.handleChange("re_password", e.target.value)}/>
        </FormGroup>
        {this.state.error ? <div className="error-state" style={{marginBottom: 10}}><img src={Warning} alt="" /><span className="text"> {this.state.error}</span></div> : null}
        <Button type="submit">
             Submit
        </Button>
      </Form>
      </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Reset))
