import React from "react"
import ContentLoader from "react-content-loader"
import Card from "../Card"

const Loader = (props) => (
    <ContentLoader 
      speed={2}
      width={320}
      height={280}
      viewBox="0 0 320 280"
      backgroundColor="#ffffff"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="2" ry="2" width="320" height="202" /> 
      <rect x="21" y="212" rx="2" ry="2" width="224" height="18" /> 
      <rect x="21" y="240" rx="0" ry="0" width="71" height="18" />
    </ContentLoader>
  )

const CardLoader = ({key}) => (
    <div className="reg-powerup-container" key={key}>
        <Card className="regular h-[272px]" >
            <Loader />
        </Card>
    </div>
)

export default CardLoader;