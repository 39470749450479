import React from 'react'
import Static from './StaticWrapper'
import './AboutUs.css'
import Sam from '../../img/AboutUsSam.png'
import Celia from '../../img/AboutUsCelia.png'
import Alex from '../../img/AboutUsAlex.png'

const AboutUs = () => {
  return (
    <Static className="about-us px-4 max-w-full mt-8 mb-8 sm:mb-[115px] lg:mt-[115px] lg:mx-auto">
      <div className="static-page-header max-w-full md:text-[44px] text-[36px]">About Us</div>
      <div className="main-section max-w-full">
        <div className="main-people flex md:place-items-center grid gap-x-12 gap-y-10 grid-cols-1 md:grid-cols-2">
          <div className="person flex-none mx-0 max-w-full">
            <div className="picture">
              <img src={Sam} alt="Samantha Perlman"/>
              <div className="name-title">
                <div className="name">Samantha Perlman</div>
                <div className="title">Founder & CEO</div>
              </div>
            </div>
            <div className="text">
              <p>Sam is the Co-founder and CEO of 1up Events. She studied Classics at Stanford, so she’s the resident expert on all things toga party related and is fluent in 2 dead languages.</p>
              <p>Sam has over 10 years experience building teams at top tech companies (including Airbnb and OneSignal), and has been planning events as long as she can remember.</p>
            </div>
          </div>
          <div className="person flex-none mx-0 max-w-full" style={{marginRight: 0}}>
            <div className="picture">
              <img src={Alex} alt="Alex Lerman"/>
              <div className="name-title">
                <div className="name">Alex Lerman</div>
                <div className="title">CTO</div>
              </div>
            </div>
            <div className="text">
              <p>Alex is the CTO of 1up Events. He studied Symbolic Systems at Stanford and has worked in startups for the past 12 years. He previously was the co-founder and CTO of a mobile fitness startup.</p>
              <p>Alex is a perennial entrepreneur, musician and robe enthusiast. He also moonlights as Thor. He’ll sign your poster but no guarantees on authenticity.</p>
            </div>
          </div>
        </div>
        <div className="whys mx-auto flex grid gap-x-8 gap-y-6 grid-cols-1 md:grid-cols-3">
          <div className="why flex-none max-w-full mx-0">
            <div className="section-header">Why 1up Events?</div>
            <div className="text">
              <p>We were tired of going to boring events, and frustrated that planning one could be a painful experience. Thus 1up was born. </p>
              <p>We’ve assembled a curated marketplace of the best vendors and the most creative events out there, coupled with real-time availability.</p>
              <p>Now planning an event is seamless — with just a few clicks, you can customize and book an amazingly fun event. </p>
            </div>
          </div>
          <div className="why flex-none max-w-full  mx-0">
            <div className="section-header">Why do events matter?</div>
            <div className="text">
              <p>{"Because your company's culture matters. It can make or break your team."}</p>
              <p>{"If you already love your company culture, we'll help you strengthen it. And if you're still figuring out exactly what your company culture is, we'll help you define it."}</p>
              <p>{"Most importantly, your team is your company's most valuable asset, so keep them happy."}</p>
            </div>
          </div>
          <div className="why last flex-none max-w-full  mx-0">
            <div className="section-header">What types of events?</div>
            <div className="text">
              <p>Sky’s the limit! We have events to bring out your inner child, gamer, foodie, wino — you name it!</p>
              <p>{"We plan holiday parties, happy hours, summer BBQ's, birthdays, company milestones, intern events, and more."}</p>
              <p>Every event is expertly crafted and meticulously planned down to the last detail.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrapper">
      </div>
    </Static>
  )
}

export default AboutUs


// <div className="person">
//   <div className="picture">
//     <img src={Celia} alt="Celia Song"/>
//     <div className="name-title">
//       <div className="name">Celia Song</div>
//       <div className="title">Co-founder & COO</div>
//     </div>
//   </div>
//   <div className="text">
//     <p>Celia is the co-founder and COO of 1up Events. She studied mechanical engineering at Cornell and, with over 6 years of project management experience, is all about productivity.</p>
//     <p>She hopes to someday create robots to automate the cupcake decorating process. She’s an aspiring crazy cat lady and avid corrector of typos (mostly Sam’s).</p>
//   </div>
// </div>
