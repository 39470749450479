import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import ItemsIcon from '../../img/EventInfoItems.svg'
import Duration from '../../img/BookBarDuration.svg'
import Location from '../../img/BookBarLocation.svg'
import DateIcon from '../../img/BookBarDate.svg'
import People from '../../img/BookBarPeople.svg'
import Photos from '../../img/EventInfoPhotos.svg'
import Survey from '../../img/EventInfoSurvey.svg'
import BookAgainIcon from '../../img/BookAgainIcon.svg'
import '../../css/UserDash/Summaries.css'
import Shared from '../../img/WishListPublic.svg'
import NotShared from '../../img/WishListPrivate.svg'
import { getTimezoneShortName } from '../Utils/Helpers'

function getImage(pack, components){
  if(pack){
    return pack.images[0]
  }
  return components && components.length > 0 && components[0].images && components[0].images.length > 0 ? components[0].images[0] : null
}

function getLocationText(e, venue){
  if(e.location){
    return <span>Your venue (<span className="location">{e.location}</span>)</span>
  }
  if(venue && venue.name){
    return venue.name
  }
  return "To Be Determined"
}


function getContents(pack, components){
  if(pack && (!components || components.length === 0)){
    return <span><b>{pack.name} Package</b> </span>
  }
  if(pack && components.length > 0){
    return <span><b>{pack.name} Package</b> and {components.length > 1 ? String(components.length) + " more items" : components[0].name}</span>
  }
  if(components.length === 1){
    return <span>{components[0].name}</span>
  }
  if(components.length === 2){
    return <span>{components[0].name + " and " + components[1].name}</span>
  }
  if(components.length > 0){
    return <span>{components[0].name} and {String(components.length - 1) + " more items"}</span>
  }
  return <span>Nothing</span>
}

function getWishlistContents(packages, components){
  if(packages.length === 1 && (!components || components.length === 0)){
    return <span><b>{packages[0].name} Package</b> </span>
  }
  if(packages.length > 0 && (packages.length > 1 || components.length > 0)){
    return <span><b>{packages[0].name} Package</b> and {components.length + packages.length > 1 ? String(components.length + packages.length - 1) + " more items" : (packages[0] ? packages[0].name : components[0].name)}</span>
  }
  if(components.length === 1){
    return <span>{components[0].name}</span>
  }
  if(components.length === 2){
    return <span>{components[0].name + " and " + components[1].name}</span>
  }
  if(components.length === 0){
    return <span>No items added yet</span>
  }
  return <span>{components[0].name} and {String(components.length - 1) + " more items"}</span>
}

const UpcomingSummary = ({e, pack, components, venue, admin}) => {
  let start_time = moment.tz(e.start_time, e.timezone)
  return (
    <div className="card event">
    {admin ?
      <div className="details">
        <div>{e.email}</div>
        {e.company_name ? <div>{e.company_name}</div> : null}
      </div>
      : null
    }

    {admin && e.canceled ?
      <div className="canceled-event">
        CANCELLED
      </div>
      : null
    }
    <div className="date"><b>{start_time.format("ddd, MMM D, YYYY")}</b> at <b>{start_time.format("LT").toLowerCase().replace(" ", "")}</b></div>
      <div className="details">
        <div>
          <img src={ItemsIcon} style={{marginLeft: 2}} alt=""/><span>{getContents(pack, components)}</span>
        </div>
        <div>
          <img src={People} alt=""/><span>Around {e.num_people} people</span>
        </div>
        <div>
          <img src={Location} alt=""/><span>{getLocationText(e, venue)}</span>
        </div>
        <div>
          <img src={Duration} alt=""/><span>{Number(e.hours_needed) === 1 ? "1 hour long" : e.hours_needed + " hours long" }</span>
        </div>
      </div>
      <Link className="custom-button" to={admin ? "/admin_panel/event/" + e.id : "/your-events/" + e.id} >View & Change Details</Link>
      <img className="image" src={"https://d2c831se9gn8j1.cloudfront.net/" + getImage(pack, components)} alt="" / >
    </div>

  )

}

const PastSummary = ({e, pack, components, venue, photos, onClick}) => {
  let survey = false
  let start_time = moment.tz(e.start_time, e.timezone)
  return (
    <div className="card event">
      <div className="date">{e.canceled ? <span style={{color: "#FF5200"}}>Canceled </span> : null }<b>{start_time.format("ddd, MMM D, YYYY")}</b> at <b>{start_time.format("LT").toLowerCase().replace(" ", "")}{e.canceled ? "" : " " + getTimezoneShortName(start_time, e.timezone) }</b></div>
      <div className="details">
        <div>
          <img src={ItemsIcon} style={{marginLeft: 2}} alt=""/><span>{getContents(pack, components)}</span>
        </div>
        <div>
          <img src={Location} alt=""/><span>{getLocationText(e, venue)}</span>
        </div>
        {photos ?
          <div>
            <img src={Photos} alt=""/><span>Photos available for download people</span>
          </div>
          : null
        }
        {survey ?
          <div>
            <img src={Survey} alt=""/><span>Post event survey completed</span>
          </div>
          : null
        }
      </div>
      <Link className="secondary-button" to={"/your-events/" + e.id} >View Details</Link>
      <div className="rebook">
        <img className="rebook-icon" src={BookAgainIcon} alt="" />
        <span className="secondary-button with-icon" onClick={onClick} >Rebook Event</span>
      </div>
      <img className="image" src={"https://d2c831se9gn8j1.cloudfront.net/" + getImage(pack, components)} alt="" / >
    </div>
  )

}

const WishlistSummary = ({e, packages, components, venue, admin, remove}) => {
  console.log(e)
  // let start_time = moment.tz(e.start_time, e.timezone)
  let no_information = !e.date && !e.num_people && !e.location && !e.duration
  return (
    <div className="card event wishlist" style={{height: 204}}>
    <div className="date"><b>{e.name}</b></div>
      <div className="details">
        <div>
          <img src={ItemsIcon} style={{marginLeft: 2}} alt=""/><span>{getWishlistContents(packages, components)}</span>
        </div>
        {no_information ?
          <div>
            <img src={DateIcon} alt=""/><span>No event information added yet</span>
          </div>
        : null}
        {e.date ?
          <div>
            <img src={DateIcon} alt=""/><span>{moment(e.date).format("ddd, MMM Do")}</span>
            {e.duration ?
                <span>{Number(e.duration) === 1 ? " for 1 hour" : " for " + e.duration + " hours" }</span>
              : null
            }
          </div>
          :
          null
        }
        {e.num_people ?
          <div>
            <img src={People} alt=""/><span>Around {e.num_people} people</span>
          </div>
        : null}
        {e.location ?
          <div>
            <img src={Location} alt=""/><span>{getLocationText(e, venue)}</span>
          </div>
        :null}

      </div>
      <Link className="secondary-button view-list" to={"/wishlists/" + e.list_id + "/" + e.name.replace(/ /g, '_').replace(/\//g,"-")} >View List</Link>
      {packages.length > 0 || components.length > 0 ? <img className="image" src={"https://d2c831se9gn8j1.cloudfront.net/" + getImage(packages.length > 0 ? packages[0] : null, components)} alt="" / > : null}
      {e.share ? <div className="shared"><img src={Shared} alt=""/><div className="text">Shared</div></div> : <div className="shared"><img src={NotShared} alt=""/><div className="text">Not Shared</div></div>}
      <div className="delete-text" onClick={() => remove(e.list_id)}>Delete</div>
    </div>

  )

}

export { UpcomingSummary, PastSummary, WishlistSummary }
