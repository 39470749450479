import React from 'react'
import Static from '../StaticPages/StaticWrapper'
import '../StaticPages/EventHost.css'
import './AddService.css'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import Title from '../Title'
import request from '../../request'
import Alert from '../Utils/Alert'
import Image from '../Utils/SpecialImage'
import SurveyFlow from '../Utils/SurveyFlow'
import ServiceSelector from './ServiceSelector'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Checkmark from '../../img/CheckmarkCheck.svg'
import {MenuItem, Dropdown} from 'react-bootstrap'
import pluralize from 'pluralize'
import CheckIcon from '../../img/CheckmarkCircle.svg'
import moment from 'moment'
import CheckboxControl from '../Utils/CheckboxControl'
import { getCurrentRegion, regionsToNodes, getLocationNames } from '../Utils/Locations'
import UnitPicker from '../Utils/UnitPicker'
import TravelFees from '../Utils/TravelFees'

async function getLocation(){
  var res = await request('https://ipinfo.io/json?token=b143d73a857a48')
  return res
}

var requirement_options = [{name: "outlet",showQty: true, qty: 1},{name: "table", showQty:true, qty: 1}, {name: "chair", showQty: true, qty: 1},
{name: "regular elevator", showQty: false, qty: 1}, {name: "freight elevator", showQty: false, qty: 1}, {name: "sink", showQty: false, qty: 1}, {name: "WIFI", showQty: false, qty: 1}]

function getValues(values, checked, qty){
  var clone = JSON.parse(JSON.stringify(values))
  console.log("getting values", clone, checked, values)
  if(!!values.find(v => v.name === checked.name)){
    return clone.filter(v => v.name !== checked.name)
  }else{
    clone.push({name: checked.name, qty})
    return clone
  }
}

function setQty(values, opt, qty){
  var clone = JSON.parse(JSON.stringify(values))
  var v = clone.find(v => v.name === opt.name)
  if(v){
    v.qty = qty
  }
  return clone
}

const Requirements = ({values, setValues}) => {
  return (
    <div className="requirements">
      {requirement_options.map((opt, key) => {
        var v = values.find(v => v.name === opt.name)
        return (
          <div className="opt" key={key} >
            <CheckboxControl checked={!!values.find(v => v.name === opt.name)} onChange={checked => setValues(getValues(values, opt, opt.qty))} />
            <label onClick={() => setValues(getValues(values, opt, opt.qty))} htmlFor={opt.name}>{opt.name}</label>
            {opt.showQty ?
              <span>
                <input placeholder="How many?" className="text-field" type="number" value={v ? v.qty : ""} onChange={(e) => setValues(setQty(values, opt, e.target.value))}/>
                {pluralize(opt.name)}
              </span>
              :
            null}
          </div>
        )})
      }
    </div>
  )
}


function getBody(details){
  var locations_names = []
  for (var r of details.regions){
    for(var s of r.children){
      for(var l of s.children){
        if(details.locations.includes(l.value)){
          locations_names.push(l.label)
        }
      }
    }
  }
  return {component_id: details.selected ? details.selected.id : null,
  component_name: details.selected ? details.selected.name: null,
  name: details.name,
  locations: details.locations,
  locations_names,
  requirements: details.requirements,
  unit: details.unit,
  price: details.price,
  minimum: isNaN(details.minimum) ? "" : details.minimum,
  maximum: isNaN(details.maximum) ? "" : details.maximum,
  normal_duration: details.normal_duration, //kits only
  extra_hours_fee: details.extra_hours_fee, //kits only
  typical_num_staff: details.typical_num_staff,
  attendees_per_staff: details.attendees_per_staff,
  setup_time: details.setup_time,
  takedown_time: details.takedown_time,
  late_booking_percentage: details.late_booking_percentage,
  late_booking_threshold: details.late_booking_threshold, //(days)
  max_events: details.max_events,
  booking_lead_time: details.booking_lead_time,
  description: details.description,
  included: details.included,
  options: details.options,
  can_provide: details.can_provide,
  deposit: details.deposit,
  flat_fee: details.flat_fee,
  travel_fees: details.travel_fees,
  extra_hours_fee_scalar: details.extra_hours_fee_scalar,
  extra_hours_fee_scalar_unit: details.extra_hours_fee_scalar_unit === "people" ? "person" : "kit",
  }
}

class AddService extends React.Component{

  constructor(props){
    super(props)

    var s = JSON.parse(localStorage.getItem("service_addition"))
    // console.log("add serice constructor",s)
    var state = {
      name: "",
      index: 0,
      type: null,
      component_id: "",
      regions: [],
      locations: [],
      expanded: [],
      requirements: [],
      unit: "Choose Unit",
      price: "1",
      minimum: "1",
      maximum: "",
      normal_duration: "", //kits only
      extra_hours_fee: "", //kits only
      typical_num_staff: "1",
      setup_time: "1",
      takedown_time: "0.5",
      late_booking_percentage: "",
      late_booking_threshold: "", //(days)
      max_events: "1",
      booking_lead_time: "",
      new_item: false,
      pictures: "",
      can_provide: "",
      description: "",
      included: "",
      vendor: null,
      extra_charge_late: "",
      extra_charge_too_long: "",
      deposit: "25",
      select_service: false,
      selected: null,
      deposit_required: "",
      new_service: false,
      options: "",
      attendees_per_staff: "",
      vendor_id: "",
      set_vendor_id: false,
      current_services: [],
      flat_fee: "0",
      extra_hours_fee_scalar_unit: "people",
      extra_hours_fee_scalar: "",
      extra_charge_scales: "",
      travel_fees: {},
      region_fees: {},
      sub_region_fees:{},
      travel_fee: "no",
      remote: false
    }
    if(s) s.regions =  []
    if(s){
      state = Object.assign(state, s)
    }
    this.state = state
  }

  changeNumber(field, val, char_limit = 7){
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < char_limit + 1){
        this.changeState({[field]: val})
      }
    }
  }

  changeDecimal(field, val){
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9.\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.changeState({[field]: val})
      }
    }
  }

  changeNegativeDecimal(field, val){
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9.\-\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.changeState({[field]: val})
      }
    }
  }


  changeState(state){
    console.log(state)
    this.setState(state, () => localStorage.setItem("service_addition", JSON.stringify(this.state)))
  }

  selectService(selected){
    this.changeState({selected, new_service: false})
  }

  async componentDidMount(){
    var regions = await request('/api/regions')
    var vendor = await request('/api/vendor/basic')
    var locations = this.state.locations
    if(locations.length === 0) locations = vendor.locations
    var expanded = getCurrentRegion(locations, regions)
    regions = regionsToNodes(regions)
    this.changeState({regions, expanded, vendor, locations})
  }

  isNotDuplicate(){
    console.log("isNotDuplicate", this.state.selected)
    return !this.state.vendor.services.includes(this.state.selected.id)
  }

  newService(){
    this.changeState({new_service: true, select_service: false})
  }

  sections(index){
    console.log(index, "sections", isNaN(this.state.extra_hours_fee))
    switch(index){
      case 1:
      console.log("Checkbox",this.state.locations, this.state.regions, this.state.expanded)
        if(this.state.selected && this.state.selected.remote){
          return <div className="locations-section">
            <Title title="This service is remote. Please continue" />
          </div>
        }

        return (
          <div className="locations-section">
            <Title title="Select which areas you can offer your service" />
            <div style={{marginBottom : 8, marginLeft: 53  }}>
              <CheckboxControl checked={this.state.remote} onChange={checked => this.changeState({remote: checked})} />
              <label className="rct-title" onClick={() => this.changeState({remote: !this.state.remote})} htmlFor={"Remote"} style={{fontSize: 16}} >Remote</label>
            </div>
            {this.state.remote ? null:
              <CheckboxTree
                  nodes={this.state.regions}
                  checked={this.state.locations}
                  expanded={this.state.expanded}
                  onCheck={locations => this.changeState({ locations })}
                  onExpand={expanded => this.changeState({ expanded })}
              />
            }
          </div>
        )
      case 0:
        return (
          <div className="service-selection-section">
            {this.props.login.admin ?
              (this.state.set_vendor_id ?
                <div>
                  <div className="question">What is the vendor id?</div>
                  <input type="number" placeholder="Vendor ID" className="text-field" value={this.state.vendor_id} onChange={(e) => this.changeState({vendor_id: e.target.value})}/>
                  <button className="secondary-button" style={{float: "none", marginLeft: 20}} onClick={() => this.setState({set_vendor_id: false, vendor_id: ""})}>Cancel Setting Vendor ID</button>
                </div>
              : <div><button className="secondary-button" style={{float: "none"}} onClick={() => this.setState({set_vendor_id: true})}>Set Vendor ID</button></div>)
              : null
            }
            <Title title="Select an Item to Service" />
            <div className="instructions">
              <p style={{marginBottom: 8}}><b>Signup for Existing Services:</b> Select this option to signup for a service from the list of services we already provide (please scroll through the list or use the search bar in the top right corner of the pop up).</p>

              <p style={{marginBottom: 8}}><b>Create New Service:</b> If you can't find your service on the list and would like us to offer it please create a new service and we'll reach out about adding it to the list.</p>

              <p style={{marginBottom: 8}}><b>Multiple services:</b> If you find more than one that you can service, please add each separately.</p>
            </div>
            <div className="custom-button" style={{marginLeft: 0}} onClick={() => this.changeState({select_service: true})}>{this.state.selected && !this.state.new_service ? "Change Service" : "Signup for Existing Service"}</div>
            <div className="secondary-button" style={{marginLeft: 20}} onClick={() => this.newService()}>{"Create a New Service"}</div>
            {this.state.select_service ? <ServiceSelector locations={this.state.locations} onHide={() => this.changeState({select_service: false})} onSelect={(selected) => this.selectService(selected)} newService={() => this.newService()} selected={this.state.selected} /> : null }
            <div className="application add-service">
              {this.state.selected && !this.state.new_service ?
                <div>
                  <div className="service-name question">{this.state.selected.name}</div>
                  {this.isNotDuplicate() ? null:
                    <div style={{fontSize: 17, color: 'var(--missing-color)', marginBottom: 12}}>Already servicing this item!</div>
                  }
                  <div className="formatted-description" >
                    <div dangerouslySetInnerHTML={{ __html: this.state.selected.description }} />
                    <div style={{height: 25}}/>
                  </div>
                  <div className="question">Can you provide everything described in the item description? If not, explain.*</div>
                  <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>{"Please read the descriptions carefully and note if there's anything you can't provide that is listed in the description, or if there's anything else you can provide in addition to the description."}</div>
                  <textarea className="text-field" value={this.state.can_provide} onChange={(e) => this.changeState({can_provide: e.target.value})} />
                  <div className="question">Please list whatever options you typically offer (flavors, themes, etc.)</div>
                  <textarea className="text-field" value={this.state.options} onChange={(e) => this.changeState({options: e.target.value})} />
                  <div className="question">What do you require to be provided for you on-site ?</div>
                  <Requirements values={this.state.requirements} setValues={requirements => this.changeState({requirements})} />
                </div>
              : null}
              {this.state.new_service ?
                <div>
                  <div className="service-name question">What do you call your service?*</div>
                  <input type="text" className="text-field" value={this.state.name} onChange={(e) => this.changeState({name: e.target.value})}/>
                  <div className="question">Please provide a description of your service including what you bring.*</div>
                  <textarea className="text-field" value={this.state.description} onChange={(e) => this.changeState({description: e.target.value})} />
                  <div className="question">What do you require on-site?</div>
                  <Requirements values={this.state.requirements} setValues={requirements => this.changeState({requirements})} />
                  <div className="question">Please list whatever options you typically offer (flavors, themes, etc.)</div>
                  <textarea className="text-field" value={this.state.options} onChange={(e) => this.changeState({options: e.target.value})} />
                </div>
                : null}
            </div>
          </div>
        )
      case 2:
        return (
          <div className="application add-service">
            <Title title="Pricing and Fees" />
            <div className="question">What is your standard pricing?* </div>
            <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>All discounts will be applied after.</div>
            <div style={{position: "relative"}}><span className="desc-text money">$</span><input type="text" value={this.state.price} onChange={(e) => this.changeDecimal("price", e.target.value)} className="text-field hour" /><span className="desc-text" style={{marginLeft: 12}}> per </span><UnitPicker units={["Choose Unit","person", "hour", "kit"]} value={this.state.unit} select={(unit) => this.changeState({unit})} /></div>
            {this.state.unit !== "Choose Unit" ?
              <div>
              {this.state.unit !== "kit" ?
                  <div>
                    <div className="question">What is the minimum number of {pluralize(this.state.unit)} you are willing to serve?*</div>
                    <input type="text" className="text-field" value={this.state.minimum} onChange={(e) => this.changeDecimal("minimum", e.target.value)}/>
                  </div>
                  : null
                }
                <div className="question">If applicable, what is the maximum number of {pluralize(this.state.unit)} you are able to serve?</div>
                <input type="text" className="text-field" value={this.state.maximum} onChange={(e) => this.changeDecimal("maximum", e.target.value)}/></div>
                : null}

            <div className="question">Do you have any setup fees or other fixed per-event costs?</div>
            <div style={{position: "relative"}}>
              <span className="desc-text money">$ </span>
              <input type="text" style={{width: 62}} className="text-field hour" value={this.state.flat_fee} onChange={(e) => this.changeDecimal("flat_fee", e.target.value)}/>
            </div>
            <div className="question">Do you require a deposit?*</div>
            <div className="four-hour">
              <div className="opt" >
                <input type="radio" id={"yes_deposit"} value={"yes"} name={"yes_deposit"} onChange={(e) => this.changeState({deposit_required: e.currentTarget.value})} checked={this.state.deposit_required === "yes"} />
                <label htmlFor={"yes_deposit"}>Yes</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"no_deposit"} value={"no"} name={"no_deposit"} onChange={(e) => this.changeState({deposit_required: e.currentTarget.value})} checked={this.state.deposit_required === "no"} />
                <label htmlFor={"no_deposit"}>No</label>
              </div>
            </div>
            {this.state.deposit_required === "yes" ?
              <div>
                <div className="question">What percent deposit do you require upfront?*</div>
                <div style={{position: "relative", width: "fit-content"}}>
                  <input style={{width: 62}} type="text" placeholder="0" className="text-field" value={this.state.deposit} onChange={(e) => this.changeNumber("deposit", e.target.value, 3)}/>
                  <span className="desc-text percent"> %</span>
                </div>
              </div>
            : null}
            <div className="question">Do you have any travel or delivery fees based on the location of the event?*</div>
            <div className="four-hour">
              <div className="opt" >
                <input type="radio" id={"yes_travel"} value={"yes"} name={"yes_travel"} onChange={(e) => this.changeState({travel_fee: e.currentTarget.value})} checked={this.state.travel_fee === "yes"} />
                <label htmlFor={"yes_travel"}>Yes</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"no_travel"} value={"no"} name={"no_travel"} onChange={(e) => this.changeState({travel_fee: e.currentTarget.value})} checked={this.state.travel_fee === "no"} />
                <label htmlFor={"no_travel"}>No</label>
              </div>
            </div>
            {this.state.travel_fee === "yes" ?
              <div>
              <div className="question">Set your travel/delivery fee*</div>
              <TravelFees region_fees={this.state.region_fees} sub_region_fees={this.state.sub_region_fees} travel_fees={this.state.travel_fees} regions={this.state.regions} locations={this.state.locations}
                  changeState={this.changeState.bind(this)}
              />
              </div>
            : null}
            <div className="question">Do you charge extra for last minute bookings?*</div>
            <div className="four-hour">
              <div className="opt" >
                <input type="radio" id={"yes_late"} value={"yes"} name={"yes_late"} onChange={(e) => this.changeState({extra_charge_late: e.currentTarget.value})} checked={this.state.extra_charge_late === "yes"} />
                <label htmlFor={"yes_late"}>Yes</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"no_late"} value={"no"} name={"no_late"} onChange={(e) => this.changeState({extra_charge_late: e.currentTarget.value})} checked={this.state.extra_charge_late === "no"} />
                <label htmlFor={"no_late"}>No</label>
              </div>
            </div>
            {this.state.extra_charge_late === "yes" ?
            <div>
              <div className="question">What percentage extra?*</div>
              <div style={{position: "relative", width: "fit-content"}}>
                <input style={{width: 62}} type="text" className="text-field" value={this.state.late_booking_percentage} onChange={(e) => this.changeNumber("late_booking_percentage", e.target.value, 3)}/>
                <span className="desc-text percent"> %</span>
              </div>
              <div className="question">Less than how many days prior to an event do you consider a last minute booking?*</div>
              <input type="text" className="text-field" value={this.state.late_booking_threshold} onChange={(e) => this.changeNumber("late_booking_threshold", e.target.value)}/>
            </div>
            :null}
            <div className="question">Do you charge extra for events lasting longer than a certain amount of time?*</div>
            <div className="four-hour">
              <div className="opt" >
                <input type="radio" id={"yes_long"} value={"yes"} name={"yes_long"} onChange={(e) => this.changeState({extra_charge_too_long: e.currentTarget.value})} checked={this.state.extra_charge_too_long === "yes"} />
                <label htmlFor={"yes_long"}>Yes</label>
              </div>
              <div className="opt" >
                <input type="radio" id={"no_long"} value={"no"} name={"no_long"} onChange={(e) => this.changeState({extra_charge_too_long: e.currentTarget.value})} checked={this.state.extra_charge_too_long === "no"} />
                <label htmlFor={"no_long"}>No</label>
              </div>
            </div>
            {this.state.extra_charge_too_long === "yes" ?
            <div>
              <div className="question">How many hours is the maximum duration before you charge the extra fee?*</div>
              <input type="text" className="text-field" value={this.state.normal_duration} onChange={(e) => this.changeDecimal("normal_duration", e.target.value)}/>
              <div className="question">How much more per extra hour?*</div>
              <div style={{position: "relative"}}>
                <span><span className="desc-text money" >$ </span>
                <input style={{width: 62}} type="text" className="text-field hour" value={this.state.extra_hours_fee} placeholder="0" onChange={(e) => this.changeNegativeDecimal("extra_hours_fee", e.target.value)}/> {isNaN(this.state.extra_hours_fee) ? "Needs to be a single number with no symbols" : ""}</span>
              </div>
            </div>
            : null}
            </div>

        )
    case 3:
      return (
        <div className="application add-service">
          <Title title="Details" />
          <div className="question">How many days before an event is the latest you will accept a booking?*</div>
          <input type="text" className="text-field" value={this.state.booking_lead_time} onChange={(e) => this.changeNumber("booking_lead_time", e.target.value)}/>
          <div className="question">What is the maximum number of events you are able to service at the same time?*</div>
          <input type="text" className="text-field" value={this.state.max_events} onChange={(e) => this.changeNumber("max_events", e.target.value)}/>
          <div className="question">How many hours does it take you to set up on-site?*</div>
          <input type="number" style={{width: 50}} className="text-field" value={this.state.setup_time} onChange={(e) => this.changeDecimal("setup_time",  e.target.value)} /> <span className="desc-text"> hours</span>
          <div className="question">How many hours does it take you to take everything down on-site?*</div>
          <input type="number" style={{width: 50}} className="text-field" value={this.state.takedown_time} onChange={(e) => this.changeDecimal("takedown_time",  e.target.value)}/>  <span className="desc-text"> hours</span>
          <div className="question">How many staff do you typically bring?</div>
          <input type="text" className="text-field" value={this.state.typical_num_staff} onChange={(e) => this.changeNumber("typical_num_staff",  e.target.value)}/>
          <div className="question">{"If it's a ratio, how many attendees per staff member?"}</div>
          <span>1 staff per every <input type="text" style={{width: 45}} className="text-field" value={this.state.attendees_per_staff} onChange={(e) => this.changeNumber("attendees_per_staff", e.target.value)}/> attendees</span>
        </div>
      )
    default:
      if(this.state.new_service) return (
        <div className="finished">
          <img src={CheckIcon} className="finished-check" alt=""/>
          <div className="title">Thanks for submitting your request to add a new service to our site!</div>
          <div className="text">We’re excited by what you have to offer and think our clients will be too!</div>
          <div className="text">Our staff will contact you if we have any questions and will let you know as soon as the service is added to our site. </div>
          <div className="text">Our unique events are why our clients keep coming back to us and you help make that happen, so we value all your new and unique event ideas! They help us keep the party going!</div>
          <div className="text">Thanks so much!</div>
        </div>
      )
      return (
        <div className="finished">
          <img src={CheckIcon} className="finished-check" alt=""/>
          <div className="title">Thank you for submitting all your info!</div>
          <div className="text">You are now ready to receive event requests for your designated service!</div>
        </div>
      )
    }
  }

  async submit(){
    // this.props.history.push("/vendors/services")
    if(this.state.new_service){
      var body = getBody(this.state)
      var result = await request("/api/vendor/service/new", {method: "POST", body: JSON.stringify(body)})
      if(!result.err){
        localStorage.setItem("service_addition", null)
        this.setIndex(Number(this.props.match.params.index) + 1)
      }
    }else if(this.props.login.admin && this.state.vendor_id){
      var body = getBody(this.state)
      var result = await request("/api/admin/vendor/" + this.state.vendor_id + "/service/add", {method: "POST", body: JSON.stringify(body)})
      if(!result.err){
        localStorage.setItem("service_addition", null)
        this.setIndex(Number(this.props.match.params.index) + 1)
      }
    }else{
      var body = getBody(this.state)
      var result = await request("/api/vendor/service/add", {method: "POST", body: JSON.stringify(body)})
      if(!result.err){
        localStorage.setItem("service_addition", null)
        this.setIndex(Number(this.props.match.params.index) + 1)
      }
    }

  }

  canProgress(index){
    switch(index){
      case 1:
        return this.state.locations.length > 0 || this.state.selected.remote || this.state.remote
      case 0:
        if(this.state.new_service){
          return this.state.name && this.state.description
        }
        return this.state.selected && this.state.can_provide && (this.state.vendor_id || this.isNotDuplicate() )
      case 2:
        var pass = true
        if(this.state.deposit_required === "yes"){
          pass = !!this.state.deposit
        }
        if(this.state.extra_charge_late === "yes"){
          pass = !!this.state.late_booking_threshold && !!this.state.late_booking_percentage
        }
        if(this.state.extra_charge_too_long === "yes"){
          pass = !!this.state.normal_duration && !!this.state.extra_hours_fee
        }
        if(this.state.extra_charge_scales === "yes"){
          pass = !!this.state.extra_hours_fee_scalar && !!this.state.extra_hours_fee_scalar_unit
        }
        if(this.state.unit !== "kit"){
          pass = this.state.minimum !== ""
        }
        return this.state.price && pass && this.state.unit !== "Choose Unit"
      case 3:
        return this.state.booking_lead_time !== "" && this.state.max_events && this.state.setup_time !== "" && this.state.takedown_time !== "" && !isNaN(Number(this.state.setup_time)) && !isNaN(Number(this.state.takedown_time))
      default:
        return true
    }
  }

  setIndex(index){
    this.props.history.push("/vendors/services/add/" + index)
  }

  render(){
    var i =  Number(this.props.match.params.index || 0)
    return (
        <SurveyFlow setIndex={(index) => this.setIndex(index)} canProgress={this.canProgress(i)} length={4} index={i} submit={() => this.submit()} next={() => this.setIndex(i + 1)} back={() => this.setIndex(i - 1)} >
          {this.sections(i)}
        </SurveyFlow>
    )
  }

}

const mapStateToProps = state => ({
  login: state.login
})

export default withRouter(connect(
  mapStateToProps
)(AddService))


// <div className="question">Does your extra hours fee scale according to the details of the event?</div>
// <div className="four-hour">
//   <div className="opt" >
//     <input type="radio" id={"yes_scales"} value={"yes"} name={"yes"} onChange={(e) => this.changeState({extra_charge_scales: e.currentTarget.value})} checked={this.state.extra_charge_scales === "yes"} />
//     <label htmlFor={"yes_scales"}>Yes</label>
//   </div>
//   <div className="opt" >
//     <input type="radio" id={"no_scales"} value={"no"} name={"no"} onChange={(e) => this.changeState({extra_charge_scales: e.currentTarget.value})} checked={this.state.extra_charge_scales === "no"} />
//     <label htmlFor={"no_scales"}>No</label>
//   </div>
// </div>
// {this.state.extra_charge_scales === "yes" ?
//   <div>
//     <div className="question">How does it scale?* </div>
//     <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>E.g. An event with 100 attendees that scales with every 20 people will have an extra fee of 5x the extra hours fee per extra hour.</div>
//     <div style={{position: "relative"}}>
//       <span className="desc-text">Scales every </span>
//       <input type="text" value={this.state.extra_hours_fee_scalar} style={{textAlign: "center", padding: 0, width: 50, marginLeft: 6}} onChange={(e) => this.changeDecimal("extra_hours_fee_scalar", e.target.value)} className="text-field" />
//       <UnitPicker units={["people", "kits"]} value={this.state.extra_hours_fee_scalar_unit} select={(unit) => this.changeState({extra_hours_fee_scalar_unit: unit})} /></div>
//   </div>
//
// : null}
