import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/Footer.css'
import Logo from '../../img/1upLargeGrey.svg'

const Footer = ({style}) => {
  return (
    <div className="footer simple">
      <div style={style} className="content">
        <div className="simple">
          <img src={Logo} className="logo inline-flex" alt=""/>
          <span className="copyright">Copyright © {new Date().getFullYear()} 1up Events, Inc. All Rights Reserved.</span>
          <div className="others">
            <Link to="/terms-of-use">Privacy & Terms of Use</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
