import 'react-dates/initialize';
import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import * as cartActions from '../../actions/'
import Card from '../Card'
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-override.css'
import moment from 'moment'
import request from "../../request"
import Duration from '../../img/BookBarDuration.svg'
import DateIcon from '../../img/BookBarDate.svg'
import Location from '../../img/BookBarLocation.svg'
import People from '../../img/BookBarPeople.svg'
import RedDuration from '../../img/BookBarDurationRed.svg'
import RedDateIcon from '../../img/BookBarDateRed.svg'
import RedLocation from '../../img/BookBarLocationRed.svg'
import RedPeople from '../../img/BookBarPeopleRed.svg'
import {Dropdown, MenuItem} from 'react-bootstrap'
import CircleCheck from '../../img/CheckmarkCircle.svg'
import { getStartTime } from '../Utils/math'
import Alert from '../Utils/Alert'

// async function getLocation(){
//   var res = await request("https://ipinfo.io/json")
//   return res
// }

var time_range = [];
for (var i = .5; i <= 9.5; i+= 0.5) {
    time_range.push(i);
}


class BookBar extends Component {
  constructor(props){
    super(props)
    this.zipInput = new Set()
    this.state = {
      focused: false,
      zip: false,
      zipFocus: false,
      zipInput: false,
      location: "office",
      duration: false,
      valid_zipcodes: [],
      email: "",
      subscribed: false,
      servicable: null,
      late_fee: false
    }
  }

  async componentDidMount(){

    var locations = await request('/api/locations', {method: "GET"})
    if(!locations.err){
      var codes = locations.map( l => l.zipcodes)
      var valid_zipcodes = [].concat(...codes)
      this.setState({valid_zipcodes, servicable: valid_zipcodes.includes(this.props.values.zipcode)})
    }
    // if(!this.props.values.zipcode){
    //   var zipcode = await getLocation()
    //   zipcode = zipcode.postal
    //   this.props.changeBooking("zipcode", zipcode)
    // }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.app.missing && nextProps.values.date && nextProps.values.people && nextProps.values.duration && nextProps.values.zipcode && nextProps.values.location){
      this.props.missing("")
    }
  }

  displayFormat(){
    return "ddd, MMM D, YYYY"
  }

  changeZipcode(zipcode){
    this.setState({servicable: this.state.valid_zipcodes.includes(zipcode) })
    this.props.changeZipcode(zipcode)
  }

  blockOut(date){
    if(this.props.login.admin) return false
    return date < moment().add(2, 'days').subtract(5, "hours")
  }

  isOutsideRange(date){
    if(this.props.login.admin) return false
    return date < moment()
  }

  displayWithCommas(value){
    if(typeof value === 'string'){
      value = value.replace(/,/g , "");
      try{
        Number(value)
      }catch (err){
        return
      }
      this.setState({budget: Number(value).toLocaleString('en', {useGrouping:true})})

    }else{
      this.setState({budget: value.toLocaleString('en', {useGrouping:true})})
    }
  }

  ziptext(){
    if(this.props.values.zipcode){
      switch (this.props.values.location){
        case "venue":
          return <span>Your venue <i>({this.props.values.zipcode})</i></span>
        case "find":
          return <span>Find venue <i>(+$500)</i></span>
        default:
          return this.props.values.zipcode
      }
    }else{
      return <span className={this.props.app.missing ? "input-missing" : "" }>{"Zip code & venue"}</span>
    }

  }

  durationText(duration){

    if(duration === 1){
      return "Around 1 hour"
    }else if(duration === 10){
      return "10 or more hours"
    }else if(duration){
      return "Around " +duration + " hours"
    }
    return <span className={this.props.app.missing ? "input-missing" : "" }>Duration</span>
  }

  onToggle(open, a, source){
    if(source === 'keydown'){
      this.setState({duration: open})
    }else if(source === "click" &&  this.state.duration === "focused"){

      this.setState({duration: "clicked"})
    }else if(source === "select"  &&  this.state.duration === "focused"){
      this.setState({duration: false})
    }else{

      this.setState({duration: this.state.duration === "clicked" && !open ? true: open})
    }
  }

  focusZipcode(){
    console.log("focusZipcode")
    if(!this.state.zip){
      this.setState({zip: "focused"})
      setTimeout(() => document.getElementById("zipcode-text").focus(), 0)
    }
  }

  changePeople(val){
    if (!val){
      this.props.changePeople("")
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.props.changePeople(val)
      }
    }
  }

  changeDate(date){
    var start_time;
    if(this.props.values.timezone){
      start_time = getStartTime(date, moment(), this.props.values.timezone )
    }else{
      start_time = moment(date)
    }
    if(start_time.diff(moment(), "days") < 4){
      this.setState({late_fee: true})
    }
    this.props.changeDate(date)
  }

  changeStartTime(value){
    if(this.props.values.date && this.props.values.timezone){
      var start_time = getStartTime(this.props.values.date, value, this.props.values.timezone )
      var old_start = getStartTime(this.props.values.date, this.props.values.start_time, this.props.values.timezone )
      if(start_time.diff(moment(), "days") < 4 && old_start.diff(moment(), "days") >= 4){
        this.setState({late_fee: true})
      }
    }
    this.props.changeBooking("start_time", value.format())
  }



  onSelect(key, e){
    this.props.changeBooking("duration", Number(key))

  }

  async subscribe(){
    if(!this.state.email){
      document.getElementById("subscribe-email").focus()
      return
    }
    var params = {method: "POST", body: JSON.stringify({email: this.state.email, zipcode: this.props.values.zipcode})}
    var subscribed =  await request('/api/subscribe', params )
    if(!subscribed.err){
      this.setState({subscribed: true})
    }else{
      if(subscribed.err.code === "23505"){
        this.setState({subscribed: true})
      }
      console.log(subscribed.err)
    }
  }

  render(){
    var dropdown_ids = ["zipcode-text", "venue", "find", "subscribe-email", "service-link", "zipcode", "subscribe-button"]
    var zipStyle = {}
    if(this.state.zip){
      zipStyle.display = "block"
    }else{
      zipStyle.display = "none"
    }
    let missing = ""
    if(this.props.app.missing === "missingAlert"){
      missing = " missing"
    }
    return (
      <Card className={"bookbar" + (this.props.large ? " large":"") + missing}>
        <div className="bookbar-container independent">
          <img src={!this.props.values.date && this.props.app.missing ? RedDateIcon : DateIcon} className="icon date" alt="Date" />
          <span className={!this.props.values.date && this.props.app.missing ? "input-missing" : ""}><SingleDatePicker id="date_input"
            date={this.props.values.date ? moment(this.props.values.date) : null}
            focused={this.state.focused}
            onDateChange={(date) => this.changeDate(date)}
            onFocusChange={({ focused }) => this.setState({focused})}
            displayFormat={this.displayFormat}
            isOutsideRange={(date) => this.isOutsideRange(date)}
            isDayBlocked={(date) => this.blockOut(date)}
            hideKeyboardShortcutsPanel={true}
            placeholder="Date of event"
            readOnly={true}
            numberOfMonths={1}/></span>
          <div className="people">
            <img src={!this.props.values.people && this.props.app.missing ? RedPeople : People} className="icon" alt="people" />
            {this.props.values.people ? <span className="bookbar-text" style={{position: "absolute", left: 0}}><span style={{color: "white"}}>{this.props.values.people}</span>{Number(this.props.values.people) === 1 ? " person": " people"}</span>: null }
            <input id="people" className={!this.props.values.people && this.props.app.missing ? "input-missing" : ""} style={{position: "absolute", left: 0, background: "transparent"}} value={this.props.values.people} onChange={(e) => this.changePeople(e.target.value)} placeholder="# of people"/>
          </div>
          <div onFocus={() => this.focusZipcode()} className={"zipcode" + (this.state.zip ? " selected": "")} id="zipcode"  tabIndex="0"  onBlur={() => setTimeout(() => {if(!dropdown_ids.includes(document.activeElement.id)) this.setState({zip: false})}, 0)} >
            <div className="ziptext" onClick={() => this.state.zip === "focused" ?  this.setState({zip: true}):  this.setState({zip: !this.state.zip})}>
              <img src={!this.props.values.zipcode && this.props.app.missing ? RedLocation : Location} className="icon" alt="location" />
              {this.ziptext()}
              <div className={"my-small-caret zip" +(!this.props.values.zipcode && this.props.app.missing ? " missing" : "")}/>
            </div>
            <div className="zip-dropdown" style={zipStyle}   >
              <input className={"text-field" + (!this.props.values.zipcode && this.props.app.missing ? " missing-border" : "")} type="number" id="zipcode-text" value={this.props.values.zipcode} onChange={(e) => this.changeZipcode(e.target.value)} placeholder="Enter your zip code"  onFocus={()=> console.log("focus zip!") }/>
              {this.state.servicable ?
                <div>
                  <div className="question">Where would you like your event to take place?</div>
                  <div  onChange={(e) => this.props.changeVenue(e.target.value)} style={{fontSize: 15, boxShadow: "0 0"}}>
                    <input id="venue" type="radio" value="venue" name="location" defaultChecked={this.props.values.location === "venue"} />
                    <label htmlFor="venue">{"Your venue (office, home, or space you've booked already)."}</label>
                    <br/>
                    <input id="find" type="radio" value="find" name="location" defaultChecked={this.props.values.location === "find"}/>
                    <label htmlFor="find" style={{marginBottom: 0}}>{"Help me find a venue"}<i> (+$500)</i></label>
                  </div>
                  <div className="venue"> Our Event Team will contact you after you book. You’ll be charged $500 plus the cost of the venue. </div>
                </div>
                : (this.props.values.zipcode.length === 5 ?
                  <div>
                      <div className="not-available" ><span>Unfortunately we’re not serving your area yet.</span><Link className="link" style={{fontWeight: "normal"}} id="service-link" to="/locations" > View Locations</Link></div>
                      <div className="enter-email">Enter your email address to be notified when we expand: </div>
                      <input type="email" id="subscribe-email" style={this.state.subscribed ? {background: "rgba(0,0,0,0.08)"}: {}} className="text-field" value={this.state.email} onChange={e => this.setState({email: e.target.value})} placeholder="Email Address"/>
                      {this.state.subscribed ? <div className="secondary-button accepted" style={{width: "100%", textAlign: "center"}}><img src={CircleCheck} alt="" style={{height: 16, marginBottom: 2}} />  Subscribed</div>
                        : <div id="subscribe-button" className="secondary-button" style={{width: "100%", textAlign: "center"}} onClick={()=> this.subscribe()}>Sign Up for Updates</div>
                      }
                  </div>
                    :  null)
                }
            </div>
          </div>
          <Dropdown onClick={(e) => e.stopPropagation()} id="duration" className="duration" open={!!this.state.duration} onToggle={(open, a, b) => this.onToggle(open, a, b.source)}  onFocus={() => {console.log("should open"); this.setState({duration: !this.state.duration ? "focused": false})}} onSelect={(key, e) => this.props.changeDuration(Number(key))} >
            <Dropdown.Toggle noCaret >
              <div className="durtext" >
                <img src={!this.props.values.duration && this.props.app.missing ? RedDuration : Duration} className="icon" alt="duration" />
                <span>{this.durationText(this.props.values.duration)}</span>
                <div className={"my-small-caret" +(!this.props.values.duration && this.props.app.missing ? " missing-border" : "")}/>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="duration-options">
            {time_range.map(t =>
              <MenuItem eventKey={String(t)} active={this.props.values.duration === t} >{t} hours</MenuItem>
            )}
              <MenuItem eventKey="10" active={this.props.values.duration === 10} >10+ hours</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
          {this.props.large ? <Link to="/events" className="custom-button">Get Started</Link> : null}
        </div>
        <Alert show={this.state.late_fee} zIndex={9005} onHide={() => this.setState({late_fee: false})} title={"Last minute booking fees may apply."} text="Your event is less than 4 days away, which means the prices shown will reflect an increase in order to accommodate last minute bookings. Please choose a date at least 4 days away to avoid any last minute fees." />
      </Card>
    )
  }
}


const mapStateToProps = state => ({
  app: state.app, cart: state.cart, login: state.login
})

const mapDispatchToProps = dispatch => ({
  changeBooking: bindActionCreators(cartActions, dispatch).changeParams, missing: bindActionCreators(cartActions, dispatch).assignMissing
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBar))
