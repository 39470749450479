import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header'
import Title from '../Title'
import ComponentSearch from './ComponentSearch'
import Selector from '../Utils/Selector'

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class NewLocation extends Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      zipcodes: "",
      timezone: "",
      timezone_name: "",
      tax: "",
      region_id: null,
      sub_region_id: null,
      error: "",
      allComponents: [],
      regions: []
    }
  }

  async componentDidMount(){
    var regions = await request('/api/regions')
    this.setState({regions})
  }

  async validate(){
    let jsonList = ["zipcodes"]
    try{
      for(var c of jsonList){
        if(this.state[c]){
          try{
            JSON.parse(JSON.stringify(this.zipToArray(this.state[c])))
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      if(!this.state.name){
        document.getElementById("name").focus()
        this.setState({error: "Name can't be empty"})
        return false
      }
      if(!this.state.timezone){
        document.getElementById("timezone").focus()
        this.setState({error: "timezone"})
        return false
      }
      if(!this.state.timezone_name){
        document.getElementById("timezone_name").focus()
        this.setState({error: "timezone_name"})
        return false
      }
      if(!this.state.tax){
        document.getElementById("tax").focus()
        this.setState({error: "tax"})
        return false
      }
      if(!this.state.region_id){
        this.setState({error: "region_id"})
        return false
      }
      if(!this.state.sub_region_id){
        this.setState({error: "sub_region_id"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  zipToArray(zipcodes){
    return zipcodes.split(",").map(z => z.trim())
  }


  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate()
    if(validated){
      var location = {name: this.state.name, zipcodes: this.zipToArray(this.state.zipcodes), timezone: this.state.timezone, timezone_name: this.state.timezone_name, tax: this.state.tax, region_id: this.state.region_id.value, sub_region_id: this.state.sub_region_id.value}
      var res  = await request("/api/admin/location", {method: "POST", body: JSON.stringify(location)})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.props.history.push("/admin_panel/locations/" + res.id)
      }
    }
  }

  render(){
    console.log(this.state.regions)
    var regions = this.state.regions.map( r => {return {value: r.id, name: r.name}})
    var sub_regions = []
    if(this.state.region_id){
      sub_regions = this.state.regions.find(r => Number(r.id) === Number(this.state.region_id.value)).sub_regions.map( s => {return {value: s.id, name: s.name}})
    }
    return (
      <div>
        <Header />
        <div style={{marginTop: 40, marginLeft: 40}}>
          <Title title="New Location"/>
        </div>
        <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          <FieldGroup
               id={"name"}
               key={"name"}
               type="text"
               label={"name"}
               className="text-field"
               placeholder={"name"}
               value={this.state.name}
               onChange={(e)=> this.setState({name: e.target.value})}
               bsSize="sm"
             />
          <FormGroup key={"zipcodes"} controlId={"zipcodes"}>
            <ControlLabel>{"zipcodes"}</ControlLabel>
            <FormControl className="text-field" componentClass="textarea" style={{resize: "vertical"}} rows="8"  bsSize="sm" placeholder={"94041, 94042"} value={this.state.zipcodes} onChange={(e)=> this.setState({zipcodes: e.target.value})}/>
          </FormGroup>

          <FieldGroup
               id={"timezone_name"}
               key={"timezone_name"}
               type="text"
               label={"timezone name"}
               className="text-field"
               placeholder={"Pacific Time"}
               value={this.state.timezone_name}
               onChange={(e)=> this.setState({timezone_name: e.target.value})}
               bsSize="sm"
             />
         <FieldGroup
              id={"timezone"}
              key={"timezone"}
              type="text"
              label={"Timezone"}
              className="text-field"
              placeholder={"America/Los_Angeles"}
              value={this.state.timezone}
              onChange={(e)=> this.setState({timezone: e.target.value})}
              bsSize="sm"
            />
        <FieldGroup
             id={"tax"}
             key={"tax"}
             type="number"
             label={"Tax"}
             className="text-field"
             placeholder={"0.085"}
             value={this.state.tax}
             onChange={(e)=> this.setState({tax: e.target.value})}
             bsSize="sm"
           />
         <FormGroup key={"region_id"} controlId={"region_id"}>
          <ControlLabel>{"Region"}</ControlLabel>
          <Selector value={this.state.region_id} update={(region_id) => this.setState({region_id})} options={regions}/>
         </FormGroup>
         <FormGroup key={"sub_region_id"} controlId={"sub_region_id"}>
          <ControlLabel>{"Sub Region"}</ControlLabel>
          <Selector value={this.state.sub_region_id} update={(sub_region_id) => this.setState({sub_region_id})} options={sub_regions} placeholder="Select region"/>
         </FormGroup>
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(NewLocation)
