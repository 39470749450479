import React from "react"
import '../css/Title.css'

const Title = ({title, subtitle, wrapperClassName}) => {
  return (
    <div className={"title-wrapper max-w-full " + wrapperClassName } >
      <h1 className="title">{title}</h1>
      {subtitle ? <div className="subtitle">{subtitle}</div>: <div className="subtitle" style={{height: 20}} />}
    </div>
  )
}

export default Title
