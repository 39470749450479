import React, { Component } from 'react'
import Modal from '../Modal'
import request from '../../request'
import Search from '../Search'
import AddToCartButton from '../../img/AddToCartButton.svg'
import InCartButton from '../../img/InCartButton.svg'
import moment from 'moment'
import ListItem from '../Powerups/ListItem'
import './AdderStyles.css'
import { getStartTime, getLateFee } from '../Utils/math'

var categories = ["remote", "food", "drink", "animals", "entertainment", "decor", "logistics"]

function getOptionNames(options){
  var keys = []
  for (let t of options.types){
    keys = keys.concat(t.options.map(opt => opt.name.toLowerCase()))
  }
  return keys
}

const SideBar = ({categories, onSelect, selected}) => {
  return (
    <div className="adder-sidebar" >
      {categories.map( (cat, i) =>
        <div key={i} className={"category" + (cat === selected ? " selected" : "") } onClick={() => onSelect(cat)}>{cat}</div>
      )}
    </div>
  )
}

const ItemList =({items, booking, actions, onChange, add, remove, added}) => {
  var added_ids = []
  var added_comps = []
  console.log(added)
  var i = 0
  for (let a of added){
    if(a.change !== "remove" && !a.canceled){
      added_ids.push(Number(a.id))
      a.index = i
      added_comps.push(a)
    }
    i++
  }
  console.log(added_ids, added_comps)
//!added_ids.includes(c.id) && !c.event_component_id
  return (
    <div className="item-adder cart-contents">
      {items.map(c => {
        if(added_ids.includes(Number(c.id))){
          c = added_comps.find(a => a.id === c.id)
        }
        return (
          <div style={{position: 'relative'}} key={c.id} >
            <div style={{width: 280, display: 'inline-block'}}>
              <ListItem booking={booking} noChange={!added_ids.includes(Number(c.id))} viewDetails={!added_ids.includes(c.id)} c_id={c.id} component={c} package={false} showModal={true} inCart={false}
                remove={remove} add={add} change={onChange} to_add={c.change === "add" || c.change === "update" } to_remove={c.change === "remove"} changeable={added_ids.includes(Number(c.id))}/>
            </div>
            {c.is_available ?
            (added_ids.includes(Number(c.id)) || c.event_component_id ?
              <img style={{position: 'absolute', right: "18px", bottom: "7px"}} src={InCartButton} alt=""/>
              : <img className="add" src={AddToCartButton}  onClick={() => add(c)} alt="" /> )
              : null
            }
          </div>
        )
      })}
    </div>
  )
}

export default class ItemAdder extends Component{

  constructor(props){
    super(props)
    this.state = {
      items: [],
      category: "food",
      search: ""
    }
  }

  async componentDidMount(){
    // console.log("DATE", this.props.booking.date, moment(this.props.booking.date).format())
    var start_time = getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)
    var end_time = this.props.booking.duration ? moment(start_time).add(this.props.booking.duration, "hours") : start_time.add(1, "hours")
    var items = await request('/api/components', {method: "POST", body: JSON.stringify({start_time, end_time, zipcode: this.props.booking.zipcode})})
    if(items.err){
      console.log(items.err)
      return
    }else{
      for(let c of items){
        for(let s of c.services){
          s.late_booking_fee = getLateFee(moment(), start_time)
        }
        c.is_available = c.remaining !== 0
        if (c.keywords){
          c.keywords.push(c.name.toLowerCase())
          if(c.options) c.keywords = c.keywords.concat(getOptionNames(c.options))
        }else{
          c.keywords = [c.name.toLowerCase()]
          if(c.options) c.keywords = c.keywords.concat(getOptionNames(c.options))
        }
      }
      this.setState({items})
    }
  }

  search(comps, search){
    search =  search.toLowerCase();
    return comps.filter( c => {
      if (c.keywords){
        for (var k of c.keywords){
          if(k.includes(search)){
            return true
          }
        }
        return false
      }
      return false
    })
  }

  getOptionNames(options){
    var keys = []
    for (let t of options.types){
      keys = keys.concat(t.options.map(opt => opt.name.toLowerCase()))
    }
    return keys
  }

  filter(items, cat, search){
    if(search){
      return this.search(items, search)
    }else{
      return items.filter( c => c.categories && c.categories.includes(cat))
    }
  }

  render(){

    var items = this.filter(this.state.items, this.state.category, this.state.search)
    console.log("rerender adder")
    return (
      <Modal show={true} onHide={() => this.props.onHide()} zIndex={2000} customClassName="adder-modal" backdropClassName="custom-backdrop">
        <div className="adder-header">
          <span className="text">Choose Items to Add</span>
          <Search value={this.state.search} category="" updateSearch={(e) => this.setState({search: e.target.value})}/>
        </div>
        <div>
          <SideBar categories={categories} onSelect={(category) => this.setState({category})}  selected={this.state.category}/>
          <ItemList items={items} booking={this.props.booking} remove={this.props.remove} onChange={this.props.edit} add={this.props.add} added={this.props.components} />
        </div>
        <div className="adder-footer">
          <div className="custom-button" onClick={() => this.props.onHide()}> Done </div>
        </div>
      </Modal>
    )
  }
}
