import React, {Component} from 'react'
import { withRouter, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import request from '../../request'
import moment from 'moment'
import './Overview.css'
import _ from 'underscore'
import { UpcomingSummary, PastSummary }  from './Summaries'
import Title from '../Title'
import Static from '../StaticPages/StaticWrapper'
import Chevron from '../../img/Chevron.svg'
import ConfirmationModal from '../Utils/ConfirmationModal'

class Overview extends Component{

  constructor(props){
    super(props)
    this.state = {
      pastEvents: [],
      upcomingEvents: [],
      merge: null,
    }
  }


  async componentDidMount(){
    var res = await request('/api/events')
    if (res.err){
      console.log(res.err)
      return
    }else{
      console.log(res)
      var upcomingEvents = []
      var pastEvents = []
      for (var e of res){
         if(moment(e.start_time.replace('Z', "")) < moment() || e.canceled){
           pastEvents.push(e)
         }else{
           upcomingEvents.push(e)
         }
      }
      upcomingEvents = _(upcomingEvents).sortBy( e => e.start_time)
      pastEvents = _(pastEvents).sortBy( e => e.start_time).reverse()
      console.log("upcoming", upcomingEvents)
      this.setState({upcomingEvents, pastEvents})
    }
  }

  merge(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: this.state.merge, componentsRemoved: []}})
  }

  replace(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: true, event_id: this.state.merge, componentsRemoved: []}})
  }

  shouldMerge(id){
    var cart_count = 0;
    for (var key in this.props.cart){
      cart_count += this.props.cart[key].length
    }
    if(cart_count === 0){
      this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: id, componentsRemoved: []}})
    }else{
      this.setState({merge: id})
    }
  }

  render(){
    if(!this.state.pastEvents && !this.state.upcomingEvents) return null

    return (
      <Static className="wide">
        <div className="nav-links"> <Link to="/account" className="link">Account</Link>  <img src={Chevron} alt=""/> </div>
        <div className="static-page-header">Your events</div>
        <div className="static-page-subheader">View all your upcoming and past events.</div>
        <div className="section-wrapper">
          <div className="dashboard" >
            <Title title="Upcoming Events" subtitle=""/>
            {this.props.login.vendor_id ?
              <Link className="custom-button vendor" style={{position: "right", right: 0, top: 0, paddingLeft: 20, paddingRight: 20, width: "fit-content"}} to={"/vendors/events"}>View Events to Service</Link>
            :null}
            <div className="event-list">
            {this.state.upcomingEvents.length === 0 ? <div style={{ marginLeft: 30, color: "var(--primary-content-color)", fontSize: 15}}><i>{"You don't have any upcoming events"}</i></div> : null}
            {this.state.upcomingEvents.map(e =>
              <UpcomingSummary key={e.id} e={e} components={e.components} pack={e.packages ? e.packages[0] : null} venue={e.venue} />
            )}
            </div>
            {this.state.pastEvents.length > 0 ?
              <div style={{marginTop: 50}}>
              <Title title="Past Events" subtitle=""/>
              <div className="event-list">
                {this.state.pastEvents.map( e =>
                  <PastSummary key={e.id} e={e} components={e.components} pack={e.packages ? e.packages[0] : null} venue={e.venue} onClick={() => this.shouldMerge(e.id)} />
                )}
              </div>
              </div>
            : null}
          </div>
        </div>
        <ConfirmationModal title="You have items in your cart already" text="Would you like to replace your cart or merge it with your event?" show={!!this.state.merge} onHide={() => this.setState({merge: null})} secondaryAction={ () => this.merge()} secondaryText={"Merge"} confirmationText={"Replace"} onConfirm={() => this.replace()} tertiary={true}/>
      </Static>
    )
  }
}


const mapStateToProps = state => ({
  store: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview))
