import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header'
import Title from '../Title'
import ComponentSearch from './ComponentSearch'
import Selector from '../Utils/Selector'

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class NewSubRegion extends Component{

  constructor(props){
    super(props)
    this.state = {
      name: ""
    }
  }

  async validate(){
    let jsonList = ["zipcodes"]
    try{
      if(!this.state.name){
        document.getElementById("name").focus()
        this.setState({error: "Name can't be empty"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate()
    if(validated){
      var location = {name: this.state.name}
      var res  = await request("/api/admin/region", {method: "POST", body: JSON.stringify(location)})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.props.history.push("/admin_panel")
      }
    }
  }

  render(){
    return (
      <div>
        <Header />
        <div style={{marginTop: 40, marginLeft: 40}}>
          <Title title="New Region"/>
        </div>
        <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          <FieldGroup
               id={"name"}
               key={"name"}
               type="text"
               label={"name"}
               className="text-field"
               placeholder={"name"}
               value={this.state.name}
               onChange={(e)=> this.setState({name: e.target.value})}
               bsSize="sm"
             />
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(NewSubRegion)
