import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import BookBar from '../Header/IndependentBookBar'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { getStartTime } from './math'
import { Dropdown, MenuItem } from 'react-bootstrap'
import './Reschedule.css'

class BlockOff extends Component {

  constructor(props){
    super(props)
    this.state = {
      new_date: props.start_time ? moment(props.start_time) : moment(),
      new_end_date: props.end_time ? moment(props.end_time) : "",
      start_time: props.start_time || "",
      end_time: props.end_time || "",
      focused: false,
      focused_end: false,
      time_open: false,
      end_time_open: false,
    }
  }

  blockOut(date){
    return date < moment()
  }


  blockOutPast(date){
    return date < moment(this.state.new_date)
  }


  isOutsideRange(date){
    return date < moment()
  }



  render(){
    var start_time = getStartTime(this.state.new_date, this.state.start_time, this.props.timezone)
    var end_time   = getStartTime(this.state.new_end_date || this.state.new_date, this.state.end_time, this.props.timezone)
    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="reschedule-modal booking-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")} onHide={() => this.props.onHide()}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title">{this.props.title}</div>
          <div className="questions">Select start and end time and dates</div>
          <div>
            <div className="text-field date">
              <SingleDatePicker
                date={moment(this.state.new_date)}
                focused={this.state.focused}
                onDateChange={(new_date) => this.setState({new_date})}
                onFocusChange={({ focused }) => this.setState({focused})}
                displayFormat={"ddd, MMM D, YYYY"}
                isOutsideRange={(date) => this.isOutsideRange(date)}
                isDayBlocked={(date) => this.blockOut(date)}
                hideKeyboardShortcutsPanel={true}
                placeholder="Date of event"
                readOnly={true}
                numberOfMonths={1}/>
            </div>
            <span className="to"> at </span>
            <TimePicker
               ref={(ref) => this.time_picker = ref }
               id={"start_time"}
               showSecond={false}
               value={this.state.start_time ? moment(this.state.start_time) : null}
               className={this.state.time_open ? "time open": "time"}
               placeholder="Start time"
               onChange={(value) => this.setState({start_time: value.format()}) }
               format={'h:mma'}
               minuteStep={15}
               getPopupContainer = {node => node}
               onOpen={() => this.setState({time_open: true})}
               onClose={() => this.setState({time_open: false})}
               use12Hours
             />
            <span className="to"> to </span>
            <div className="text-field date">
             <SingleDatePicker
               date={moment(this.state.new_end_date || this.state.new_date)}
               focused={this.state.focused_end}
               onDateChange={(new_end_date) => this.setState({new_end_date})}
               onFocusChange={({ focused }) => this.setState({focused_end: focused})}
               displayFormat={"ddd, MMM D, YYYY"}
               isOutsideRange={(date) => this.isOutsideRange(date)}
               isDayBlocked={(date) => this.blockOutPast(date)}
               hideKeyboardShortcutsPanel={true}
               placeholder="Date of event"
               readOnly={true}
               numberOfMonths={1}/>
            </div>
            <span className="to"> at </span>
            <TimePicker
              ref={(ref) => this.time_picker = ref }
              id={"start_time"}
              showSecond={false}
              value={this.state.end_time ? moment(this.state.end_time) : null}
              className={this.state.end_time_open ? "time open": "time"}
              placeholder="End time"
              onChange={(value) => this.setState({end_time: value.format()}) }
              format={'h:mma'}
              minuteStep={15}
              getPopupContainer = {node => node}
              onOpen={() => this.setState({end_time_open: true})}
              onClose={() => this.setState({end_time_open: false})}
              use12Hours
            />
          </div>
          <div className={"custom-button"} style={{float: "right", marginLeft: 9}} onClick={() => this.props.save(start_time, end_time)}>{this.props.confirmText}</div>
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
          {this.props.tertiaryAction ?
            <div className="tertiary-button" onClick={this.props.tertiaryAction}>Delete</div>
            : null
          }
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockOff))
// export default CreateWishlist
