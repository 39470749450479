import React from 'react'
import moment from 'moment-timezone'
import './EventSummary.css'
import {Link} from 'react-router-dom'
import {getEarliestStart, getLatestEnd} from '../Utils/math'

function getName(data){
  var names = data.services.map(s => s.name)
  names = names.join(", ")
  return (
    <div className="name">
      {names}
    </div>
  )
}

const EventSummary = ({data, viewDetails, length, vendor_timezone}) => {

  var image = ""
  if(data.services[0].images) image = "https://d2c831se9gn8j1.cloudfront.net/"  + data.services[0].images[0]
  var name = data.services && data.services[0] ? data.services[0].name: "service"
  var setup_times = data.services.map(s => s.setup_time || 0 )
  var setup_time = Math.max(...setup_times)
  var earliest_start = getEarliestStart(data.services)
  var latest_end = getLatestEnd(data.services)
  let timezone = data.services.every(s => s.remote) ? vendor_timezone :  data.timezone
  var arrive_by = moment(earliest_start).tz(timezone).subtract(setup_time, "hours").format("h:mm a z")
  return (
    <div className="event-summary">
      <div className="overview-container">
        <div className="powerup-image-container">
          <img className="powerup-image" src={image} alt={name}/>
        </div>
        <div className="name-date">
          {getName(data)}
          <div className="date"><b>{moment(earliest_start).tz(timezone).format("ddd, MMM D")}</b> from <b>{moment(earliest_start).tz(timezone).format('h:mm')}-{moment(latest_end).tz(timezone).format('h:mma z')}</b></div>
        </div>
        <Link to={"/vendors/event/" + data.id } className="view-details">View Full Details</Link>
        <div className="details">
          <div className="sec"><b>{data.location}</b></div><div className="sec"><b>Arrive by {arrive_by}</b></div>
        </div>
      </div>
    </div>
  )
}

export default EventSummary
