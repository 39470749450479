import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Textarea from 'react-textarea-autosize';
import Static from '../StaticPages/StaticWrapper'
import Title from '../Title'
import CheckboxControl from '../Utils/CheckboxControl'
import ItemList from '../Utils/ItemList'
import WarningIcon from '../../img/WarningIconWhite.svg'
import { SingleDatePicker } from 'react-dates';
import request from '../../request'
import currencyFormatter from 'currency-formatter'
import { getComponentPrice, finalTotal, getCouponValue, getBookedTax, getBookedPrice, getStartTime } from '../Utils/math'
import { getTimezoneShortName } from '../Utils/Helpers'

import './EventView.css'
import Chevron from '../../img/Chevron.svg'
import moment from 'moment-timezone'
import TimePicker from 'rc-time-picker';
import Duration from '../../img/BookBarDuration.svg'
import Date from '../../img/BookBarDate.svg'
import Location from '../../img/BookBarLocation.svg'
import People from '../../img/BookBarPeople.svg'
// import Track from '../Utils/Analytics'
import PlacesAutocomplete from 'react-places-autocomplete'
import Alert from '../Utils/Alert'
import ChangeBooking from '../Utils/ChangeBooking'
import { BeatLoader } from 'react-spinners';
import ItemAdder from './ItemAdder'
import ConfirmationModal from '../Utils/ConfirmationModal'
import Modal from '../Modal'
import Selector from '../Utils/ValueSelector'

import VendorList from './VendorList'
// const PPlacesAutocomplete = () => <div/>

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}

function getLocationText(e, venue){
  if(venue && venue.venue_name){
    return venue.venue_name
  }
  if(e.location){
    if(venue){
      return <span className="location">{e.location}</span>
    }
    return <span>Your venue </span>
  }

  return "To Be Determined"
}

export default class UpcomingEvent extends Component {

  constructor(props){
    super(props)
    var e = props.event.event
    let v = props.event.venue || {}
    this.state = {
      new_name: "",
      new_email: "",
      new_company_name: "",
      address: e.location,
      old_address: e.location,
      payment_method: e.payment_method,
      start_time: moment.tz(e.start_time, e.timezone),
      time_open: false,
      instructions: e.day_notes,
      parking_sit: e.parking,
      elevator: e.elevator,
      coi: e.coi ? "yes" : "no",
      coi_req: "",
      coi_verified: e.coi_verified,
      new_phone: "",
      type: v.type || "",
      budget: v.budget || "",
      location: v.location || "",
      tables_chairs: v.tables_chairs || false,
      classroom_seating: v.classroom_seating || false,
      theater_seating: v.theater_seating || false,
      wifi_internet: v.wifi_internet || false,
      av_setup: v.av_setup || false,
      parking: v.parking || false,
      anything_else: v.notes || "",
      disabled: false,
      coupon: "",
      invalid_coupon: "",
      coupon_details: e.coupon || null,
      error: "",
      change_contact_info: false,
      showAlert: false,
      addItems: false,
      event: props.event,
      added: [],
      removed: [],
      edited: false,
      cancel: false,
      decrease: false,
      reschedule: false,
      new_date: moment.tz(e.start_time, e.timezone),
      focused: false,
      unavailable: false,
      notify: false,
      pack_unavailable: [],
      booking: false,
      vendors: [],
      venue_address: e.location || "",
      venue_name: v.venue_name || "",
      venue_price: v.price || "",
      ec_id: "",
      special_notes: e.special_notes,
    }
  }

  async componentDidMount(){
    if(this.props.location.state && this.props.location.state.addItems){
      var elmnt = document.getElementById("items-section");
      elmnt.scrollIntoView();
    }

  }


  getCancellationCharge(e, total){
    if(moment(e.start_time.replace('Z', "")) > moment().add(14, "days")){
      if(moment(Number(e.created_at)).add(1, "days") > moment()){
        return "No fee will be charged within 24 hours of booking"
      }
      return "Due to cancelling more than 24 hours after booking, a cancellation fee of " + currencyFormatter.format(.5*total , { code: 'USD', precision: 0}) + " will be charged."
    }else{
      if(moment(Number(e.created_at)).add(1, "hours") > moment()){
        return "No fee will be charged within 1 hour of booking"
      }
      return "Due to cancelling within 2 weeks of your event, you will be charged the full amount for your event."
    }
  }

  getItemCancellationFee(comp){
    if(!comp) return
    let clone = cloneObj(comp)
    if(moment(Number(this.props.event.event.created_at)).add(1, "hours") > moment() || (moment(Number(this.props.event.event.created_at)).add(1, "days") > moment() && moment(this.props.event.event.start_time) > moment().add(14, "days")) ){
      clone.canceled = "no_charge"
    }else{
      clone.canceled = moment(this.props.event.event.start_time) < moment().add(14, "days") ? "late" : "early"
    }
    return "You will be charged " + currencyFormatter.format(getComponentPrice(clone, {duration: this.props.event.event.hours_needed, people: this.props.event.event.num_people}, null, true), { code: 'USD', precision: 0})
  }

  cancelEvent(){
    this.setState({cancel: true})
  }

  async cancel(){
    var cancel = await request('/api/event/'+this.props.event.event.id+'/cancel', {method: "POST"})
    if(!cancel || cancel.err){
      console.log("could not cancel")
      this.setState({error: "Could not cancel event. Sorry. Please try again or contact us.", showAlert: true})
    }else{
      this.cancelChanges()
      window.scrollTo(0, 0)
      await this.props.reloadEvent()
    }
  }

  changeState(obj){
    obj.edited = true
    this.setState(obj)
  }

  changeNotes(value, component, cat){
    let clone = cloneObj(this.state.event)
    if(cat === "package"){
      clone.package.notes = value
    }else{
      var c  = clone.components[component.index]
      if(c.change !== "add" && !c.canceled){
        c.change = "update"
      }
      c.notes =  value
    }
    this.setState({event: clone, edited: true})
  }

  changeOffset(value, component, cat){
    let clone = cloneObj(this.state.event)
    var c  = clone.components[component.index]
    if(c.change !== "add" && !c.canceled){
      c.change = "update"
    }
    c.start_offset = value
    c.start_time = moment.tz(this.state.new_date, this.state.event.event.timezone).add(value, "hours")
    this.setState({event: clone, edited: true})
  }


  applyStateChanges(event){
    let clone = cloneObj(this.state.event)
    if(clone.venue){
      clone.venue.type = this.state.type
      clone.venue.budget = this.state.budget
      clone.venue.location = this.state.location
      clone.venue.tables_chairs = this.state.tables_chairs
      clone.venue.classroom_seating = this.state.classroom_seating
      clone.venue.theater_seating = this.state.theater_seating
      clone.venue.wifi_internet = this.state.wifi_internet
      clone.venue.av_setup = this.state.av_setup
      clone.venue.parking = this.state.parking
      clone.venue.notes = this.state.anything_else
    }
    clone.event.location = this.state.old_address
    clone.event.new_location = this.state.old_address !== this.state.address ? this.state.address : null
    clone.event.payment_method = this.state.payment_method

    let new_date = moment(this.state.new_date)
    let old_date = moment(this.state.event.event.start_time)
    let old_end = moment(old_date).add(this.state.event.event.hours_needed, 'hours')

    // var start_time = this.state.start_time.format("HH:mm")
    // start_time = getStartTime(this.state.new_date, start_time, this.state.event.event.timezone)
    // console.log("applyStateChanges", start_time, start_time.format())
    var duration = Number(this.state.new_hours_needed) || Number(this.props.event.event.hours_needed)
    var end_time = moment(new_date).add(duration, 'hours')
    clone.event.new_start = new_date
    clone.event.new_end = end_time
    clone.event.day_notes = this.state.instructions
    clone.event.parking = this.state.parking_sit
    clone.event.elevator = this.state.elevator
    clone.event.coi = this.state.coi === "yes" ? true : false
    clone.event.coi_verified = this.state.coi_verified
    clone.event.date = new_date.format()
    clone.event.new_email = this.state.new_email
    clone.event.new_phone = this.state.new_phone
    clone.event.new_name = this.state.new_name
    clone.event.new_company_name = this.state.new_company_name
    clone.event.special_notes = this.state.special_notes
    for(var c of clone.components){
      c.start_offset =  c.start_offset ? c.start_offset.value : 0
      c.start_time = moment(new_date).add(c.start_offset, "hours")
      c.end_time = c.unit === "hours" ? moment(c.start_time).add(c.qty, 'hours') : (c.duration ? moment(c.start_time).add(c.duration, 'hours') : end_time)
      console.log(c)
    }
    if(!new_date.isSame(old_date, "minutes")){
      for(var c of clone.components){
        if(c.change !== "add" && c.change !== "remove" && !c.canceled){
          c.change = "update"
        }
      }
    }
    if(!end_time.isSame(old_end, "minutes")){
      for(var c of clone.components){
        if(c.change !== "add" && c.change !== "remove" && !c.canceled && !c.duration){
          c.change = "update"
        }
      }
    }
    return clone
  }

  async saveChanges(){
    var event = this.applyStateChanges(this.state.event)
    console.log("savechanges", event)
    var result = await request('/api/event/' + this.props.match.params.id, {method: "PUT", body: JSON.stringify({event})})
    if (result && !result.err){
      await this.props.reloadEvent()
      this.cancelChanges()
    }else{
      console.log(result)
      this.setState({error: "There was an issue saving your changes. Please try again or contact us.", showAlert: true})
    }
  }


  addComponent(comp){
    let clone = cloneObj(this.state.event)
    comp.change = "add"
    comp.index = clone.components.length
    console.log("add", comp, comp.qty)
    if(!comp.qty){
      switch(comp.unit){
        case "hour":
          comp.qty = this.state.event.event.hours_needed
          break
        case "kit":
          comp.qty = 1
          break
        default:
          comp.qty = this.state.event.event.num_people
      }
    }
    console.log(comp.qty)
    clone.components.push(comp)
    this.setState({event: clone, edited: true})
  }

  changeCOI(coi_verified){
    console.log(this.state.coi_verified, coi_verified)
    this.changeState({coi_verified})
  }

  editComponent(comp){
    console.log("editing", comp)
    let clone = cloneObj(this.state.event)
    var index = comp.index
    var c  = clone.components[index]
    if(c.change !== "add" && !c.canceled){
      comp.change = "update"
    }
    if(!c.canceled){
      if(!comp.qty){
        switch(comp.unit){
          case "hour":
            comp.qty = this.state.new_hours_needed || this.state.event.event.hours_needed
            break
          case "kit":
            comp.qty = 1
            break
          default:
            comp.qty = this.state.new_num_people || this.state.event.event.num_people
        }
      }
      console.log(comp.qty, this.state.event.components[index].qty)
      if(c.change !== "add"){
          if( (comp.qty/this.props.event.components[index].qty) < .75 || (comp.num_staff && this.props.event.components[index].num_staff && (comp.num_staff/this.props.event.components[index].num_staff < .75))) this.setState({decrease: true})
      }
    }
    clone.components.splice(index, 1, comp);
    console.log(clone.components)
    this.setState({event: clone, edited: true})
  }

  changeHeadcount(count){
    let clone = cloneObj(this.state.event)
    console.log("changing heacount")
    for(var c of clone.components){
      console.log("updating comp", c, c.qty, c.unit, clone.event.new_num_people || clone.event.num_people)
      if(c.unit === "person" && Number(clone.event.new_num_people || clone.event.num_people) === Number(c.qty) && !c.canceled){
        console.log("updating comp")
        c.qty = count
        c.change = "update"
      }
    }
    clone.event.new_num_people = count
    return clone
    // this.setState({event: clone, edited: true})
  }

  changeDuration(clone, duration){
    for(var c of clone.components){
      var oc = this.props.event.components.find(oc => oc.id === c.id)
      if(c.unit === "hour" && clone.event.hours_needed === c.qty && !c.canceled){
        if(oc){
          if(oc.qty === duration){
            c.change = null
            c.qty = duration
          }else{
            c.change = "update"
            c.qty = duration
          }
        }else{
          c.qty = duration
        }
      }
      // }else{
      //   if(oc && oc.duration === null){
      //     c.change = "update"
      //   }
      //
      //
      // }
    }
    clone.event.new_hours_needed = duration
    return clone
  }

  changeBooking(start, end, duration, headcount){
    console.log("changeBooking", start, end, duration, headcount)
    var clone = this.changeHeadcount(headcount)
    this.changeDate(clone, start, end, duration)
  }

  remove(comp){
    if(comp.change === "add" || (moment(Number(comp.created_at)).add(1, "days") > moment() && moment(this.props.event.event.start_time) > moment().add(14, "days"))){
      let clone = cloneObj(this.state.event)
      clone.components.splice(comp.index, 1)
      console.log("remove", clone.components)
      this.changeState({event: clone})
    }else{
      this.setState({remove: comp})
    }
  }

  removeComponent(comp){
    let clone = cloneObj(this.state.event)
    var c  = clone.components[comp.index]
    c.change = "remove"
    if(!c.is_available) c.is_available = true
    if(moment(Number(this.props.event.event.created_at)).add(1, "days") > moment() && moment(this.props.event.event.start_time) > moment().add(14, "days")){
      c.canceled = "no_charge"
    }else{
      c.canceled = moment(this.props.event.event.start_time) < moment().add(14, "days") ? "late" : "early"
    }
    var available = this.getAvailability(clone)
    // var start = moment(this.state.new_date || this.props.event.event.start_time)
    // var duration = this.props.event.event.new_hours_needed || this.props.event.event.hours_needed
    // this.getComponentsWithAvailability(clone, start, moment(start).add(duration))
    this.setState({event: clone, edited: true, unavailable: !available, remove: null})
  }


  cancelChanges(){
    var e = this.props.event.event
    let v = this.props.event.venue || {}
    var state = {
      new_name: "",
      new_email: "",
      new_company_name: "",
      address: e.location,
      start_time:  moment.tz(e.start_time, e.timezone),
      time_open: false,
      type: v.type || "",
      budget: v.budget || "",
      location: v.location || "",
      instructions: e.day_notes,
      parking_sit: e.parking,
      payment_method: e.payment_method,
      elevator: e.elevator,
      coi: e.coi ? "yes" : "no",
      coi_req: "",
      coi_verified: e.coi_verified,
      new_phone: "",
      tables_chairs: v.tables_chairs || false,
      classroom_seating: v.classroom_seating || false,
      theater_seating: v.theater_seating || false,
      wifi_internet: v.wifi_internet || false,
      av_setup: v.av_setup || false,
      parking: v.parking || false,
      disabled: false,
      anything_else: v.notes || "",
      coupon: "",
      invalid_coupon: "",
      coupon_details: null,
      error: "",
      change_contact_info: false,
      showAlert: false,
      addItems: false,
      event: this.props.event,
      edited: false,
      reschedule: false,
      focused: false,
      new_date: moment.tz(e.start_time, e.timezone),
      unavailable: false,
      notify: false,
      pack_unavailable: [],
      booking: false,
      venue_address: e.location || "",
      venue_name: v.venue_name || "",
      venue_price: v.price || "",
      assign_service: false,
      service_id: "",
      ec_id: "",
      cancel: false,
      special_notes: e.special_notes,
    }
    this.setState(state)
    // if(this.props.location.pathname.includes("admin_panel")){
    //   this.getAdmin()
    // }
  }

  displayFormat(){
    return "ddd, MMM D, YYYY"
  }

  blockOut(date){
    if(this.props.login.admin) return false
    return date < moment().add(3, 'days').subtract(5, "hours")
  }

  isOutsideRange(date){
    if(this.props.login.admin) return false
    return date < moment()
  }

  changeDate(clone, start, end, duration){
    if(Number(duration) !== Number(this.state.event.event.new_hours_needed || this.state.event.event.hours_needed)) clone = this.changeDuration(clone, duration)
    this.getComponentsWithAvailability(clone, start, end, duration)
    this.setState({booking: false, edited: true})
  }

  componentWillUpdate(nextProps, nextState){
    //console.log("WillUpdate", nextState.event.components, JSON.stringify(nextState.event.components[0]), nextState.event.components[0].is_available )
  }

  async getComponentsWithAvailability(clone, start_time, end_time, duration){
    if(moment(start_time).isSame(moment(this.state.event.event.start_time), "minute") && Number(duration) === Number(this.state.event.event.hours_needed)){
      if(clone.package){
        clone.package.is_available = true
      }
      for(var cc of clone.components){
        cc.is_available = true
      }
      this.setState({event: clone, notify: false, unavailable: false})
      return
    }
    var unavailable = false
    var notify = false
    var ids = []
    var event_component_ids = []
    if(this.state.event.package){
        ids = ids.concat(this.state.event.package.components.map(c => Number(c.id)))
        event_component_ids = event_component_ids.concat(this.state.event.package.components.map(c => Number(c.event_component_id)))
    }
    for (var ec of this.state.event.components){
      if(!ec.canceled && ec.change !== "remove"){
        ids = ids.concat(ec.id)
        if(ec.event_component_id) event_component_ids.push(ec.event_component_id)
      }
    }
    console.log("event_component_ids",event_component_ids)
    let zipcode = this.state.event.event.zipcode
    let result = await request("/api/availability/components", {method: "POST", body: JSON.stringify({start_time, end_time, ids, event_component_ids, zipcode})})
    if(!result || result.err){
      return
    }
    var s = new Set(result.map(c => c.id ))
    let pack_unavailable = []
    if(clone.package){
      let pack_ids = clone.package.components.map(c => Number(c.id))
      let avail =  true
      for (let c of pack_ids){
        if(!s.has(c)){
          clone.package.is_available = false
          avail = false
          unavailable = true
          notify =  true
          pack_unavailable.push(clone.package.component_details.find( p => Number(p.id) === Number(c)))
        }
      }
      if(avail || clone.package.canceled){
        clone.package.is_available = true
        unavailable = false
        notify =  false
      }
    }
    for (var c of clone.components){
      // eslint-disable-next-line
      var updated = result.find(u => u.id === c.id)
      var offset = c.start_offset ? c.start_offset.value : 0
      c.start_time = moment(start_time).add(offset, "hours")
      c.end_time = moment(c.start_time).add(c.unit === "hour" ? c.qty : duration, "hours")
      c.start_offset = {name: moment(c.start_time).format("h:mma"), value: offset}
      c.remaining = updated ? updated.remaining : null
      console.log("updated", updated, s, c.id)
      if((!s.has(c.id) || (updated.remaining !== null && Number(c.num_staff) > Number(updated.remaining))) && !c.canceled && c.change !== "remove"){
        c.is_available = false
        unavailable = true
        notify =  true
      }else{
        c.is_available = true
      }
    }
    this.setState({event: clone, unavailable, disabled: unavailable, notify, new_date: start_time, pack_unavailable})
  }

  getAvailability(event){
    console.log("New date",this.state.new_date )
    console.log(moment(this.state.new_date).format(), moment(this.state.event.event.start_time).format())
    if(moment(this.state.new_date).isSame(this.state.event.event.start_time, "minute")){
      return true
    }
    for(var c of event.components){
      if(!c.is_available && !c.canceled && c.change !== "remove") return false
    }
    return true
  }

  getText(){
    var comp_count = this.state.event.components.length
    var unavailable = []
    for (var c of this.state.event.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length
    var un, untext;
    if(this.state.pack_unavailable.length > 0){
      if(!this.state.event.package.is_available  && !this.state.event.package.canceled){
        if(unavailable_count === 0){
          if(this.state.pack_unavailable.length > 1){
            un = this.state.pack_unavailable.map(c => c.name)
            untext = un.slice(0, un.length-1).join(", ").trim()
            untext += " and " + un[un.length]
            return "The " + untext + " parts of your package are unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + untext + " will not be at your event."
          }else{
            return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event."
          }
        }else{
          if(this.state.pack_unavailable.length > 1){
            un = this.state.pack_unavailable.map(c => c.name)
            untext = un.slice(0, un.length-1).join(", ").trim()
            untext += " and " + un[un.length-1]
            return "The " + untext + " parts of your package are unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + untext + " will not be at your event. You will need to remove any and all addons that are not available."
          }else{
            return "The " + this.state.pack_unavailable[0].name + " part of your package is unavailable on the new event date you chose. If you reschedule to this date, you will still be charged the full amount of your event package, but " + this.state.pack_unavailable[0].name + " will not be at your event. You will need to remove any and all addons that are not available."
          }
        }

      }
    }

    if(comp_count === unavailable_count){
      return "None of the items in your cart are available on the new event date you chose. Please check another event date, keep your current event date, or cancel your current event."
    }else{
      if(unavailable_count > 1){
        un = unavailable.map(c => c.name)
        untext = un.slice(0, un.length-1).join(", ").trim()
        untext += " and " + un[un.length-1]
        return untext + " are unavailable on the new event date you chose. Please either cancel the items, check another event date, or keep your current event date."
      }else if(unavailable_count === 1){
        return unavailable[0].name + " is unavailable on the new event date you chose. Please either cancel the item, check another event date, or keep your current event date."
      }else{
        return "All good"
      }
    }
  }

  getTitle(){
    var comp_count = this.state.event.components.length
    var unavailable = []
    for (var c of this.state.event.components){
      if(!c.is_available){
        unavailable.push(c)
      }
    }
    var unavailable_count = unavailable.length

    if(this.state.pack_unavailable.length > 0){
      if(unavailable_count > 0){
        return "Part of Package and Some Items Not Available"
      }
      return "Part of Package Not Available"
    }
    if(unavailable_count > 0){
      if(comp_count === unavailable_count){
        return "Items Not Available"
      }
      return "Some Items Not Available"
    }
    return "Item Not Available"
  }

  async assignVenue(){
    var res = await request('/api/admin/events/' + this.props.event.event.id + "/venue" , {method: "PUT", body: JSON.stringify({venue_name: this.state.venue_name, venue_address: this.state.venue_address, venue_price: this.state.venue_price})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't save venue")
    }else{
      await this.props.reloadEvent()
      this.cancelChanges()
    }
  }

  async waiveFee(event_component_id){
    var res = await request('/api/admin/events/' + this.props.event.event.id + "/waive" , {method: "PUT", body: JSON.stringify({event_component_id})})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't waive fee")
    }else{
      await this.props.reloadEvent()
      this.cancelChanges()
    }
  }

  async removeLateBookingFees(){
    var res = await request('/api/admin/events/' + this.props.event.event.id + "/removeLateBookingFees" , {method: "PUT"})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't remove fees")
    }else{
      await this.props.reloadEvent()
      this.cancelChanges()
    }
  }

  async removeLateBookingFee(event_component_id){
    var res = await request('/api/admin/events/' + this.props.event.event.id + "/" + event_component_id + "/removeLateBookingFee" , {method: "PUT"})
    if(res.err){
      this.setState({error: JSON.stringify(res) })
      console.log("Couldn't remove fee")
    }else{
      await this.props.reloadEvent()
      this.cancelChanges()
    }
  }

  async showAssignService(ec_id){
    console.log("showAssignService", ec_id)
    this.setState({assign_service: true, ec_id})
  }

  async assignService(ec_id){
    if(this.state.service_id){
      var res = await request('/api/admin/events/' + this.props.event.event.id + "/" + this.state.ec_id + "/assignService", {method: "PUT", body: JSON.stringify({service_id: this.state.service_id})})
      if(res.err){
        console.log(res)
      }else{
        await this.props.reloadEvent()
        this.cancelChanges()
      }
    }
  }

  render(){
    if(!this.state.event.event){
      return null
    }
    const cssClasses = {
      input: 'address text-field',
      autocompleteContainer: 'my-autocomplete-container'
    }
    let e = this.state.event.event
    let start_time = moment.tz(this.state.new_date, e.timezone)
    var booking = {duration: (e.new_hours_needed || e.hours_needed), people: (e.new_num_people || e.num_people), date: this.state.new_date, start_time , zipcode: e.zipcode, timezone: e.timezone, location_ids: e.location_ids}
    let end_time = moment(start_time).add(booking.duration, "hours").tz(e.timezone)

    // console.log(e.new_num_people, e.num_people, booking)
    let total = getBookedPrice(this.state.event, booking)
    var coupon = e.coupon
    var fee = (this.state.event.venue ? 500: 0)
    var findVenue = !!this.state.event.venue
    // let venueFound = this.state.event.venue && this.state.event.venue.name && e.location
    var noChange = moment(e.start_time).subtract(3, 'days') < moment() && !this.props.location.pathname.includes("admin_panel")
    var admin = this.props.location.pathname.includes("admin_panel")
    var coupon_value = getCouponValue(coupon, total, this.props.cartify(this.state.event.package, this.state.event.components, booking.people), booking)
    let available = this.getAvailability(this.state.event)
    let final_total = finalTotal(total, coupon_value, fee, this.state.event.event.credited )
    let tax = this.state.event.event.tax
    let tax_amount = getBookedTax(this.state.event, booking, tax, coupon, total)
    var late_booking_fee = false
    for (var c of this.props.event.components){
      if(this.props.login.admin && c.late_booking_fee && Number(c.late_booking_fee)){
        late_booking_fee = true
        break
      }
    }
    return (
      <Static className="events wide">
        <div className="nav-links"> <Link to="/account" className="link">Account</Link> <img src={Chevron} alt=""/> <Link className="link" to="/your-events">Your Events</Link> <img src={Chevron} alt=""/> </div>
        <div className="static-page-header">{start_time.format("ddd, MMM D, YYYY")} from {start_time.format("LT").toLowerCase().replace(" ", "")}-{end_time.format("LT").toLowerCase().replace(" ", "")} {getTimezoneShortName(start_time, e.timezone)}</div>
        <div className="static-page-subheader">Event details can only be edited if the event is at least 3 days away.</div>
        <div className="section-wrapper">
        <div className="checkout-container upcoming-event">
          <div className="checkout-details">
            { noChange && !admin?
            <div className="card warning">
              <div className="icon p-0 inline-flex items-center justify-center ">
                <img src={WarningIcon} alt="" />
              </div>
              <div className="text-container">
                <div className="text">This event can’t be edited online since it’s less than 3 days away.</div>
                <div className="subtext"><Link className="link" to="/contact-us">Contact us</Link> if you need to change details of this event.</div>
              </div>
            </div>
            : null}
            <Title title={"Event Basics"} subtitle={"Here are the high level details for your event."} />
            <div className="card section" >
              <div className="booking">
                <div className="subheader">Event information:</div>
                <div><img src={Date} alt=""/><span className="text">{start_time.format("ddd, MMM D, YYYY")}</span></div>
                <div><img src={People} alt=""/><span className="text">{booking.people || 0} people</span></div>
                <div><img src={Location} alt=""/><span className="text">{getLocationText(e, this.state.event.venue)}</span></div>
                <div><img src={Duration} alt=""/><span className="text">{moment(start_time).format("h:mma") || 0} for {booking.duration || 0} {Number(booking.duration) === 1 ? "hour" : "hours"}</span></div>
                <div onClick={()=> this.setState({booking: true})} className="change">Change Details</div>
              </div>
              <div className="contact">
                <div className="subheader">Point of contact for this event:</div>
                {this.state.change_contact_info ?
                  <div>
                    <input className="text-field" type="text" value={this.state.new_name} onChange={(e) => this.changeState({new_name: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Contact Name" />
                    {e.contact_company ? <input className="text-field" type="text" value={this.state.new_company_name} onChange={(e) => this.changeState({new_company_name: e.target.value})} placeholder="Company Name" style={{width: 240, marginTop: 10}} /> : null}
                    <input className="text-field" type="email" value={this.state.new_email} onChange={(e) => this.changeState({new_email: e.target.value})} style={{width: 240, marginTop: 10}} placeholder="Email Address"/>
                    {e.contact_phone ? <input className="text-field" type="tel" value={this.state.new_phone} onChange={(e) => this.changeState({new_phone: e.target.value})} placeholder="E.g. (650) 888-5252" style={{width: 240, marginTop: 10}} /> : null}
                  </div>
                  :
                  <div>
                    <div>{e.contact_name}</div>
                    {e.contact_company ? <div>{e.contact_company}</div> : null}
                    <div>{e.contact_email}</div>
                    {e.contact_phone ? <div>{e.contact_phone}</div> : null}
                  </div>
                }
                <div onClick={()=> this.changeState({change_contact_info: true, new_name: e.contact_name, new_email: e.contact_email, new_phone: e.contact_phone, new_company_name: e.contact_company})} className="change">Change Contact</div>

              </div>
              {noChange ?
                <div className="other">
                  {e.location ?
                    <div>
                    <div className="questions">{"Address"} </div>
                    <div className="address text-field disabled">{e.location}</div>
                    </div>
                    : null
                  }
                  <div>
                    <div className="questions" style={{marginTop: 20}}>{"Payment Method"} </div>
                    <div>{[{name: "Credit Card", value: "cc"}, {name: "ACH", value: "ach"}].find(o => o.value === this.state.payment_method).name} </div>
                  </div>
                </div> :
              <div className="other">
                {!findVenue ?
                  <div>
                    <div className="questions">{"What's the address of your event?"} </div>
                    <PlacesAutocomplete classNames={cssClasses} inputProps={{ value: this.state.address, onChange: (address) => this.changeState({address}), placeholder: "E.g. 123 Main St, San Francisco" }}   />

                  </div>
                  : null
                }
                {!e.contact_phone ?
                  <div>
                    <div className="questions" style={{marginTop: 20}}>{"What's your phone number?*"} </div>
                    <input className="text-field" id="new_phone" type="tel" value={this.state.new_phone} onChange={(e) => this.changeState({new_phone: e.target.value})} placeholder="E.g. (650) 888-5252" style={{width: 220}} />
                  </div>
                  : null
                }
                {!e.contact_company ?
                  <div>
                    <div className="questions" style={{marginTop: 20}}>{"What's your company's name? (If applicable)"} </div>
                    <input className="text-field" type="text" value={this.state.new_company_name} onChange={(e) => this.changeState({new_company_name: e.target.value})} placeholder="" style={{width: 220}} />
                  </div>
                  : null
                }
                <div>
                  <div className="questions" style={{marginTop: 20}}>{"Payment Method"} </div>
                  <Selector options={[{name: "Credit Card", value: "cc"}, {name: "ACH", value: "ach"}]} value={this.state.payment_method} update={(payment_method) => this.changeState({payment_method})} />
                  {this.state.payment_method !== e.payment_method ?  <div className={"disclaimer"} style={{textAlign: "left"}}>Changes to payment method will only apply to future invoices or charges, not to invoices or charges already issued.</div> : null}
                </div>
                {this.props.login.admin ?
                  <div>
                    <div>
                      <div className="questions" style={{marginTop: 20}}>{"COI Status"} </div>
                      <CheckboxControl checked={this.state.coi_verified} onChange={(value) => this.changeCOI(value)}/>
                      <label style={{marginLeft: 10}} onClick={() => this.changeCOI(!this.state.coi_verified)}>COI sent/verified</label>
                    </div>
                    <div>
                      <div className="questions" style={{marginTop: 20}}>{"Admin Event Notes"} </div>
                      <Textarea
                        value={this.state.special_notes}
                        onChange={(e) => this.changeState({special_notes: e.target.value})}
                        className="text-field notes" style={{ width: '100%' }}
                        placeholder="Notes only admin will see"/>
                    </div>
                  </div>
                  : null
                }

                {this.props.login.admin && late_booking_fee ?
                  <div>
                    <button onClick={() => this.removeLateBookingFees()} className="secondary-button" style={{float: "none"}}>Remove All Late Booking Fees</button>
                  </div>
                  : null
                }

              </div>
             }
            </div>
            <Title title={"Items & Instructions"} subtitle={"Let us know about any food allergies, special needs, etc here."} />
            <div id={"items-section"} className="card section items" style={{paddingTop: 4}}>

              {noChange ? null : <div className="custom-button" onClick={() => this.setState({addItems: true})}> Add New Items </div> }
              <ItemList booking={booking} added={this.state.event.components} dash={true} noChange={noChange} cart={this.props.cartify(this.state.event.package, this.state.event.components, booking.people)} edit={(c) => this.editComponent(c)} remove={(c) => this.remove(c)} onChange={(v,c,cat) => this.changeNotes(v,c,cat)} changeOffset={(v,c,cat) => this.changeOffset(v,c,cat)} admin={this.props.login.admin && !this.state.edited} waiveFee={(id) => this.waiveFee(id)} removeLateBookingFee={(id) => this.removeLateBookingFee(id)} assignService={(id) => this.showAssignService(id)} booked={true}/>
            </div>
            <Title title={ findVenue ? "Venue"  : "Your Venue"} subtitle={""} />
            <div className="card section">
              {findVenue ?
              <div className="venue-questions" style={{marginTop: -20}}>
              {this.props.login.admin ?
                <div style={{marginTop: 20}}>
                  <input type="text" placeholder="Venue Address" value={this.state.venue_address} onChange={(e) => this.setState({venue_address: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
                  <input type="text" placeholder="Venue Name" value={this.state.venue_name} onChange={(e) => this.setState({venue_name: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
                  <input type="number" placeholder="Price" value={this.state.venue_price} onChange={(e) => this.setState({venue_price: e.target.value}) } style={{marginRight: 12}} className="text-field"  />
                  <button onClick={() => this.assignVenue()} style={{float: "none"}} className="secondary-button">Assign</button>
                </div>
                : null}
                <div className="questions" >Type of event:</div>
                <input className="text-field" id="type" placeholder="party, offsite, meeting, etc" value={this.state.type} onChange={(e) => this.changeState({type: e.target.value})} style={{width: 220}} />
                <div className="questions">Amenities required:</div>
                <div className="checkbox-section">
                  <CheckboxControl checked={this.state.tables_chairs} onChange={ (tables_chairs) => this.changeState({tables_chairs})} />
                  <label onClick={() => this.checkMarkLabel("tables_chairs")}>Tables and Chairs</label>
                  <CheckboxControl checked={this.state.classroom_seating} onChange={ (classroom_seating) => this.changeState({classroom_seating})} />
                  <label onClick={() => this.checkMarkLabel("classroom_seating")}>Classroom Seating</label>
                  <CheckboxControl checked={this.state.theater_seating} onChange={ (theater_seating) => this.changeState({theater_seating})} />
                  <label onClick={() => this.checkMarkLabel("theater_seating")}>Theater Seating</label>
                  <div style={{marginTop: 10}}>
                    <CheckboxControl checked={this.state.wifi_internet} onChange={ (wifi_internet) => this.changeState({wifi_internet})} />
                    <label onClick={() => this.checkMarkLabel("wifi_internet")}>Wifi/Internet</label>
                    <CheckboxControl checked={this.state.av_setup} onChange={ (av_setup) => this.changeState({av_setup})} />
                    <label onClick={() => this.checkMarkLabel("av_setup")}>A/V Setup</label>
                    <CheckboxControl checked={this.state.parking} onChange={ (parking) => this.changeState({parking})} />
                    <label onClick={() => this.checkMarkLabel("parking")}>Parking</label>
                  </div>
                </div>
                <div className="questions">Location:</div>
                <input className="text-field" id="location" placeholder="E.g. Within 5 miles of San Francisco" value={this.state.location} onChange={(e) => this.changeState({location: e.target.value})} style={{width: 220}} />
                <div className="questions">Venue budget:</div>
                <input className="text-field" id="budget" placeholder="E.g. Less than $8,000" value={this.state.budget} onChange={(e) => this.changeState({budget: e.target.value})} style={{width: 220}} />
                <div className="questions">Anything else?</div>
                <textarea className="text-field" value={this.state.anything_else} onChange={(e) => this.changeState({anything_else: e.target.value})} style={{width: 420}} />
              </div>

              :
              <div className="venue-questions" style={{marginTop: -20}}>
               <div className="questions">Load in/load out instructions:</div>
               <textarea className="text-field" placeholder="How do we get into the building? Anything else we should know?" value={this.state.instructions} onChange={(e) => this.changeState({instructions: e.target.value})} style={{width: 420}} />
               <div className="questions">What is the parking situation?</div>
               <input className="text-field" placeholder="Limited, plentiful, non-existant, etc" value={this.state.parking_sit} onChange={(e) => this.changeState({parking_sit: e.target.value})} style={{width: 240}} />
               <div className="questions">What floor will your event be on?</div>
               <div>
                 <input type="radio" id="first" value={"first"} name="first" onChange={(e) => this.changeState({elevator: e.currentTarget.value})} checked={this.state.elevator === "first"} />
                 <label htmlFor="first">First floor</label>
                 <input type="radio" id="yes" value={"yes"} name="yes" onChange={(e) => this.changeState({elevator: e.currentTarget.value})} checked={this.state.elevator === "yes"} />
                 <label htmlFor="yes">{"Other (elevator available)"}</label>
                 <input type="radio" id="no" value={"no"} name="no" onChange={(e) => this.changeState({elevator: e.currentTarget.value})} checked={this.state.elevator === "no"} />
                 <label htmlFor="no">Other (no elevator)</label>
               </div>
               <div className="questions">Does your building require a Certificate of Insurance?</div>
               <div>
                 <input type="radio" id="coi_yes" value={"yes"} name="coi_yes" onChange={(e) => this.changeState({coi: e.currentTarget.value})} checked={this.state.coi === "yes"} />
                 <label htmlFor="coi_yes">{"Yes"}</label>
                 <input type="radio" id="coi_no" value={"no"} name="coi_no" onChange={(e) => this.changeState({coi: e.currentTarget.value})} checked={this.state.coi === "no"} />
                 <label htmlFor="coi_no">No</label>
               </div>
              </div>
              }
            </div>
            {this.props.login.admin && this.props.location.pathname.includes("admin_panel") ?
              <div>
              <Title title={"Vendors"} />
              <div className="card section items" style={{paddingTop: 4}}>
                <VendorList event={this.state.event} booking={booking} start_time={e.start_time} timezone={e.timezone}/>
              </div>
              </div>
            : null}

            {noChange || !this.state.edited ? null :
            <div className="bottom-order">
              <div style={{ width: 88, marginBottom: 6, marginRight: 12, display: "inline-block", height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} onClick={() => this.cancelChanges()} className="secondary-button" >Cancel</div>
              <button style={{ width: 180}} className={"custom-button" + (this.state.disabled || !available ? " disabled" : "") } onClick={()=> this.state.disabled  || !available ? null : this.saveChanges()}>
                Save Changes
                {this.state.disabled && !this.state.unavailable ?
                  <div className="loader-wrapper">
                    <div className="loader">
                      <BeatLoader
                        color={'#FBB200'}
                        size={12}
                        loading={this.state.loading}
                      />
                    </div>
                  </div> : null
                }
                </button>
                <span className="cost">Total Cost: </span> <span className="price">{currencyFormatter.format(final_total + tax_amount, { code: 'USD', precision: 0})}</span>
              <div className="disclaimer" style={{textAlign: 'left'}}>We’ll review your event changes and contact you if any additional information is needed.</div>
            </div>
          }
          <div className="cancel-event" onClick={() => this.cancelEvent()}>Cancel This Event</div>
          <div className="cancellation-charge">{this.getCancellationCharge(e, finalTotal(total, coupon_value, fee))}</div>
          </div>
          <div className="order">
            <div className={"wrap" + (!this.state.edited || noChange ? " no-button" : "")}>
            {noChange || !this.state.edited ? null :
              <div>
                <div style={{ width: 88, marginBottom: 6, marginRight: 12, display: "inline-block", height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} onClick={() => this.cancelChanges()} className="secondary-button" >Cancel</div>
                <button style={{ width: 180, marginBottom: 6, height: 44, float: "none", lineHeight: 44+"px", fontSize: 17}} className={"custom-button" + (this.state.disabled  || !available ? " disabled" : "") } onClick={()=> this.state.disabled  || !available ? null : this.saveChanges()}>
                  Save Changes
                  {this.state.disabled && !this.state.unavailable ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <BeatLoader
                          color={'#FBB200'}
                          size={12}
                          loading={this.state.loading}
                        />
                      </div>
                    </div> : null
                  }
                </button>
                <div className="disclaimer">We’ll review your event changes and contact you if any additional information is needed.</div>
              </div>
            }
            <div className={"summary" + (!this.state.edited || noChange ? " no-button" : "")}>
              <div className="summary-header">Event Summary</div>
              <div className="lineItem" ><span>Items:</span><span className="price">{currencyFormatter.format(total, { code: 'USD', precision: 0})}</span></div>
              {this.state.coupon_details? <div className="lineItem" ><span>Coupon:</span><span className="price">{currencyFormatter.format(-coupon_value , { code: 'USD', precision: 0})}</span></div> : null}
              {findVenue ? <div className="lineItem" ><span>Venue Finding Fee:</span><span className="price">{currencyFormatter.format(500, { code: 'USD', precision: 0})}</span></div> : null}
              {this.state.event.event.credited > 0 ? <div className="lineItem" ><span>Credit:</span><span className="price">{currencyFormatter.format(-this.state.event.event.credited, { code: 'USD', precision: 0})}</span></div> : null}
              <div className="lineItem"><span>Tax:</span><span className="price">{currencyFormatter.format(tax_amount, { code: 'USD', precision: 0})}</span> </div>
              </div>
              <div className="total">
                <div><span className="cost">Total Cost:</span> <span className="price">{currencyFormatter.format(final_total + tax_amount, { code: 'USD', precision: 0})}</span>
                </div>
              </div>
              {admin ? <div>Paid: ${(Number(e.first_payment_amount || 0) + Number(e.second_payment_amount|| 0))/100} </div> : null}
            </div>
          </div>
        </div>
        </div>
        <ConfirmationModal show={this.state.cancel} title="Are you sure?" text={this.getCancellationCharge(e, finalTotal(total, coupon_value, fee))} onHide={() => this.setState({cancel: false})} onConfirm={() => this.setState({cancel: false})} secondaryAction={() => this.cancel()} confirmationText="Keep Event" secondaryText="Cancel Event" />
        <ConfirmationModal show={!!this.state.remove} title="Are you sure?" text={() => this.getItemCancellationFee(this.state.remove) } onHide={() => this.setState({remove: null})} onConfirm={() => this.setState({remove: null})} secondaryAction={() => this.removeComponent(this.state.remove)} confirmationText="Keep Item" secondaryText="Remove Item" />
        <Alert title={() => this.state.error} text="" show={this.state.showAlert} onHide={()=> this.changeState({showAlert: false})} />
        <ChangeBooking show={this.state.booking} onHide={() => this.setState({booking: false})} save={(start, end, duration, headcount) => this.changeBooking(start, end, duration, headcount)} login={this.props.login} values={{duration: booking.duration, date: start_time, start_time, timezone: e.timezone, people: booking.people}}/>
        <Alert title={"Decreasing quantity by 25% or more may incure fees."} text="We'll contact if there's an issue." show={this.state.decrease} onHide={()=> this.changeState({decrease: false})} />
        <Alert title={() => this.getTitle()} text={() => this.getText()} show={this.state.notify} onHide={()=> this.setState({notify: false})} buttonText="Got It"/>
        <Modal show={this.state.assign_service} zIndex={7000} onHide={() => this.setState({assign_service: false})} customClassName="reschedule-modal booking-modal" backdropClassName="customize-backdrop ">
          <input value={this.state.service_id} className="text-field" placeholder="Service ID" onChange={(e) => this.setState({service_id: e.target.value})}/>
          <div className="secondary-button" style={{width: 100}} onClick={() => this.setState({assign_service: false})}>Cancel</div>
          <div className="custom-button" onClick={() => this.assignService()} >Assign</div>
        </Modal>
        {this.state.addItems ? <ItemAdder booking={booking} edit={(c) => this.editComponent(c)} add={(c) => this.addComponent(c)} remove={(c) => this.remove(c)} components={this.state.event.components} onHide={()=> this.setState({addItems: false})} /> : null}
      </Static>
    )
  }

}
