import React from 'react'
import Static from './StaticWrapper'
import request from '../../request'
import Image from '../Utils/SpecialImage'
import NewsletterImage from '../../img/Newsletter.jpg'
import './Newsletter.css'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'

async function getLocation(){
  var res = await request("https://ipinfo.io/json")
  return res
}

class Newsletter extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      email: "",
      zipcode: this.props.values.zipcode,
      subscribed: false
    }
  }

  async componentDidMount(){
    if(!this.state.zipcode){
      var zipcode = await getLocation()
      zipcode = zipcode.postal
      this.setState({zipcode})
    }
  }

  async handleSubmit(e){
    e.preventDefault()
    e.stopPropagation();

    if(!this.state.email){
      document.getElementById("email").focus()
      return
    }
    var params = {method: "POST", body: JSON.stringify({email: this.state.email, name: this.state.name, zipcode: this.state.zipcode})}
    var subscribed = await request('/api/newsletter', params )
    if(!subscribed.err){
      this.setState({subscribed: true, name: "", email: ""})
      console.log("set the state")
    }else{
      console.log("eerrr the state", subscribed)
      if(subscribed.err.code === "23505"){
        console.log("set the state")
        this.setState({subscribed: true, name: "", email: ""})
      }
      console.log(subscribed.err)
    }
  }

  render(){
    return (
      <Static className="newsletter">
        <div className="static-page-header">Sign Up for Our Newsletter</div>
        <div className="card content" >
          <form className="section">
            <div className="text">
            Thanks for your interest in 1up! We'd love to share more fun event ideas with you.

            Please leave your name and email address and we'll get back to you shortly.</div>
            <label>Your Name:</label>
            <input className="text-field" type="text" id="name" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
            <label>Email:</label>
            <input className="text-field" type="email" id="email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
            <button className="custom-button large" onClick={(e)=> this.handleSubmit(e)}>Sign Up</button>
            <span className="disclaimer">We won’t sell or give away your email address. Unsubscribe anytime.</span>
          </form>
          <Image src={NewsletterImage} caption="Karaoke Night at Shippo"/>
        </div>
      </Static>
    )
  }

}

const mapStateToProps = state => ({
  values: state.booking
})

export default withRouter(connect(
  mapStateToProps,
)(Newsletter))
