import React from 'react'
import {Calendar as BigCalendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css"

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
 // or globalizeLocalizer
const localizer = momentLocalizer(moment)
// let allViews = Object.keys(Views).map(k => Views[k])



function eventStyleGetter(event, start, end, isSelected){
    var backgroundColor = event.hexColor;
    var style = {
        backgroundColor: backgroundColor
    }
    if(event.hexColor === "#848484") style.cursor = "auto"
    return {style}
}

const MyCalendar = ({events, vacations, viewDetails, newEvent, month}) => (
  <div>
    <BigCalendar
      localizer={localizer}
      events={events || []}
      startAccessor='start_time'
      endAccessor='end_time'
      views={month ? ["week", "day", "month", "agenda"] : ["week", "day"]}
      showMultiDayTimes
      defaultView={Views.WEEK}
      scrollToTime={new Date(1970, 1, 1, 6, 0, 0)}
      onSelectEvent={event => viewDetails(event)}
      eventPropGetter={ (event, start, end, isSelected) => eventStyleGetter(event, start, end, isSelected)}
      />
  </div>
);

// selectable
// onSelectSlot={slotInfo => newEvent(slotInfo)}

// alert(
//   `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
//     `\nend: ${slotInfo.end.toLocaleString()}` +
//     `\naction: ${slotInfo.action}`
// )}

//      selectable
// onSelectSlot={slotInfo =>
//   alert(
//     `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
//       `\nend: ${slotInfo.end.toLocaleString()}` +
//       `\naction: ${slotInfo.action}`
//   )}

export default MyCalendar
