import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import BookBar from '../Header/IndependentBookBar'
import moment from 'moment-timezone'
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { getStartTime } from './math'
import { Dropdown, MenuItem } from 'react-bootstrap'
import './Reschedule.css'

var time_range = [];
for (var i = .5; i <= 9.5; i+= 0.5) {
    time_range.push(i);
}


class Reschedule extends Component {

  constructor(props){
    super(props)
    console.log("reschedule ", props.values)
    this.state = {
      new_date: props.values.date ? moment(props.values.date) : "",
      duration: props.values.duration || "",
      start_time: props.values.start_time ? moment(props.values.start_time) : "",
      forcused: false,
      time_open: false,
      duration_open: false,
      headcount: props.values.people
    }
  }

  blockOut(date){
    if(this.props.login.admin) return false
    return date < moment().add(2, 'days').subtract(5, "hours")
  }

  isOutsideRange(date){
    if(this.props.login.admin) return false
    return date < moment()
  }

  onToggle(open, a, source){
    if(source === 'keydown'){
      this.setState({duration_open: open})
    }else if(source === "click" &&  this.state.duration_open === "focused"){

      this.setState({duration_open: "clicked"})
    }else if(source === "select"  &&  this.state.duration_open === "focused"){
      this.setState({duration_open: false})
    }else{

      this.setState({duration_open: this.state.duration_open === "clicked" && !open ? true: open})
    }
  }

  onSelect(key, e){
    this.setState({duration: Number(key)})
  }

  changePeople(val){
    if (!val){
      this.setState({headcount: val})
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.setState({headcount: val})
      }
    }
  }

  getHeadcountDisclaimer(date){
    var diff = moment(date).diff(moment(), "days")
    if(diff > 21){
      return "Headcount cannot be changed by more than 20%"
    }
    if( diff > 7 ){
      return "Headcount cannot be changed by more than 10%"
    }
    return "Cannot edit headcount this close to the event date."
  }

  getDisable(date){
    if(this.props.login.admin) return false
    var diff = moment(date).diff(moment(), "days")
    var percentage = Math.abs(Number(this.state.headcount) - Number(this.props.values.people))/Number(this.props.values.people)
    if(diff > 21){
      return percentage > .2
    }
    if( diff > 7 ){
      return percentage > .1
    }
    return percentage !== 0
  }


  render(){
    var start_time = getStartTime(this.state.new_date, this.state.start_time, this.props.values.timezone)
    var end_time = moment(start_time).add(this.state.duration, "hours")
    var disable = this.getDisable(start_time)
    var too_late = moment(start_time).diff(moment(), "days") < 7
    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="reschedule-modal booking-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")} onHide={() => this.props.onHide()}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Change Details</div>
          <div className="questions">Select a new date and time for your event:</div>
          <div>
            <div className="text-field date">
              <SingleDatePicker
                date={moment(this.state.new_date)}
                focused={this.state.focused}
                onDateChange={(new_date) => this.setState({new_date})}
                onFocusChange={({ focused }) => this.setState({focused})}
                displayFormat={"ddd, MMM D, YYYY"}
                isOutsideRange={(date) => this.isOutsideRange(date)}
                isDayBlocked={(date) => this.blockOut(date)}
                hideKeyboardShortcutsPanel={true}
                placeholder="Date of event"
                readOnly={true}
                numberOfMonths={1}/>
            </div>
            <span className="to"> at </span>
            <TimePicker
               ref={(ref) => this.time_picker = ref }
               id={"start_time"}
               showSecond={false}
               value={this.state.start_time ? moment(start_time).tz(this.props.values.timezone) : null}
               className={this.state.time_open ? "time open": "time"}
               placeholder="Start time"
               onChange={(value) => this.setState({start_time: moment(value).tz(this.props.values.timezone)}) }
               format={'h:mma'}
               minuteStep={15}
               getPopupContainer = {node => node}
               onOpen={() => this.setState({time_open: true})}
               onClose={() => this.setState({time_open: false})}
               use12Hours
             />
             <span className="to"> to </span>
            <Dropdown onClick={(e) => e.stopPropagation()} id="duration" className="duration" open={!!this.state.duration_open} onToggle={(open, a, b) => this.onToggle(open, a, b.source)}  onFocus={() => {console.log("should open"); this.setState({duration_open: !this.state.duration_open ? "focused": false})}} onSelect={(key, e) => this.onSelect(key, e)} >
              <Dropdown.Toggle noCaret >
                <div className="durtext text-field" >
                  { !this.state.duration ?
                    <span>End time</span>
                    :
                    <div>
                    <span>{start_time ? moment(start_time).add(this.state.duration, "hours").format('h:mma') : moment().add(this.state.duration, "hours").format('h:mma')}</span>
                    <div className="duration-text">{this.state.duration} hr</div>
                    <div className={"my-small-caret"}/>
                    </div>
                  }
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="duration-options">
                {time_range.map(t =>
                  <MenuItem key={t} className="list-member" eventKey={String(t)} active={this.props.values.duration === t} >{moment(start_time).add(t, "hours").format('h:mma')} <div className="number" >{t} hr</div>
                  </MenuItem>
                )}
                  <MenuItem key={10} className="list-member" eventKey="10" active={this.props.values.duration === 10} >{moment(start_time).add(10, "hours").format('h:mma')} <div className="number" >+10 hr</div></MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="questions">{too_late ? "Your headcount:" : "Update your headcount:"}</div>
          {too_late ?
            <div>
            <div className="people">
              <div style={{paddingRight: 12}} className="text-field">{this.state.headcount} people </div>
            </div>
            <div className="disclaimer"><i>{this.getHeadcountDisclaimer(start_time)}</i></div>
            </div>
          : <div><div className="people">
              {this.state.headcount ? <span className="people-text"><span style={{color: "transparent"}}>{this.state.headcount}</span>{Number(this.state.headcount) === 1 ? " person": " people"}</span>: null }
              <input id="people" className="text-field" value={this.state.headcount} onChange={(e) => this.changePeople(e.target.value)} placeholder="# of people"/>
            </div>
            <div className="disclaimer"><i>{this.getHeadcountDisclaimer(start_time)}</i></div>
          </div>}
          <div className={"custom-button" + (disable ? " disabled" : "")} style={{float: "right", marginLeft: 9}} onClick={() => {disable ? null: this.props.save(start_time, end_time, this.state.duration, this.state.headcount)}}>Check Availability</div>
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Reschedule))
// export default CreateWishlist
