import React, { Component } from 'react';
import '../../css/Header.css'
// import logo from '../img/1up-color-small-alt.png'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as cartActions from '../../actions'
import { BeatLoader } from 'react-spinners';
import { Link, withRouter } from 'react-router-dom'
import BookBar from './BookBar'
import {DropdownButton, Dropdown, MenuItem} from 'react-bootstrap'
import Cart from '../CartPage'
import logo from '../../img/1upLarge.svg'
import CartIcon from '../../img/NavBarCart.svg'
import request from '../../request'
import { LinkContainer } from 'react-router-bootstrap'
import EventDetailsBar from './EventDetailsBar';
import _ from 'underscore'

class Header extends Component{

  constructor(props){
    super(props)
    this.state = {
      account: false,
      more: false,
      account_drop: false,
      loading: false,
    }
  }

  componentDidMount(){
    if(this.props.closeCart){
      this.props.actions.closeCart()
    }
  }

  toggleAccount(){
    this.setState({account: !this.state.account})
  }

  signOut(){
    this.props.actions.logOut()
    request('/api/signout/', {method: "POST"})
  }

  componentDidUpdate(nextProps){
    if(!_.isEqual(nextProps.booking, this.props.booking)){
      if(nextProps.booking.people !== this.props.booking.people){
        if(this.peopleTimer) clearTimeout(this.peopleTimer)
        this.peopleTimer = setTimeout(() => {
          this.setState({loading: true})
          this.timer = setTimeout(() => this.setState({loading: false}), 1000)
        }, 200)
      }else{
        if(this.timer) clearTimeout(this.timer)
        this.setState({loading: true})
        this.timer = setTimeout(() => this.setState({loading: false}), 1000)
      }

    }

  }

  account(){
    if(this.props.login.loggedIn){
      return (
        <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-1" className="account" open={!!this.state.account_drop} onToggle={(open, a, b) => this.onToggle("account_drop", open, a, b.source)}  onFocus={() => {console.log("should open"); this.setState({account_drop: "focused"})}}>
          <Dropdown.Toggle noCaret >
            <div className="text" >
              <span>Account</span>
              <span className="my-caret"/>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            {this.props.login.admin ? <LinkContainer to="/admin_panel"><MenuItem eventKey="3">Admin Dashboard</MenuItem></LinkContainer>: null}
            {this.props.login.vendor_id ? <LinkContainer to="/vendors/dashboard"><MenuItem eventKey="3">Vendor Dashboard</MenuItem></LinkContainer>: null}
            <LinkContainer to="/account"><MenuItem eventKey="1" active={ window.location.pathname.includes("account")}>Account</MenuItem></LinkContainer>
            <LinkContainer to="/your-events"><MenuItem eventKey="2" active={ window.location.pathname.includes("your-events")}>Your Events</MenuItem></LinkContainer>
            <LinkContainer to="/wishlists"><MenuItem eventKey="3" active={ window.location.pathname.includes("wishlists")}>Wish Lists</MenuItem></LinkContainer>

            <MenuItem divider />
            <MenuItem eventKey="4" onClick={() => this.signOut()}>Sign Out</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      )
    }
    return <Link className="sign-in" to={{pathname: '/signin', state: {from: this.props.location.pathname}}}  >Sign In</Link>
  }

  onToggle(menu, openMenu, a, source){
    let menuToClose = menu === "more" ? "account_drop": "more"
    if(source === 'keydown'){
      this.setState({[menu]: openMenu, [menuToClose]: false})
    }else if(source === "click" &&  this.state[menu] === "focused"){
      this.setState({[menu]: "clicked", [menuToClose]: false})
    }else{
      this.setState({[menu]: this.state[menu] === "clicked" && !openMenu ? true: openMenu, [menuToClose]: false})
    }
  }

  render(){
    var powerups_sel = window.location.pathname.includes("events") ? " selected" : ""
    var cart_count = 0;
    for (var key in this.props.cart){
      cart_count += this.props.cart[key].length
    }

    return (
      <div className='new'>
      <div className={"header " + (this.props.app.missing === "missingAlert" ? " missing": "")}>
        <Link className="hidden sm:inline-flex" to="/"><img src={logo} className="logo" alt="logo" /></Link>
        <div className='hidden lg:inline-flex'><BookBar /></div>
        <EventDetailsBar eventDetails={this.props.booking} />
        <div className="loader-wrapper hidden sm:inline-flex">
          <div className="loader">
          <BeatLoader
            color={'#FBB200'}
            size={12}
            loading={this.state.loading}
          />
          </div>
        </div>
        <div className='hidden sm:inline-flex ml-auto' style={{alignItems: 'center', verticalAlign: "top"}}>
          <Link className={"nav-text hidden" + powerups_sel} to="/events">Book an Event</Link>
          <DropdownButton className="hidden xl:inline-block" title={"More"} id={`more`} open={!!this.state.more} onToggle={(open, a, b) => this.onToggle("more", open, a, b.source)}  onFocus={() => {console.log("should open"); this.setState({"more": "focused"})}}>
            <LinkContainer to="/contact-us"><MenuItem eventKey="1" >Contact Us</MenuItem></LinkContainer>
            <LinkContainer to="/how-it-works"><MenuItem eventKey="5" >How It Works</MenuItem></LinkContainer>
            <LinkContainer to="/faq"><MenuItem eventKey="6" >FAQs</MenuItem></LinkContainer>
            <LinkContainer to="/about-us"><MenuItem eventKey="4" >About Us</MenuItem></LinkContainer>
            <MenuItem divider />
            <LinkContainer to="/event-host"><MenuItem eventKey="2" >Be an Event Host</MenuItem></LinkContainer>
            <LinkContainer to="/vendor"><MenuItem eventKey="3" >Be a Vendor</MenuItem></LinkContainer>
          </DropdownButton>
          <div className="header-divider hidden xl:inline-block" style={{height: 64}}/>
          {this.account()}
          <div className="header-divider" style={{height: 40, marginTop: 12}}/>
          <div className="cart-button mr-0" tab="0" onClick={this.props.actions.toggleCart} onFocus={() => console.log("focus")}  >
            <div className="cart-text">{cart_count ? cart_count : ""}</div>
            <img src={CartIcon}  alt="" />
            <div className={"my-caret" + (!this.props.app.cart ? " left": "")}/>
          </div>
        </div>

        <div className='hidden sm:block'><Cart width={this.props.app.cart ? 320 : 0} /></div>
        {this.props.app.wishlist === "first" ?
          <div className="created-wishlist-container top-[60px] sm:block hidden" style={{position: "relative"}}>
            <div className="created-wishlist">
              <div className="title">Item added to wish list</div>
              <div className="text">Click Account to view and share your wish lists.</div>
              <button className="custom-button" onClick={() => this.props.actions.assignWishlist("seen")}>Got It!</button>
            </div>
            <div className="arrow  top-[60px]"/>
          </div>
          : null
        }
      </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header))
