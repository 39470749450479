import React, {Component} from 'react'
import queryString from 'query-string'
import request from '../../request'
import CalendarSelector from './CalendarSelector'
import ValueSelector from '../Utils/ValueSelector'
import {Table} from 'react-bootstrap'
import SurveyFlow from '../Utils/SurveyFlow'

const ServiceCalendarMapper = ({mapping, setMapping, services, calendars, primary}) => {
    return (
      <Table>
        <thead >
          <tr style={{marginBottom: 10}}>
            <th>Service</th>
            <th>Calendar</th>
          </tr>
        </thead>
        <tbody>
          {services.map( (s, i) =>
              <tr key={i}>
                <td style={{minWidth: 200, paddingRight: 20}}>{s.name}</td>
                <td><ValueSelector placeholder={primary ? "Primary" : (calendars.length === 1 ? "Default" : "Choose One")} value={mapping[s.id]} options={calendars} update={(value) => setMapping(s.id, value)} /></td>
              </tr>
            )}
        </tbody>
      </Table>
    )
}

export default class GoogleCalendar extends Component{

  constructor(props){
    super(props)
    const parsed = queryString.parse(props.location.search);
    if(parsed.code){
      this.callApi(parsed.code)
    }else{
      this.getCalendars()
      this.getServices()
    }
    this.state = {
      calendars: [],
      cals_to_sync: [],
      mapping: {},
      services: []
    }
  }

  async callApi(code){
    await request('/api/gc/auth', {method: "PUT", body: JSON.stringify({code})})
    // this.props.history.push('/vendors/dashboard')
    this.getCalendars()
    this.getServices()
  }

  setMapping(service_id, calendar_id){
    var mapping = JSON.parse(JSON.stringify(this.state.mapping))
    mapping[service_id] = calendar_id
    console.log(mapping)
    this.setState({mapping})
  }

  compareNames(a, b){
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  sortCalendars(calendars){
    var cals = []
    cals.push(calendars.find(c => c.primary))
    cals = cals.concat(calendars.filter(c => !c.primary).sort( (a, b) => this.compareNames(a, b)))
    return cals
  }

  async getCalendars(){
    var calendars = await request('/api/vendor/gc/calendars')
    console.log("calendars", calendars)
    if(!calendars.err && calendars.length > 0){
      for(var c of calendars){
        if(c.primary){
          c.title = `Primary Calendar (${c.summary})`
          c.name = c.title
        }else{
          c.title = c.summary
          c.name = c.summary
        }
        c.value = c.id
      }
      var calendars = this.sortCalendars(calendars)
      this.setState({calendars, cals_to_sync: calendars})
    }else{
      console.log("getCalendars", calendars.err, calendars)
    }
  }

  async getServices(){
    var services = await request('/api/vendor/services')
    if(!services.err){
      for(var s of services){
        s.name = s.component_name
      }
      this.setState({services})
    }
  }

  onSelect(c){
    if(this.state.cals_to_sync.map(s=> s.id).includes(c.id)){
      this.setState({cals_to_sync: this.state.cals_to_sync.filter(s => s.id !== c.id ) })
    }else{
      this.setState({cals_to_sync: this.state.cals_to_sync.concat([c])})
    }
  }

  async syncCalendars(){
    //prevent if services > 0 and mulitple calendars and none are primary and mapping hasn't been chosen
    //this.state.cals_to_sync.length === 1 || this.state.cals_to_sync.find(c => c.primary)
    var mapping = JSON.parse(JSON.stringify(this.state.mapping))
    if(!this.state.cals_to_sync.find(c => c.primary)){
      if(!Object.keys(mapping).length === this.state.services.length){
        if(!this.state.cals_to_sync.length === 1) console.log("Should not be the case")
        for(var s of this.state.services){
          if(!mapping[s.id]) mapping[s.id] = this.state.cals_to_sync[0].value
        }
      }
    }

    var calendars = await request('/api/vendor/gc/sync', {method: "PUT", body: JSON.stringify({calendars: this.state.cals_to_sync, mapping: this.state.mapping}) })
    this.props.history.push('/vendors/dashboard')
  }

  assigningSyncedCalendars(){

  }

  canSync(){
    if(this.state.services.length  === 0) return true
    if(this.state.cals_to_sync.length === 1) return true
    if(this.state.cals_to_sync.find(c => c.primary)) return true
    if(Object.keys(this.state.mapping).length === this.state.services.length) return true
    return false
  }

  // render(){
  //   let canSync = this.canSync()
  //   return (
  //     <div className="card" style={{padding: 20}}>
  //       <div className="instructions">Select the calendars you'd like to sync</div>
  //       <div className="subtext">Make sure to only select calendars where you want the events to block your availability.</div>
  //       <CalendarSelector calendars={this.state.calendars} selected={this.state.cals_to_sync} onSelect={(c) => this.onSelect(c)} services={this.state.services}/>
  //       {this.state.services.length > 0 ?
  //         <div>
  //           <div className="instructions" style={{marginTop: 30}}>Specify a calendar for your services</div>
  //           <div className="subtext">If you would like specific calendars keep track of specific services, please select the calendar for each service.</div>
  //           <ServiceCalendarMapper services={this.state.services} calendars={this.state.cals_to_sync} setMapping={(s_id, c_id) => this.setMapping(s_id, c_id)} mapping={this.state.mapping} primary={this.state.cals_to_sync.length === 1 || this.state.cals_to_sync.find(c => c.primary)}  />
  //         </div>
  //       : null}
  //       <button disabled={!canSync} className={"custom-button" + (canSync ? "" : " disabled" )} style={{marginLeft: 0, paddingLeft: 30, paddingRight: 30}} onClick={() => this.syncCalendars()}>Sync</button>
  //     </div>
  //   )
  // }

  sections(i){
    switch(i){
      case 0:
        return (
          <div>
            <div className="instructions-header">Select the calendars you'd like to sync</div>
            <div className="instructions-subtext">Make sure to only select calendars where you want the events to block your availability.</div>
            <CalendarSelector calendars={this.state.calendars} selected={this.state.cals_to_sync} onSelect={(c) => this.onSelect(c)} services={this.state.services}/>
          </div>
        )
      case 1:
        return (
          <div>
            <div className="instructions" >Specify a calendar for your services</div>
            <div className="subtext">If you would like specific calendars keep track of specific services, please select the calendar for each service.</div>
            <ServiceCalendarMapper services={this.state.services} calendars={this.state.cals_to_sync} setMapping={(s_id, c_id) => this.setMapping(s_id, c_id)} mapping={this.state.mapping} primary={this.state.cals_to_sync.find(c => c.primary)}  />
          </div>
        )
    }
  }

  setIndex(index){
    this.setState({index})
  }

  canProgress(i){
    switch(i){
      case 0:
        return this.state.cals_to_sync.length > 0
      case 1:
        return this.canSync()
    }
  }

  render(){
    var i =  Number(this.state.index || 0)
    let length = this.state.services.length > 0 ? 2 : 1
    return (
        <SurveyFlow setIndex={(index) => this.setIndex(index)} canProgress={this.canProgress(i)} length={length} index={i} submit={() => this.syncCalendars()} next={() => this.setIndex(i + 1)} back={() => this.setIndex(i - 1)} >
          {this.sections(i)}
        </SurveyFlow>
    )
  }

}
