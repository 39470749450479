import React, { Component } from 'react';
import {Form, FormGroup, FormControl} from 'react-bootstrap'
import * as cartActions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import request from '../../request'
import PlainHeader from '../Header/PlainHeader'
import Signup from './Signup'
import Footer from '../Utils/Footer'
import '../../css/Login.css'
import Warning from '../../img/WarningIcon.svg'

class Login extends Component{

  constructor(props){
    super(props)
    console.log(props)

    this.state = {
      email: "",
      password: "",
      error: "",
      signup: false,
    }
  }

  componentDidMount(){
    document.title = "Sign In/Register - 1up Events"

  }

  handleChange(key, value){
    this.setState({[key]: value, error: false})
  }

  async handleSubmit(event){
    event.preventDefault();
    //to send to server
    var params = { method: 'POST',
                  body: JSON.stringify({email: this.state.email, password: this.state.password})
              };
    var res =  await request('/api/login', params)
    console.log(res)
    if(!res){
       this.setState({error: "Server could not be reached"})
       return
    }
    if (res.err){
      console.log(res.err)
      this.setState({error: res.err})
      return
    }
    this.props.actions.setLogin({loggedIn: true, admin: res.is_admin, wishlists: res.wishlists, vendor_id: res.vendor_id, name: res.name})
    if(res.is_admin && this.props.location.state && this.props.location.state.from ==="/admin_panel"){
      this.props.history.push('/admin_panel')
    }else if(this.props.location.state && this.props.location.state.from){
      this.props.history.push(this.props.location.state.from)
    }else{
      this.props.history.push("/")
    }
  }

  render(){
    return (
    <div>
      <div className='w-full pl-4 h-[80px] overflow-hidden'><div className='h-full w-full relative'><PlainHeader title={""} /></div></div>
      <div className="login px-4 mt-[20px] sm:mt-[40px] mx-auto w-full flex sm:flex-row flex-col-reverse gap-3 items-center sm:items-start justify-center">
        {this.state.signup ? 
                <>
                  <div className='sm:hidden underline underline-offset-2 decoration-2 text-strong-content-color font-semibold cursor-pointer' onClick={() => this.setState({signup: false})}>Sign In</div>
                  <Signup history={this.props.history} location={this.props.location} />
                </>
                :
                <>
                <div className='hidden sm:block'><Signup history={this.props.history} location={this.props.location} /></div>
                <div className='sm:hidden underline underline-offset-2 decoration-2 text-strong-content-color mt-6 font-semibold cursor-pointer' onClick={() => this.setState({signup: true})}>Create Account</div>
                <Form className="login-form ml-0 max-w-full" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="title" style={{marginBottom: 31}}>Sign In</div>
                  <FormGroup controlId="email">
                    <FormControl className="text-field" type="email" placeholder="Email Address" value={this.state.email} onChange={(e)=> this.handleChange("email", e.target.value)}/>
                  </FormGroup>
                  <FormGroup controlId="login-password">
                    <FormControl className="text-field" type="password" placeholder="Password" value={this.state.password} onChange={(e)=> this.handleChange("password", e.target.value)}/>
                  </FormGroup>
                  {this.state.error ? <div className="error-state"><img src={Warning} alt="" /><span className="text"> {this.state.error}</span></div> : null}
                  <Link to={"/signin/forgotpassword"} className="forgot">Forgot password?</Link>
                  <button className={"custom-button" + ((!this.state.email || !this.state.password) ? " disabled" : "" )} style={{width: 107}} type="submit">
                      Sign In
                  </button>
                </Form>
                </>
        }
       
      </div>
      <Footer />
    </div>

    )
  }
}


const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login))
