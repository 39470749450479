import React from "react"
import { withRouter } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import pluralize from 'pluralize'
import ServicePage from "../Services/ServicePage"
import PackagePage from "../Packages/PackagePage"

import { getPackagePrice, getComponentPrice, getQty} from '../Utils/math'


class ListItem extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      change: false,
      view: false
    }
  }

  getPrice(component){
    if(this.props.category === "package" || this.props.category === "packages"){
      if(component.calculated_price) {
        return component.calculated_price
      }
      return getPackagePrice((component.component_details || []), this.props.booking, component.discount, component.setup_time, component.takedown_time)
    }
    return getComponentPrice(component, this.props.booking)

  }

  change(component, included, noChange, showModal){
    console.log("change called", component.change, "included", included, "noChange", noChange, "showModal", showModal, this.props.viewDetails, this.props.showModal)
    if(!included && !noChange && component.change !== "remove" && !component.canceled){
      if(showModal){
        this.setState({change: true})
      }
    }else if(this.props.viewDetails){
      this.setState({view: true})
    }
    //this.setState({change: true})
  }

  render(){
    var {component, category, included, booking, showQty, onClick, theme, noChange, remove, change, add, className, to_remove, to_add, changeable, showModal, viewDetails, hidePrice} = this.props

    var image = ""
    if(component.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]

    if(!theme){
      theme =  component.option_values ? component.option_values["Theme"] : null
      if(theme  && theme.toLowerCase() === "custom" ) theme = component.option_values["customTheme"]
    }

    var qty = ""
    showQty = (showQty || !!component.qty)  && !included
    if(showQty || theme || component.num_staff > 1) qty = " qty"
    var detailText = () => {
      var text = ""
      if(theme){
        text = theme
      }
      if(component.num_staff > 1){
        text += (theme  ? " for ": "For ")
        text += component.num_staff + " " + pluralize(component.name)
      }
      if(showQty){
        text += (theme || component.num_staff > 1 ? " for ": "For ")
        var q = getQty(component, booking)
        switch (component.unit){
          case "hour":
            text += q + (booking.duration === 1 ? " hour" : " hours")
            break;
          case "kit":
            text += q + " " + pluralize(component.unit_name || "kit")
            break
          default:
            text += q + " people"
        }
      }
      return text
    }

    to_remove = to_remove || component.canceled ? " to-remove" : ""
    to_add = to_add ? " to-add" : ""
    var clickable = onClick ? {cursor: "pointer"} : {}
    var incl =  included || noChange ? " included" : ""
    if(component.name === "Puppies"){
      console.log(component.name, component)
    }
    if(component.change === "remove"){
      console.log(component.name, component)
    }
    // console.log('list item ', component.name, component.is_available, included )
// onClick={onClick || (showModal ? () => this.setState({change: true}): (viewDetails ? () => this.setState({view: true}) : null ) )} style={clickable}
    return (
      <div>
      <div key={this.props.key} className={"powerup" + (className ? " " + className : "") } >
        <div className="powerup-image-container" onClick={onClick || (() => this.change(component, included, noChange, showModal))} >
          <img className="powerup-image object-cover" src={image} alt={component.name}/>
        </div>
        <div className="powerup-wrapper">
          <div className={"powerup-details" + to_add + to_remove + qty + incl + (!component.is_available ? " unavailable": "") }>
            <div className={"powerup-name"} onClick={onClick || (() => this.change(component, included, noChange, showModal))}> {component.name} </div>

            {!hidePrice ?
              (included ? <div className="powerup-price included"> Included </div> :
              <div style={{display: "inline-block", float: "right"}}>
                <div className="powerup-price">{currencyFormatter.format(this.getPrice(component),  { code: 'USD', precision: 0})} </div>
                <div className="powerup-price-per">{currencyFormatter.format(this.getPrice(component)/ (getQty(component, booking)|| 1) , { code: 'USD', precision: 0})}/{component.unit||"person"} </div>
               </div>)
            : null}
          </div>
          <div className={"quantity" +  qty + incl + to_add + to_remove +(!component.is_available && !included ? " unavailable": "")}>
            {!component.is_available && !included ? "Unavailable on Event Date" : detailText()}
          </div>
          { !included && !noChange && component.change !== "remove" && !component.canceled ?
            (!this.props.package ?
              <div className="hovered">
                <span className="change"onClick={onClick || (() => this.change(component, included, noChange, showModal))}>Change</span>
                <span> | </span>
                <span className="remove" onClick={() => remove(component, category)}>Remove</span>
              </div>
              :
              <div className="hovered">
                <span className="remove" onClick={() => remove(component, category)}>Remove</span>
              </div>
            )
            : null
          }
        </div>

      </div>
      {this.state.view && !this.props.package ? <ServicePage readOnly={false} setBooking={this.props.setBooking} included={false} booking={booking} add={add} edit={change} fromCart={true} show={this.state.view} inCart={changeable} component={component} onHide={()=> this.setState({view: false})} category={category} booked={this.props.booked} />  : null}
      {this.state.view && this.props.package ? <PackagePage noChange={true} setBooking={this.props.setBooking} booking={booking} fromCart={true} show={this.state.view} inCart={true} component={component} onHide={()=> this.setState({view: false})} />  : null}

      {this.state.change && !this.props.package ? <ServicePage setBooking={this.props.setBooking} booking={booking} add={add} edit={change} fromCart={true} show={this.state.change} inCart={changeable} component={component} onHide={()=> this.setState({change: false})} category={category} booked={this.props.booked} />  : null}
      {this.state.change && this.props.package ? <PackagePage setBooking={this.props.setBooking} booking={booking} fromCart={true} show={this.state.change} inCart={true} component={component} onHide={()=> this.setState({change: false})} />  : null}
      </div>
    )
  }

}

export default withRouter(ListItem)
