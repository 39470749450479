import React, {useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../actions";
import { withRouter } from "react-router-dom";
import request from "../request";
import moment from "moment";
import { getStartTime} from './Utils/math'
import _ from 'underscore'

function getOptionNames(options){
    var keys = []
    for (let t of options.types){
      keys = keys.concat(t.options.map(opt => opt.name.toLowerCase()))
    }
    return keys
  }


const Browsing = ({booking, login, actions, cart}) => {
    
    useEffect(() => {
        const start_time = getStartTime(booking.date, booking.start_time, booking.timezone);
        const end_time = booking.duration ? 
            moment(start_time).add(booking.duration, "hours") : 
            moment(start_time).add(1, "hours");
        getComponents(start_time, end_time, booking.zipcode, booking.timezone);
        getPackages(start_time, end_time, booking.zipcode);
    }, [booking.date, booking.start_time, booking.duration, booking.zipcode, booking.timezone, login.admin]);

    const getComponents = async (start_time, end_time, zipcode, timezone) => {
        actions.setLoading(true);
        
        if (login.admin) {
          const allcomps = await request('/api/admin/getComponents/', {
            method: "POST", 
            body: JSON.stringify({zipcode})
          });
          
          if (!allcomps || allcomps.err) return;
          
          const processedComponents = allcomps.map(c => {
            if (c.keywords) {
              c.keywords.push(c.name.toLowerCase());
              if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
            } else {
              c.keywords = [c.name.toLowerCase()];
              if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
            }
            c.is_available = true;
            c.wrong_location = false;
            return c;
          });
          actions.setAllComponents(processedComponents);
          updateCartComponents(processedComponents);
          return;
        } else {
            
        }
    
        try {
          const response = await fetch('/api/components', {
            method: "POST",
            body: JSON.stringify({start_time, end_time, zipcode, timezone}),
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          });
          
          const components = await response.json();
          
          if (components.err) {
            console.error(components.err);
            return;
          }
    
          console.log("CMP:", components)
    
          const processedComponents = components.map(c => {
            if (c.keywords) {
              c.keywords.push(c.name.toLowerCase());
              if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
            } else {
              c.keywords = [c.name.toLowerCase()];
              if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
            }
            c.wrong_location = false;
            
            if (start_time && moment(start_time) < moment().add(c.booking_lead_time, 'days')) {
              c.is_available = false;
              c.remaining = 0;
            }
            return c;
          });
        //   if (details) {
                //     // setDetailedComponent(processedComponents.find(c => c.id === details));
          //}
            actions.setAllComponents(processedComponents);
        
          updateCartComponents(processedComponents);
        } catch (error) {
          console.error(error);
          actions.setLoading(false);
        }
    };

    const getPackages = async (start_time, end_time, zipcode, timezone) => {
        if (login.admin) {
          const packages = await request('/api/admin/packages/' + zipcode);
          if (!packages || packages.err) return;
          actions.setAllPackages(packages);
          return;
        }
    
        const packages = await request('/api/packages', {
          method: "POST", 
          body: JSON.stringify({start_time, end_time, zipcode, timezone})
        });
    
        if (!packages || packages.err) return;
    
        const processedPackages = packages.map(c => {
          if (c.keywords) {
            c.keywords.push(c.name.toLowerCase());
          } else {
            c.keywords = [c.name.toLowerCase()];
          }
          
          if (moment(start_time) < moment().add(c.booking_lead_time, 'days')) {
            c.is_available = false;
          }
          return c;
        });
    
        actions.setAllPackages(processedPackages);
        updateCartPackage(processedPackages);
      };

    const updateCartComponents = (components) => {
        const s = new Set(components.map(c => c.id));
        let clone = Object.assign({}, cart);
        
        for (let cat in clone) {
          if (cat !== "package") {
            clone[cat] = clone[cat].map(c => {
              const updated = components.find(u => u.id === c.id);
              if (updated) {
                c.component.price = updated.price;
                c.component.setup_time = updated.setup_time;
                c.component.takedown_time = updated.takedown_time;
                c.component.normal_duration = updated.normal_duration;
                c.component.extra_hours_fee = updated.extra_hours_fee;
                c.component.minimum = updated.minimum;
                c.component.maximum = updated.maximum;
                c.component.unit = updated.unit;
                c.component.wrong_location = false;
                c.component.remaining = updated ? updated.remaining : null;
                c.component.is_available = !(updated && 
                  updated.remaining !== null && 
                  (Number(updated.remaining) === 0 || 
                   Number(c.component.num_staff) > Number(updated.remaining)));
              } else {
                c.component.wrong_location = true;
                c.component.is_available = false;
              }
              
    
              return c;
            });
          }
        }
        actions.setCart(clone);
    };

    const updateCartPackage = (packages) => {
        var updated_pack =  null
        if(cart.package && cart.package.length > 0){
          var updated_pack = packages.find(p => Number(p.id) === Number(cart.package[0].id))
        }
        if(updated_pack){
          console.log("updating packages", updated_pack, cart.package[0])
          let clone =  Object.assign({}, cart)
          console.log("updating components", updated_pack, clone.package[0].component, clone.package[0].component.component_details, updated_pack.component_details)
          clone.package[0].component.components = updated_pack.components
          clone.package[0].component.component_details = updated_pack.component_details
          clone.package[0].component.is_available = updated_pack.is_available
          let pack_ids = clone.package[0].component.components.map(c => Number(c.id))
          let avail =  true
          if(moment(booking.date) < moment().add(clone.package[0].component.booking_lead_time || 3, 'days') ){
            clone.package[0].component.is_available = false;
            avail = false
          }
          if(_.intersection(clone.package[0].component.locations, booking.location_ids).length < 1){
            clone.package[0].component.wrong_location = true;
          }else{
            clone.package[0].component.wrong_location = false
          }
          actions.setCart(clone)
        }else{
          console.log("updating packages", "wrong location")
    
          let clone =  Object.assign({}, cart)
          if(clone.package && clone.package[0]){
            clone.package[0].component.wrong_location = true;
            clone.package[0].component.is_available = false;
            actions.setCart(clone)
          }
        }
      }

    return (<div className="absolute bottom-0 right-0 h-0 w-0"></div>)
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Browsing))
  