import React from "react"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import currencyFormatter from 'currency-formatter'
import pluralize from 'pluralize'
import ServicePage from "../Services/ServicePage"
import PackagePage from "../Packages/PackagePage"
import moment from 'moment'
import { getPackagePrice, getComponentPrice, getQty, getLateFee, getStartTime} from '../Utils/math'


class CartPowerup extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      change: false
    }
  }

  getPrice(component){
    var late_fee = getLateFee(moment(), getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone))
    if(this.props.category === "package"){
      return getPackagePrice((component.component_details || []), this.props.booking, component.discount, component.setup_time, component.takedown_time, late_fee)
    }
    return getComponentPrice(component, this.props.booking, null, null, null, late_fee)

  }

  render(){
    var {c_id, component, category, included, booking, actions, showQty, onClick, theme, noChange, hidePrice} = this.props

    var image = ""
    if(component.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]

    if(!theme){
      theme =  component.option_values ? component.option_values["Theme"] : null
      if(theme  && theme.toLowerCase() === "custom" ) theme = component.option_values["customTheme"]
    }

    var qty = ""
    showQty = (showQty || !!component.qty)  && !included
    if(showQty || theme || component.num_staff > 1) qty = " qty"

    var detailText = () => {
      var text = ""
      if(theme){
        text = theme
      }
      if(component.num_staff > 1){
        text += (theme  ? " for ": "For ")
        text += component.num_staff + " " + pluralize(component.name)
      }
      if(showQty){
        text += (theme || component.num_staff > 1 ? " for ": "For ")
        var q = getQty(component, booking)
        switch (component.unit){
          case "hour":
            text += q + (booking.duration === 1 ? " hour" : " hours")
            break;
          case "kit":
            text += q + " " + pluralize(component.unit_name || "kit")
            break
          default:
            text += q + " people"
        }
      }
      return text
    }

    var clickable = onClick ? {cursor: "pointer"} : {}
    var incl =  included ? " included" : ""
    return (
      <div key={this.props.key} className="powerup" onClick={onClick} style={clickable}>
        <div className="powerup-image-container" onClick={() => {if(!included) this.setState({change: true})}} >
          <img className="powerup-image object-cover" src={image} alt={component.name}/>
        </div>
        <div className="powerup-wrapper">
          <div className={"powerup-details" + qty + incl + ((!component.is_available && !included) || (component.wrong_location && !included) ? " unavailable": "") }>
            <div className="powerup-name" onClick={() => {if(!included) this.setState({change: true})}}> {component.name} </div>
            {!hidePrice ?
              (included ? <div className="powerup-price included"> Included </div> :
              <div style={{display: "inline-block", float: "right"}}>
                <div className="powerup-price">{currencyFormatter.format(this.getPrice(component),  { code: 'USD', precision: 0})} </div>
                <div className="powerup-price-per">{currencyFormatter.format(this.getPrice(component)/ (getQty(component, booking)|| 1) , { code: 'USD', precision: 0})}/{component.unit||"person"} </div>
               </div>)
            : null}
          </div>
          <div className={"quantity" +  qty + incl + ((!component.is_available && !included) || (component.wrong_location && !included) ? " unavailable": "")}>
            {component.wrong_location && !included ? "Unavailable at Event Location" : null}
            {!component.wrong_location && !component.is_available && !included ? "Unavailable on Event Date" : detailText()}
          </div>
          <div className="hidden sm:block">
            { !included && !noChange ?
              (!this.props.package ?
                <div className="hovered">
                  <span className="change" onClick={() => this.setState({change: true})}>Change</span>
                  <span> | </span>
                  <span className="remove" onClick={() => actions.deleteCartItem(c_id, category)}>Remove</span>
                </div>
                :
                <div className="hovered">
                  <span className="remove" onClick={() => actions.deleteCartItem(c_id, category)}>Remove</span>
                </div>
              )
              : null
            }
          </div>
        </div>
        {this.state.change && !this.props.package ? <ServicePage booking={this.props.booking} fromCart={true} show={this.state.change} inCart={true} component={component} onHide={()=> this.setState({change: false})} category={category} />  : null}
        {this.state.change && this.props.package ? <PackagePage booking={this.props.booking} fromCart={true} show={this.state.change} inCart={true} component={component} onHide={()=> this.setState({change: false})} />  : null}

      </div>
    )
  }

}

const mapStateToProps = state => ({
  booking: state.booking
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPowerup))
