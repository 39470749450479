import React from 'react'
import HeartButton from '../../img/HeartButton.svg'
import RedHeartButton from '../../img/RedHeartButton.svg'
import {Dropdown, MenuItem} from 'react-bootstrap'
import CreateWishlist from './CreateWishlist'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import request from '../../request'
import Checkmark from '../../img/InCartButton.svg'
import Track from './Analytics'

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class Hearting extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      open: false,
      create: false
    }
  }

  open(open){
    if(!this.props.login.loggedIn){
      this.props.history.push({pathname: '/signin', state: {from: this.props.location.pathname}})
    }else{
      this.setState({open})
    }
  }

  async addToWishlist(c, isPackage, l, index){
    Track.addToWishlist(c.name)
    if(!this.props.app.wishlist) this.props.actions.assignWishlist("first")
    if(isPackage){
      var pack = await request('/api/wishlist/package', {method: "POST", body: JSON.stringify({package_id: c.id, list_id: l.list_id})})
      this.props.actions.addPackageToWishlist({package_id: c.id, wishlist_id: l.list_id, wishlist_package_id: pack.id}, index)
    }else{
      var comp = await request('/api/wishlist/component', {method: "POST", body: JSON.stringify({component_id: c.id, list_id: l.list_id})})
      this.props.actions.addComponentToWishlist({component_id: c.id, wishlist_id: l.list_id, wishlist_component_id: comp.id}, index)
    }
  }

  async removeFromWishlist(c, isPackage, l, index){
    if(isPackage){
      var pack = l.packages.find(p => Number(c.id) === Number(p.package_id) )
      await request('/api/wishlist/package', {method: "DELETE", body: JSON.stringify({wishlist_package_id: pack.wishlist_package_id, list_id: l.list_id})})
      this.props.actions.removePackageFromWishlist({wishlist_package_id: pack.wishlist_package_id, wishlist_id: l.list_id}, index)
    }else{
      var comp = l.components.find(p => Number(c.id) === Number(p.component_id) )
      await request('/api/wishlist/component', {method: "DELETE", body: JSON.stringify({wishlist_component_id: comp.wishlist_component_id, list_id: l.list_id})})
      this.props.actions.removeComponentFromWishlist({wishlist_component_id: comp.wishlist_component_id, wishlist_id: l.list_id}, index)
    }
  }


  render(){
    var in_a_list = false
    var lists = this.props.lists || []
    for (var l of lists){
      if(this.props.isPackage){
        in_a_list = l.packages.map(c => Number(c.package_id)).includes(Number(this.props.component.id))
        if(in_a_list) break
      }else{
        in_a_list = l.components.map(c => Number(c.component_id)).includes(Number(this.props.component.id))
        if(in_a_list) break
      }
    }

    let cN = this.state.open ? "heart shadow rounded-t " : "heart "
    cN += this.props.className ? this.props.className : ""
    return(
      <Dropdown onClick={(e) => e.stopPropagation()} id="wishlist-dropdown"   className={"w-[36px] h-[36px] flex justify-center items-center " + cN} open={this.state.open} onToggle={(open, a, source) => {if(source.source !== "select" ) this.open(!this.state.open)}} >
        <CustomToggle bsRole="toggle" >
          <img className="hover:w-[36px] hover:h-[36px]" src={ in_a_list ? RedHeartButton : HeartButton} alt="Wishlist" />
        </CustomToggle>
        <Dropdown.Menu onSelect={(key, e) => {e.stopPropagation();}} className={"top-[100%] mt-[1px]" || ""} style={ this.props.right ? {left: "auto", right: 1} : {}}>
          <div className="choose">Choose Wish List</div>
          {lists.map( (l, i) => {
            var in_list = false
            if(this.props.isPackage){
              in_list = l.packages.map(c => Number(c.package_id)).includes(Number(this.props.component.id))
            }else{
              in_list = l.components.map(c => Number(c.component_id)).includes(Number(this.props.component.id))
            }
            return (
              <MenuItem key={i} active={false} onClick={() => !in_list ? this.addToWishlist(this.props.component, this.props.isPackage, l, i) : this.removeFromWishlist(this.props.component, this.props.isPackage, l, i)}>
                {in_list ? <img src={Checkmark} className="in-wishlist" alt="" /> : null }{l.name}
                </MenuItem>)
            }
          )}
          <MenuItem divider />
          <MenuItem active={false} onClick={() => this.setState({create: true})} >Create New List</MenuItem>
        </Dropdown.Menu>
        <CreateWishlist onHide={() => this.setState({create: false})} onCreate={(l) => this.addToWishlist(this.props.component,this.props.isPackage, l, lists.length - 1)} show={this.state.create} />
      </Dropdown>
    )
  }

}

const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Hearting))
