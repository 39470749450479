const moment = require('moment-timezone')

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}

function findCartItem(id, cart){
  for (var cat in cart){
    for(var c of cart[cat]){
      if(c.id === id) return c.component
    }
  }
  return null
}

function finalTotal(pre, coupon_value, fee, credited){
  // console.log("finalTotal", pre, coupon_value, fee, credited)
  pre -= coupon_value || 0
  pre += fee || 0
  pre -= credited || 0
  return pre
}

function getQty(component, booking){
  if(component.qty) return Number(component.qty)
  if(component.unit === "person") return Number(booking.people || 1)
  if(component.unit === "hour") return Number(booking.duration || 1)
  if(component.unit === "kit") return 1
  return Number(booking.people || 1)
}

function getPackageQty(component, booking){
  if(component.setQty) return Number(component.setQty)
  if(component.unit === "person") return Number(booking.people || 1)
  if(component.unit === "hour") return Number(booking.duration || 1)
  if(component.unit === "kit") return 1
  return Number(booking.people || 1)
}

function param(component, booking, qty){
  if(!qty) qty = Number(getQty(component, booking))
  if( (component.minimum && Number(qty) < Number(component.minimum))){
    return Number(component.minimum)
  }else if(component.maximum && Number(component.maximum) > 0 && Number(qty) > Number(component.maximum)){
    return Number(component.maximum)
  }
  return qty
}

function getExtraHoursFee(component, booking, qty){
  if(component.normal_duration){
    var duration = 0
    if(component.unit === "hour"){
      duration = qty
    }else{
      duration = Number(component.duration || booking.duration || 1)
    }
    if(duration > Number(component.normal_duration)){
      var scalar = 1
      if(!isNaN(component.extra_hours_fee_scalar) && Number(component.extra_hours_fee_scalar) > 0){
        if(component.extra_hours_fee_scalar_unit === component.unit){
          scalar = Math.ceil(qty/Number(component.extra_hours_fee_scalar))
        }else{
          if(component.extra_hours_fee_scalar_unit === "person"){
            scalar = Math.ceil(booking.people/Number(component.extra_hours_fee_scalar))
          }
        }
      }
      return (duration - Number(component.normal_duration)) * Number(component.extra_hours_fee || 0) * scalar
    }
  }
  return  0
}

function getTravelFee(comp, booking){
  if(comp.travel_fee) return Number(comp.travel_fee || 0)
  let loc = comp.location || (booking.location_ids && booking.location_ids.length > 0 ? booking.location_ids[0] : null)
  if(comp.travel_fees && loc){
    return Number(comp.travel_fees[String(loc)] || 0)
  }
  return 0
}

function getComponentPrice(component, booking, qty, fee, num_staff, late_booking_fee){
  var p = 0;
  num_staff = num_staff || Number(component.num_staff || 1)
  if(component.services && component.services.length){
    for(var s of component.services){
      s.qty = component.qty
      s.duration = component.duration || booking.duration || 1
      s.individual = component.individual
      var price = getServicePrice(s, booking, booking.start_time, booking.timezone, qty, fee, num_staff, late_booking_fee)
      if(price > p) p = price
    }
    p = p / (1 - Number(component.margin || 0))
  }else{
    p =  getServicePrice(component, booking, booking.start_time, booking.timezone, qty, fee, num_staff, late_booking_fee)
    p = p / (1 - Number(component.margin || 0))
    // p = getPrice(component, booking, qty, fee, num_staff, late_booking_fee)
  }
  // if(component.name === "Archery") console.log("Archery", component.services,  component.margin)
  return p
}


function getPrice(component, booking, qty, fee, num_staff, late_booking_fee){
  qty = param(component, booking, qty)
  let p =  Number(component.price) * qty + getExtraHoursFee(component, booking, qty)
  num_staff = num_staff  || Number(component.num_staff || 1)
  if(component.individual){
    p += Number(component.flat_fee || 0)
    p += getTravelFee(component, booking)
    p *= num_staff
  } else{
    p *= num_staff
    p += Number(component.flat_fee || 0)
    p += getTravelFee(component, booking)
  }
  p *= 1 + Number(late_booking_fee || component.late_booking_fee || 0)
  // console.log(component, p, qty)
  // console.log(component, component.price, qty, getExtraHoursFee(component, booking, qty), num_staff,component.flat_fee, getTravelFee(component, booking), late_booking_fee || component.late_booking_fee )

  if(fee){
    switch(component.canceled){
      case "no_charge":
        return 0
      case "early":
        return Number(component.cancellation_charge) * p
      default:
        return p
    }
  }
  return p
}
//
// function getComponentPrice(component, booking, qty, fee, num_staff, late_booking_fee){
//   qty = param(component, booking, qty)
//   let p =  Number(price) * qty + getExtraHoursFee(component, booking, qty)
//   num_staff = num_staff  || Number(component.num_staff || 1)
//   if(component.name === "Bartender"){
//     p += Number(component.flat_fee || 0)
//     p *= num_staff
//   } else{
//     p *= num_staff
//     p += Number(component.flat_fee || 0)
//   }
//   p += getTravelFee(component, booking)
//   p *= 1 + Number(late_booking_fee || component.late_booking_fee || 0)
//   // console.log(component, p, qty)
//   // console.log(component, component.price, qty, getExtraHoursFee(component, booking, qty), num_staff,component.flat_fee, getTravelFee(component, booking), late_booking_fee || component.late_booking_fee )
//
//   if(fee){
//     switch(component.canceled){
//       case "no_charge":
//         return 0
//       case "early":
//         return Number(component.cancellation_charge) * p
//       default:
//         return p
//     }
//   }
//   return p
// }

function getServicePrice(service, booking, event_date, timezone, qty, fee, num_staff, late_booking_fee){
  if(!late_booking_fee && !!service.booked_at && moment(event_date).diff(moment(service.booked_at), "days") < Number(service.late_booking_threshold)){
    late_booking_fee = Number(service.late_booking_percentage || 0)
  }
  var discount = 1
  if(service.individual && !num_staff){
    num_staff = 1
  }else{
    num_staff = num_staff || service.num_staff
  }
  // if(service.individual) num_staff = 1
  var p = getPrice(service, booking, qty, fee, num_staff, late_booking_fee)
  if(service.discount_threshold){
    if(p > Number(service.discount_threshold)){
      discount = 1 - Number(service.discount || 0)
    }
  }else{
    discount = 1 - Number(service.discount || 0)
  }
  return p * discount
}

// function getServicePrice(service, booking, event_date, timezone){
//   var late_booking_fee = null
//   if(moment(event_date).diff(moment(service.booked_at), "days") < Number(service.late_booking_threshold)){
//     late_booking_fee = Number(service.late_booking_percentage || 0)
//   }
//   var discount = 1
//   let num_staff = service.num_staff
//   if(service.individual) num_staff = 1
//   var p = getComponentPrice(service, booking, null, null, num_staff, late_booking_fee)
//   if(service.discount_threshold){
//     if(p > Number(service.discount_threshold)){
//       discount = 1 - Number(service.discount || 0)
//     }
//   }else{
//     discount = 1 - Number(service.discount || 0)
//   }
//   return p * discount
// }

function getPackagePrice(components, params, discount, setup, takedown, late_fee){
  // console.log(components, params, discount, setup, takedown)
  let total = 0
  for( let comp of components){
    var qty = Number(getPackageQty(comp, params))
    if(comp.minimum  && qty < Number(comp.minimum)){
      qty = Number(comp.minimum)
    }
    if(comp.maximum && Number(comp.maximum) !== 0 && qty > Number(comp.maximum)){
      qty = Number(comp.maximum)
    }
    // console.log(comp.name, comp.qty, qty, setup, takedown)
    if(!qty) qty = 1
    if(comp.name === "Event Host"){
      let extra = 0
      if(setup) extra += Number(setup)
      if(takedown) extra += Number(takedown)
      qty = Number(params.duration) + extra
    }
    // console.log(comp.name, qty)
    if(comp.qtyScaler) qty = qty * Math.ceil((params.people || 1)/Number(comp.qtyScaler))
    // console.log(comp.name, qty)

    // var sub = Number(comp.price) * qty * (comp.num_staff || 1)  + (comp.normal_duration && params.duration > comp.normal_duration ? (params.duration - comp.normal_duration) * comp.extra_hours_fee : 0)

    let price = getComponentPrice(comp, params, qty, null, comp.num_staff || 1, late_fee)
    // console.log(comp.name, qty)

    total += price
    // console.log(comp.name,  price, qty, comp.num_staff, late_fee)
  }
  return total - (total * (Number(discount) || 0))
}


function getPackageTaxable(components, params, discount, setup, takedown, late_fee, tax){
  // console.log(components, params, discount, setup, takedown)
  let total = 0
  discount = 1 - Number(discount || 0)
  for( let comp of components){
    var qty = Number(getPackageQty(comp, params))
    if(comp.minimum  && qty < Number(comp.minimum)){
      qty = Number(comp.minimum)
    }
    if(comp.maximum && Number(comp.maximum) !== 0 && qty > Number(comp.maximum)){
      qty = Number(comp.maximum)
    }
    if(!qty) qty = 1
    if(comp.name === "Event Host"){
      let extra = 0
      if(setup) extra += Number(setup)
      if(takedown) extra += Number(takedown)
      qty = Number(params.duration) + extra
    }
    if(comp.qtyScaler) qty = qty * Math.ceil((params.people || 1)/Number(comp.qtyScaler))
    // var sub = Number(comp.price) * qty * (comp.num_staff || 1)  + (comp.normal_duration && params.duration > comp.normal_duration ? (params.duration - comp.normal_duration) * comp.extra_hours_fee : 0)
    if(comp.taxable) total += getComponentPrice(comp, params, qty, null, comp.num_staff || 1, late_fee) * discount
    // console.log(comp.name,  Number(comp.price) * qty * (comp.num_staff || 1))
  }
  return total
}

// function getPackagePrice(pack, params, late_fee){
//   let components = pack.component_details
//   let discount = pack.discount
//   let setup = pack.setup_time
//   let takedown = pack.takedown_time
//   // console.log(components, params, discount, setup, takedown)
//   let total = 0
//   for( let comp of components){
//     var qty = Number(getQty(comp, params))
//     if(comp.minimum  && qty < Number(comp.minimum)){
//       qty = Number(comp.minimum)
//     }
//     if(comp.maximum && Number(comp.maximum) !== 0 && qty > Number(comp.maximum)){
//       qty = Number(comp.maximum)
//     }
//     if(!qty) qty = 1
//     if(comp.name === "Event Host"){
//       if(setup && takedown) qty += Number(setup) + Number(takedown)
//     }
//     if(comp.qtyScaler) qty = qty * Math.ceil((params.people || 1)/Number(comp.qtyScaler))
//     // var sub = Number(comp.price) * qty * (comp.num_staff || 1)  + (comp.normal_duration && params.duration > comp.normal_duration ? (params.duration - comp.normal_duration) * comp.extra_hours_fee : 0)
//
//     total += getComponentPrice(comp, params, qty, null, comp.num_staff || 1, late_fee)
//     // console.log(comp.name,  Number(comp.price) * qty * (comp.num_staff || 1))
//   }
//   return total - (total * (Number(discount) || 0))
// }


function getTotal(cart, params, late_fee){
    var total = 0
    for (var cat in cart){
      for(var c of cart[cat]){
        var comp = c.component
        if(cat === "package"){
          // console.log("Package", c)
          total += getPackagePrice(comp.component_details, params, comp.discount, comp.setup_time, comp.takedown_time, late_fee)
          // console.log(comp, total, params)
          continue
        }
        total +=  getComponentPrice(comp, params, null, null, null, late_fee)
      }
    }
    return total
}

function getCouponValue(coupon, total, cart, params, late_fee){
  if(!coupon) return 0
  if(coupon.value){
    if(coupon.component_id){
      var comp = findCartItem(coupon.component_id, cart)
      if(!comp){
        return 0
      }
    }
    if(coupon.package_id){
      if(!cart.package || !cart.package[0] || Number(cart.package[0].id) !== Number(coupon.package_id)){
        return 0
      }
    }
    return coupon.value
  }
  if(coupon.component_id){
    var coup_comp = findCartItem(coupon.component_id, cart)
    if(coup_comp){
      return coupon.percent * getComponentPrice(coup_comp, params,  null, null, null, late_fee)
    }else{
      return 0
    }
  }
  if(coupon.package_id){
    var pack = cart.package ? (cart.package[0] ? cart.package[0].component : null) : null
    if(pack){
      return coupon.percent * Number(pack.calculated_price || getPackagePrice(pack.component_details, params, pack.discount, pack.setup_time, pack.takedown_time))
    }else{
      return 0
    }
  }
  return (coupon.percent || 0) * total
}

function getTax(cart, params, late_fee, tax, coupon, total){
    var price = 0
    for (var cat in cart){
      for(var c of cart[cat]){
        var comp = c.component
        if(cat === "package"){
          // console.log("Package", c)
          price += getPackageTaxable(comp.component_details, params, comp.discount, comp.setup_time, comp.takedown_time, late_fee, tax)
          // console.log(comp, total, params)
        }else{
          if(comp.taxable){
            let comp_price = getComponentPrice(comp, params, null, true, null, late_fee)
            if(coupon && Number(c.id) === Number(coupon.component_id)){
              if(coupon.value){
                comp_price -= Number(coupon.value)
              }else{
                comp_price *= 1 - Number(coupon.percent)
              }
            }
            price += comp_price
          }
        }
      }
    }

    if(coupon && !coupon.component_id && coupon.percent) price = price * (1-Number(coupon.percent))
    if(coupon && !coupon.component_id && coupon.value) price = price - (coupon.value*(price/total))
    return price * Number(tax)
}
// function getPostTax(pretax, tax){
//   tax = Number(tax)
//   if(!tax) tax =  0.085
//   tax += 1
//   return pretax * tax
// }

function getStartTime(date, start_time, timezone){
  timezone = timezone || moment.tz.guess();
  var start_time = moment(start_time).format("HH:mm")
  var d = moment(date).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)
  start_time = moment(d).add(start_time.split(":")[0], 'hours').add(start_time.split(":")[1], 'minutes')
  return moment.tz(start_time.format("YYYY-MM-DDTHH:mm"), timezone)
}

function getEarliestStart(comps){
  var earliestDate = moment(comps[0].start_time);
  for(var i = 1; i < comps.length ; i++){
      var currentDate = moment(comps[i].start_time);
      if(currentDate < earliestDate){
          earliestDate = currentDate;
      }
  }
  return earliestDate;
}

function getLatestEnd(comps){
  var latestDate = moment(comps[0].end_time);
  for(var i = 1; i < comps.length ; i++){
      var currentDate = moment(comps[i].end_time);
      if(currentDate > latestDate){
          latestDate = currentDate;
      }
  }
  return latestDate;
}

function daysDiff(interval){
  switch(interval){
    case "weekly":
      return 7
    case "biweekly":
      return 14
    case "monthly":
      return 31
    default:
      return 31
  }
}

function getPriceForMultipleOccurrences(cart, params, venue_fee, occurrences, interval){
  var grand_total = 0
  for (var i = 0; i < occurrences; i++){
    let late_fee = getLateFee(moment(), getStartTime(params.date, params.start_time, params.timezone).add(daysDiff(interval)*i, "days"))
    let total = getTotal(cart, params, late_fee) + venue_fee
    grand_total += total
  }
  return grand_total

}

function getBookedPrice(e, booking){
  var price = 0
  for (var c of e.components){
    // console.log('getting price of', c.name, c.num_staff)
    price += getComponentPrice(c, booking, null, true)
    // console.log('price', price)
  }
  if(e.packages){
    for(var p of e.packages){
      if(p.calculated_price){
        price += Number(p.calculated_price)
      }else{
        price += getPackagePrice( p.component_details, booking,  p.discount, p.setup_time, p.takedown_time, 0)
      }
    }
  }
  if(e.package){
    if(e.package.calculated_price){
      price += Number(e.package.calculated_price)
    }else{
      price += getPackagePrice( e.package.component_details, booking,  e.package.discount, e.package.setup_time, e.package.takedown_time, 0)
    }
  }
  return price
}

function getBookedTax(e, booking, tax, coupon, total){
  var price = 0
  for (var c of e.components){
    if(c.taxable){
      let comp_price = getComponentPrice(c, booking, null, true)
      if(coupon && Number(c.id) === Number(coupon.component_id)){
        if(coupon.value){
          comp_price -= Number(coupon.value)
        }else{
          comp_price *= 1 - Number(coupon.percent)
        }
      }
      price += comp_price
    }
  }
  if(e.package) price += getPackageTaxable( e.package.component_details, booking,  e.package.discount, e.package.setup_time, e.package.takedown_time, 0, tax, true)

  if(coupon && !coupon.component_id && coupon.percent) price = price * (1-Number(coupon.percent))
  if(coupon && !coupon.component_id && coupon.value) price = price - (coupon.value*(price/total))
  return price * (e.event.tax || tax)
}

function cartify(pack, components, people){
  var cart = {}
  if (pack){
    var p_clone = cloneObj(pack)
    p_clone.qty = people
    cart.package = [{id: p_clone.id, component: p_clone}]
  }
  components.forEach( (c, i) => {
    var clone = cloneObj(c)
    if(clone.is_available === undefined) clone.is_available = true
    clone.index =  i
    cart[clone.categories[0]] ? cart[clone.categories[0]].push({id: clone.id, component: clone}) : cart[clone.categories[0]] = [{id: clone.id, component: clone}]
  })
  return cart
}

function getTotalEventPrice(event){
  let e = event.event
  let start_time = moment(e.start_time).tz(e.timezone)
  var booking = {duration: e.hours_needed, people: e.num_people, date: start_time, start_time , zipcode: e.zipcode, timezone: e.timezone}
  let end_time = moment(start_time).add(booking.duration, "hours").tz(e.timezone)
  let total = getBookedPrice(event, booking)
  var coupon = e.coupon
  let tax = getBookedTax(event, booking, e.tax, coupon, total)
  var fee = (event.venue ? 500: 0)
  var findVenue = !!event.venue
  var coupon_value = getCouponValue(coupon, total, cartify(event.package, event.components, booking.people), booking)
  let pretax = finalTotal(total, coupon_value, fee, e.credited)
  tax = Math.round(tax * 100)/100
  return {pretax, tax}
}


function getLateFee(booked_at, start){
  var diff = moment(start).diff(moment(booked_at), 'days')
  switch(diff){
    case 6:
      return 0
    case 5:
      return 0
    case 4:
      return .05
    case 3:
      return .15
    case 2:
      return .3
    case 1:
      return .4
    case 0:
      return .8
    default:
      return 0
  }

  // switch(diff){
  //   case 10:
  //     return .05
  //   case 9:
  //     return .10
  //   case 8:
  //     return .10
  //   case 7:
  //     return .10
  //   case 6:
  //     return .15
  //   case 5:
  //     return .15
  //   case 4:
  //     return .20
  //   case 3:
  //     return .25
  //   case 2:
  //     return .5
  //   case 1:
  //     return .50
  //   case 0:
  //     return 1
  //   default:
  //     return 0
  // }

  // switch(diff){
  //   case 10:
  //     return .05
  //   case 9:
  //     return .05
  //   case 8:
  //     return .1
  //   case 7:
  //     return .1
  //   case 6:
  //     return .1
  //   case 5:
  //     return .25
  //   case 4:
  //     return .30
  //   case 3:
  //     return .35
  //   case 2:
  //     return .50
  //   case 1:
  //     return 1
  //   case 0:
  //     return 1
  //   default:
  //     return 0
  // }
}

module.exports = {
  getPackagePrice,
  getTotal,
  getComponentPrice,
  getQty,
  finalTotal,
  getCouponValue,
  getTax,
  getStartTime,
  getLateFee,
  getServicePrice,
  getEarliestStart,
  getLatestEnd,
  getPriceForMultipleOccurrences,
  getTotalEventPrice,
  getBookedPrice,
  getBookedTax,
  cartify
}
