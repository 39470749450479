import React from 'react';
import { Link } from 'react-router-dom'
import Card from './Card'
import Header from './Header/SimpleHeader'
import HeartLogo from '../img/1upLogoHeart.svg'
import Footer from "./Utils/BigFooter"
import '../css/StaticPages/NotFound.css'

const NotFound = () => {

  return (
    <div>
    <Header />
    <Card className="not-found">
      <div className="content">
        <div className="title">404 baaahhhhd request.</div>
        <div className="text">

          <p>We recently launched a totally new website and some older links may not work. You may be looking for our event packages:</p>

          <p><Link to="/events/packages">View all of our event packages.</Link></p>

          <p>If you are looking for Power Ups, they can be found <Link to="/events/food"><b>here</b></Link>, and you can still mix and match items to build your event.</p>

          <p><Link to="/builder" >See all our offerings.</Link></p>

          <p>Thanks so much!</p>
        </div>
        <img src={HeartLogo} alt=""/>
      </div>
      <div className="image-wrapper card">
        <img src="https://d2c831se9gn8j1.cloudfront.net/powerups/goats01.jpg" className="image" alt="Goat"/>
      </div>
    </Card>
    <Footer />
    </div>
  )

}

export default NotFound
