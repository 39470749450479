import React, {useState} from "react";
import moment from 'moment-timezone';
import { UserRound } from "lucide-react";
import BookingModal from "../Utils/BookingModal";

const EventDetailsBar = ({ eventDetails , 
    setShowBookingModal, onHide, large, customClasses, callToAction, confirm, dismiss}) => {

    const [show, setShow] = useState(false)

    const onClick = () => {
        if(setShowBookingModal) {
            setShowBookingModal(true)
        } else{
            setShow(true)
        }
    }

    let hasMinutes =  moment(eventDetails.start_time).minutes()
    let timeString = hasMinutes ? moment(eventDetails.start_time).format("h:mma") : moment(eventDetails.start_time).format("ha")
    return (
        <div className="flex-1 w-4/5 lg:hidden">
        <button 
          onClick={() => onClick()}
          className={"w-full flex items-center rounded-full " + customClasses}
        >
          <div className={"flex w-full items-center space-x-1 px-4 rounded-full bg-white border shadow-sm hover:shadow-md transition-shadow " + (large ? " py-2" : " py-1.5") }>
            <div className="flex-1 flex items-center min-w-0">
              <div className="flex-1 min-w-0 border-r pr-3">
                <div className="text-xs font-medium text-gray-800">Date & Time</div>
                <div className="text-sm truncate text-gray-500">
                  {!!eventDetails.date && !!eventDetails.start_time 
                    ? `${moment(eventDetails.date).format('M/D')} · ${timeString} · ${eventDetails.duration}${eventDetails.duration === 1 ? "hr": "hrs"}`
                    : 'Add date & time'}
                </div>
              </div>
              <div className="flex-1 min-w-0 pl-3">
                <div className="text-xs font-medium text-gray-800">Guests & Location</div>
                <div className="text-sm truncate text-gray-500">
                  {eventDetails.people && eventDetails.zipcode 
                    ? 
                    <div>
                        <div className='inline'>{eventDetails.people}</div>
                        <UserRound className='h-3 w-3 ml-0.5 mb-0.5 inline' />
                        {eventDetails.location === "find" ? 
                          <div className='inline'>{` · Find Venue`}</div>
                        : <div className='inline'>{` · ${eventDetails.zipcode}`}</div>}
                    </div>
                    : 'Add guests & location'}
                    {/* <img src={People} className="icon" alt="people" /> */}
                    

                </div>

              </div>
            </div>
          </div>
        </button>
        <BookingModal callToAction={callToAction} show={show} onHide={() => onHide ? onHide() : setShow(false)} confirm={confirm || onHide} dismiss={dismiss || onHide || (() => setShow(false))} />
      </div>
    );
  };

  export default EventDetailsBar