import React from 'react'
import Checkmark from '../../img/CheckmarkCheck.svg'
import _ from 'underscore'

export function getCurrentRegion(locations, regions){
  var expanded = []
  for (var r of regions){
    for(var s of r.sub_regions){
      for(var l of s.locations){
        if(locations.includes(l.id)){
          expanded.push(String(r.id + "r"))
          expanded.push(String(s.id + "sub"))
        }
      }
    }

  }
  return expanded
}

export function getCurrentRegionWithZipcode(zip, regions){
  var expanded = []
  for (var r of regions){
    for(var s of r.sub_regions){
      for(var l of s.locations){
        if(l.zipcodes.includes(zip)){
          expanded.push(String(r.id + "r"))
          expanded.push(String(s.id + "sub"))
        }
      }
    }
  }
  return expanded
}

export function regionsToNodes(regions){
  const nodes = []
  for(var r of regions){
    var n = {value: String(r.id) + "r", label: r.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
    for(var s of r.sub_regions){
      var p = {value: String(s.id) + "sub", label: s.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
      for (var l of s.locations){
        p.children.push({value: String(l.id), label: l.name, icon: <img src={Checkmark} className="checkmark" />})
      }
      p.children = _(p.children).sortBy("label")
      n.children.push(p)
    }
    nodes.push(n)
  }
  return nodes
}


export function getLocationNames(regions, ids){
  const nodes = []
  for(var r of regions){
    for(var s of r.children){
      for (var l of s.children){
        if(ids.includes(l.value)) nodes.push(l.label)
      }
    }
  }
  return nodes
}
