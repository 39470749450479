var cartID = 0
export const addToCart = (component, category) => {
  return {
    type: 'ADD_TO_CART',
    id: cartID++,
    component,
    category
  }
}

export const addMultipleToCart = (components) => ({ type: 'ADD_MULTIPLE_CART_ITEMS', components })
export const deleteCartItem = (id, category) => ({ type: 'DELETE_CART_ITEM', id, category })
export const deleteMultipleCartItems = (ids) => ({ type: 'DELETE_MULTIPLE_CART_ITEMS', ids })
export const editCartItem = (id, category, field, value ) => ({ type: 'EDIT_CART_ITEM', id, category, field, value})
export const replaceCartItem = (id, category, component) => ({ type: 'REPLACE_CART_ITEM', id, category, component})
export const clearCart = () => ({type: 'CLEAR_CART'})
export const setLogin = (loggedIn) => ({type: 'LOGIN', loggedIn})
export const logOut = () => ({type: 'LOGOUT'})
export const setEvents = (events) => ({type: 'SET_EVENTS', events})
export const toggleCart = () => ({type: 'TOGGLE_CART'})
export const openCart = () => ({type: 'OPEN_CART'})
export const closeCart = () => ({type: 'CLOSE_CART'})
export const assignMissing = (value) => ({type: 'ASSIGN_MISSING', value})
export const changeParams = (field, value) => ({type: "CHANGE", field, value})
export const clearParams = () => ({type: "CLEAR"})
export const setCart = (cart) => ({type: "SET_CART", cart})
export const setSession = (session) => ({type: "SET_SESSION", session})
export const assignWishlist = (value) => ({type: 'ASSIGN_WISHLIST', value})
export const addComponentToWishlist = (value, index) => ({type: 'ADD_COMPONENT_TO_WISHLIST', value, index})
export const addPackageToWishlist = (value, index) => ({type: 'ADD_PACKAGE_TO_WISHLIST', value, index})
export const removeComponentFromWishlist = (value, index) => ({type: 'REMOVE_COMPONENT_FROM_WISHLIST', value, index})
export const removePackageFromWishlist = (value, index) => ({type: 'REMOVE_PACKAGE_FROM_WISHLIST', value, index})
export const addWishlist = (value) => ({type: 'ADD_WISHLIST', value})
export const deleteWishlist = (list_id) => ({type: 'DELETE_WISHLIST', list_id})
export const renameWishlist = (name, list_id) => ({type: 'RENAME_WISHLIST', name, list_id})
export const setAllComponents = (components) => ({type: 'SET_ALL_COMPONENTS', components})
export const setAllPackages = (packages) => ({type: 'SET_ALL_PACKAGES', packages})
export const setLoading = (isLoading) => ({type: 'SET_LOADING', isLoading})
