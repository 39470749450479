import React, {Component} from 'react'
import CheckboxControl from '../Utils/CheckboxControl'
import ValueSelector from '../Utils/ValueSelector'

const CalendarSelector = ({calendars, selected, onSelect}) => {
  console.log(calendars, selected)
  return (
      <div>
        {
          calendars.map( (c, i) =>
            <div key={i} style={{marginBottom: 8}}>
              <CheckboxControl checked={selected.map(s => s.id).includes(c.id)} onChange={() => onSelect(c)}/>
              <label onClick={() => onSelect(c)}>{c.title}</label>
            </div>
          )
        }
      </div>
  )

}

export default CalendarSelector
