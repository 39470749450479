import React from 'react'

class Earnings extends React.Component{


  render(){
    return (
      <div> TODO </div>
    )
  }
}

export default Earnings
