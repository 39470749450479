import React, {useState} from "react";
import { Link } from "react-router-dom";
import { EllipsisVertical, ShoppingCart, UserRound } from "lucide-react";
import OneUpLogo from "../../img/1upLarge.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions";
import request from "../../request";

function MobileHeader(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);


    const handleNavigation = (path) => {
        console.log(`Navigating to: ${path}`);
        props.history.push(path)
      };
    
    const signOut = () => {
        props.actions.logOut()
        request('/api/signout/', {method: "POST"})
        setIsAccountMenuOpen(false)
      }

    const getCartCount = () => {
        var cart_count = 0;
        for (var key in props.cart){
            cart_count += props.cart[key].length
        }
        return cart_count
    }

    return (
        <header className="sticky top-0 z-50 bg-white shadow-sm lg:hidden">
        <div className="px-4 py-3 flex items-center justify-between">
          <button onClick={() => handleNavigation('/')}><img src={OneUpLogo} alt="1up Events" className="h-8 mt-1.5" /></button>

          <div className='flex'>
            {props.login.loggedIn ? (
              <button 
                onClick={() => {if(isMenuOpen) setIsMenuOpen(false); setIsAccountMenuOpen(!isAccountMenuOpen);}}
                className="p-2 rounded-full hover:bg-highlight-color/25"
              >
              <UserRound className="h-6 w-6 text-strong-content-color" />
            </button>
            ) : (
              <Link 
              to="/signin"
              className="p-2 rounded-full hover:bg-highlight-color/25"
            >
              <UserRound className="h-6 w-6 text-strong-content-color" />
            </Link>
            )
            }

            <Link 
              to="/events/food/all"
              className="p-2 rounded-full hover:bg-highlight-color/25 relative"
            >
              <ShoppingCart className="h-6 w-6 text-strong-content-color" />
              <span className={"absolute -top-[1px] -right-[1px] bg-highlight-color text-white text-xxs rounded-full h-4 w-4 flex items-center justify-center " + (getCartCount() === 0 ? "hidden" : '')} >
                {getCartCount()}
              </span>

            </Link>

            <button 
              onClick={() => {if(isAccountMenuOpen) setIsAccountMenuOpen(false); setIsMenuOpen(!isMenuOpen);}}
              className="p-2 rounded-full hover:bg-highlight-color/25"
            >
              <EllipsisVertical className="h-6 w-6 text-strong-content-color" />
            </button>
          </div>
          
        </div>
        
        {isMenuOpen && (
          <nav className="py-2 bg-white shadow-lg rounded-b-xl items-right">
            <button 
              onClick={() => handleNavigation('/contact-us')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Contact Us
            </button>
            <button 
              onClick={() => handleNavigation('/how-it-works')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              How It Works
            </button>
            <button 
              onClick={() => handleNavigation('/faq')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              FAQs
            </button>
            <button 
              onClick={() => handleNavigation('/about-us')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              About Us
            </button>
            <div className='h-[1px] w-full bg-gray-200 my-1' />
            <button 
              onClick={() => handleNavigation('/event-host')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Be an Event Host
            </button>
            <button 
              onClick={() => handleNavigation('/vendor')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Become a Vendor
            </button>
          </nav>
        )}

        {isAccountMenuOpen && (
          <nav className="py-2 bg-white shadow-lg rounded-b-xl">
            {props.login.admin ? 
                      <button 
                        onClick={() => handleNavigation('/admin_panel')}
                        className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
                      >
                        Admin Dashboard
                      </button>
                      : null
            }
            {props.login.vendor_id ?
                        <button 
                        onClick={() => handleNavigation('/vendors/dashboard')}
                        className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
                      >
                        Vendor Dashboard
                      </button>
                      : null
            }

            <button 
              onClick={() => handleNavigation('/account')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Account
            </button>
            <button 
              onClick={() => handleNavigation('/your-events')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              My Events
            </button>
            <button 
              onClick={() => handleNavigation('/wishlists')}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Wishlists
            </button>
            <div className='h-[1px] w-full bg-gray-200 my-1' />
            <button 
              onClick={() => signOut()}
              className="px-4 block w-full text-left py-3 text-gray-600 hover:text-green-600 font-medium"
            >
              Sign out
            </button>
          </nav>
        )}    
      </header>
    )
}


const mapStateToProps = state => ({
    ...state
  })
  
const mapDispatchToProps = dispatch => ({
      actions: bindActionCreators(cartActions, dispatch)
  })
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(MobileHeader))

  