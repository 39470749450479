import React, {useEffect} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import BookingDetails from '../Header/BookingDetails'
import './Reschedule.css'
import { ChevronLeft } from 'lucide-react'

const neutralizeBack = (callback) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    callback();
  };
};

const revivalBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};

function BookingModal(props) {

  const callToAction = props.callToAction || "Search";

  useEffect(() => {
    if(props.show) {
      neutralizeBack(dismissModal);
    }
  }, [props.show])

  const dismissModal = () => {
    revivalBack();
    props.dismiss ? props.dismiss() : props.onHide();
  }

  const confirmModoal = () => {
    if(props.confirm) {
      revivalBack();
      setTimeout( () => props.confirm(), 5);
    } else {
      revivalBack();
      props.onHide();
    }
  }

  return (
  <>
    <Modal show={props.show} zIndex={props.zIndex || 9050} customClassName="generic-modal new eventDetailsModal p-0 booking-modal bg-background-color overflow-hidden w-full min-h-full h-full sm:min-h-fit sm:w-[560px] sm:h-fit sm:min-h-fit m-0 sm:mx-auto sm:mt-[10vh] sm:min-w-[400px] sm:w-[60%] max-w-[640px]" backdropClassName={"customize-backdrop" + (props.backdropClassName || "")} onHide={dismissModal}>
      <div className='overflow-scroll max-h-full'>
        <div className='h-fit p-[20px]' >
          <div className='flex justify-center items-end'>
            <ChevronLeft className='cursor-pointer w-10 h-10 text-primary-content-color absolute left-4' onClick={dismissModal} />
            <div className='mt-1 flex text-[28px] opacity-[0.5]'>        
              Event Details
          </div>
          </div>
          <div style={{display: "block", width: "100%"}}>
            <BookingDetails values={props.customValues || props.values} changeBooking={props.changeDetails || props.changeBooking} />
          </div>
          <div className='h-20 sm:h-16'/>
        </div>
        <div className={"custom-button hidden sm:flex h-12 w-fit flex absolute bottom-3 right-3 justify-center items-center text-lg px-10"} onClick={confirmModoal}>{callToAction}</div>
      </div>
      {props.show ?
          <div className="shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] sm:hidden p-3 fixed w-full bottom-0 bg-white z-10">
            <div className='flex justify-center rounded items-center h-[40px] cursor-pointer w-full bg-primary-action-color text-white text-lg font-semibold' onClick={confirmModoal}>{callToAction}</div>
            {/* <div className={"custom-button flex h-14 mt-0 w-full flex justify-center items-center text-[24px]"} onClick={props.onHide}>Search</div> */}
            {/* <div className={"custom-button flex mr-4 mb-3 h-12 w-28 flex justify-center items-center text-lg"} onClick={props.onHide}>Search</div> */}
        </div> :
        null }
    </Modal>
  </>
  )
}


const mapStateToProps = state => ({
  values: state.booking, app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
  changeBooking: bindActionCreators(cartActions, dispatch).changeParams, actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingModal))
