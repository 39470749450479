import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import '../../css/Checkout.css'
import './Account.css'
import {CardElement, StripeProvider, Elements, injectStripe} from 'react-stripe-elements'
import Title from '../Title'
import currencyFormatter from 'currency-formatter'
import request from '../../request'
import { BeatLoader } from 'react-spinners';
import Amex from '../../img/creditcards/amex.png'
import Visa from '../../img/creditcards/visa.png'
import Master from '../../img/creditcards/master.png'
import JCB from '../../img/creditcards/jcb.png'
import Diners from '../../img/creditcards/diners.png'
import Discover from '../../img/creditcards/discover.png'
import Credit from '../../img/creditcards/credit.png'
import Warning from '../../img/WarningIcon.svg'
import Static from '../StaticPages/StaticWrapper'
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import ScrollableAnchor from 'react-scrollable-anchor'

var stripe_key = "pk_live_4IAFeRo1czDhMwEzjo2iH1We";

if (  window.location.hostname.includes("1upevents")){
  console.log("production")
}else{
  stripe_key = "pk_test_UXa8gJrBB1hXxScBLHVyTGSm"
  console.log(window.location.hostname, "pk_test_UXa8gJrBB1hXxScBLHVyTGSm")
}
const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = change => {
  console.log('[change]', change);
};
// const handleClick = () => {
//   console.log('[click]');
// };
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, Menlo, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class CardForm extends Component {


  render() {
    return (
        <CardElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(this.props.fontSize)}
        />
    );
  }
}

class _Checkout extends Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      new_name: "",
      email: "",
      new_email: "",
      company_name: "",
      new_company_name: "",
      phone: "",
      new_phone: "",
      card_details: null,
      on_file: false,
      change_creditcard: false,
      loading: false,
      error: "",
      account_email: this.props.login.email,
      account_email_verify: "",
      change_account_email: false,
      profile_error: "",
      credit: 0,
      referral_count: 0,
      referral_code: "",
      copied: false
    }
  }

  async componentDidMount(){
    var contact_info = await request('/api/contactInfo')
    if (!contact_info || contact_info.err){
      console.log(contact_info)
    }else{
      this.setState({name: contact_info.contact_name || "", phone: contact_info.phone || "", email: contact_info.contact_email || "", company_name: contact_info.company_name || "", account_email: contact_info.email, credit: contact_info.credit, referral_count: contact_info.referral_count, referral_code: contact_info.referral_code || ""})
      console.log(contact_info)
    }
    var stripe = await request('/api/stripe_id')
    if(stripe && !stripe.err){

      this.setState({card_details: stripe, on_file: true})
    }else{
      console.log(stripe)
    }

  }

  async addCard(payload){
    if(payload){
      var stripe = await request('/api/add_customer', {method: "POST", body: JSON.stringify({contactname: this.state.name, email: this.state.email, stripeToken: payload.token.id})})
      if(stripe && !stripe.err){
        stripe = await request('/api/stripe_id')
        if(stripe && !stripe.err){
          this.setState({card_details: stripe, on_file: true, change_creditcard: false, loading: false})
        }else{
          this.setState({error: stripe.err, loading: false})
        }
      }else{
        if(stripe) this.setState({error: stripe.err, loading: false})
        if(!stripe) this.setState({error: "Could not reach server.", loading: false})
      }
    }
  }

  async updateCard(payload){
    if(payload){
      var stripe = await request('/api/update_customer/', {method: "POST", body: JSON.stringify({contactname: this.state.name, email: this.state.email, stripeToken: payload.token.id})})
      if(stripe && !stripe.err){
        stripe = await request('/api/stripe_id')
        if(stripe && !stripe.err){
          this.setState({card_details: stripe, on_file: true, change_creditcard: false, loading: false})
        }else{
          this.setState({error: stripe.err, loading: false})
        }
      }else{
        if(stripe) this.setState({error: stripe.err, loading: false})
        if(!stripe) this.setState({error: "Could not reach server.", loading: false})
      }
    }
  }


  async updateProfile(payload){
    if(this.state.new_name || this.state.new_name || this.state.new_phone || this.state.new_company_name){
      var contact = {}
      contact.contact_name = this.state.new_name || this.state.name
      contact.contact_email = this.state.new_email || this.state.email
      contact.email = this.state.email
      contact.phone = this.state.new_phone || this.state.phone
      contact.company_name = this.state.new_company_name || this.state.company_name
      var contact_info = await request('/api/contactInfo', {method: "PUT", body: JSON.stringify({contact_info: contact})})
      if(contact_info && !contact_info.err){
        this.setState({name: contact.contact_name, email:contact.contact_email, phone: contact.phone, company_name: contact.company_name, change_contact_info: false})
      }else{
        this.setState({profile_error: "Could not save information. Try again."})
      }
    }
  }

  handleSubmit = ev => {
    ev.preventDefault();
    this.setState({loading: true})
    this.props.stripe.createToken().then(payload => {
      if(payload.error){
        this.setState({error: payload.error.message, loading: false})
      }else{
        if(this.state.on_file){
          this.updateCard(payload)
        }else{
          this.addCard(payload)
        }
      }
    }).catch(err => {
      console.log("Caught err", err)
      this.setState({loading: false})
    })


  };

  async changeEmail(){
    var account = await request('/api/changeEmail', {method: "PUT", body: JSON.stringify({email: this.state.account_email})})
    console.log(account)
    if(!account.err){
      this.setState({change_account_email: false})
      this.props.actions.setLogin({loggedIn: true, admin: this.props.login.admin, email: this.state.account_email, wishlists: this.props.login.wishlists, vendor_id: this.props.login.vendor_id, name: this.props.login.name})
    }
  }


  getCard(){
    switch(this.state.card_details.brand.toLowerCase()){
      case "american express":
        return Amex
      case "visa":
        return Visa
      case "discover":
        return Discover
      case "mastercard":
        return Master
      case "jcb":
        return JCB
      case "diners club":
        return Diners
      default:
        return Credit
    }
  }

  getExpirey(){
    var month = this.state.card_details.exp_month.toString()
    if (month.length < 2) month = "0"+ month

    return month + "/" + this.state.card_details.exp_year.toString().slice(2)
  }

  copy(){
    var copyText = document.getElementById("ref_code");
    copyText.select();
    document.execCommand("Copy");
    this.setState({copied: true})
  }

  render(){
    return (
      <Static className="wide">
        <div className="static-page-header">Account</div>
        <div className="static-page-subheader">View upcoming events, modify your account info, and more.</div>
        <div className="section-wrapper">
        <div className="checkout-container account-page">
          <div className="checkout-details">
            <Title title={"Your Events"} subtitle={""} />
            <div className="card section" style={{overflow:"hidden"}}>
              <div className="your-events">
                <Link to="/your-events" className="secondary-button">View Your Events </Link>
              </div>
            </div>
            <Title title={"Your Wish Lists"} subtitle={""} />
            <div className="card section" style={{overflow:"hidden"}}>
              <div className="your-events">
                <Link to="/wishlists" className="secondary-button">View Your Wish Lists </Link>
              </div>
            </div>
            <Title title={"Contact Information"} subtitle={""} />
            <div className="card section" style={{overflow:"hidden"}}>
              <div className="contact">
                {this.state.change_contact_info ?
                  <div >
                    <div style={{width: 300}}>
                      <input className="text-field" type="text" value={this.state.new_name} onChange={(e) => this.setState({new_name: e.target.value})} style={{width: 266, marginTop: 10}} placeholder="Contact Name" />
                      <input className="text-field" type="text" value={this.state.new_company_name} onChange={(e) => this.setState({new_company_name: e.target.value})} placeholder="Company Name" style={{width: 266, marginTop: 10}} />
                      <input className="text-field" type="email" value={this.state.new_email} onChange={(e) => this.setState({new_email: e.target.value})} style={{width: 266, marginTop: 10}} placeholder="Email Address"/>
                      <input className="text-field" type="tel" value={this.state.new_phone} onChange={(e) => this.setState({new_phone: e.target.value})} placeholder="Phone Number" style={{width: 266, marginTop: 10}} />
                    </div>
                    {this.state.profile_error ? <div className="error-state"><img src={Warning} alt=""/><span className="text"> {this.state.profile_error}</span></div> : null }
                    <div className="secondary-button" onClick={()=> this.setState({change_contact_info: false, profile_error: ""})}>Cancel</div>
                    <div className={"custom-button" + (this.state.disabled ? " disabled" : "")} onClick={()=> this.updateProfile()}>Save Changes</div>
                  </div>
                  :
                  <div>
                    <div>{this.state.name}</div>
                    {this.state.company_name ? <div>{this.state.company_name}</div> : null}
                    <div>{this.state.email || this.state.account_email || ""}</div>
                    {this.state.phone ? <div>{this.state.phone}</div> : null}
                    <div onClick={()=> this.setState({change_contact_info: true, new_name: this.state.name, new_email: this.state.email, new_phone: this.state.phone, new_company_name: this.state.company_name})} className="change">Change Profile</div>

                  </div>
                }
              </div>
            </div>
            {/* <ScrollableAnchor id={'payment'}> */}
              <div>
                <Title title={"Payment"}  />
              </div>
            {/* </ScrollableAnchor> */}
              <div className="card section">
                <form onSubmit={(ev) => this.handleSubmit(ev)}>
                  <div className="questions">Credit card information:</div>
                  {this.state.on_file && !this.state.change_creditcard ? 
                    <div className="text-field credit-card flex gap-x-4 justify-between items-center py-1"> 
                    <div className='flex items-center'>
                      <div className="brand mr-0 inline-block">
                        <img src={this.getCard()} className="little-card mr-0 mt-0" alt={this.state.card_details.brand}/>
                      </div>
                      <div className="ending text pl-2 ml-0">{"ending in " + this.state.card_details.last4}</div>
                    </div>
                    <div className="text ml-0 max-w-[220px] text-ellipsis text-nowrap overflow-hidden">{this.state.name}</div>
                    <div className="text ml-0">Exp: {this.getExpirey()}</div>
                    <span className="change" onClick={()=> this.setState({change_creditcard: true})}>Change</span>
                  </div> 
                  : 
                    <div>
                      <CardForm fontSize={18} />
                      {this.state.error ? <div className="error-state"><img className="inline" src={Warning} alt=""/><span className="text"> {this.state.error}</span></div>: null }
                      <div className="secondary-button" onClick={()=> this.setState({change_creditcard: false,  error: ""})}>Cancel</div>
                      <button className={"custom-button" + (this.state.loading ? " disabled" : "") }>
                        Update Card
                        {this.state.loading ?
                          <div className="loader-wrapper">
                            <div className="loader">
                              <BeatLoader
                                color={'#FBB200'}
                                size={12}
                                loading={this.state.loading}
                              />
                            </div>
                          </div> : null
                        }
                        </button>
                    </div>
                  }
                </form>
              </div>
            <Title title={"Account"} subtitle={""} />
            <div className="card section" style={{overflow:"hidden"}}>
              <div className="contact">
                {this.state.change_account_email ?
                  <div >
                    <div style={{width: 300}}>
                      <input className="text-field" type="email" value={this.state.account_email} onChange={(e) => this.setState({account_email: e.target.value})} style={{width: 266, marginTop: 10}} placeholder="Email Address"/>
                      <input className="text-field" type="email" value={this.state.account_email_verify} onChange={(e) => this.setState({account_email_verify: e.target.value})} style={{width: 266, marginTop: 10}} placeholder="Email Address"/>
                    </div>
                    <div className="secondary-button" onClick={()=> this.setState({change_account_email: false})}>Cancel</div>
                    <div className={"custom-button" + (this.state.disabled ? " disabled" : "")} onClick={()=> this.changeEmail()}>Save Changes</div>
                  </div>
                  :
                  <div>
                    <div>{this.state.account_email}</div>
                    <div onClick={()=> this.setState({change_account_email: true})} className="change">Change Email</div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="referral">
            <Title title="Credit & Referrals" subtitle=""/>
            <div className="card">
              <div className="subheader">1up Credit</div>
              <div className="credit">{currencyFormatter.format(this.state.credit, { code: 'USD', precision: 0} )}</div>
              <div className="divider"/>
              <div className="subheader ref">Your Referral Link</div>
              <CopyToClipboard text={"1upevents.com/ref/" + this.state.referral_code} onCopy={() => this.setState({copied: true})}>
                <div className="ref-wrapper">
                  {!this.state.copied ?
                      <span className="copy">Copy</span>
                    : <span className="copy">Copied</span>
                   }
                  <div className="text-field" style={{marginTop: 8, marginBottom: 8}}>1upevents.com/ref/{this.state.referral_code} </div>
                </div>
              </CopyToClipboard>
              <div className="count">{this.state.referral_count > 0 ? "You’ve referred "+this.state.referral_count+ " " + (Number(this.state.referral_count) === 1 ? "person" : "people") + " so far!" : "You’ve referred 0 people so far. Hurry up and invite your friends so you can both earn money!"}</div>
              <div className="divider"/>
              <div className="subheader">Give $100 Earn $100</div>
              <div className="text">Share your referral link with others and have them sign up using it. Once they book you’ll get $100 in 1up credit!</div>
            </div>
          </div>
        </div>
        </div>
      </Static>
    )
  }

}



const Checkout = injectStripe(_Checkout);


class CheckoutWrapper extends Component{
  render(){
    return (
      <StripeProvider apiKey={stripe_key}>
        <Elements >
          <Checkout {...this.props} />
        </Elements>
      </StripeProvider>
    )
  }
}

const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutWrapper))
