import React from 'react'
import {withRouter, Redirect, Link} from 'react-router-dom'
import request from '../../request'
import Static from '../StaticPages/StaticWrapper'

class ServiceResponse extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      past_deadline: false,
      error: false,
      accepted: false,
      declined: false,
      event_id: ""
    }
  }

  async componentDidMount(){
    console.log(this.props)
    if(this.props.accept){
      var res = await request('/api/vendor/accept/' + this.props.match.params.token, {method: "PUT"})
      console.log(res)
      if(res.err === "too_late"){
        this.setState({past_deadline: true})
      }else if(res.err){
        this.setState({error: true})
      }else{
        this.setState({accepted: true, event_id: res.event_id})
      }
    }

    if(this.props.decline){
      await request('/api/vendor/decline/' + this.props.match.params.token, {method: "PUT"})
      this.props.history.push("/vendors/dashboard/")
      if(res.err === "too_late"){
        this.setState({declined: true})
      }else if(res.err){
        this.setState({error: true})
      }else{
        this.setState({declined: true})
      }
    }
  }

  render(){
    if(this.state.past_deadline) return (
      <Static>
        <div className="card check-email">
          <div className="title">{"Too late to respond."}</div>
          <div style={{width: 600}} className="text">{"Thanks so much for letting us know that you are available for this event, but we're afraid you have responded past the deadline for this event. There is a strict response window to ensure the optimal customer experience."}</div>
          <div style={{width: 600}} className="text">{"We are currently contacting other vendors at this time, but will loop back around to you if no other vendors are available."}</div>
        </div>
      </Static>
    )

    if(this.state.accepted) return (
      <Static>
        <div className="card check-email">
          <div className="title">{"Thank you for accepting!"}</div>
          <div style={{width: 600}} className="text">{"You are confirmed for the event! Thanks so much, we can't wait! Click below to see all the details."}</div>
          <div>
            <Link to={"/vendors/event/" + this.state.event_id} className="custom-button" style={{float: 'none', marginBottom: 36, width: 221}}>Go to Event</Link>
          </div>
        </div>
      </Static>
    )

    if(this.state.declined) return (
      <Static>
        <div className="card check-email">
          <div className="title">{"Event Declined"}</div>
          <div style={{width: 600}} className="text">{"Thank you for letting us know you're unable to servivce this event. You can accept or decline any other requests in your dashboard."}</div>
          <div>
            <Link to={"/vendors/dashboard"} className="custom-button" style={{float: 'none', marginBottom: 36, width: 221}}>Go to Dashboard</Link>
          </div>
        </div>
      </Static>
    )

    if(this.state.error) return (
      <Static>
        <div className="card check-email">
          <div className="title">{"Encounter an error."}</div>
          <div style={{width: 600}} className="text">{"We're so sorry, but we seem to have encountered an error. Please contact us."}</div>
        </div>
      </Static>
    )
    return null
  }
}

export default withRouter(ServiceResponse)
