import React from 'react'
import SearchIcon from '../img/SearchFieldIcon.svg'
import Clear from '../img/ClearTextButton.svg'

import '../css/Search.css'

function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

class Search extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      value: this.props.value || "",
      timeout: null
    }
  }

  update(event, updateSearch){

    if (this.state.timeout) clearTimeout(this.state.timeout);
    let value = event.target.value
    this.setState({
       value,
       timeout: setTimeout(function () {
           updateSearch({target: {value}})
         }, 400)
    });
  }

  render(){
    var {value, updateSearch, category} = this.props
    return (
      <div className="search-box">
        <img style={{position: "absolute", left: 9, top: 7}} src={SearchIcon} alt=""/>
        <input type="search" className="text-field" style={{position: "relative"}} value={this.state.value} onChange={(e) => this.update(e, updateSearch)} placeholder={"Search " + capitalize(category)} />
        {this.state.value ? <img style={{position: "absolute", right: 9, top: 5, cursor: "pointer"}} src={Clear} alt="" onClick={() => {updateSearch({target: {value: ""}}); this.setState({value: ""}) }} /> : null}
      </div>
    )
  }


}

export default Search
