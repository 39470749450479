import React from 'react'
import moment from 'moment-timezone'
import currencyFormatter from 'currency-formatter'
import './Request.css'
import { getServicePrice } from '../Utils/math'


function getDetails(data){
  switch(data.unit){
    case "person":
      return (
        <div className="sec">
          <div ><b>{data.qty}</b> people</div>
        </div>
      )
    case "hour":
      return (
        <div className="sec">
          <div ><b>{data.qty}</b> hours</div>
          {data.num_staff  && Number(data.num_staff) > 1 && !data.individual ? <div className="sec"><b>{data.num_staff}</b> staff</div> : null}
        </div>
      )
    default:
      if(Number(data.qty) > 1){
        return (
          <div className="sec">
            <div ><b>{data.qty}</b> kits</div>
          </div>
        )
      }
  }
}


const Request = ({data, accept, decline, key, length, vendor_timezone}) => {
  var diff = moment(data.deadline) - moment()
  var duration = moment.duration(diff);
  var time_remaining = ""
  if(duration.days() > 0) time_remaining = duration.days() + "d "
  time_remaining += duration.hours() + "h " + duration.minutes() + "m"
  console.log(data, data.images)
  data.booked_at = moment()
  var image = ""
  if(data.images) image = "https://d2c831se9gn8j1.cloudfront.net/"  + data.images[0]
  let timezone = data.remote ? vendor_timezone : data.timezone
  return (
    <div className="request">
      <div className="purple-bar"/>
      <div className="overview-container">
        <div className="powerup-image-container">
          <img className="powerup-image" src={image} alt={data.name}/>
        </div>
        <div className="name-date">
          <div className="name">{data.name}</div>
          <div className="date"><b>{moment(data.start_time).tz(timezone).format("ddd, MMM D")}</b> from <b>{moment(data.start_time).tz(timezone).format('h:mm')}-{moment(data.end_time).tz(timezone).format('h:mma z')}</b></div>
        </div>
        <div className="custom-button vendor" onClick={() => accept()}>Accept</div>
        <div className="secondary-button vendor" onClick={() => decline()}>Decline</div>
        <div className="details">
          <div className="sec"><b>{currencyFormatter.format(getServicePrice(data, {duration: moment(data.end_time).diff(moment(data.start_time), "hours")}, data.start_time, data.timezone), { code: 'USD', precision: 0} )}</b> earnings</div>
          {getDetails(data)}
          <div className="sec"><b>{time_remaining}</b> left to respond</div>
        </div>
        <div className="details">
          <b>{data.location}</b>
        </div>
      </div>
    </div>
  )
}
// <div className="details">
//   <div className="sec"><b>{data.qty}</b> people</div>
//   {data.kits > 1 ? <div className="sec"><b>{data.kits}</b> kits</div>: null}
// </div>
export default Request
