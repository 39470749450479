import React from 'react'
import Header from '../Header/Header'
import Logo from '../../img/1upLogoHeart.svg'

const CheckEmail = () => {
  return (
    <div>
      <Header />
      <div className="card check-email">
        <div className="text"> Please verify your email by clicking the link sent to you.</div>
        <img src={Logo} className="heart-logo" alt="" />
      </div>
    </div>
  )
}

export default CheckEmail
