const initialState = {loggedIn: false, admin: false, email: "", vendor_id: null, events: []}

export default function login(state = initialState, action) {
  var clone;
  switch (action.type) {
    case 'LOGIN':
      return action.loggedIn

    case 'LOGOUT':
      return {loggedIn: false, admin: false, email: "", vendor_id: null}

    case 'SET_EVENTS':
      clone = Object.assign({}, state);
      clone.events = action.events
      return clone

    case 'ADD_COMPONENT_TO_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        clone.wishlists[action.index].components.push(action.value)
      }
      return clone

    case 'ADD_PACKAGE_TO_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        clone.wishlists[action.index].packages.push(action.value)
      }
      return clone

    case 'REMOVE_COMPONENT_FROM_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        var wishlist = clone.wishlists.find(w => Number(w.list_id) === Number(action.value.wishlist_id))
        if (wishlist){
          wishlist.components = wishlist.components.filter( p => Number(p.wishlist_component_id) !== Number(action.value.wishlist_component_id))
        }else{
          console.log("no wishlist", wishlist, action, clone.wishlists)
        }
      }
      return clone

    case 'REMOVE_PACKAGE_FROM_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        var wishlist = clone.wishlists.find(w => Number(w.list_id) === Number(action.value.wishlist_id))
        if (wishlist){
          wishlist.packages = wishlist.packages.filter( p => Number(p.wishlist_package_id) !== Number(action.value.wishlist_package_id))
        }else{
          console.log("no wishlist", wishlist, action, clone.wishlists)
        }
      }
      return clone

    case 'ADD_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        clone.wishlists.push(action.value)
      }
      return clone

    case 'DELETE_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        clone.wishlists = clone.wishlists.filter(w => Number(w.list_id) !== Number(action.list_id))
      }
      return clone

    case 'RENAME_WISHLIST':
      clone = Object.assign({}, state);
      if(clone.wishlists){
        var wishlist = clone.wishlists.find(w => Number(w.list_id) === Number(action.list_id))
        wishlist.name = action.name
      }
      return clone


    default:
      return state
  }


}
