"use client"
import React, { useEffect, useState, useLayoutEffect } from 'react';
// import { useRouter } from 'next/navigation'
import { Menu, ShoppingCart, UserRound, X, ChevronLeft, Search, ArrowDown01, ArrowDownAZ, CalendarArrowDown, ArrowDownWideNarrow } from 'lucide-react';
// import { Dialog, DialogContent } from "@/components/ui/dialog"
// import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet"
import People from '../../img/BookBarPeople.svg'
import * as cartActions from '../../actions/';
import { Link, Route, Switch } from 'react-router-dom';
import ServiceItem from './ServiceItem';
import RegularPackage from './RegularPackage';
import ServicePage from './ServicePage';
import PackagePage from '../Packages/PackagePage';
import Sidebar from '../Sidebar';
import Title from '../Title';
import Header from '../Header/Header';
import BookBar from '../Header/BookBar';
import _ from 'underscore';
import '../../css/Utils/Button.css';
import '../../css/Packages.css';
import '../../css/Powerups.css';
import CustomizationModal from '../Utils/CustomizationModal';
import CheckmarkWhite from '../../img/CheckmarkWhite.svg';
import CheckmarkGrey from '../../img/CheckmarkGrey.svg';
import ConfirmationModal from '../Utils/ConfirmationModal';
import request from '../../request';
import { getComponentPrice, getPackagePrice, getStartTime, getLateFee } from '../Utils/math'
import Sort from '../Utils/Sort';
import moment from 'moment-timezone';
import Divider from '../../img/StepBar/DividerShadowOverlay.svg';
import RegularLeft from '../../img/StepBar/RegularLeft.svg';
import RegularRight from '../../img/StepBar/RegularRight.svg';
import Footer from '../Utils/SimpleFooter';
import Alert from '../Utils/Alert';
import BookingModal from '../Utils/BookingModal';
import currencyFormatter from 'currency-formatter';
import {Dropdown, MenuItem} from 'react-bootstrap'
import Cart from '../CartPage'
import BottomTabBar  from './BottomTabBar'
import CardLoader from './CardLoader';
import ContentLoader from "react-content-loader"
import useWindowDimensions from '../Utils/GetWindowDimensions';


const FeaturedLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={74}
    height={28}
    viewBox="0 0 74 28"
    backgroundColor="#ffffff"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="74" height="28" /> 
  </ContentLoader>
)

const mainTabs = ['food', 'drink', 'animals', 'entertainment', 'decor', 'remote', 'packages'];
const bottomBar = ['EX', 'wish', 'events', 'acc', 'cart']
const categories = ['All', 'Appetizers', 'Breakfast', 'Dessert', 'Dinner', 'Lunch', 'Snacks', 'Staffed Station'];

const noItemsMessage = "No items are available in your zipcode for this category. Please check to see if your zipcode is correct, and update it if it is not. If your zipcode is correct and we are not available in your zipcode, please sign up for our waitlist and we will let you know when we are available in your area." 
const noResultsMessage = "Nothing found for "


String.prototype.capitalize = function(){
    return this.charAt(0).toUpperCase() + this.slice(1);
  }

  // = {
  //   date: '',
  //   start_time: '',
  //   duration: 3,
  //   people: 60, 
  //   zipcode: '94107', 
  //   location: ""
  // }


  const calcPrice = (component, store) => {
    var late_fee = getLateFee(moment(), getStartTime(store.date, store.start_time, store.timezone))
    var price = getComponentPrice(component, store, null, null, null, late_fee)
    console.log("CALC PRICE", component, price, store, late_fee)
    switch(component.unit){
      case "person":
        if(store.people){
          return currencyFormatter.format(price, { code: 'USD', precision: 0})
        }
        if(component.minimum){
          return "Starting at " + currencyFormatter.format(price, { code: 'USD', precision: 0})
        }
        // eslint-disable-next-line
      case "hour":
        if(store.duration){
          return currencyFormatter.format(price, { code: 'USD', precision: 0})
        }
        if(component.minimum){
          return "Starting at " + currencyFormatter.format(price, { code: 'USD', precision: 0})
        }
        // eslint-disable-next-line
      default:
        return currencyFormatter.format(price, { code: 'USD', precision: 0})
    }
  }

const ServicesPage = (props) => {
//   const router = useRouter()
//   const pTab = React.use(params).category;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState((props.match.params.filter || "all").toLowerCase());
  const [selectedTab, setSelectedTab] = useState(props.match.params.category.toLowerCase() || "food");
  const [searchString, setSearchString] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [index, setIndex] = useState(0);
  // const [packages, setPackages] = useState([]);
  // const [allComponents, setAllComponents] = useState(null);
  const [details, setDetails] = useState(props.match.params.id || null);
  const [subCategories, setSubCategories] = useState([]);
  const [detailedComponent, setDetailedComponent] = useState(null);
  const [cart, setCart] = useState(false);
  const [customQuantity, setCustomQuantity] = useState(false);
  const [first, setFirst] = useState(!localStorage.getItem("not_first"));
  const [builder, setBuilder] = useState(true);
  const [missing, setMissing] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [sort, setSort] = useState("alphabetical");
  const [hovered, setHovered] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const {width} = useWindowDimensions();

  const [showBottomTabs, setShowBottomTabs] = useState(true);

  const [visibleServices, setVisibleServices] = useState([]);
  const [visiblePackages, setVisiblePackages] = useState([]);
  const [featuredServices, setFeaturedServices] = useState([]);
  const [featuredPackages, setFeaturedPackages] = useState([]);

  console.log(props.match.params, props.booking)

  useEffect(() => {
    console.log("props changed, filter",props.match.params )
    setSelectedCategory((props.match.params.filter || "all").toLowerCase())
  }, [props.match.params.filter])

  useEffect(() => {
    // if(!searchQuery){
      console.log("props changed, category",props.match.params )
      let cat = props.match.params.category || 'food'
      cat = mainTabs.find( (t) =>  t === cat.toLowerCase()) || 'food'
      console.log("CATEGORY FROM URL", cat)
      setSelectedTab(cat)
      if(document.getElementById(cat || "food")){
        document.getElementById(cat || "food").scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
      });
      }
    // }
  }, [props.match.params.category])

  useLayoutEffect(() => {
    if(document.getElementById(selectedCategory)) {
      document.getElementById(selectedCategory).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
    });
  }
  }, [subCategories])


  const setVisibleServicesAndPackages = (subCat) => {
    var comps = props.booking.allComponents || []
    var packs = props.booking.allPackages || []
    var sub_categories = subCategories
    let category = selectedTab
    let filter = subCat || selectedCategory
    var d = new Date();
    var month = d.getMonth();
    if(selectedTab !== "packages"){
      comps = comps.filter( c => c.categories && c.categories.includes(selectedTab))
    }
    var featured = null
    filter = isNaN(parseInt(filter)) ? filter : null
    if (searchQuery){
      if(filter  && filter !== "all") filter = "all"
      comps = search(props.booking.allComponents, searchQuery)
      packs = search(props.booking.allPackages, searchQuery)
      featured = null
    }else {
      if(!filter || (filter && filter === "all")){
        if(selectedTab === "packages"){
          featured = packs.filter(c => {
            if(c.seasonal){
              return c.seasonal.includes(month)
            }
            return false
          })
        }else{
          featured = comps.filter(c => {
            if(c.seasonal){
              return c.seasonal.includes(month)
            }
            return false
          })
        }
      }else if (sub_categories.includes(filter)){
        if(selectedTab === "packages"){
          packs = packs.filter(c => c.categories.includes(filter.replace(/\_/g,"/")))
        }else{
          comps = comps.filter(c => {
            if(c.sub_categories && c.sub_categories[selectedTab]){
              return c.sub_categories[category].includes(filter.replace(/\_/g,"/"))
            }
            return false
          })
        }
      } else{
        filter = null
      }
    }
    // console.log("featured", featured)
    if(featured && featured.length === 0 ) featured = null
    let featuredPacks = []
    if (featured){
      if(category === "packages"){
        featuredPacks = sortPackages(featured, sort)
      }else{
        featured = sortServices(featured, sort)
      }
    }
    var featured_ids = featured ? featured.map( f => f.id) : []
    var featuredpack_ids = featuredPacks ? featuredPacks.map( f => f.id) : []
    comps = comps.filter( c => !featured_ids.includes(c.id))
    packs = packs.filter( c => !featuredpack_ids.includes(c.id))
    packs = sortPackages(packs, sort)
    comps = sortServices(comps, sort)
    setVisiblePackages(packs)
    setVisibleServices(comps)
    setFeaturedServices(featured ? sortServices(featured, sort) : [])
    setFeaturedPackages(featured ? sortPackages(featuredPacks, sort) : [])
    // if(category === "packages"){
    //   page = sortPackages(page, sort)
    // }else{
    //   page = sortServices(page, sort)
    // }
    return [packs, comps]

  }

  useEffect(() => {
    if(props.booking.allComponents !== null) {
      setLoading(false)
    }
  }, [visibleServices, featuredServices])

  const getAndSetSubCategories = (packs, comps) => {
    let sub_categories = []
    if(selectedTab === "packages"){
      sub_categories = getCategories(packs)
    }else{
      comps = comps.filter( c => c.categories && c.categories.includes(selectedTab))
      sub_categories = getSubCategories(comps, selectedTab)
    }
    sub_categories.sort((a, b) => {
      if(a.toLowerCase() < b.toLowerCase()) { return -1; }
      if(a.toLowerCase() > b.toLowerCase()) { return 1; }
      return 0;}
    )
    sub_categories.unshift("all")
    console.log("SETTING SUBCATEGORIES", sub_categories)
    setSubCategories(sub_categories)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchQuery(searchString), 300);
    return () => clearTimeout(timeOutId);
  }, [searchString])

  useEffect(() => {
    const [packs, comps] = setVisibleServicesAndPackages()
    if(searchQuery) {
      setSubCategories(["all"])
    } else {
      console.log("searcQuery useeffect")
      getAndSetSubCategories(packs, comps)
    }
  }, [searchQuery])


  useEffect(() => {
    // setSelectedCategory("all")
    console.log("selectedTab useEffect", props.match.params)
    const [packs, comps] = setVisibleServicesAndPackages("all")
    getAndSetSubCategories(packs, comps)
    setSearchString(""); 
    setSearchQuery("");
    if(selectedTab) {
      if(document.getElementById(selectedTab)) {
        document.getElementById(selectedTab).scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
      });
    }
  }
  }, [selectedTab])

  useEffect(() => {
    console.log("selectedCategory useEffect", props.match.params, selectedCategory)
    setVisibleServicesAndPackages()
  }, [selectedCategory, sort])

  useLayoutEffect(() => {
    if(document.getElementById(selectedCategory)) {
      document.getElementById(selectedCategory).scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
     });  
    } 
  }, [selectedCategory])

  useEffect(() => {
    console.log("components, packages useeffect")
    const [packs, comps] = setVisibleServicesAndPackages()
    getAndSetSubCategories(packs, comps)
    // updateCartComponents(comps)
    // updateCartPackage(packs)
    if(details) {
      setDetailedComponent(comps.find(c => c.id === details));
    }
  }, [props.booking.allComponents, props.booking.allPackages])

  useEffect(() => {
    if (first) localStorage.setItem("not_first", true);
    if (builder) localStorage.setItem("builder", true);
    console.log("params", props.match.params)
  }, []);

  useEffect(() => {
    if (!cartIsEmpty(props.cart) && width > 640 ) props.openCart();
    // const start_time = getStartTime(props.booking.date, props.booking.start_time, props.booking.timezone);
    // const end_time = props.booking.duration ? 
    //   moment(start_time).add(props.booking.duration, "hours") : 
    //   moment(start_time).add(1, "hours");
    // console.log("GETTING COMPONENTS", start_time, end_time, props.booking.zipcode )
    // getComponents(start_time, end_time, props.booking.zipcode);
    // getPackages(start_time, end_time, props.booking.zipcode);
    document.title = "Book an Event - 1up Events";
    document.description = "Book an all-inclusive event package, or instantly mix and match event components to build your own custom event.";
  }, []);

  // useEffect(() => {
  //   if (props.booking.date !== props.prevBooking?.date || 
  //       props.booking.location_id !== props.prevBooking?.location_id ||
  //       props.booking.start_time !== props.prevBooking?.start_time ||
  //       props.booking.duration !== props.prevBooking?.duration ||
  //       (props.booking.zipcode && 
  //        props.booking.zipcode.length === 5 && 
  //        props.booking.zipcode !== props.prevBooking?.zipcode)) {
      
  //     const start_time = getStartTime(props.booking.date, props.booking.start_time, props.booking.timezone);
  //     const end_time = props.booking.duration ? 
  //       moment(start_time).add(props.booking.duration, "hours") : 
  //       moment(start_time).add(1, "hours");
      
  //     getPackages(start_time, end_time, props.booking.zipcode, props.booking.timezone);
  //     getComponents(start_time, end_time, props.booking.zipcode, props.booking.timezone);
  //   }
  // }, [props.booking]);

  const cartIsEmpty = (cart) => {
    let cart_count = 0;
    for (const key in cart) {
      cart_count += cart[key].length;
    }
    return cart_count === 0;
  };

  // const getPackages = async (start_time, end_time, zipcode, timezone) => {
  //   if (props.login.admin) {
  //     const packages = await request('/api/admin/packages/' + zipcode);
  //     if (!packages || packages.err) return;
  //     setPackages(packages);
  //     return;
  //   }

  //   const packages = await request('/api/packages', {
  //     method: "POST", 
  //     body: JSON.stringify({start_time, end_time, zipcode, timezone})
  //   });

  //   if (!packages || packages.err) return;

  //   const processedPackages = packages.map(c => {
  //     if (c.keywords) {
  //       c.keywords.push(c.name.toLowerCase());
  //     } else {
  //       c.keywords = [c.name.toLowerCase()];
  //     }
      
  //     if (moment(start_time) < moment().add(c.booking_lead_time, 'days')) {
  //       c.is_available = false;
  //     }
  //     return c;
  //   });

  //   setPackages(processedPackages);
  //   updateCartPackage(processedPackages);
  // };

  // const getComponents = async (start_time, end_time, zipcode, timezone) => {
  //   setLoading(true);
    
  //   if (props.login.admin) {
  //     const allcomps = await request('/api/admin/getComponents/', {
  //       method: "POST", 
  //       body: JSON.stringify({zipcode})
  //     });
      
  //     if (!allcomps || allcomps.err) return;
      
  //     const processedComponents = allcomps.map(c => {
  //       if (c.keywords) {
  //         c.keywords.push(c.name.toLowerCase());
  //         if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
  //       } else {
  //         c.keywords = [c.name.toLowerCase()];
  //         if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
  //       }
  //       c.is_available = true;
  //       c.wrong_location = false;
  //       return c;
  //     });
  //     setAllComponents(processedComponents);
  //     updateCartComponents(processedComponents);
  //     return;
  //   }

  //   try {
  //     const response = await fetch('/api/components', {
  //       method: "POST",
  //       body: JSON.stringify({start_time, end_time, zipcode, timezone}),
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       }
  //     });
      
  //     const components = await response.json();
      
  //     if (components.err) {
  //       console.error(components.err);
  //       return;
  //     }

  //     console.log("CMP:", components)

  //     const processedComponents = components.map(c => {
  //       if (c.keywords) {
  //         c.keywords.push(c.name.toLowerCase());
  //         if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
  //       } else {
  //         c.keywords = [c.name.toLowerCase()];
  //         if (c.options) c.keywords = c.keywords.concat(getOptionNames(c.options));
  //       }
  //       c.wrong_location = false;
        
  //       if (start_time && moment(start_time) < moment().add(c.booking_lead_time, 'days')) {
  //         c.is_available = false;
  //         c.remaining = 0;
  //       }
  //       return c;
  //     });
  //   //     let image = 'powerups/icecream01.jpg'
  //   //     processedComponents[0].name ="Custom Thanksgiving Lunch"
  //   //   processedComponents[0].images = [image]
  //   //   processedComponents[0].sub_categories = {"food" : ["death", "taxes"]}
  //   //   processedComponents[0].categories = ["food"]
  //   //   console.log("processedComponents", processedComponents)
  //   //   processedComponents.push(processedComponents[0])
  //   //   processedComponents.push(processedComponents[0])
  //   //   processedComponents.push(processedComponents[0])
  //   //   processedComponents[1].id = 2
  //     if (details) {
  //       setAllComponents(processedComponents);
  //       setDetailedComponent(processedComponents.find(c => c.id === details));
  //     } else {
  //       setAllComponents(processedComponents);
  //     }
  //   //   const s = [
  //   //     { id: 1, name: 'Cake Pops', price: 275, images: [''], sub_categories: {"food" : ["death", "taxes"]}, categories: ["food"] },
  //   //     { id: 2, name: 'Candy Station', price: 1299, images: [''], sub_categories: {"food" : ["death", "taxes", "vollyball"]}, categories: ["food"]},
  //   //     { id: 3, name: 'Cooking Class', price: 5999, images: [''], sub_categories: {"food" : ["death", "taxes"], "entertainment" : ["ponies"]}, categories: ["food", "entertainment"] },
  //   //     { id: 4, name: 'Croquembouche Tower', price: 899, images: [''], sub_categories: {"drink" : ["death", "taxes", "poison"]}, categories: ["drink"] },
  //   //   ];
  //     updateCartComponents(processedComponents);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };



  function getCategories(components){
    var categories = new Set([])
    for (var comp of components){
      if(comp.categories){
        for (var sub of comp.categories){
          categories.add(sub)
        }
      }
    }
    return Array.from(categories)
  }

  // const updateCartComponents = (components) => {
  //   const s = new Set(components.map(c => c.id));
  //   let clone = Object.assign({}, props.cart);
    
  //   for (let cat in clone) {
  //     if (cat !== "package") {
  //       clone[cat] = clone[cat].map(c => {
  //         const updated = components.find(u => u.id === c.id);
  //         if (updated) {
  //           c.component.price = updated.price;
  //           c.component.setup_time = updated.setup_time;
  //           c.component.takedown_time = updated.takedown_time;
  //           c.component.normal_duration = updated.normal_duration;
  //           c.component.extra_hours_fee = updated.extra_hours_fee;
  //           c.component.minimum = updated.minimum;
  //           c.component.maximum = updated.maximum;
  //           c.component.unit = updated.unit;
  //           c.component.wrong_location = false;
  //           c.component.remaining = updated ? updated.remaining : null;
  //           c.component.is_available = !(updated && 
  //             updated.remaining !== null && 
  //             (Number(updated.remaining) === 0 || 
  //              Number(c.component.num_staff) > Number(updated.remaining)));
  //         } else {
  //           c.component.wrong_location = true;
  //           c.component.is_available = false;
  //         }
          

  //         return c;
  //       });
  //     }
  //   }
  //   props.setCart(clone);
  // };

  const powerup = (routeProps, category) => {
    const detailed_component = (props.booking.allComponents || []).find(c => c.id == routeProps.match.params.id) || 
      {id: null, categories: [""]};
    
    return (
      <ServicePage 
        booking={props.booking}
        show={true}
        id={detailed_component.id}
        inCart={isInCart(detailed_component.id)}
        component={detailed_component}
        onHide={() => hide()}
        category={searchQuery ? detailed_component.categories[0] : category}
      />
    );
  };

  const pack = (routeProps) => {
    const detailed_component = props.booking.allPackages.find(c => c.id == routeProps.match.params.id);
    const inCart = detailed_component && 
      !!props.cart.package && 
      !!props.cart.package[0] && 
      props.cart.package[0].component.id === detailed_component.id;
    
    return (
      <PackagePage 
        booking={props.booking}
        show={true}
        inCart={inCart}
        component={detailed_component}
        onHide={() => hide()}
        addToCart={() => addPackageToCart(detailed_component, props.login.loggedIn)}
      />
    );
  };

  // const updateCartPackage = (packages) => {
  //   var updated_pack =  null
  //   if(props.cart.package && props.cart.package.length > 0){
  //     var updated_pack = packages.find(p => Number(p.id) === Number(props.cart.package[0].id))
  //   }
  //   if(updated_pack){
  //     console.log("updating packages", updated_pack, props.cart.package[0])
  //     let clone =  Object.assign({}, props.cart)
  //     console.log("updating components", updated_pack, clone.package[0].component, clone.package[0].component.component_details, updated_pack.component_details)
  //     clone.package[0].component.components = updated_pack.components
  //     clone.package[0].component.component_details = updated_pack.component_details
  //     clone.package[0].component.is_available = updated_pack.is_available
  //     let pack_ids = clone.package[0].component.components.map(c => Number(c.id))
  //     let avail =  true
  //     if(moment(props.booking.date) < moment().add(clone.package[0].component.booking_lead_time || 3, 'days') ){
  //       clone.package[0].component.is_available = false;
  //       avail = false
  //     }
  //     if(_.intersection(clone.package[0].component.locations, props.booking.location_ids).length < 1){
  //       clone.package[0].component.wrong_location = true;
  //     }else{
  //       clone.package[0].component.wrong_location = false
  //     }
  //     props.setCart(clone)
  //   }else{
  //     console.log("updating packages", "wrong location")

  //     let clone =  Object.assign({}, props.cart)
  //     if(clone.package && clone.package[0]){
  //       clone.package[0].component.wrong_location = true;
  //       clone.package[0].component.is_available = false;
  //       props.setCart(clone)
  //     }
  //   }
  // }

const setIsMissing = () => {
    setMissing(true)
    props.assignMissing("missingAlert")
  }

const missingDismissed = () => {
    setMissing(false)
    props.assignMissing("missing")
  }

function getSubCategories(components, c){
    c = c.toLowerCase()
    var sub_categories = new Set([])
    for (var comp of components){
      if (comp.sub_categories && comp.sub_categories[c]){
        for (var sub of comp.sub_categories[c]){
          sub_categories.add(sub.replace(/\//g,"_"))
        }
      }
    }
    return Array.from(sub_categories)
  }

function updateFilter(f){
    console.log("setting filter", f)
    f = f ? f.replace(/\//g,"_") : null
    setSearchString("")
    setSearchQuery("")
    // setSelectedCategory(f)
    props.history.push('/events/' + selectedTab + (f ? "/" + f : ""))
    window.scrollTo(0, 0)
  }

function viewDetails(component, sub_category){
    var name = component.name.replace(/\//g,"-")
    name = component.name.replace(/(\ )/g,"-")
    var id =  component.id
    setDetailedComponent(component)
    props.history.push( props.location.pathname + "/"  + id + "/" + (name || ""))
  }

function hide(){
    setDetails(false)
    var root = props.location.pathname.split("/")
    root = root.splice(0, root.length - 2).join("/")
    // if(props.history.length <= 2){
    props.history.push(root)
    // }else{
    //   props.history.goBack()
    // }
  }

function getOptionNames(options){
    var keys = []
    for (let t of options.types){
      keys = keys.concat(t.options.map(opt => opt.name.toLowerCase()))
    }
    return keys
  }

function search(comps, s){
    s =  s.toLowerCase();

    return comps.filter( c => {
      if (c.keywords){
        for (var k of c.keywords){
          if(k.includes(s)){
            return true
          }
        }
        return false
      }
      return false
    })
  }


  function addToCart(component, category, loggedIn){
    if(searchQuery){
        props.addToCart(component, component.categories[0], loggedIn)
    }else{
      props.addToCart(component, category, loggedIn)
    }

  }

  function addPackageToCart(detailed_component, loggedIn){
    let componentsRemoved = []
    let toRemove = Object.keys(detailed_component.components).map( id => Number(id))
    let inCart = []
    let componentsInCart = []
    let cart = props.cart
    for (let cat in cart){
      if(cat !== "package"){
        componentsInCart = componentsInCart.concat(cart[cat].map(c => Object.assign({}, c.component)))
        inCart = inCart.concat(cart[cat].map(c =>c.id))
      }
    }
    toRemove = toRemove.filter(r => inCart.includes(r))
    componentsRemoved = componentsInCart.filter( i => toRemove.includes(i.id))
    componentsRemoved = componentsRemoved.map(d=> d.name)
    if(props.cart.package && !!props.cart.package[0]){
      setConfirm(() => {
          props.deleteMultipleCartItems(toRemove)
          props.addToCart(detailed_component, "package", loggedIn);
          setConfirm(false);
          props.history.push({pathname:'/package-booked', state: {componentsRemoved}})
        })
    } else {
      props.deleteMultipleCartItems(toRemove)
      props.addToCart(detailed_component, "package", loggedIn)
      props.history.push({pathname:'/package-booked', state: {componentsRemoved}})
    }
  }

  function isInCart(id){
    for (var cat in props.cart){
      if(cat === "package" && props.cart.package && props.cart.package.length > 0 ){
        var comp_ids = props.cart[cat][0].component.components.map(c => c.id)
        if(comp_ids.includes(String(id))) return true
      }
      for(var comp of props.cart[cat] ){
        if (comp.component.id === id){
          return true
        }
      }
    }
    return false
  }


  function sortServices(components, sort){
    switch(sort){
      case 'availablity':
        var unavailable = components.filter(p => p.remaining === 0)
        var avail = components.filter(p => p.remaining !== 0)
        avail =  _(avail).sortBy((p) => p.name )
        unavailable = _(unavailable).sortBy((p) => p.name )
        return avail.concat(unavailable)
      case 'price':
        return _(components).sortBy((p) => getComponentPrice(p, props.booking) )
      case 'alphabetical':
        return _(components).sortBy((p) => p.name )
      default:
        return _(components).sortBy((p) => p.name )
    }
  }

  function sortPackages(components, sort){
    switch(sort){
      case 'availablity':
        var unavailable = components.filter(p => !p.is_available)
        var avail = components.filter(p => p.is_available)
        avail =  _(avail).sortBy((p) => p.name )
        unavailable = _(unavailable).sortBy((p) => p.name )
        return avail.concat(unavailable)
      case 'price':
        return _(components).sortBy((p) => getPackagePrice((p.component_details || []), props.booking, p.discount, p.setup_time, p.takedown_time) )
      case 'alphabetical':
        return _(components).sortBy((p) => p.name )
      default:
        return _(components).sortBy((p) => p.name )
    }
  }

  function getCategories(components){
    var categories = new Set([])
    for (var comp of components){
      if(comp.categories){
        for (var sub of comp.categories){
          categories.add(sub)
        }
      }
    }
    return Array.from(categories)
  }

//   function showServiceModal(props, category){
//     console.log("Render POWERUP!", props)
//     var detailed_component = allServices.find(c => c.id == props.match.params.id) || {id: null, categories: [""]}
//     if (!detailed_component.id) console.log("null?", props.match.params.id, allServices)
//     return <ServicePage booking={props.booking} show={true} id={detailed_component.id} inCart={isInCart(detailed_component.id)} component={detailed_component} onHide={()=> hide()} category={searchQuery ? detailed_component.categories[0] : category} />
//   }


// function showPackageModal(props){
//     var detailed_component = packages.find(c => c.id == props.match.params.id)
//     var inCart = detailed_component && !!props.cart.package && !!props.cart.package[0] && props.cart.package[0].component.id === detailed_component.id
//     return <PackagePage booking={props.booking} show={true} inCart={inCart} component={detailed_component} onHide={()=> hide()} addToCart={() => addPackageToCart(detailed_component, props.login.loggedIn) } />
//   }
//   const CartModal = () => (
//     <Dialog open={isCartOpen} onOpenChange={setIsCartOpen}>
//       <DialogContent className="sm:max-w-[425px] h-[80vh]">
//         <div className="flex flex-col h-full">
//           <div className="flex justify-between items-center mb-4">
//             <h2 className="text-xl font-bold">Your Cart</h2>
//             <button onClick={() => setIsCartOpen(false)}>
//               <X className="h-6 w-6" />
//             </button>
//           </div>
          
//           <div className="flex-grow overflow-auto">
//             {/* Cart items would go here */}
//             <div className="text-center text-gray-500 mt-10">
//               No items in cart yet
//             </div>
//           </div>
          
//           <div className="border-t pt-4 mt-4">
//             <div className="flex justify-between mb-4">
//               <span className="font-medium">Total</span>
//               <span className="font-bold">$0.00</span>
//             </div>
//             <button className="w-full bg-alt-highlight text-white py-3 rounded-xl font-semibold">
//               Checkout
//             </button>
//           </div>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );

//   const FiltersSheet = () => (
//     <Sheet open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
//       <SheetContent side="left">
//         <SheetHeader>
//           <SheetTitle>Categories</SheetTitle>
//         </SheetHeader>
//         <div className="mt-4">
//           {categories.map((category) => (
//             <button
//               key={category}
//               onClick={() => {
//                 setSelectedCategory(category);
//                 setIsFiltersOpen(false);
//               }}
//               className={`block w-full text-left py-3 px-2 rounded-lg ${
//                 selectedCategory === category 
//                   ? 'bg-green-50 text-highlight-color' 
//                   : 'text-gray-600 hover:bg-gray-50'
//               }`}
//             >
//               {category}
//             </button>
//           ))}
//         </div>
//       </SheetContent>
//     </Sheet>
//   );

const getSortIcon = () => {
  if(sort === "price") {
    return (<ArrowDownWideNarrow className="h-5 w-5" />)
  } else if(sort === "alphabetical"){
    return (<ArrowDownWideNarrow className="h-6 w-6" />)
  } else {
    return (<ArrowDownWideNarrow className="h-5 w-5" />)
  }
}

const displaySort = () => {
  let value = sort
  return (
    <div className="sort-box">
    <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" >
        <Dropdown.Toggle noCaret >
        <div 
            className="p-2.5 pointer rounded-xl hover:bg-gray-200"
          >
          {getSortIcon()}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          <MenuItem eventKey="1" active={value === "alphabetical" || value === null} onSelect={() => setSort("alphabetical")}>A-Z</MenuItem>
          <MenuItem eventKey="2" active={value === "price"} onSelect={() => setSort("price")}>Price</MenuItem>
          <MenuItem eventKey="3" active={value === "availablity"} onSelect={() => setSort("availablity")}>Availability</MenuItem>
        </Dropdown.Menu>
      </Dropdown>
      </div>
  )
}
//<div className='flex w-[80px] bg-primary-content-color/50 h-[1px]'/>
//      <div className={`grid grid-cols-[repeat(auto-fill,_minmax(320px,_1fr))] gap-7 justify-items-center md:justify-items-start`}>

  const DisplayServices = (services, title) => {
  //
    return (
    <div className='mb-6'>
      {title ? <div className='flex items-center justify-center md:justify-start mb-5'><div className='text-primary-content-color text-lg text-[22px] font-light inline flex-none'>{title}</div></div> : null} 
      <div className={`flex flex-wrap gap-y-9 gap-x-10 justify-center md:justify-start`}>
        {services.map((item, key) => (
            <ServiceItem component={item} key={key} viewDetails={()=> viewDetails(item, filter)} category={selectedTab} inCart={isInCart(item.id)} filter={selectedCategory}/>
        ))}
      </div>
    </div>
    )
  }

  const DisplayPackages = (packs, title) => (
    <div className='mb-6'>
      {title ? <div className='flex items-center justify-center md:justify-start mb-5'><div className='text-primary-content-color text-lg text-[22px] font-light inline flex-none'>{title}</div></div> : null} 
      <div className={`flex flex-wrap gap-y-9 gap-x-12 justify-center md:justify-start`}>
                {packs.map((item, key) => (
                    <RegularPackage component={item} key={key} viewDetails={()=> viewDetails(item)} addToCart={()=> addPackageToCart(item, props.login.loggedIn)} inCart={ !!props.cart.package && !!props.cart.package[0] && props.cart.package[0].component.id === item.id} filter={selectedCategory}/>
                ))}
      </div>
    </div>
  )

  //      <div className={`grid grid-cols-[repeat(auto-fit,_minmax(338px,_1fr))] gap-8 justify-items-center  md:justify-items-start`}>


  const displayMessage = (message) => (
    <div className='italic text-primary-content-color'>{message}</div>
  )

  const MainContent = () => {

    if(loading) {
      return (
        <div className='mb-6'>
          <div className='flex items-center justify-center md:justify-start mb-5'><FeaturedLoader /></div>
          <div className={`grid grid-cols-[repeat(auto-fill,_minmax(320px,_1fr))] gap-7 justify-items-center md:justify-items-start`}>
            {[1,2,3,4,5,6,7,8,9,10].map((item, key) => (
                <CardLoader key={key} />
            ))}
          </div>
        </div>
      )
    }
    if (searchQuery) {
      return (
        <>
          {visibleServices.length > 0 ? DisplayServices(visibleServices, "Individual Items"): null}
          {visiblePackages.length > 0 ? DisplayPackages(visiblePackages, "Packages") : null}
          {visibleServices.length === 0 && visiblePackages.length === 0 ? displayMessage(noResultsMessage + searchQuery) : null}
        </>
      )
    } else if(selectedTab === "packages"){ 
      if(featuredPackages.length > 0 || visiblePackages.length > 0){
        return (
          <>
              {featuredPackages.length > 0 ? DisplayPackages(featuredPackages, "Featured") : null}
              {visiblePackages.length > 0 ? DisplayPackages(visiblePackages, featuredPackages.length > 0 ? "Everything Else" : null): null}
          </>
        )
      } else {
        return displayMessage(noItemsMessage)
      }
    } else {
      if(featuredServices.length > 0 || visibleServices.length > 0) {
        return (
          <>
              {featuredServices.length > 0 ? DisplayServices(featuredServices, "Featured") : null}
              {visibleServices.length > 0 ? DisplayServices(visibleServices, featuredServices.length > 0 ? "Everything Else" : null): null}
          </>
        )
      } else {
        return displayMessage(noItemsMessage)
      }
    }
  }
  var cartMargin = (props.app.cart) ? {marginRight: 320} : {marginRight: 0}

  return (
    <div className="min-h-screen flex-col flex bg-background-color">
      {/* Header */}
      <header className="sm:sticky sm:top-0 z-30 bg-white shadow-sm">
        <div className='new'><Header/></div>
        {/* <div className="px-3 py-3 flex items-center lg:hidden justify-between">
          <EventDetailsBar eventDetails={props.booking} setShowBookingModal={setShowBookingModal} />
          <div className='hidden sticky z-[9000] original new lg:block'><BookBar /></div>
          {showBottomTabs ? null : (
                    <div className="flex-none items-center space-x-3 pl-2">
                      <button 
                        onClick={() => setIsCartOpen(true)}
                        className="p-2 rounded-full hover:bg-gray-100 relative"
                      >
                        <ShoppingCart className="h-6 w-6" />
                        <span className="absolute -top-1 -right-1 bg-alt-highlight text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                          3
                        </span>
                    </button>
                  </div>)
          
          }
        </div> */}
        {/* Main Navigation Tabs */}
        <div className="border-t border-b w-full sm:w-[unset]" style={cartMargin}>
          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex min-w-max">
              {mainTabs.map((tab) => (
                <button
                  key={tab}
                  id={tab}
                  onClick={() => { props.history.push('/events/' + tab);}
                  }
                  className={`px-6 py-3 transition-colors relative ${
                    selectedTab === tab && !searchQuery
                      ? 'text-alt-dark-highlight font-medium'
                      : 'text-gray-600 hover:text-alt-dark-highlight'
                  }`}
                >
                  {tab.capitalize()}
                  {selectedTab === tab && !searchQuery ?
                    <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-dark-highlight" /> : null
                  }
                </button>
              ))}
            </div>
          </div>
        </div>
      </header>
        {/* Search and Filter Bar */}
      <header className='sticky top-0 sm:top-[124px] z-20 bg-white shadow-sm w-full sm:w-[unset]' style={cartMargin}>
        <div className="px-4 py-2 flex items-center space-x-2 border-b">
          <div className="flex-1 relative">
            <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              placeholder={"Search everything" }
              className="w-full pl-10 pr-4 py-2 rounded-xl border-2 border-gray-200 focus:border-green-400 focus:ring-2 focus:ring-green-200"
            />
          </div>
          {displaySort()}
        </div>

        {/* Category Pills */}
        <div className="overflow-x-auto scrollbar-hide">
          <div className="px-4 py-2 flex space-x-2 min-w-max">
            {subCategories.map((c) => (
              <button
                key={c}
                id={c}
                onClick={() => updateFilter(c)}
                className={`px-4 py-2 rounded-xl whitespace-nowrap ${
                  c.toLowerCase() === (searchQuery ? "all" : selectedCategory.toLowerCase())
                    ? 'bg-alt-highlight text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {c.capitalize()}
              </button>
            ))}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className={"px-2 sm:px-4 py-6 flex-1 " + (cartMargin.marginRight ? "mx-auto  sm:ml-2 sm:mr-[320px]": 'mx-auto sm:ml-2')}>
        <div>
          {MainContent()}
        </div>
      </main>
      <Switch>
            <Route path={ selectedCategory ? "/events/packages/:filter/:id" : "/events/packages/:id" } render={ (props) =>  pack(props)} />
            <Route path={ selectedCategory ? "/events/:category/:filter/:id" : "/events/:category/:id" } render={(props) => powerup(props, selectedTab)} />
      </Switch>
      <BottomTabBar selected={"browse"} />
      {/* {isCartOpen ? <Cart width={true} /> : null} */}
      <BookingModal show={showBookingModal} onHide={() => setShowBookingModal(false)} />
    </div>
  );
};

export default ServicesPage;