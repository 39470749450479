import React, {useEffect} from 'react'
import '../css/Modal.css'
import {Modal} from 'react-bootstrap'

const MyModal = ({children, className, show, onHide, customClassName, backdropClassName, zIndex}) => {
  // var cl = className || ""

  return (
    <Modal style={{display: "block", zIndex}} show={show} onHide={onHide} enforceFocus={false} dialogClassName={customClassName + " new "} backdropClassName={backdropClassName || ""}>
      {children}
     </Modal>
  )
}

export default MyModal
