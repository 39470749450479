import React from "react"
import currencyFormatter from 'currency-formatter';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import { getPackagePrice, getLateFee, getStartTime} from '../Utils/math'
import PackagePage from './PackagePage'
import moment from 'moment'
import { Trash } from 'lucide-react'

class CartPackage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      preview: false
    }
  }

  render(){
    var {c_id, pack, actions, booking} = this.props
    var image = ""
    if(pack.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + pack.images[0]
    var image2 = image
    if(pack.images) image2 =  "https://d2c831se9gn8j1.cloudfront.net/"  + pack.images[1]
    var late_fee = getLateFee(moment(), getStartTime(booking.date, booking.start_time, booking.timezone))
    let price = getPackagePrice((pack.component_details || []), booking, pack.discount, pack.setup_time, pack.takedown_time, late_fee)
    return (
      <div className="package" >
        <div className="flex" style={{overflow: 'hidden', height: 140}}>

          <div className="h-[140px] w-[139px]" onClick={() => this.setState({preview: true})} style={{borderRight: "1px solid white", display: "inline-block", overflow: "hidden", width: 139}}>
            <img className="h-full w-auto object-cover " src={image} alt={pack.name}/>
          </div>
          <div className="h-[140px] w-[139px]" onClick={() => this.setState({preview: true})} style={{display: "inline-block", overflow: "hidden", width: 138}}>
            <img className="h-full w-auto object-cover self-center" src={image2} alt={pack.name}/>
          </div>
        </div>
        <div className="name" onClick={() => this.setState({preview: true})}>{pack.name}</div>
        <div className="price">{currencyFormatter.format(price, { code: 'USD', precision: 0})}</div>
        <div className="price-per">{currencyFormatter.format(price/(booking.people || 1), { code: 'USD', precision: 0})}/person</div>
        {!pack.is_available ? <div className="includes unavailable">Package unavailable</div> : <div className="includes mr-[40px]">{pack.includes}</div>}
        <div className="hidden sm:block">
          <div className="hovered">
            <span className="remove" onClick={() => actions.deleteCartItem(c_id, "package")}>Remove</span>
          </div>
        </div>
        <button className='sm:hidden absolute bottom-2 right-2 flex bg-gray-200 w-[26px] h-[26px] rounded-full items-center justify-center' onClick={() => actions.deleteCartItem(c_id, "package")}>
            <Trash className='h-4 w-4 text-strong-content-color'/>
        </button>

        {this.state.preview ? <PackagePage booking={this.props.booking} show={this.state.preview} inCart={true} component={pack} onHide={()=> this.setState({preview: false})} addToCart={() => null } /> : null}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  booking: state.booking
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPackage))
