import React from 'react'
import './TravelFees.css'

function cloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}

class TravelFees extends React.Component{

  constructor(props){
    super(props)
  }

  changeLocationFee(id, fee){
    var travel_fees = cloneObj(this.props.travel_fees)
    travel_fees[id] = fee
    this.props.changeState({travel_fees})
  }

  changeRegionFee(id, fee){
    var region = this.props.regions.find(s  => s.value === id)
    var travel_fees = cloneObj(this.props.travel_fees)
    var region_fees = cloneObj(this.props.travel_fees.region_fees || {})
    var sub_region_fees = cloneObj(this.props.travel_fees.sub_region_fees || {})
    region_fees[id] = fee
    for(var s of region.children){
      sub_region_fees[s.value] = fee
      for(var l of s.children){
        travel_fees[l.value] = fee
      }
    }
    travel_fees.region_fees = region_fees
    travel_fees.sub_region_fees = sub_region_fees

    this.props.changeState({travel_fees})
  }

  changeSubRegionFee(region_id, sub_id, fee){
    var region = this.props.regions.find(s  => s.value === region_id)
    var sub_region = region.children.find(s  => s.value === sub_id)
    var travel_fees = cloneObj(this.props.travel_fees)
    var sub_region_fees = cloneObj(this.props.travel_fees.sub_region_fees || {})
    sub_region_fees[sub_id] = fee
    for(var c of sub_region.children){
      travel_fees[c.value] = fee
    }
    travel_fees.sub_region_fees = sub_region_fees
    this.props.changeState({travel_fees})
  }

  filterRegions(){
    var set_locations = this.props.locations.map(l => Number(l))
    var regions = []
    var reg = cloneObj(this.props.regions)
    for (var r of reg){
      var sub_regions = []
      for (var s of r.children){
        var locations = []
        for(var l of s.children){
          if(set_locations.includes(Number(l.value))){
            locations.push(l)
          }
        }
        if(locations.length > 0){
          console.log("should add locations")
          s.children = locations
          sub_regions.push(s)
        }
      }
      if(sub_regions.length > 0){
        r.children = sub_regions
        regions.push(r)
      }
    }
    return regions
  }

  isDecimal(val){
    if (!val){
      return true
    }
    const re = /^[0-9.\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        return true
      }
    }
    return false
  }


  render(){
    if(!this.props.regions) return null
    console.log(this.props.regions)
    console.log(this.props.locations)
    var regions = this.filterRegions()
    console.log(regions)
    var travel_fees = this.props.travel_fees
    var region_fees = travel_fees.region_fees || {}
    var sub_region_fees = travel_fees.sub_region_fees || {}
    console.log(travel_fees, sub_region_fees)

    return (
      <div className={"travel-fees"}>
        {regions.map( (r, i) =>
          <div key={i}>
            <div className="region">
              <span className="location-label">{r.label}: </span>
              <div className="fee">
                <span className="desc-text money">$</span>
                <input className={"text-field hour"} onChange={(e) => {if(this.isDecimal(e.target.value)) this.changeRegionFee(r.value, e.target.value)} } value={region_fees[r.value]} />
              </div>
            </div>
          <div>
              {r.children.map( (s, key) =>
                <div key={key}>
                  <div className="sub-region">
                    <span className="location-label">{s.label}: </span>
                    <div className="fee">
                      <span className="desc-text money">$</span>
                      <input className={"text-field hour"} onChange={(e) => {if(this.isDecimal(e.target.value)) this.changeSubRegionFee(r.value, s.value, e.target.value)} } value={sub_region_fees[s.value]} />
                      </div>
                  </div>
                  <div>
                    {s.children.map( (l, key2) =>
                      <div className="location" key={key2}>
                        <span className="location-label">{l.label}: </span>
                        <div className="fee">
                          <span className="desc-text money">$</span>
                          <input className={"text-field hour"} onChange={(e) => {if(this.isDecimal(e.target.value)) this.changeLocationFee(l.value, e.target.value)} } value={travel_fees[l.value]} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

        )}
      </div>
    )
  }

}

export default TravelFees
