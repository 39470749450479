import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import '../../css/Powerups.css';
import currencyFormatter from 'currency-formatter';
import Card from '../Card'
import AddToCartButton from '../../img/AddToCartButtonWhite.svg'
import InCartButton from '../../img/InCartButton.svg'
import AvailabilityIcon from '../../img/SoldOutViewAvailabilityIcon.svg'
import request from '../../request'
import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import OptionsModal from '../Utils/OptionsModal'
import LimitationModal from '../Utils/LimitationModal'
import ServicePage from './ServicePage'
import MultiOptions from '../../img/MultipleOptionsIcon.svg'
import { getComponentPrice, getStartTime, getLateFee } from '../Utils/math'
import Hearting from '../Utils/Hearting'
import AvailabilityViewer from '../Utils/AvailabilityViewer'
import ContentLoader from "react-content-loader"

const ImageLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={320}
    height={202}
    viewBox="0 0 320 202"
    backgroundColor="#ffffff"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="320" height="202" /> 
  </ContentLoader>
)

function optionsEnabled(options){
  if(options){
    return options.types.filter(t => !t.disabled).length > 0
  }
  return false
}

class ServiceItem extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      showOptions: false,
      limitation: null,
      calendar: false,
      unavailable: [],
      imageLoaded: false,
      change: false,
      showAddOn: false,
      scroll: null,
      right: null,
      availability : []
    }
  }

  async componentDidMount(){
    this.loadImage()
    this._ismounted = true
    if(!this.props.component.is_available){
      if(this.props.component.booking_lead_time && Number(this.props.component.booking_lead_time) > getStartTime(this.props.store.date, this.props.store.start_time, this.props.store.timezone).diff(moment(), "days")){
        this.setState({availability: []})
      }else{
        this.getAvailability()
      }
    }
  }

  async getAvailability(){
    var start_time = moment(this.props.store.date).set('hour', 0).set('minute', 0).set('second', 0);
    var end_time = moment(start_time).add(1, "day").add(this.props.store.duration, "hours")
    var availability = await request('/api/availability/component/' + this.props.component.id + "/" + start_time + "/" + end_time + "/" + (this.props.store.timezone ? this.props.store.timezone.replace("/", "%2F") : ""))
    if(!availability.err && this._ismounted){
      this.setState({availability})
    }
  }

  loadImage(){
    this.setState({imageLoaded: false})
    var src
    if(this.props.component.images) src =  "https://d2c831se9gn8j1.cloudfront.net/"  + this.props.component.images[0]
    const image = new Image();
    image.onload = () => this._ismounted ? this.setState({imageLoaded: true}): null;
    // image.onerror = err => console.log(err);
    image.src = src
  }

  componentWillUnmount() {
   this._ismounted = false;
  }

  componentDidUpdate(prevProps){
    if ((!this.props.component.images && prevProps.component.images) || (prevProps.component.images && prevProps.component.images[0] !== this.props.component.images[0]) ){
      this.loadImage()
    }
    let start = getStartTime(this.props.store.date, this.props.store.start_time, this.props.store.timezone)
    let old_start = getStartTime(prevProps.store.date, prevProps.store.start_time, prevProps.store.timezone)
    if((!this.props.component.is_available && !start.isSame(old_start, "minute")) || (!this.props.component.is_available && prevProps.component.is_available) ){
      if(this.props.component.booking_lead_time && Number(this.props.component.booking_lead_time) > start.diff(moment(), "days")){
        this.setState({availability: []})
      }else{
        this.getAvailability()
      }
    }
  }

  blockOut(date){
    if(date < moment().add(this.props.component.booking_lead_time, 'days')){
      return true
    }
    // console.log("Block out called", this.state.unavailable, this.unavailable, this._ismounted)
    for (var d of this.state.unavailable){
      if(moment(d).isSame(date, 'day')){
        return true
      }
    }
    return false
  }
  getQty(){
    if(this.state.qty) return this.state.qty
    if(this.props.component.unit === "person") return this.props.store.people
    if(this.props.component.unit === "hour") return this.props.store.duration
    return 1
  }

  addToCart(component){
    if (optionsEnabled(component.options)){
      this.setState({showOptions: true})
    }else{
      var qty = this.getQty()
      // if(this.props.component.minimum && qty < this.props.component.minimum){
      //   this.setState({limitation: "min"})
      // }else
      if(this.props.component.maximum && Number(this.props.component.maximum) > 0 && qty > this.props.component.maximum){
        this.setState({limitation: "max"})
      }else{
        this.props.actions.addToCart(component, this.props.category || component.categories[0], this.props.login.loggedIn )
        if(this.props.app.session){
          // this.props.actions.openCart()
          this.props.actions.setSession(false)
        }
      }
    }

  }

  confirmedAddToCart(){
    console.log("confirm add to cart")
    this.setState({limitation: null})
    var clone = Object.assign({}, this.props.component)
    clone.qty = null
    this.props.actions.addToCart(clone, this.props.category || clone.categories[0], this.props.login.loggedIn )
    if(this.props.app.session){
      // this.props.actions.openCart()
      this.props.actions.setSession(false)
    }
  }

  isInCart(id){
    for (var cat in this.props.cart){
      for(var comp of this.props.cart[cat] ){
        if (comp.component.id === id){
          return true
        }
      }
    }
    return false
  }

  getCompFromCart(id){
    for (var cat in this.props.cart){
      for(var comp of this.props.cart[cat] ){
        if (comp.component.id === id){
          return comp.component
        }
      }
    }
    return {id: null}
  }

  render(){


    var {component, inCart, store, path, category, filter} = this.props
    var image = ""
    if(component.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]

    var name = component.name.replace(/\//g,"-")
    if(name === "Balloons") console.log("Ballons", component)
    name = component.name.replace(/ /g,"-")
    var id =  component.id
    path = path || "/events/" + category + "/" + (filter ? filter + "/" : "") + id + "/" + (name || "")
    var right = false
    setTimeout(() => {if(document.getElementById("powerup-list") && this.state.scroll !== document.getElementById("powerup-list").scrollWidth && document.getElementById(component.id)) this.setState({right: document.getElementById(component.id).getBoundingClientRect().right, scroll: document.getElementById("powerup-list").scrollWidth })}, 400)
    if(this.state.scroll){
      right = this.state.scroll - this.state.right < 320
    }
    inCart = inCart || this.isInCart(component.id)
    var c = () => {
      if(!component.is_available){
        return (
          <div style={{width: 41, height: 32}} >
            <AvailabilityViewer name={component.name} lead_time={component.booking_lead_time} start={getStartTime(store.date, store.start_time, store.timezone)} right={right} availability={this.state.availability} />
          </div>
        )
      }
      return (
        <div>
         { inCart ? (
              <div style={{width: 36, height: 34, alignContent: 'center'}}>
                <img alt="" src={InCartButton} />
              </div> )
         : (
          <button className="bg-primary-action-color add rounded float-right hover:bg-primary-action-color-hover active:bg-primary-action-color-hover" style={{width: 36, height: 34, alignContent: 'center', paddingLeft: 1}}  onClick={() => this.addToCart(component)}>
            <img alt="" src={AddToCartButton}  />
          </button>
         )
            }
        </div>
      )
    }

    var calcPrice = () => {
      var late_fee = getLateFee(moment(), getStartTime(store.date, store.start_time, store.timezone))
      var price = getComponentPrice(component, store, null, null, null, late_fee)
      switch(component.unit){
        case "person":
          if(store.people){
            return currencyFormatter.format(price, { code: 'USD', precision: 0})
          }
          if(component.minimum){
            return "Starting at " + currencyFormatter.format(price, { code: 'USD', precision: 0})
          }
          // eslint-disable-next-line
        case "hour":
          if(store.duration){
            return currencyFormatter.format(price, { code: 'USD', precision: 0})
          }
          if(component.minimum){
            return "Starting at " + currencyFormatter.format(price, { code: 'USD', precision: 0})
          }
          // eslint-disable-next-line
        default:
          return currencyFormatter.format(price, { code: 'USD', precision: 0})
      }
    }

    return (
      <div className="reg-powerup-container h-[272px]" id={component.id} key={this.props.key} ref={(node) => this.node = node}>
        <Card className="regular h-[272px]" >
          {!component.is_active && this.props.login.admin ? <div className="inactive">INACTIVE</div> : null }
          {inCart ?
            <div>
              {this.state.imageLoaded ? <div onClick={() => this.setState({change: true})}><img className={"image"} alt={component.name} src={image} onLoad={() => this.setState({imageLoaded: true})}/></div> : <ImageLoader />}
              <div  onClick={() => this.setState({change: true})} ><div className="name text-base truncate w-auto max-w-full" >{component.name} {optionsEnabled(component.options) ? <img src={MultiOptions} alt="" style={{height: 14, marginLeft: 8}} /> : null}</div></div>
            </div>
            : (this.props.addOn ?
              <div>
                {this.state.imageLoaded ? <div onClick={() => this.setState({showAddOn: true})}><img className={"image"} alt={component.name} src={image} onLoad={() => this.setState({imageLoaded: true})}/></div> : <ImageLoader />}
                <div  onClick={() => this.setState({showAddOn: true})} ><div className="name text-base truncate  w-auto max-w-full" >{component.name} {optionsEnabled(component.options) ? <img src={MultiOptions} alt="" style={{height: 14, marginLeft: 8}} /> : null}</div></div>
              </div>
            :
            <div>
              {this.state.imageLoaded ? <Link to={path}><img className={"image"} alt={component.name} src={image} onLoad={() => this.setState({imageLoaded: true})}/></Link> : <ImageLoader />}
              <Link className="" to={path}><div className="name px-[8px] mx-0 text-base truncate w-auto max-w-full" >{component.name} {optionsEnabled(component.options) ? <img src={MultiOptions} alt="" style={{height: 14, marginLeft: 8}} /> : null}</div></Link>
            </div>
            )
          }
          {this.state.showOptions ? <OptionsModal component={component} show={this.state.showOptions} onHide={() => this.setState({showOptions: false})} category={this.props.category || component.categories[0]}/> : null  }
          {optionsEnabled(component.options)? null: <LimitationModal component={component} limitation={this.state.limitation} store={this.props.store} show={!!this.state.limitation} onHide={() => this.setState({limitation: false})} onConfirm={() => this.confirmedAddToCart()}/>}
        </Card>
        <div className='absolute bottom-0 w-full h-fit p-2 py-1.5 flex justify-between items-center'>
            <div className='flex'>
              <div className="price float-left">{inCart ? "Added to Cart" : calcPrice()}</div>
            </div>
            <div className='flex space-x-2 items-center'>
              {this.props.login.admin ? <Link to={{pathname:"/admin_panel/powerup/"+ component.id, state: component }} className="edit"><i style={{color: "var(--primary-content-color)"}} className="fa fa-pencil-square-o w-4"></i></Link> : null }
              <Hearting right={true} lists={this.props.login.wishlists || []} component={component} isPackage={false} className="h-[34px] w-[34px]" />
              {c()}
            </div>
          </div>
        {this.state.change && !this.state.showAddOn ? <ServicePage availablity={this.state.availablity} booking={this.props.store} fromCart={true} show={this.state.change} inCart={true} component={this.getCompFromCart(component.id)} onHide={()=> this.setState({change: false})} category={this.props.category || component.categories[0]} />  : null}
        {this.state.showAddOn ? <ServicePage availablity={this.state.availablity}  booking={this.props.store} inCart={inCart} fromCart={inCart} show={this.state.showAddOn} component={component} onHide={()=> this.setState({showAddOn: false})} category={this.props.category || component.categories[0]} />  : null}

      </div>
    )
  }
}



const mapStateToProps = state => ({
  store: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceItem))
