import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header'
import Title from '../Title'
import ComponentSearch from './ComponentSearch'

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class EditPromotion extends Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      zipcodes: "",
      timezone: "",
      timezone_name: "",
      error: ""
    }
  }

  async validate(){
    let jsonList = ["zipcodes"]
    try{
      for(var c of jsonList){
        if(this.state[c]){
          try{
            JSON.parse(JSON.stringify(this.zipToArray(this.state[c])))
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      if(!this.state.name){
        document.getElementById("name").focus()
        this.setState({error: "Name can't be empty"})
        return false
      }
      if(!this.state.timezone){
        document.getElementById("timezone").focus()
        this.setState({error: "timezone"})
        return false
      }
      if(!this.state.timezone_name){
        document.getElementById("timezone_name").focus()
        this.setState({error: "timezone_name"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  componentDidMount(){
    this.getData(this.props)
  }

  async getData(props){
    var location = await request('/api/admin/locations/'+ props.match.params.id)
    this.setState({name: location.name, zipcodes: location.zipcodes, timezone: location.timezone, timezone_name: location.timezone_name })
  }


  zipToArray(zipcodes){
    return zipcodes.split(",").map(z => z.trim())
  }

  async handleSubmit(event){
    event.preventDefault();
    console.log("submitting")
    var validated = await this.validate()
    if(validated){
      var location = {name: this.state.name, zipcodes: JSON.stringify(this.zipToArray(this.state.zipcodes)), timezone: this.state.timezone, timezone_name: this.state.timezone_name}
      var res  = await request("/api/admin/location", {method: "PUT", body: JSON.stringify({location, id: this.props.match.params.id } )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.setState({name: res.name, description: res.description, components: JSON.stringify(res.components), packages: JSON.stringify(res.packages), error: "Updated" })

      }
    }
  }

  render(){
    return (
      <div>
        <Header />
        <div style={{marginTop: 40, marginLeft: 40}}>
          <Title title="Edit Promotion"/>
        </div>
        <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          <FieldGroup
               id={"name"}
               key={"name"}
               type="text"
               label={"name"}
               className="text-field"
               placeholder={"name"}
               value={this.state.name}
               onChange={(e)=> this.setState({name: e.target.value})}
               bsSize="sm"
             />
             <FormGroup key={"zipcodes"} controlId={"zipcodes"}>
               <ControlLabel>{"zipcodes"}</ControlLabel>
               <FormControl className="text-field" componentClass="textarea" style={{resize: "vertical"}} rows="8"  bsSize="sm" placeholder={"94041, 94042"} value={this.state.zipcodes} onChange={(e)=> this.setState({zipcodes: e.target.value})}/>
             </FormGroup>

             <FieldGroup
                  id={"timezone_name"}
                  key={"timezone_name"}
                  type="text"
                  label={"timezone name"}
                  className="text-field"
                  placeholder={"Pacific Time"}
                  value={this.state.timezone_name}
                  onChange={(e)=> this.setState({timezone_name: e.target.value})}
                  bsSize="sm"
                />
            <FieldGroup
                 id={"timezone"}
                 key={"timezone"}
                 type="text"
                 label={"Timezone"}
                 className="text-field"
                 placeholder={"America/Los_Angeles"}
                 value={this.state.timezone}
                 onChange={(e)=> this.setState({timezone: e.target.value})}
                 bsSize="sm"
               />
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(EditPromotion)
