import React from 'react'
import Modal from '../Modal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import AddToCartButton from '../../img/AddToCartButtonWhite.svg'
import InCartButton from '../../img/InCartButton.svg'
import CheckmarkWhite from '../../img/CheckmarkWhite.svg'
import CircleCheck from '../../img/CheckmarkCircle.svg'
import AvailabilityViewer from '../Utils/AvailabilityViewer'
import CustomizationModal from '../Utils/CustomizationModal'
import LimitationModal from '../Utils/LimitationModal'
import ConfirmationModal from '../Utils/ConfirmationModal'
import moment from 'moment'
import currencyFormatter from 'currency-formatter'
import request from '../../request'
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import AvailabilityIcon from '../../img/SoldOutViewAvailabilityIcon.svg'
import pluralize from 'pluralize'
import Options from '../Utils/Options'
import { getComponentPrice, getStartTime, getLateFee } from '../Utils/math'
import Hearting from '../Utils/Hearting'
import {DropdownButton, Dropdown, MenuItem} from 'react-bootstrap'
import { ChevronLeft } from 'lucide-react'

function isSet(v){
  if(v !== null && v !== undefined){
    return true
  }
  return false
}

var time_range = [];
for (var i = .5; i <= 10; i+= 0.5) {
    time_range.push(i);
}

function strip(html){
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const PriceWrapper = ({ children }) => (
  <div className="flex w-full xs:w-auto flex-wrap items-center gap-1">
    {children}
  </div>
);

const PriceDisplay = ({ amount }) => (
  <span className="price">
    {currencyFormatter.format(amount, { code: 'USD', precision: 0 })}
  </span>
);

const ForText = () => (
  <span className="for mr-1">for</span>
);
const AndText = () => (
  <span className="for mr-1">and</span>
);

class ServicePage extends React.Component {

  constructor(props){
    super(props)
    var options = props.component.option_values || {}
    let qty = props.component.qty
    if(!qty){
      switch(props.component.unit){
        case "person":
          qty =  props.booking.people || null
          break
        case "hour":
          qty =  props.booking.duration || null
          break
        default:
          qty = 1
      }
    }
    this.state = {
      calendar: false,
      unavailable: [],
      changeQty: false,
      qty,
      num_staff: props.component.num_staff || 1,
      limitation: null,
      options,
      inPackage: false,
      saveChanges: false,
      leaving: false,
      availability: this.props.availability || [],
      change_duration: false,
      duration: props.component.duration || props.booking.duration || 1
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.component && nextProps.component.id !== (this.props.component ? this.props.component.id : undefined)) {
      this.getAvailability(nextProps, "recevied")
      let qty = nextProps.component.qty
      if(!qty){
        switch(nextProps.component.unit){
          case "person":
            qty =  nextProps.booking.people || null
            break
          case "hour":
            qty =  nextProps.booking.duration || null
            break
          default:
            qty = 1
        }
      }
      this.setState({qty})
      document.title = nextProps.component.name ? nextProps.component.name + " - 1up Events": "1up Events"
      document.description = nextProps.component.description ? strip(nextProps.component.description || "") :  "1up Events"
    }
  }

  async getAvailability(props, received){
    if (props.component.id){
      var start_time = moment(props.booking.date).set('hour', 0).set('minute', 0).set('second', 0);
      var end_time = moment(start_time).add(1, "day").add(props.booking.duration, "hours")
      var availability = await request('/api/availability/component/' + props.component.id + "/" + start_time + "/" + end_time + "/" + (props.booking.timezone ? props.booking.timezone.replace("/", "%2F") : ""))
      if(!availability.err){
        this.setState({availability})
      }
    }

  }

  componentDidMount(){
    if(this.props.location.pathname.includes("builder") && !this.props.inCart){
      document.title = this.props.component.name + " - 1up Events" || "1up Events"
      document.description = this.props.component.description ? strip(this.props.component.description || "") :  "1up Events"
    }
    if(this.props.component.remaining === 0){
      this.getAvailability(this.props)
    }
  }

  changeQuantity(val){
    if (!val){
      this.setState({qty: val, saveChanges: true})
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.setState({qty: val, saveChanges: true})
      }
    }
  }

  changeQuantityInput(value, unit, max, name){
    let warn_max = max && Number(max) && (Number(value) > Number(max))
    let input_class = (warn_max ?  "warning" : "")
    return (
      <div className='flex-1 min-w-fit'>
        <div className="quantity-input w-full m-0">
          {value ? 
            <span className={"text" + (warn_max ? "warning" : "")} >
                <span style={{color: "white"}}>{value}</span>
                {Number(value) === 1 ? " " + unit: " " + pluralize(unit)}
            </span>
            : <span className="text" style={{color: "white"}}>{"# of " + pluralize(unit)}</span> 
            }
          <input key={name + "text-input"} id={"qty-input"} className={input_class} style={{position: "absolute", left: 0, background: "transparent"}} value={value} onChange={(e) => this.changeQuantity(e.target.value)} placeholder={"# of " + pluralize(unit)} />
        </div>
        {warn_max ? <div className="maximum-warning">({max} {pluralize(unit)} max)</div> : null}
      </div>
    )
  }

  onToggle(open, a, b){
    let source = b.source
    if(source === 'keydown'){
      this.setState({change_duration: open})
    }else if(source === "click" &&  this.state.change_duration === "focused"){

      this.setState({change_duration: "clicked"})
    }else if(source === "select"  &&  this.state.change_duration === "focused"){
      this.setState({change_duration: false})
    }else{

      this.setState({change_duration: this.state.change_duration === "clicked" && !open ? true: open})
    }
  }


  changeDuration(key, e){
    console.log("changeDuration", key)
    if(this.props.component.unit === "hour"){
      this.setState({qty: Number(key), duration: Number(key), saveChanges: true})
    }else{
      this.setState({duration: Number(key), saveChanges: true})
    }
  }


  changeQuantityDropdown(value, max){
    return (
      <Dropdown dropup={true} onClick={(e) => e.stopPropagation()} id="duration" className="duration-qty relative flex-1 ml-0" open={!!this.state.change_duration} onToggle={this.onToggle.bind(this)}  onSelect={(key, e) => this.changeDuration(key, e) } >
        <Dropdown.Toggle noCaret >
          <div className="durtext" >
            <span>{value} {value === 1 ?  "hour": "hours"}</span>
            {/* <div className={"my-small-caret"}/> */}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="duration-options shadow-dropup h-[200px] overflow-scroll left-0  top-0 translate-y-[calc(-100%-6px)]">
        {time_range.map(t =>
          <MenuItem key={String(t)} eventKey={String(t)} active={value === t} >{t} {t === 1 ?  "hour": "hours"}</MenuItem>
        )}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  calcPrice(component, store, booked) {
    var late_fee = booked ? component.late_fee : getLateFee(moment(), getStartTime(store.date, store.start_time, store.timezone))
    var comp = JSON.parse(JSON.stringify(component))
    comp.duration = this.state.duration
    comp.qty = this.state.qty
    comp.num_staff = this.state.num_staff
    var price = getComponentPrice(comp, store, this.state.qty, false, this.state.num_staff, late_fee)
    var min_price = getComponentPrice(comp, store, this.state.qty || 1, false, this.state.num_staff, late_fee)
  
    switch(component.unit) {
      case "person":
        var people = this.state.qty
        if(people) {
          return (
            //acai bowls
            <PriceWrapper>
              <div className="flex flex-none items-center gap-1">
                <PriceDisplay amount={price} />
                <ForText /> 
                {/* <span className='text-bold text-lg'>:</span> */}
              </div>
              <div className="flex flex-1 min-w-[240px] w-full items-center gap-1">
                {this.changeQuantityInput(this.state.qty, component.unit, component.maximum, component.name)}
                <AndText />
                {this.changeQuantityDropdown(this.state.duration, 10)}
              </div>
            </PriceWrapper>
          );
        }
        if(component.minimum && Number(component.minimum)) {
          return (
            <PriceWrapper>
              <div className="flex flex-none items-center gap-1">
                <span>Starting at</span>
                <PriceDisplay amount={min_price} />
                <ForText /> 
              </div>
              <div className="flex flex-1 min-w-[240px] w-full items-center gap-1">
                {this.changeQuantityInput(this.state.qty, component.unit, component.maximum, component.name)}
                <AndText />
                {this.changeQuantityDropdown(this.state.duration, 10)}
              </div>
            </PriceWrapper>
          );
        }
        return (
          <PriceWrapper>
            <div className="flex flex-none items-center gap-1">
              <PriceDisplay amount={component.price} />
              <span>per person</span>
            </div>
            <div className="flex flex-1 min-w-[240px] items-center gap-1">
              {this.changeQuantityInput(this.state.qty, component.unit, component.maximum, component.name)}
              <AndText />
              {this.changeQuantityDropdown(this.state.duration, 10)}
            </div>
          </PriceWrapper>
        );
  
      case "hour":
        var hours = this.state.qty || 1
        if(this.state.num_staff && this.state.num_staff > 1) {
          if(hours) {
            return (
              // bartender
              <PriceWrapper>
                <div className="flex items-center gap-1">
                  <PriceDisplay amount={price} />
                  <span>for {this.state.num_staff} {pluralize(component.name)}</span>
                  <ForText />
                </div>
                <div className="flex items-center gap-1">
                  {this.changeQuantityDropdown(hours, component.maximum)}
                </div>
              </PriceWrapper>
            );
          }
          if(component.minimum) {
            return (
              <PriceWrapper>
                <div className="flex items-center gap-1">
                  <span>Starting at</span>
                  <PriceDisplay amount={min_price} />
                  <span>for {this.state.num_staff} {pluralize(component.name)}</span>
                </div>
                <div className="">
                  {this.changeQuantityDropdown(hours, component.maximum)}
                </div>
              </PriceWrapper>
            );
          }
          return (
            <PriceWrapper>
              <PriceDisplay amount={component.price} />
              <span>per hour for {this.state.num_staff} {pluralize(component.name)}</span>
              {this.changeQuantityDropdown(hours, component.maximum)}
            </PriceWrapper>
          );
        }
        if(hours) {
          return (
            <PriceWrapper>
              <div className="flex items-center gap-1">
                <PriceDisplay amount={price} />
                <ForText />
              </div>
              <div className="">
                {this.changeQuantityDropdown(hours, component.maximum)}
              </div>
            </PriceWrapper>
          );
        }
        if(component.minimum) {
          return (
            <PriceWrapper>
              <span>Starting at</span>
              <PriceDisplay amount={min_price} />
              {this.changeQuantityDropdown(hours, component.maximum)}
            </PriceWrapper>
          );
        }
        return (
          <PriceWrapper>
            <PriceDisplay amount={component.price} />
            <span>per hour</span>
            {this.changeQuantityDropdown(hours, component.maximum)}
          </PriceWrapper>
        );
  
      default:
        if(this.state.qty) {
          return (
            <PriceWrapper>
              <div className="flex items-center gap-1">
                <PriceDisplay amount={price} />
                <ForText />
              </div>
              <div className=" flex items-center gap-1">
                {this.changeQuantityInput(this.state.qty, component.unit, component.maximum || component.remaining)}
                <AndText />
                {this.changeQuantityDropdown(this.state.duration, 10)}
              </div>
            </PriceWrapper>
          );
        }
        return (
          <PriceWrapper>
            <div className="flex items-center gap-1">
              <PriceDisplay amount={price} />
              <span>per {component.unit_name || "kit"}</span>
            </div>
            <div className=" flex items-center gap-1">
              {this.changeQuantityInput(this.state.qty, component.unit, component.maximum || component.remaining)}
              <ForText />
              {this.changeQuantityDropdown(this.state.duration, 10)}
            </div>
          </PriceWrapper>
        );
    }
  }

  mapUnitToParam(unit, params){
    switch (unit){
      case "person":
        return "people"
      case "hour":
        return "duration"
      case "kit":
        return null
      default:
        return null
    }
  }

  focusParam(p){
    document.getElementById(p).focus()
    this.props.actions.assignMissing("missing")
  }

  getMissingString(unit){
    switch (unit){
      case "person":
        return "Enter # of people to see exact price."
      case "hour":
        return "Enter the duration of your event to see exact price."
      default:
        return "Change"
    }
  }

  describeParams(component, params, included, booked){
    // $4,800 for 40 people on Tues, Jan 15, 2018 for 2 hours
    if(included){
      return <div className="params px-[21px]">Included!</div>
    }

    var p = this.mapUnitToParam(component.unit, params)
    var par = p ? params[p] : true
    return (
      <div className="params px-[21px]">
        {this.calcPrice(component, params, booked)}
      </div>
    )
  }

  // {par ? <span className="change" onClick={() => this.setState({changeQty: true})}>{ this.props.readOnly ? null :  " Change"}</span> : <div className="change" onClick={() => this.props.setBooking ? this.props.setBooking() : this.focusParam(p)}>{ this.props.readOnly ? null : this.getMissingString(component.unit)}</div> }


  blockOut(date){
    if(date < moment().add(this.props.component.booking_lead_time, 'days')){
      return true
    }
    for (var d of this.state.unavailable){
      if(moment(d).isSame(date, 'day')){
        return true
      }
    }
    return false
  }


  getQty(){
    if(this.state.qty) return this.state.qty
    if(this.props.component.unit === "person") return this.props.booking.people
    if(this.props.component.unit === "hour") return this.props.booking.duration
    return 1
  }

  addToCart(){
    var qty = this.getQty()
    if(this.props.component.maximum && Number(this.props.component.maximum) > 0 && qty > this.props.component.maximum){
      this.setState({limitation: "max"})
    }else{
      var clone = Object.assign({}, this.props.component)
      clone.qty = this.state.qty
      clone.num_staff = this.state.num_staff
      clone.duration = this.state.duration !== this.props.booking.duration ? this.state.duration : null
      clone.option_values = this.state.options
      this.setState({saveChanges: false})
      this.props.onHide()
      this.props.add ? this.props.add(clone, this.props.category): this.props.actions.addToCart(clone, this.props.category)
      if(this.props.app.session){
        // this.props.actions.openCart()
        this.props.actions.setSession(false)
      }
    }
  }

  addable(){
    if(!this.props.component.options || !this.props.component.options.types) return true
    for(var o of this.props.component.options.types){
      if(!o.disabled){
        if(o.type === "multiple"){
          if(!this.state.options[o.name] || this.state.options[o.name].length === 0) return false
        }else{
          if(!this.state.options[o.name] ) return false
        }
      }
    }
    return true
  }


  confirmedAddToCart(){
    this.setState({limitation: null, saveChanges: false})
    var clone = Object.assign({}, this.props.component)
    clone.qty = null
    clone.option_values = this.state.options
    this.props.add ? this.props.add(clone, this.props.category): this.props.actions.addToCart(clone, this.props.category)
    if(this.props.app.session){
      this.props.actions.openCart()
      this.props.actions.setSession(false)
    }
  }

  confirmChanges(){
    var clone = Object.assign({}, this.props.component)
    clone.is_available = clone.remaining !== 0
    clone.qty = this.state.qty
    clone.num_staff = this.state.num_staff
    clone.duration = this.state.duration !== this.props.booking.duration ? this.state.duration : null
    clone.option_values = this.state.options
    this.props.edit ? this.props.edit(clone): this.props.actions.replaceCartItem(clone.id, this.props.category, clone)
    this.props.onHide()
  }

  savePowerup(qty, num_staff){
    this.setState({changeQty: false, qty, num_staff, saveChanges: true})

  }

  limitationTitle(){
    var component = this.props.component
    if(this.state.limitation === "min"){
      switch(component.unit){
        case "hour":
          return component.name + " requires booking for a minimum of " + component.minimum + (component.minimum === 1 ? " hour." : " hours.")
        case "person":
          return component.name + " requires booking for a minimum of " + component.minimum + "people"
        default:
          return component.name + " requires booking a minimum of " + component.minimum + pluralize(component.unit_name || " kit") + "."
      }
    }
    switch(component.unit){
      case "hour":
        return component.name + " has a maximum booking of " + component.minimum + (component.minimum === 1 ? "hour" : "hours") + "."
      case "person":
        return component.name + " has a maximum booking of " + component.minimum + "people."
      default:
        return component.name + " has a maximum booking of " + component.minimum + pluralize(component.unit_name || "kit") + "."
    }
  }

  limitationText(){
    var component = this.props.component
    var billedFor = ""
    var current = ""
    if(this.state.limitation === "min"){
      switch(component.unit){
        case "hour":
          billedFor = component.minimum + (component.minimum === 1 ? " hour" : " hours")
          current = this.props.booking.duration +  (this.props.booking.duration === 1 ? " hour" : " hours")
          return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b> even though your event is currently set for only {current}.</span>
        case "person":
          billedFor = component.minimum + " people"
          current = this.props.booking.people +  (this.props.booking.people === 1 ? " person" : " people")
          return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b> even though your event is currently set for only {current}.</span>
        default:
          billedFor = component.minimum + " " + pluralize(component.unit_name || "kit")
          return <span>You can add {component.name} to your cart, but will be <b>billed for {billedFor}</b>.</span>
      }
    }

    return <span> You can add {component.name} to your cart, but you may not have enough at your event</span>
  }

  fromPackage(id){
    if(this.props.cart.package && this.props.cart.package.length > 0 ){
      var comp_ids = Object.keys(this.props.cart.package[0].component.components)
      if(comp_ids.includes(String(id))){
        return true
      }
    }
    return false
  }

  onHide(inCart){
    if(this.state.saveChanges && this.props.inCart){
      this.setState({leaving: true})
    }else{
      this.props.onHide()
    }
  }

  changeStaff(num_staff, component){
    if (!num_staff){
      this.setState({num_staff, saveChanges: component.num_staff !== num_staff})
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (num_staff == '' || re.test(num_staff)) {
      if(num_staff.length < 7){
        this.setState({num_staff, saveChanges: component.num_staff !== num_staff})
      }
    }
  }

  render(){
    var component = this.props.component || {img: ""}
    if(component.id === null){
      return null
    }

    console.log("comp", component)

    let compIsAvailable = (component.remaining > 0 || component.remaining === undefined || component.remaining === null) && !component.wrong_location
    var image = ""
    if(component.images) image =  "https://d2c831se9gn8j1.cloudfront.net/"  + component.images[0]

    var c = () => {
      if (this.props.included || this.props.hideActions){
        return null;
      }
      if(component.remaining > 0 || component.remaining === undefined || component.remaining === null){
        return (
          <div className="buttons flex space-x-3 absolute right-5 top-5">
            {/* <img className="available" src={CircleCheck} alt=""/> */}
            {/* <div className="text text-white flex" >Available</div> */}
            <div className="flex justify-center items-center bg-white shadow-lg cursor-pointer h-10 w-10 rounded-full z-[9999] ">
              <Hearting right={true}  lists={this.props.login.wishlists || []} component={component} isPackage={false} />
            </div>
          </div>
        )
      }
      return (
        <div className="buttons flex space-x-3 absolute right-5 top-5">
          {/* <div className="text text-white" >Sold Out</div> */}
          <div className="flex justify-center items-center bg-white shadow-lg cursor-pointer h-10 w-10 rounded-full z-[9999] ">
            <Hearting right={true}  lists={this.props.login.wishlists || []} component={component} isPackage={false} />
          </div>        
        </div>
      )
    }

    var actionButton = () => {
      if (this.props.included || this.props.hideActions){
        return null;
      }
      if(component.remaining > 0 || component.remaining === undefined || component.remaining === null){
        if(this.props.inCart){
          if(this.state.saveChanges){
            if((isSet(component.remaining) || isSet(component.daily_max)) && (Number(this.state.num_staff) > Number(component.remaining  || component.daily_max)) || !this.addable()){
              return (
                <div className="relative flex items-center justify-center h-[40px] bg-primary-action-color/[0.1] rounded-[5px] text-primary-action-color px-4 w-full xs:w-fit xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2">
                  <span className="text">Save Changes</span>
                </div>
              )
            }
            return (
                <div className="xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2 ">
                  <div className={"add px-4 flex items-center gap-1 justify-center h-[40px] xs:h-[34px] w-full xs:w-fit"} onClick={() => this.confirmChanges()} >
                    <img alt="" className="cart-icon inline ml-[-2px]" src={CheckmarkWhite}/>
                    <span className="add-text xs:hidden sm:inline m-0" >Save Changes</span>
                    <span className="add-text hidden xs:inline sm:hidden m-0" >Save</span>
                  </div>
                </div>
            )
          }
          return (
            <div className="relative flex items-center justify-center h-[40px] bg-primary-action-color/[0.1] rounded-[5px] text-primary-action-color px-4 w-full xs:w-fit xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2">
              <img className="icon ml-[-8px] inline" src={InCartButton} alt=""/>
              <span className="text">{this.fromPackage(component.id) ? "Included in Package": "Added" } </span>
            </div>
          )
        }
        if((isSet(component.remaining) || isSet(component.daily_max)) && (Number(this.state.num_staff) > Number(component.remaining  || component.daily_max)) || !this.addable()){
          return (
          <div className="relative flex items-center justify-center h-[40px] bg-primary-action-color/[0.1] rounded-[5px] text-primary-action-color px-4 w-full xs:w-fit xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2">
              <span className="text">Add</span>
            </div>
          )
        }
        return (
          <div className="xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2 ">
            <div className={"add px-4 flex items-center gap-1 justify-center h-[40px] xs:h-[34px] w-full xs:w-fit"} onClick={() => this.addToCart()} >
              <img alt="" className="cart-icon inline ml-[-2px]" src={AddToCartButton}/>
              <span className="add-text m-0" >Add</span>
              <span className="add-text m-0 xs:hidden" > to Cart</span>
            </div>
          </div>
        )
      }
      return (
        <div className="w-full xs:w-fit xs:absolute xs:right-[21px] xs:top-1/2 xs:-translate-y-1/2">
          <div className='hidden sm:flex'><AvailabilityViewer name={component.name} customDropdownStyles="w-full" customDropdownMenuStyles={"absolute left-[-270px] top-auto bottom-[60px]"} lead_time={component.booking_lead_time} start={getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)} right={true} modal={true} availability={this.state.availability}/></div>
          <div className='w-full sm:hidden '><AvailabilityViewer name={component.name} customDropdownStyles="w-full h-[40px]" customDropdownMenuStyles={"fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"} lead_time={component.booking_lead_time} start={getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)} right={true} modal={true} availability={this.state.availability} /></div>
        </div>
      )
    }
    // <div className={"availability" + (this.state.calendar ? " calendar": "")} onClick={()=> this.setState({calendar: !this.state.calendar})}><img alt="" src={AvailabilityIcon} /><span className="text">Availability</span><div className="my-small-caret"/></div>
    console.log("Rendering modal, show?", this.props.show)
    return (
      <Modal show={this.props.show} onHide={() => this.onHide()} zIndex={2000} contentClassName="rounded-[5px]" customClassName="powerup-modal overflow-hidden servicePage over rounded-none sm:rounded-[5px] w-full sm:w-[602px] m-0 sm:mt-[10vh] h-full sm:h-[500px] sm:mx-auto" backdropClassName="powerup-backdrop">
        <div className="close-button hidden sm:block" onClick={() => this.onHide()} />

        <div className="content-wrapper sm:overflow-hidden rounded-none sm:rounded-[5px] w-full h-full">
          <div className="scroll-container w-full h-full">
            <div className="absolute flex justify-center items-center bg-white shadow-lg cursor-pointer h-10 w-10 rounded-full z-[9999] left-5 top-5 sm:hidden " onClick={() => this.onHide()}>
              <ChevronLeft color="#848484" className='w-7 h-7 ml-[-2px]' />
            </div>
            <div className="img-container w-[inherit] h-[300px] sm:h-[202px]">
              <img className="object-cover max-w-none h-[300px] w-full" src={image} alt={component.name}/>
            </div>
            <div className="name w-full px-[21px] max-w-full wrap"> {component.name}</div>
            {c()}
            {!this.props.included ? <div className="short-includes">{component.includes}</div> : null}
            {component.staff ?
              <div className="staff">
                <span className={"title"} > Number of {pluralize(this.props.component.name)}:</span>
                <input type="text" className={"text-field num_staff"} value={this.state.num_staff} onChange={(e) => this.changeStaff(e.target.value, component)}/>
                {isSet(this.props.component.remaining) || isSet(this.props.component.daily_max) ? (this.props.component.remaining !== 0 ? <span className="requirements"><i> {this.props.component.remaining || this.props.component.daily_max} available</i></span> : null) : null}
                </div> : null}
            {this.state.calendar ?
              <DayPickerSingleDateController
                date={this.props.booking.date ? moment(this.props.booking.date) : null}
                focused={this.state.calendar}
                onDateChange={(date) => this.props.actions.changeParams("date", date)}
                onFocusChange={({ focused }) => {console.log("focus change", focused); this.setState({calendar: focused})} }
                isDayBlocked={this.blockOut.bind(this)}
                hideKeyboardShortcutsPanel={true}
                numberOfMonths={1}/> : null
              }
            {!this.props.included && component.options && component.options.types ?
              <div>
                {!this.addable() ? <div className={"options-required"}>All options must be set to book</div> : null }
                <Options readOnly={this.props.readOnly} component={component} selectedOptions={this.state.options} save={(options) => this.setState({options, saveChanges: true})} fromCart={this.props.fromCart} />
              </div>
              : null}
            <div className="divider"/>
            <div className="description" >
              <div dangerouslySetInnerHTML={{ __html: component.description }} />
              <div style={{height: 25}}/>
            </div>

            <CustomizationModal params={this.props.booking} component={component} show={this.state.changeQty} onHide={()=> this.setState({changeQty: false})} onConfirm={(qty, s) => this.savePowerup(qty, s) }/>
            <LimitationModal component={component} limitation={this.state.limitation} store={this.props.booking} show={!!this.state.limitation} onHide={() => this.setState({limitation: false})} onConfirm={() => this.confirmedAddToCart()}/>
            <ConfirmationModal title="You have unsaved changes" text="Would you like to save these changes to the item in your cart?" show={this.state.leaving} onHide={() => this.setState({leaving: false})} secondaryAction={ () => {console.log("pressed"); this.props.onHide()}} secondaryText={"Don't Save"} confirmationText={"Save"} onConfirm={() => this.confirmChanges()} tertiary={true}/>
          </div>
        </div>
        {this.props.hidePrice ? null:
          <div className="bottom-bar relative xs:flex xs:justify-between xs:space-x-4 w-full items-center h-fit">
              <div className='h-fit block w-full min-w-full xs:flex xs:w-[auto] '>
                {compIsAvailable ?
                  this.describeParams(component, this.props.booking, this.props.included, this.props.booked)
                :
                  <div className="unavailable-warning mx-[21px] my-3 ">
                    <div className="title">{component.wrong_location ? "Unavailable at Location" : "Unavailable at Time of Event" }</div>
                    <div className="sub">{component.wrong_location ? "Services are location specific. Add this service from the correct location" : "Try a different time or another day."}</div>
                  </div>}
              </div>
              <div className='xs:flex hidden'>{component.wrong_location ? null : actionButton()}</div>
              {/* <div className='h-[40px] hidden xs:flex relative w-full flex-1 sm:hidden'>{component.wrong_location ? null : actionButton()}</div> */}
              <div className='h-fit min-w-full flex-1 xs:hidden relative w-full pb-3 px-[21px]'>{component.wrong_location ? null : actionButton()}</div>
              {/* <div className='h-fit  min-w-full flex-1 xs:hidden relative w-full sm:hidden  pb-3 px-[21px]'><div className='flex justify-center rounded-sm items-center h-[40px] w-full bg-primary-action-color text-white text-lg font-semibold'>Add to Cart</div></div> */}

              {/* <div className='flex justify-center items-center mr-8 h-[40px] w-[60px] bg-primary-action-color'>ADD</div> */}

          </div>
        }
      </Modal>
    )
  }
}


const mapStateToProps = state => ({
  login: state.login, cart: state.cart, app: state.app
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePage))
