import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import request from '../../request'
import {Dropdown, MenuItem} from 'react-bootstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import VendorRequest from './Request'
import moment from 'moment-timezone'
import Chevron from '../../img/Chevron.svg'
import EventSummary from './EventSummary'

class Overview extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      credit: 0,
      referral_count: 0,
      past_count: 0,
      upcoming_count: 0,
      services_count: 0,
      events: [],
      requests: [],
      services: [],
      ratings: [],
      vendor: {},
      referral_code: "",
      error: null,
      edit_profile: false
    }
  }

  async componentDidMount(){
    var contact_info = await request('/api/contactInfo')
    if (!contact_info || contact_info.err){
      console.log("err", contact_info)
    }else{
      //name: contact_info.contact_name || "", phone: contact_info.phone || "", email: contact_info.contact_email || "", company_name: contact_info.company_name || "", account_email: contact_info.email,
      this.setState({credit: contact_info.credit, referral_count: contact_info.referral_count, referral_code: contact_info.referral_code || ""})
      console.log("contact_info", contact_info)
    }
    this.getOverview()
  }

  async getOverview(){
    var result = await request('/api/vendor/overview')
    if(result.err){
      console.log(result)
      return
    }
    this.setState(result)
  }

  async accept(token){
    var result = await request('/api/vendor/accept/' + token, {method: "PUT"})
    if(!result.err){
      this.props.setRequestCount(this.state.requests.length - 1)
      this.setState({requests: this.state.requests.filter(r => r.token !== token )})
      this.getOverview()
    }
  }

  async decline(token){
    var result = await request('/api/vendor/decline/' + token, {method: "PUT"})
    if(!result.err){
      this.props.setRequestCount(this.state.requests.length - 1)
      this.setState({requests: this.state.requests.filter(r => r.token !== token )})
    }
  }

  async changeVendor(vend){
    var copy  = Object.assign({}, this.state.vendor)
    var vendor = Object.assign(copy, vend)
    this.setState({vendor})
  }

  async saveVendor(){
    var result = await request('/api/vendor/', {method: "PUT", body: JSON.stringify(this.state.vendor)})
    if(!result.err){
      this.setState({edit_profile: false})
    }else{
      this.setState({error: "Could not save"})
    }
  }

  async getCalendarEvents(){
    var result = await request('/api/vendor/gc/events/list')
    if(!result.err){
      console.log(result)
    }else{
      console.log(result.err)
    }
  }




  render(){
    let vendor_timezone = this.state.vendor.timezone
    return (
      <div>
        <div className="checkout-details">
          {(this.state.vendor.stripe_account_id || !this.state.vendor.payment_method) ?  null : <div>
            <div className="section-head">Payments</div>
            <div className="card section" style={{overflow:"hidden", paddingLeft: 4}}>
              <Link style={{marginTop: 0}} className="custom-button" to="/api/stripe/authorize" target="_self">Connect Your Bank Account</Link>
            </div>
          </div>}
          {(!this.state.vendor.id || this.state.vendor.calendar_connected) ?  null : <div>
            <div className="section-head">Connect Calendar</div>
            <div className="card section" style={{overflow:"hidden", paddingLeft: 4}}>
              <Link style={{marginTop: 0}} className="custom-button" to="/api/gc/auth" target="_self">Connect Your Google Calendar</Link>
            </div>
          </div>}
          {this.state.requests.length === 0 ?  null : <div>
            <div className="section-head">Requests</div>
            <div className="card section" style={{overflow:"hidden", paddingLeft: 4}}>
              <div className="your-events">
                {this.state.requests.map( (r, i) =>
                  <div key={i}>
                    <VendorRequest vendor_timezone={vendor_timezone} data={r}  accept={() => this.accept(r.token)} decline={() => this.decline(r.token)} />
                    {i !== this.state.requests.length - 1 ? <div className="divider shifted" /> : null}
                  </div>
                )}
              </div>
            </div>
          </div>}

          <div className="section-head">Events<img className="chevron" src={Chevron} alt=""/></div>
          <div className="card section" style={{overflow:"hidden"}}>
            <div className="your-events">
              <div className="title">Upcoming{Number(this.state.upcoming_count) > 0 ? " (" + this.state.upcoming_count + ")" : null}</div>
              <div className="divider" />
              {Number(this.state.upcoming_count) === 0 ? <div><i>No upcoming events</i></div> :
                this.state.events.map( (e, i) =>
                  <div key={i}>
                    <EventSummary vendor_timezone={vendor_timezone} data={e}/>
                    <div className="divider" />
                  </div>
              )}
              <div className="bottom">
                <Link to="/vendors/events" className="secondary-button">View All Events </Link>
                <div className="events-decription">
                  {this.state.upcoming_count - 2 > 0 ? <span><b>{this.state.upcoming_count - 2} more upcoming {Number(this.state.upcoming_count) - 2 === 1 ? "event" : "events"}</b></span> : null}
                  {this.state.upcoming_count - 2 > 0 && this.state.past_count > 0 ? " and " : null}
                  {this.state.past_count > 0 ? <span><b>{this.state.past_count} past {Number(this.state.past_count) === 1 ? "event" : "events"} </b></span> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="section-head">Services<img className="chevron" src={Chevron} alt=""/></div>
          <div className="card section">
            {Number(this.state.services_count) === 0 ?  <Link to="/vendors/services/add" style={{margin: 0}} className="custom-button vendor">Add New Service</Link> :
            <div>
              <Link to="/vendors/services" className="secondary-button" style={{marginTop: 0}}> View Your Services </Link>
              <div className="servicing">Right now you’re servicing <b>{this.state.services_count} {Number(this.state.services_count) === 1 ? "item" : "items"}.</b></div>
            </div>
            }

          </div>
          <div className="section-head">Profile</div>
          <div className="card section" style={{overflow:"hidden"}}>
          {this.state.edit_profile ?
            <div>
              <div style={{marginBottom: 8}}>
              <input className="text-field" value={this.state.vendor.name} placeholder="Name" onChange={(e) => this.changeVendor({name: e.target.value})} />
              </div>
              <div style={{marginBottom: 8}}>
              <input type="email" className="text-field" value={this.state.vendor.email} placeholder="Email" onChange={(e) => this.changeVendor({email: e.target.value})}/>
              </div>
              <div style={{marginBottom: 8}}>
              <input type="tel" className="text-field" value={this.state.vendor.phone} placeholder="Phone #" onChange={(e) => this.changeVendor({phone: e.target.value})} />
              </div>
              <div style={{marginBottom: 8}}>
              <input className="text-field" value={this.state.vendor.company_name} placeholder="Company Name" onChange={(e) => this.changeVendor({company_name: e.target.value})} />
              </div>
              <div style={{marginBottom: 8}}>
              <input type="text" className="text-field long" value={this.state.vendor.website} placeholder="Website" onChange={(e) => this.changeVendor({website: e.target.value})}/>
              </div>
              <div onClick={()=> this.saveVendor()} className="change">Save Changes</div>
              {this.state.error ? <div className="error-state"><span className="text"> {this.state.error}</span></div> : null}
            </div>
          :
            <div>
              <div>{this.state.vendor.name}</div>
              <div>{this.state.vendor.email}</div>
              <div>{this.state.vendor.phone}</div>
              <div>{this.state.vendor.company_name}</div>
              <div>{this.state.vendor.website}</div>
              <div onClick={()=> this.props.history.push('/vendors/profile')} className="change">Change</div>
            </div>
          }
          </div>
        </div>
        <div className="referral">
        <div className="section-head">Referrals</div>
          <div className="card">
            <div className="subheader">Referral Earnings</div>
            <div className="credit">{currencyFormatter.format(this.state.credit, { code: 'USD', precision: 0} )}</div>
            <div className="divider"/>
            <div className="subheader ref">Your Referral Link</div>
            <CopyToClipboard text={"1upevents.com/ref/" + this.state.referral_code} onCopy={() => this.setState({copied: true})}>
              <div className="ref-wrapper">
                {!this.state.copied ?
                    <span className="copy">Copy</span>
                  : <span className="copy">Copied</span>
                 }
                <div className="text-field" style={{marginTop: 8, marginBottom: 8}}>1upevents.com/ref/{this.state.referral_code} </div>
              </div>
            </CopyToClipboard>
            <div className="count">{this.state.referral_count > 0 ? "You’ve referred "+this.state.referral_count+ " " + (Number(this.state.referral_count) === 1 ? "person" : "people") + " so far!" : "You’ve referred 0 people so far. Hurry up and invite your friends so you can both earn money!"}</div>
            <div className="divider"/>
            <div className="subheader">Refer customers!</div>
            <div className="text">Earn money by referring people to 1up! We’ll pay you $100 cash for customers who sign up using your referral link and book an event.</div>
          </div>
        </div>
      </div>
    )
  }
}

// <div className="section-head">Ratings<img className="chevron" src={Chevron} alt=""/></div>
// <div className="card section" style={{overflow:"hidden"}}>
// </div>

export default withRouter(Overview)
