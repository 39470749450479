import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import AvailabilityIcon from '../../img/SoldOutViewAvailabilityIcon.svg'
import AvailabilityIconWhite from '../../img/SoldOutViewAvailabilityIconWhite.svg'

import moment from 'moment-timezone'
import { Dropdown } from 'react-bootstrap'
import { getStartTime } from './math'

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

function getDuration(start_time, duration, availability = []){
  var end_time = moment(start_time).add(duration, "hours")
  var slots = availability.filter( a => moment(a.start_time) >= start_time && moment(a.start_time).add(15, "minutes") <= end_time)
  var duration = 0
  for(var s of slots){
    if(s.is_available){
      duration += 15
    }else{
      break
    }
  }
  return duration
}


class AvailabilityViewer extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      open: false,
      timeslots: [],
      // availablity: this.props.availablity
    }
  }

  getAvailableTimeslots(start_time, duration, availability = []){
    var start = moment(start_time).subtract(5, "hours")
    var end = moment(start_time).add(duration, "hours").add(4, "hours")
    var timeslots = []
    var limit = 18
    for (var i = 0; i < limit; i++){
      // console.log("range", start.format(), end.format())
      var dur = getDuration(start, duration, availability)
      if(dur > 30) timeslots.push({start_time: start, duration: dur})
      start = moment(start).add(30, "minutes")
    }
    this.setState({timeslots, open: false})
  }

  // componentDidUpdate(prevProps){
  //   console.log("updated",this.props.availablity, prevProps.availablity )
  //   if(JSON.stringify(prevProps.availablity) !== JSON.stringify(this.props.availablity) ){
  //     this.setState({availablity: this.props.availablity})
  //   }
  // }

  changeBooking(start_time, duration){
    this.props.actions.changeParams("start_time", start_time)
    this.props.actions.changeParams("duration", duration)
  }


  message(){
    if(this.props.lead_time && Number(this.props.lead_time) > moment(this.props.start).diff(moment(), "days")){
      return (<div>
            <div>You cannot book <b>{this.props.name}</b> this close to the event date.</div>
            <div> Please choose a date at least <b>{this.props.lead_time}</b> days from today</div>
          </div>)
    }else{
      return `${this.props.name} is not available at any time on this day. Please select a different day.`
    }
  }

  onToggle(open, a, source){
    if(source.source !== "select" ){
      if(!this.state.open){
        var timezone = this.props.booking.timezone || moment.tz.guess();
        var start_time = getStartTime(this.props.booking.date, this.props.booking.start_time, timezone)
        this.getAvailableTimeslots(start_time, this.props.booking.duration, this.props.availability)
      }
      this.setState({open: !this.state.open})
    }
  }


  render(){
    var timezone = this.props.booking.timezone || moment.tz.guess();
    var start_time = getStartTime(this.props.booking.date, this.props.booking.start_time, timezone)
    var timeslots = this.state.timeslots
    return (
      <Dropdown onClick={(e) => e.stopPropagation()}  id="availablity-dropdown" className={"availability-dropdown " + this.props.customDropdownStyles}  open={this.state.open} onToggle={this.onToggle.bind(this)}>
        <CustomToggle bsRole="toggle" >
          {this.props.modal ?
            <div className={"availability w-full h-full flex items-center justify-center" + (this.state.open ? " calendar": "")} >
              <img className='inline m-0' alt="" src={AvailabilityIconWhite} /><span className="text pr-0 mr-0">Find Availability</span>
            </div>
          :
          <div className={"availability-badge-container"}>
            <div className="availability-badge">
              <img src={AvailabilityIcon} alt=""/>
              <div className="my-small-caret"/>
            </div>
          </div>
          }
        </CustomToggle>
        <Dropdown.Menu className={"time-duration " + this.props.customDropdownMenuStyles}  onSelect={(key, e) => {e.stopPropagation();}} style={ this.props.right ? {left: "auto", right: 1} : {}}>
        {timeslots.length === 0 ?
          <div className="not-available">{this.message()}</div>
          :
          <div>
            <div className="explaination"><b>{this.props.name}</b> unavailable at <b>{start_time.format("h:mma")}</b> for <b>{this.props.booking.duration} hours</b>, but is available at: </div>
            {timeslots.map( (slot, i) =>
              <div key={i} className="slot" onClick={() => this.changeBooking(getStartTime(this.props.booking.date, slot.start_time, timezone), slot.duration/60)}>
                <div className="time">{moment(slot.start_time).tz(timezone).format("h:mm a")}</div>
                <div className="duration">For {slot.duration/60} {Number(slot.duration)/60 === 1 ?  "hour" : "hrs"}</div>
              </div>
            )}
          </div>
        }
        </Dropdown.Menu>
      </Dropdown>
    )

  }
}

const mapStateToProps = state => ({
  booking: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityViewer))
