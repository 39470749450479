import React from 'react'
import Static from './StaticWrapper'
import './FAQ.css'

class FAQ extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      showAnswers: []
    }
  }

  toggleAnswer(i){
    console.log("toggling", i, this.state.showAnswers)
    if(this.state.showAnswers.includes(i)){
      var ans = this.state.showAnswers.filter(a => a !== i)
      this.setState({showAnswers: ans})
    }else{
      this.setState({showAnswers: this.state.showAnswers.concat([i])})
    }
  }

  render(){
    return (
        <Static className="faq max-w-full px-4 relative mt-8 lg:mt-[115px] ">
          <div className="static-page-header max-w-full mx-0 text-wrap md:text-[44px] text-[36px]">Frequently Asked Questions</div>
          <div className="card max-w-full" style={{padding: 35, marginTop: 35}}>
            {questions.map( (q, i) =>
              <div key={i} className="section">
                <div className="question link" onClick={ () => this.toggleAnswer(i)}>{q.question}</div>
                <div className={!this.state.showAnswers.includes(i) ? "answer": "answer show"}  >
                  {q.ans}
                </div>
              </div>
            )}
          </div>
        </Static>
      )
  }

}

export default FAQ



var questions = [{question: "How do I book an event?", ans: <div>You can instantly book your event online.
<p>- To see updated pricing and availability, just enter your event info into the book bar on the website: date, # of people, venue location zip code, and event time.</p>
<p>- To book your event with us, add the services you want to the cart and checkout (yes, it's that easy!)</p></div>},
{question: "How do I edit or add services to an event I already booked?", ans: <div>No need to make a new booking if you decide to add more services to your upcoming event after you booked. Just follow these simple steps:
<p>- Click on Account</p>
<p>- Go to Your Events</p>
<p>- Click on the upcoming event you want to add a service to </p>
<p>- Under the Items & Instructions box, click on Add New Items </p>
<p>- Search for the item you would like to add, add it, and hit save! </p></div> },
{question: "Is there a deposit and is it refundable?", ans: "We charge a nonrefundable deposit equal to 50% of the total event cost immediately once you have completed booking. The remaining balance of the total event cost will be charged upon completion of your event."},
{question: "Will I receive a confirmation email?", ans: "Yes you will receive a confirmation email at the time of booking, and a reminder email 2 days before your event."},
{question: "Can I reschedule my event?", ans: "If you need to reschedule your event and we are able to accommodate rescheduling, there is a 20% rescheduling fee. If your booking includes a venue, there may be an additional rescheduling fee. A new date within 6 months of your original date must be chosen at the time of rescheduling or it will be considered a cancellation. Change of date and time are not permitted within 14 days of an event."},
{question: "How late can I edit an event?", ans: <div>You may edit the details of an event in your dashboard up until 3 days before your event.
<p>You may edit your headcount within 10% of the headcount in your original booking up until 2 weeks before your event. Other details may be edited in your dashboard up until 3 days before your event.</p>
</div>},
{question: "Can I make special requests?", ans: "Please contact us directly at info@1upevents.com if you want to add an item to your event that we don't currently offer and we'll let you know if that's something we can accomodate."},
{question: "Can I customize a package?", ans: "No, we unfortunately cannot make changes or edit the package because packages are not customizable. You can however add additional items to your cart if they're not provided in the package and you'd like them at your event. If you only want some some parts of the package but not others, you can book the services that you want individually to customize your event. Since you are not booking the package though, you will not receive the package discount."},
{question: "Is tipping customary and how do I leave a tip?", ans: "Our pricing is all inclusive, and our onsite event teams are paid well and absolutely do not require and do not expect tipping. However, if you feel they did an exceptionally great job and would like to give them tip, we can charge your card for that -- just email us after the event with who you would like to tip and what amount, and we'll make sure they get it."},
{question: "How do I get an invoice or receipt?", ans: "After you book, we charge the 50% nonrefundable deposit within 48 hours. You will be sent a receipt through Stripe. After your event, we charge the remaining 50% and you will be sent a receipt through Stripe. If you are paying via ACH for a large event (you select this at checkout), you will be sent an invoice upon booking for the 50% nonrefundable deposit for you to pay via ACH. And you will be sent another invoice for the remaining 50% upon the completion of your event."},
{question: "Do you charge last minute booking fees?", ans: "In order to accommodate last minute bookings, we do need to charge last minute booking fees. These start 10 days before an event - you'll see a popup on our site notifying you of a price increase if you're trying to book something less than 10 days in advance."}
]
