import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/Header.css'
import logo from '../../img/1upLarge.svg'
import ConfirmationModal from '../Utils/ConfirmationModal'

class Header extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      leave: false
    }
  }

  render(){
    var title = this.props.title
    return(
      <div className="plain-header">
        <div className="wrapper">
          <div className="text">{title}</div>
          {this.props.warn ? <img className="large-logo" src={logo} alt="1up Events" onClick={() => this.setState({leave: true})}/>
            :  <Link to=""><img className="large-logo" src={logo}  alt="1up Events" /></Link>
          }
        </div>
        <ConfirmationModal confirmationText="Leave Checkout" show={this.state.leave} onConfirm={this.props.onConfirm} onHide={()=> this.setState({leave: false})} title="Are you sure you want to leave checkout?" text="This will take you back to the home page." />
      </div>
    )
  }

}

export default Header
