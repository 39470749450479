import React from 'react'
import './SpecialImage.css'

const SpecialImage = ({src, className, cardClass, imageClass, caption, alt}) => {
  return (
    <div className={"special-image " + (className || "")}>
      <div className={"image-wrapper card " + (cardClass || "")}>
        <img src={src} className={"image " + (imageClass || "")} alt={ alt || caption || ""} />
      </div>
      {caption ? <div className="caption">{caption}</div> : null}
    </div>
  )
}

export default SpecialImage
