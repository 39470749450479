import React from 'react'
import Workhours from './Workhours'
import PlacesAutocomplete from 'react-places-autocomplete'
import {Dropdown, MenuItem} from 'react-bootstrap'
import request from '../../request'
import { withRouter, Link } from 'react-router-dom'
import CalendarSelector from './CalendarSelector'
import TimezonePicker from '../Utils/TimezonePicker'

class VendorProfile extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      edit: false,
      name: "",
      company_name: "",
      stripe_account_id: "stripe",
      timezone: "",
      email: "",
      phone: "",
      website: "",
      company_address: "",
      preferred_contact: "",
      max_events: "",
      workingdays: [0,1,2,3,4,5,6],
      workinghours: {
        0: [['00:00:00', '24:00:00']],
        1: [['00:00:00', '24:00:00']],
        2: [['00:00:00', '24:00:00']],
        3: [['00:00:00', '24:00:00']],
        4: [['00:00:00', '24:00:00']],
        5: [['00:00:00', '24:00:00']],
        6: [['00:00:00', '24:00:00']]
      },
      synced_calendars: [],
      google_calendars: [],
      cals_to_sync: [],
      services: []
    }
  }

  async getGoogleCalendars(){
    var google_calendars = await request('/api/vendor/gc/calendars')
    console.log("google_calendars", google_calendars)
    if(!google_calendars.err){
      for(var c of google_calendars){
        c.calendar_id = c.id
        c.title = c.summary
      }
      return google_calendars
    }
    return []
  }

  working(t, d){
    if(t){
      var wh = Object.assign({}, this.state.workinghours)
      if(!this.state.workinghours[d] || this.state.workinghours[d].length === 0){
        wh[d] = [['00:00:00', '24:00:00']]
      }
      this.changeState({workingdays: [...this.state.workingdays, d], workinghours: wh})
    }else{
      var clone = JSON.parse(JSON.stringify(this.state.workingdays))
      var index = clone.indexOf(d);
      if (index !== -1) clone.splice(index, 1);
      this.changeState({workingdays: clone})
    }
  }

  changeState(state){
    this.setState({...state, edit: true})
  }

  changeTime(d, r, i, value){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    console.log(d, r, i, value)
    clone[d][r][i] = value
    this.changeState({workinghours: clone})
  }

  addRange(d){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    console.log(clone[d], d, clone)
    clone[d].push(['00:00:00', '24:00:00'])
    this.changeState({workinghours: clone})
  }

  removeRange(d){
    var clone = JSON.parse(JSON.stringify(this.state.workinghours))
    clone[d].pop()
    this.changeState({workinghours: clone})
  }

  getWorkinghours(){
   let wh = {}
   for(var i of [0,1,2,3,4,5,6]){
     if(this.state.workingdays.includes(i)){
       wh[i] = this.state.workinghours[i]
     }else{
       wh[i] = []
     }
   }
   return wh
  }

  getWorkdays(workinghours){
    var wd = []
    for(var i in workinghours){
      if(workinghours[i].length > 0){
        wd.push(Number(i))
      }
    }
    return wd
  }


  async componentDidMount(){
    var vendor = await request('/api/vendor')
    var synced_calendars = vendor.calendars
    for(var c of synced_calendars){
      c.id = c.calendar_id
    }
    vendor = vendor.vendor
    vendor.workinghours = vendor.workinghours || {
      0: [['00:00:00', '24:00:00']],
      1: [['00:00:00', '24:00:00']],
      2: [['00:00:00', '24:00:00']],
      3: [['00:00:00', '24:00:00']],
      4: [['00:00:00', '24:00:00']],
      5: [['00:00:00', '24:00:00']],
      6: [['00:00:00', '24:00:00']]
    }
    var workingdays = this.getWorkdays(vendor.workinghours)
    var google_calendars = await this.getGoogleCalendars()
    for(var c of google_calendars){
      c.calendar_id = c.id
      c.title = c.summary
    }
    this.setState({...vendor, workingdays, google_calendars, synced_calendars, cals_to_sync: synced_calendars})
  }

  onSelectCalendar(c){
    if(this.state.cals_to_sync.map(s=> s.id).includes(c.id)){
      this.changeState({cals_to_sync: this.state.cals_to_sync.filter(s => s.id !== c.id ) })
    }else{
      this.changeState({cals_to_sync: this.state.cals_to_sync.concat([c])})
    }
  }

  selectService(service_id, calendar_id){
    var copy = JSON.parse(JSON.stringify(this.state.google_calendars))
    var cal = copy.find(c => c.calendar_id = calendar_id)
    cal.service_id = service_id
    this.changeState({google_calendars: copy})
  }

  async saveChanges(){
    var copy = Object.assign({}, this.state)
    // copy.payment = copy.payment.value
    // copy.location_names = getLocationNames(this.state.regions, this.state.locations)
    copy.workinghours = this.getWorkinghours()
    await request('/api/vendor' , {method: "PUT", body: JSON.stringify(copy)})
    this.setState({edit: false})
    //this.props.history.push("/vendors/overview")
  }
  //
  // async connectToStripe(){
  //   window.location.href = '/api/stripe/authorize';
  // }

  render(){
    const cssClasses = {
      input: 'address text-field long',
      autocompleteContainer: 'my-autocomplete-container'
    }
    return (
      <div className="survey">
      <div className="application card">

        {!this.state.stripe_account_id ?
          <div >
            <Link style={{marginLeft: 0}} className="custom-button" to="/api/stripe/authorize" target="_self">Connect Your Bank Account</Link>
          </div>
        : null}

        <div className="group" >
          <div className="question">Your Full Name:*</div>
          <input className="text-field" value={this.state.name} onChange={(e) => this.changeState({name: e.target.value})} />
        </div>
        <div className="group" >
          <div className="question">Company (if applicable):</div>
          <input className="text-field" value={this.state.company_name} onChange={(e) => this.changeState({company_name: e.target.value})} />
        </div>
        <div className="group" >
          <div className="question">Email:*</div>
          <input type="email" className="text-field" value={this.state.email} onChange={(e) => this.changeState({email: e.target.value})}/>
        </div>
        <div className="group" >
          <div className="question">Phone:*</div>
          <input type="tel" className="text-field" value={this.state.phone} onChange={(e) => this.changeState({phone: e.target.value})} />
        </div>
        <div className="question">Website:</div>
        <input type="text" className="text-field long" value={this.state.website} onChange={(e) => this.changeState({website: e.target.value})}/>
        <div className="question">Address:</div>
        <PlacesAutocomplete classNames={cssClasses} inputProps={{id: "address", value: this.state.company_address, onChange: (company_address) => this.changeState({company_address}), placeholder: "E.g. 123 Main St, San Francisco" }}   />
        <div className="question">Preferred Timezone:</div>
        <TimezonePicker style={{marginTop: 10}} value={this.state.timezone} update={(timezone) => this.changeState({timezone})} />
        <div className="question">What is the maximum number events you can serve at one time?</div>
        <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Leave blank if there is no limit.</div>
        <input type="number" style={{marginBottom: 30}} className="text-field" value={this.state.max_events} onChange={(e) => this.changeState({max_events: e.target.value})}/>
        <div className="question">{"What's the best way to contact you?*"}</div>
        <div className="sort-box" style={{display: "block", width: "100%", height: 38, float: "none", marginBottom: 20}}>
          <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" className="source-dropdown">
            <Dropdown.Toggle noCaret >
              <div className="text-field" style={{display: "block", width: 200, height: 30, float: "none"}}>
                <span style={{textTransform: "capitalize"}}>{this.state.preferred_contact}</span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
              <MenuItem eventKey="1" active={this.state.preferred_contact === "email"}  onSelect={() => this.changeState({preferred_contact: "email" })}>Email</MenuItem>
              <MenuItem eventKey="2" active={this.state.preferred_contact === "text"} onSelect={() => this.changeState({preferred_contact: "text" })}>Text</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="question">When would you like to offer your services for events?*</div>
        <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Customize your start and end times, or add multiple time-blocks.</div>
        <Workhours style={{marginBottom: 20}} workhours={this.state.workinghours} workingdays={this.state.workingdays} changeTime={this.changeTime.bind(this)} working={this.working.bind(this)} addRange={(d) => this.addRange(d)} removeRange={(d) => this.removeRange(d)}/>
        {this.state.google_calendars.length > 0 ?
          <div>
            <div className="question">What calendars would you like to keep synced?*</div>
            <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Make sure to only select calendars where you want the events to block your availability</div>
            <CalendarSelector calendars={this.state.google_calendars} selected={this.state.cals_to_sync} onSelect={(c) => this.onSelectCalendar(c)} />
          </div>
        : null}
        {this.state.edit ?
          <div className="bottom">
            <button className={"secondary-button"} onClick={() => this.props.history.push("/vendors/dashboard")}>Cancel</button>
            <button className={"custom-button"} onClick={() => this.saveChanges()}>Save Changes</button>
          </div>
        : null}

      </div>
      </div>


    )
  }
}

export default withRouter(VendorProfile)
