import 'react-dates/initialize';
import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import * as cartActions from '../../actions/'
import Card from '../Card'
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-override.css'
import moment from 'moment-timezone'
import request from "../../request"
import Duration from '../../img/BookBarDuration.svg'
import DateIcon from '../../img/BookBarDate.svg'
import Location from '../../img/BookBarLocation.svg'
import People from '../../img/BookBarPeople.svg'
import RedDuration from '../../img/BookBarDurationRed.svg'
import RedDateIcon from '../../img/BookBarDateRed.svg'
import RedLocation from '../../img/BookBarLocationRed.svg'
import RedPeople from '../../img/BookBarPeopleRed.svg'
import {Dropdown, MenuItem} from 'react-bootstrap'
import CircleCheck from '../../img/CheckmarkCircle.svg'
import TimePicker from 'rc-time-picker';
import Alert from '../Utils/Alert'
import ConfirmationModal from '../Utils/ConfirmationModal'
import TimezonePicker from '../Utils/TimezonePicker'

import { getStartTime } from '../Utils/math'


async function getLocation(){
  var res = await request("https://ipinfo.io/json?token=b143d73a857a48")
  return res
}

var time_range = [];
for (var i = .5; i <= 9.5; i+= 0.5) {
    time_range.push(i);
}

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}


class BookBar extends Component {
  constructor(props){
    super(props)
    this.zipInput = new Set()
    this.state = {
      focused: false,
      zip: false,
      zipFocus: false,
      zipInput: false,
      location: "office",
      duration: false,
      valid_zipcodes: [],
      email: "",
      subscribed: false,
      set_time: false,
      time_open: false,
      late_fee: false,
      zipcode: this.props.values.zipcode,
      same_event: false,
      same_event_id: "",
      same_event_start: ""
    }
  }

  async componentDidMount(){

    var locations = await request('/api/locations', {method: "GET"})
    if(!locations.err){
      var codes = locations.map( l => l.zipcodes)
      var valid_zipcodes = [].concat(...codes)
      this.setState({valid_zipcodes})
      this.props.changeBooking("all_locations", locations)
    }
    if(!this.props.values.zipcode){
      var zipcode = await getLocation()
      console.log("zipcode", zipcode)
      zipcode = zipcode.postal
      this.props.changeBooking("zipcode", zipcode)
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.app.missing && this.props.values.date && this.props.values.start_time && this.props.values.people && this.props.values.duration && this.props.values.zipcode && this.props.values.location){
            this.props.missing("")
    }
  }

  displayFormat(){
    return "ddd, MMM D, YYYY"
  }

  changeZipcode(zipcode){
    this.setState({zipcode})
    if(zipcode.length === 5){
      this.props.changeBooking("zipcode", zipcode)
      this.props.changeBooking("servicable", this.state.valid_zipcodes.includes(zipcode))
    }
  }

  blockOut(date){
    if(this.props.login.admin) return false
    return date < moment().add(2, 'days').subtract(5, "hours")
  }

  isOutsideRange(date){
    if(this.props.login.admin) return false
    return date < moment()
  }

  displayWithCommas(value){
    if(typeof value === 'string'){
      value = value.replace(/,/g , "");
      try{
        Number(value)
      }catch (err){
        return
      }
      this.setState({budget: Number(value).toLocaleString('en', {useGrouping:true})})

    }else{
      this.setState({budget: value.toLocaleString('en', {useGrouping:true})})
    }
  }

  ziptext(){
    if(this.state.zipcode){
      switch (this.props.values.location){
        case "venue":
          return <span>Your venue <i><div className="code min-w-[60px] max-w-[60px]">({this.state.zipcode})</div></i></span>
        case "find":
          return <span>Find venue <i>(+$500)</i></span>
        default:
          return this.state.zipcode
      }
    }else{
      return <span className={this.props.app.missing ? "input-missing" : "" }>{"Zip code & venue"}</span>
    }

  }

  durationText(duration){

    if(duration === 1){
      return "Around 1 hour"
    }else if(duration === 10){
      return "10 or more hours"
    }else if(duration){
      return "Around " +duration + " hours"
    }
    return <span className={this.props.app.missing ? "input-missing" : "" }>Duration</span>
  }

  onToggle(open, a, source){
    if(source === 'keydown'){
      this.setState({duration: open})
    }else if(source === "click" &&  this.state.duration === "focused"){

      this.setState({duration: "clicked"})
    }else if(source === "select"  &&  this.state.duration === "focused"){
      this.setState({duration: false})
    }else{

      this.setState({duration: this.state.duration === "clicked" && !open ? true: open})
    }
  }

  focusZipcode(){
    console.log("focusZipcode")
    if(!this.state.zip){
      this.setState({zip: "focused"})
      setTimeout(() => document.getElementById("zipcode-text").focus(), 0)
    }
  }

  changePeople(val){
    if (!val){
      this.props.changeBooking("people", val)
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.props.changeBooking("people", val)
      }
    }
  }


  changeDate(date){
    var start_time;
    if(this.props.values.start_time && this.props.values.timezone){
      start_time = getStartTime(date, this.props.values.start_time, this.props.values.timezone )
    }else{
      start_time = moment(date)
    }
    if(start_time.diff(moment(), "days") < 6){
      this.setState({late_fee: true})
    }
    this.props.changeBooking("date", date)
    var events  =  this.props.login.events || []
    for( var e of events){
      var e_start = moment(e.start_time).tz(this.props.values.timezone || e.timezone)
      if(e_start.isSame(start_time, "day")){
        this.setState({same_event: true, same_event_id: e.id, same_event_start: e_start})
        break
      }
    }
  }

  changeStartTime(value){
    if(this.props.values.date && this.props.values.timezone){
      var start_time = getStartTime(this.props.values.date, value, this.props.values.timezone )
      var old_start = getStartTime(this.props.values.date, this.props.values.start_time, this.props.values.timezone )
      if(start_time.diff(moment(), "days") < 6 && old_start.diff(moment(), "days") >= 6){
        this.setState({late_fee: true})
      }
    }
    this.props.changeBooking("start_time", value.format())
  }

  onSelect(key, e){
    this.props.changeBooking("duration", Number(key))

  }

  async subscribe(){
    if(!this.state.email){
      document.getElementById("subscribe-email").focus()
      return
    }
    var params = {method: "POST", body: JSON.stringify({email: this.state.email, zipcode: this.state.zipcode})}
    var subscribed =  await request('/api/subscribe', params )
    if(!subscribed.err){
      this.setState({subscribed: true})
    }else{
      if(subscribed.err.code === "23505"){
        this.setState({subscribed: true})
      }
      console.log(subscribed.err)
    }
  }

  updateTimezone(value){
    this.props.changeBooking("timezone", value)
  }


  render(){
    var dropdown_ids = ["zipcode-text", "venue", "find", "subscribe-email", "service-link", "zipcode", "subscribe-button", "timezone-selector", "dropdown-custom-3", "America/Los_Angeles", this.props.values.timezone, "America/Denver", "America/Phoenix", "America/New_York", 'America/Chicago']
    var zipStyle = {}
    if(this.state.zip){
      zipStyle.display = "block"
    }else{
      zipStyle.display = "none"
    }
    let missing = ""
    if(this.props.app.missing === "missingAlert"){
      missing = " missing"
    }
    var start_time = this.props.values.start_time ||  moment().format()
    let dividerClasses = "after:absolute after:top-0 after:translate-y-[30%] after:right-0 border-none after:w-[1px] after:bg-[#b8b8b866] after:inline-block"

    if(this.props.large) {
      dividerClasses += " after:h-[34px] "
    } else {
      dividerClasses += " after:h-[30px] "
    }


    return (
      <Card className={"bookbar" + (this.props.large ? " large":" w-[714px]") + missing}>
        <div className="bookbar-container flex">
          <img src={!this.props.values.date && this.props.app.missing ? RedDateIcon : DateIcon} className="icon date" alt="Date" />
          <div className={!this.props.values.date && this.props.app.missing ? "input-missing relative " + dividerClasses : "relative " + dividerClasses}><SingleDatePicker id="date_input"
            date={this.props.values.date ? moment(this.props.values.date) : null}
            focused={this.state.focused}
            onDateChange={(date) => this.changeDate(date)}
            onFocusChange={({ focused }) => this.setState({focused})}
            displayFormat={this.displayFormat}
            isOutsideRange={(date) => this.isOutsideRange(date)}
            isDayBlocked={(date) => this.blockOut(date)}
            hideKeyboardShortcutsPanel={true}
            placeholder="Date of event"
            readOnly={true}
            numberOfMonths={1}/>
          </div>
            <Dropdown onClick={(e) => e.stopPropagation()} id="time-duration" className={"time-dropdown " + (this.props.large ? "leading-[56px] " : "leading-[48px] ") + dividerClasses} open={this.state.set_time} onToggle={(open, a, source) => {if(source.source !== "select" ) this.setState({set_time: !this.state.set_time})}} >
            <CustomToggle bsRole="toggle" >
              {this.props.values.start_time ?
                <div className="time-text" >
                  <img src={!this.props.values.duration && this.props.app.missing ? RedDuration : Duration} className="icon" alt="duration" />
                  <span className={(this.props.app.missing && (!this.props.values.start_time || !this.props.values.duration)) ? "input-missing" : ""} >{moment(start_time).format('h:mma')} to {this.props.values.duration ? moment(start_time).add(this.props.values.duration, "hours").format('h:mma') : "when?"}</span>
                </div>
              :
              <div className="time-text" >
                <img src={(!this.props.values.start_time || !this.props.values.duration) && this.props.app.missing ? RedDuration : Duration} className="icon" alt="duration" />
                  <span className={this.props.app.missing ? "input-missing" : ""} >Select a time</span>
                </div>
              }
            </CustomToggle>
            <Dropdown.Menu className="time-duration top-full"  onSelect={(key, e) => {e.stopPropagation();}} style={ this.props.right ? {left: "auto", right: 1} : {}}>
              <div className="question">When should your event be?</div>
              <TimePicker
                  ref={(ref) => this.time_picker = ref }
                  id={"start_time"}
                  showSecond={false}
                  value={this.props.values.start_time ? moment(start_time) : null}
                  defaultOpenValue={moment().startOf("hour")}
                  className={(this.state.time_open ? "time open": "time") + (this.props.app.missing ? " input-missing" : "")}
                  placeholder="Start time"
                  onChange={(value) => this.changeStartTime(value) }
                  format={'h:mma'}
                  minuteStep={15}
                  getPopupContainer = {node => node}
                  onOpen={() => this.setState({time_open: true})}
                  onClose={() => this.setState({time_open: false})}
                  use12Hours
                />
                <span className="to"> to </span>
              <Dropdown onClick={(e) => e.stopPropagation()} id="duration" className="duration" open={!!this.state.duration} onToggle={(open, a, b) => this.onToggle(open, a, b.source)}  onFocus={() => {console.log("should open"); this.setState({duration: !this.state.duration ? "focused": false})}} onSelect={(key, e) => this.onSelect(key, e)} >
                <Dropdown.Toggle noCaret >
                  <div className="durtext text-field" >
                    { !this.props.values.duration ?
                      <span className={(this.props.app.missing ? " input-missing" : "")}>End time</span>
                      :
                      <div>
                      <span>{this.props.values.start_time ? moment(this.props.values.start_time).add(this.props.values.duration, "hours").format('h:mma') : moment().add(this.props.values.duration, "hours").format('h:mma')}</span>
                      <div className="duration-text">{this.props.values.duration} hr</div>
                      <div className={"my-small-caret " +(!this.props.values.duration && this.props.app.missing ? " missing-border" : "")}/>
                      </div>
                    }
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="duration-options">
                  {time_range.map(t =>
                    <MenuItem key={String(t)} className="list-member" eventKey={String(t)} active={this.props.values.duration === t} >{moment(start_time).add(t, "hours").format('h:mma')} <div className="number" >{t} hr</div></MenuItem>
                  )}
                  <MenuItem className="list-member" eventKey="10" active={this.props.values.duration === 10} >{moment(start_time).add(10, "hours").format('h:mma')} <div className="number" >+10 hr</div></MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
          <div className={"people flex items-center " + dividerClasses}>
            <img src={!this.props.values.people && this.props.app.missing ? RedPeople : People} className="icon mb-0" alt="people" />
            {this.props.values.people ? <span className="text" style={{position: "absolute", left: 0}}><span style={{color: "white"}}>{this.props.values.people}</span>{Number(this.props.values.people) === 1 ? " person": " people"}</span>: null }
            <input id="people" className={!this.props.values.people && this.props.app.missing ? "input-missing " : ""} style={{position: "absolute", left: 0, background: "transparent"}} value={this.props.values.people} onChange={(e) => this.changePeople(e.target.value)} placeholder="# of people"/>
          </div>
          <div onFocus={() => this.focusZipcode()} className={"zipcode text-left" + (this.state.zip ? " selected": "")} id="zipcode"  tabIndex="0"  onBlur={() => setTimeout(() => {if(!dropdown_ids.includes(document.activeElement.id)) this.setState({zip: false})}, 0)} >
            <div className="ziptext" onClick={() => this.state.zip === "focused" ?  this.setState({zip: true}):  this.setState({zip: !this.state.zip})}>
              <img src={!this.state.zipcode && this.props.app.missing ? RedLocation : Location} className="icon mb-0" alt="location" />
              {this.ziptext()}
            </div>
            <div className="zip-dropdown" style={zipStyle} >
              <input className={"text-field" + (!this.state.zipcode && this.props.app.missing ? " missing-border" : "")} type="number" id="zipcode-text" value={this.state.zipcode} onChange={(e) => this.changeZipcode(e.target.value)} placeholder="Enter your zip code"  onFocus={()=> console.log("focus zip!") }/>
              {this.props.values.servicable ?
                <div>
                  <div className="question">Where would you like your event to take place?</div>
                  <div  onChange={(e) => this.props.changeBooking("location", e.target.value)} style={{fontSize: 15, boxShadow: "0 0"}}>
                    <input id="venue" type="radio" value="venue" name="location" defaultChecked={this.props.values.location === "venue"} />
                    <label htmlFor="venue">{"Your venue (office, home, or space you've booked already)."}</label>
                    <br/>
                    <input id="find" type="radio" value="find" name="location" defaultChecked={this.props.values.location === "find"}/>
                    <label htmlFor="find" style={{marginBottom: 0}}>{"Help me find a venue"}<i> (+$500)</i></label>
                  </div>
                  <div className="venue"> Our Event Team will contact you after you book. You’ll be charged $500 plus the cost of the venue. </div>
                </div>
                : (this.state.zipcode.length === 5 ?
                  <div>
                      <TimezonePicker style={{marginTop: 10}} value={ this.props.values.timezone} update={(timezone) => this.updateTimezone(timezone)} />
                      <div className="not-available" ><span>We’re not serving your area locally yet. But you can still book our remote offerings!</span></div>
                      <div className="enter-email">Enter your email address to be notified when we expand: </div>
                      <input type="email" id="subscribe-email" style={this.state.subscribed ? {background: "rgba(0,0,0,0.08)"}: {}} className="text-field" value={this.state.email} onChange={e => this.setState({email: e.target.value})} placeholder="Email Address"/>
                      {this.state.subscribed ? <div className="secondary-button accepted" style={{width: "100%", textAlign: "center"}}><img src={CircleCheck} alt="" style={{height: 16, marginBottom: 2}} />  Subscribed</div>
                        : <div id="subscribe-button" className="secondary-button" style={{width: "100%", textAlign: "center"}} onClick={()=> this.subscribe()}>Sign Up for Updates</div>
                      }
                  </div>
                    :  null)
                }
            </div>
          </div>
          
          {this.props.large ? <Link to="/events" className="custom-button">Get Started</Link> : null}
          <Alert show={this.state.late_fee} zIndex={9005} onHide={() => this.setState({late_fee: false})}
            title={"Last minute booking fees may apply."}
            text="Your event is less than 6 days away, which means the prices shown will reflect an increase in order to accommodate last minute bookings. Please choose a date at least 6 days away to avoid any last minute fees." />
          <ConfirmationModal show={this.state.same_event} zIndex={9005} onHide={() => this.setState({same_event: false})}
            title={"Add to your existing event? "}
            text={() => "You already have an event on scheduled for " + moment(this.state.same_event_start).format("ddd, MMM D, YYYY [at] h:mma") + ". Would you like to add to that same event?" }
            onConfirm={() => this.props.history.push({pathname: "/your-events/" + this.state.same_event_id, state: {addItems: true}})}
            confirmationText="Add To Event"
            secondaryText="Book New Event"
          />
        </div>
      </Card>
    )
  }
}


const mapStateToProps = state => ({
  values: state.booking, app: state.app, cart: state.cart, login: state.login
})

const mapDispatchToProps = dispatch => ({
  changeBooking: bindActionCreators(cartActions, dispatch).changeParams, missing: bindActionCreators(cartActions, dispatch).assignMissing
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBar))


// <MenuItem className="list-member" eventKey="1.5" active={this.props.values.duration === 1.5} >{moment(start_time).add(1.5, "hours").format('h:mma')} <div className="number" >1.5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="2" active={this.props.values.duration === 2} >{moment(start_time).add(2, "hours").format('h:mma')} <div className="number" >2 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="2.5" active={this.props.values.duration === 2.5} >{moment(start_time).add(2.5, "hours").format('h:mma')} <div className="number" >2.5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="3" active={this.props.values.duration === 3} >{moment(start_time).add(3, "hours").format('h:mma')} <div className="number" >3 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="3.5" active={this.props.values.duration === 3.5} >{moment(start_time).add(3.5, "hours").format('h:mma')} <div className="number" >3.5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="4" active={this.props.values.duration === 4} >{moment(start_time).add(4, "hours").format('h:mma')} <div className="number" >4 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="4.5" active={this.props.values.duration === 4.5} >{moment(start_time).add(4.5, "hours").format('h:mma')} <div className="number" >4.5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="5" active={this.props.values.duration === 5} >{moment(start_time).add(5, "hours").format('h:mma')} <div className="number" >5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="5.5" active={this.props.values.duration === 5.5} >{moment(start_time).add(5.5, "hours").format('h:mma')} <div className="number" >5.5 hr</div></MenuItem>
// <MenuItem className="list-member" eventKey="6" active={this.props.values.duration === 6} >{moment(start_time).add(6, "hours").format('h:mma')} <div className="number" >+6 hr</div></MenuItem>
