import React from 'react'
import CheckboxControl from './CheckboxControl'



export default class Options extends React.Component{

  componentDidMount(){
    for( var t of this.props.component.options.types){
      if(!this.props.selectedOptions[t.name] && t.type !== "multiple"){
        for(var o of t.options){
          if(o.name === "Standard"){
            this.setOption("Standard", t)
          }
        }
      }
    }
  }

  setOption(val, t){
    var clone = Object.assign({}, this.props.selectedOptions)
    clone[t.name] = val;
    this.props.save(clone)
  }

  setCustomOption(e, t){
    var clone = Object.assign({}, this.props.selectedOptions)
    clone[t.name] = "Custom";
    clone["custom"+t.name] = e.target.value;
    this.props.save(clone)
  }

  setMultipleCustomOption(e, t){
    var clone = Object.assign({}, this.props.selectedOptions)
    if(clone[t.name] && clone[t.name].length >= t.limit){
      clone["custom"+t.name] = e.target.value;
      this.props.save(clone)
      return
    }
    if (clone[t.name]){
      if (!clone[t.name].includes("Custom")) clone[t.name].push("Custom")
    }else{
      clone[t.name] = ["Custom"]
    }
    clone["custom"+t.name] = e.target.value;
    this.props.save(clone)
  }

  checkboxChange(checked, opt, limit, t){
    var clone = Object.assign({}, this.props.selectedOptions)
    if(checked){
      if(clone[t.name] && clone[t.name].length >= limit){
        return
      }
      clone[t.name] ? clone[t.name].push(opt) : clone[t.name] = [opt]
      this.props.save(clone)
    }else{
      clone[t.name] = clone[t.name].filter(item => item !== opt)
      this.props.save(clone)
    }
  }

  options(t){
    const options = t.options.filter(o => !o.disabled)
    if(t.type === "multiple"){
      return (
        <div className="options">
          {options.map((opt, key) => {
            return opt.name.toLowerCase() !== "custom"  ?
              <div className="opt" key={key + opt.name} >
                <CheckboxControl checked={this.props.selectedOptions[t.name] ? this.props.selectedOptions[t.name].includes(opt.name) : false}  onChange={(checked) => this.checkboxChange(checked, opt.name, t.limit, t)} />
                <label onClick={() => this.checkboxChange(this.props.selectedOptions[t.name] ? !this.props.selectedOptions[t.name].includes(opt.name) : true,  opt.name, t.limit, t)} htmlFor={opt.name + (this.props.fromCart ? "c": "")}>{opt.name}</label>
              </div>
            :
            <div className="opt" key={key} style={{display: "inline-table"}}>
                <CheckboxControl checked={this.props.selectedOptions[t.name] ? this.props.selectedOptions[t.name].includes(opt.name) : false}  onChange={(checked) => this.checkboxChange(checked, opt.name, t.limit, t)} />
                <input type="text" className="text-field" value={this.props.selectedOptions["custom"+t.name]} onChange={(e) => this.setMultipleCustomOption(e, t)} placeholder="Custom" />
              </div>
          }
          )}
        </div>
      )
    }

    return (
      <div className="options">
        {options.map((opt, key) =>
          opt.name.toLowerCase() !== "custom" ? (
            <div className="opt" key={key + opt.name}>
              <input
                type="radio"
                id={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}
                value={opt.name}
                name={`${t.name}${this.props.fromCart ? "c" : ""}`}
                onChange={(e) => this.setOption(e.currentTarget.value, t)}
                checked={this.props.selectedOptions[t.name] === opt.name}
              />
              <label htmlFor={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}>
                {opt.name}
              </label>
            </div>
          ) : (
            <div className="opt" key={key} style={{display: "inline-table"}}>
              <input
                type="radio"
                id={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}
                value={opt.name}
                name={`${t.name}${this.props.fromCart ? "c" : ""}`}
                onChange={(e) => this.setOption(e.currentTarget.value, t)}
                checked={this.props.selectedOptions[t.name] === opt.name}
              />
              <label htmlFor={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}> </label>
              <input
                type="text"
                className="text-field"
                value={this.props.selectedOptions[`custom${t.name}`]}
                onChange={(e) => this.setCustomOption(e, t)}
                placeholder="Custom"
              />
            </div>
          )
        )}
      </div>
    )
  }

  readOnly(t){
    if(t.type === "multiple"){
      return (
        <div className="options">
          {t.options.map((opt, key) => {
            return opt.name.toLowerCase() !== "custom"  ?
              <div className="opt" key={key + opt.name} >
                <CheckboxControl style={{cursor: "auto"}} onChange={() => null} disabled={true} checked={this.props.selectedOptions[t.name] ? this.props.selectedOptions[t.name].includes(opt.name) : false} />
                <label style={{cursor: "auto"}}  htmlFor={opt.name + (this.props.fromCart ? "c": "")}>{opt.name}</label>
              </div>
            :
            <div className="opt" key={key} style={{display: "inline-table"}}>
                <CheckboxControl style={{cursor: "auto"}} onChange={() => null} checked={this.props.selectedOptions[t.name] ? this.props.selectedOptions[t.name].includes(opt.name) : false} />
                <input type="text" disabled className="text-field disabled" value={this.props.selectedOptions["custom"+t.name]} placeholder="Custom" />
              </div>
          }
          )}
        </div>
      )
    }

    return (
      <div className="options">
        {options.map((opt, key) =>
          opt.name.toLowerCase() !== "custom" ? (
            <div className="opt" key={key + opt.name}>
              <input
                type="radio"
                disabled
                id={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}
                value={opt.name}
                name={`${t.name}${this.props.fromCart ? "c" : ""}`}
                onChange={(e) => this.setOption(e.currentTarget.value, t)}
                checked={this.props.selectedOptions[t.name] === opt.name}
              />
              <label htmlFor={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}>
                {opt.name}
              </label>
            </div>
          ) : (
            <div className="opt" key={key} style={{display: "inline-table"}}>
              <input
                type="radio"
                disabled
                id={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}
                value={opt.name}
                name={`${t.name}${this.props.fromCart ? "c" : ""}`}
                onChange={(e) => this.setOption(e.currentTarget.value, t)}
                checked={this.props.selectedOptions[t.name] === opt.name}
              />
              <label htmlFor={`${t.name}_${opt.name}${this.props.fromCart ? "c" : ""}`}> </label>
              <input
                type="text"
                disabled
                className="text-field"
                value={this.props.selectedOptions[`custom${t.name}`]}
                onChange={(e) => this.setCustomOption(e, t)}
                placeholder="Custom"
              />
            </div>
          )
        )}
      </div>
    )
  }

  render(){
    var types = this.props.component.options.types.filter(t => !t.disabled)
    return (
      <div>
        {types.map( (t, key) =>
          <div key={key}>
            <div className="options-head-wrapper" style={{marginTop: 10}}> <span className="options-header">{t.name}</span> {t.limit ? <i>(Limit {t.limit})</i> : null}</div>
            {this.props.readOnly ? this.readOnly(t) : this.options(t)}
          </div>
        )}
      </div>
    )
  }


}
// const Options = ({component, save, fromCart, selectedOptions}) => {
