import React from 'react'
import '../../css/Utils/Sort.css'
import {Dropdown, MenuItem} from 'react-bootstrap'

function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}


const Selector = ({value, update, options, placeholder, pretext, className, disabled, style, id}) => {
  return (
    <div className={"sort-box " + (className || "") } style={style}>
      <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" disabled={disabled} >
        <Dropdown.Toggle noCaret >
          <div className="sort-text">
            {value ? <span>{pretext}{value.name}</span> : <span>{placeholder || "Choose One"}</span>}
            <span className="my-small-caret"/>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu id={id} >
          {options.map((opt, i) =>
            <MenuItem id={opt.value} key={i} eventKey={i} active={value ? value.value === opt.value: false} onSelect={() => update(opt)}>{opt.name}</MenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

}

export default Selector
