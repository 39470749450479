import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'


class Alert extends Component {

  render(){

    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="customize-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title"> { typeof this.props.title === "string" ? this.props.title : this.props.title()}</div>
          <div className="text">{typeof this.props.text === "string" ? this.props.text : this.props.text()}</div>
          <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={this.props.onHide}>{ this.props.buttonText || "Dismiss"}</div>
        </div>
      </Modal>
    )
  }

}

export default Alert
