import React, {Component} from 'react'
import { withRouter, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import request from '../../request'
import './Overview.css'
import { WishlistSummary }  from './Summaries'
import Title from '../Title'
import Static from '../StaticPages/StaticWrapper'
import Chevron from '../../img/Chevron.svg'
import ConfirmationModal from '../Utils/ConfirmationModal'
import CreateWishlist from '../Utils/CreateWishlist'

class Overview extends Component{

  constructor(props){
    super(props)
    this.state = {
      lists: [],
      merge: null,
      create: false
    }
  }

  async getLists(){
    var lists = await request('/api/wishlists')
    console.log(lists)
    if (lists.err){
      console.log(lists.err)
      return
    }else{
      for (var l of lists){
        if(!l.packages) l.packages = []
        if(!l.components) l.components = []
      }
      this.setState({lists})
    }
  }

 componentDidMount(){
    this.getLists()
  }

  async remove(list_id){
    var list = await request('/api/wishlists', {method: "DELETE" , body: JSON.stringify({list_id}) })
    this.getLists()
    this.props.actions.deleteWishlist(list_id)
  }

  create(){
    this.getLists()
    this.setState({create: false})
  }

  merge(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: this.state.merge, componentsRemoved: []}})
  }

  replace(){
    this.props.history.push({pathname: "/event-rebooked", state: {empty: true, event_id: this.state.merge, componentsRemoved: []}})
  }

  shouldMerge(id){
    var cart_count = 0;
    for (var key in this.props.cart){
      cart_count += this.props.cart[key].length
    }
    if(cart_count === 0){
      this.props.history.push({pathname: "/event-rebooked", state: {empty: false, event_id: id, componentsRemoved: []}})
    }else{
      this.setState({merge: id})
    }
  }

  async createList(name){
    var list = await request('/api/wishlist', {method: "POST", body: JSON.stringify({name})})
    this.props.history.push('/wishlists/' + list.list_id + "/" + name.replace(/ /g, '_').replace(/\//g,"-"))
  }

  render(){
    if(!this.state.lists) return null

    return (
      <Static className="wide">
        <div className="nav-links"> <Link to="/account" className="link">Account</Link>  <img src={Chevron} alt=""/> </div>
        <div className="static-page-header">Wish Lists</div>
        <div style={{marginRight: "auto", marginLeft: "auto", width: 996, marginBottom: 40}}><button className="secondary-button" style={{float: 'none'}} onClick={() => this.setState({create: true})}>Create New List</button></div>
        <div className="section-wrapper">
          <div className="dashboard">
            <Title title="All Lists" subtitle=""/>
            <div className="event-list">
            {this.state.lists.length === 0 ? <div style={{ marginLeft: 30, color: "var(--primary-content-color)", fontSize: 15}}><i>{"You don't have wishlists"}</i></div> : null}
            {this.state.lists.map((e, i) =>
              <WishlistSummary key={i} e={e} components={e.components} packages={e.packages} venue={e.venue} remove={(id) => this.remove(id)}/>
            )}
            </div>
          </div>
        </div>
        <ConfirmationModal title="You have items in your cart already" text="Would you like to replace your cart or merge it with your wishlist?" show={!!this.state.merge} onHide={() => this.setState({merge: null})} secondaryAction={ () => this.merge()} secondaryText={"Merge"} confirmationText={"Replace"} onConfirm={() => this.replace()} tertiary={true}/>
        <CreateWishlist onHide={() => this.create() } show={this.state.create} />
      </Static>
    )
  }
}


const mapStateToProps = state => ({
  store: state.booking, login: state.login, cart: state.cart, app: state.app
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview))
