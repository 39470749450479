import React from 'react'
import SectionHeader from './SectionHeader'
import CartPowerup from '../Powerups/CartPowerup'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import ListItem from '../Powerups/ListItem'
import { getComponentPrice, getQty, getPackagePrice, getStartTime, getLateFee } from '../Utils/math'
import currencyFormatter from 'currency-formatter'
import './ItemList.css'
import Selector from './Selector'
import moment from 'moment-timezone'
import Textarea from 'react-textarea-autosize';

var categories = ["packages", "package", "remote", "food", "drink",  "animals", "entertainment", "decor", "logistics"]

function getFee(c, booking){
  var fee = getComponentPrice(c, booking, null, true)
  return fee ? currencyFormatter.format( fee , { code: 'USD', precision: 0}) : "none"
}

function notesSection(noChange, c, booking, onChange, cat, admin, waiveFee){
  if(c.component.canceled){
    if(admin) return <div className={"cancellation-fee"}>Cancellation Fee: {getFee(c.component, booking)}  {c.component.canceled !== "no_charge" ? <div className="waive-fee remove" onClick={() => waiveFee(c.component.event_component_id)}>Waive Fee</div> : null }</div>
    return <div className={"cancellation-fee"}  >Cancellation Fee: {getFee(c.component, booking)}</div>
  }
  if(noChange){
    var text = c.component.notes || "No special instructions"
    return <div className="text-field disabled notes" >{text.split("\n").map((i,key) => {
              return <div key={key}>{i}</div>;
          })}</div>
  }
  return <Textarea value={c.component.notes} onChange={(e) => onChange(e.target.value, c.component, cat) } className="text-field notes" placeholder="Special instructions (optional)"/>
}

function getStartOptions(start_time, duration, component){
  var times = []
  var intervals = 0
  if(Number(duration) === 10){
    intervals = 12/0.5
  }else{
    intervals = duration / 0.5
  }
  if(component.name === "Event Host"){
    times.push({name: moment(start_time).add(-2, "hours").format("h:mma"), value: -2})
    times.push({name: moment(start_time).add(-1.5, "hours").format("h:mma"), value: -1.5})
    times.push({name: moment(start_time).add(-1, "hours").format("h:mma"), value: -1})
    times.push({name: moment(start_time).add(-.5, "hours").format("h:mma"), value: -.5})
  }
  for(var i = 0; i < intervals; i++){
    times.push({name: moment(start_time).add(i*0.5, "hours").format("h:mma"), value: i*0.5})
  }
  return times

}

function getPrice(component, category, booking, applyLateFee){
  var late_fee = 0
  if(applyLateFee) late_fee = getLateFee(moment(), getStartTime(booking.date, booking.start_time, booking.timezone))
  if(category === "package" || category === "packages"){
    if(component.calculated_price) {
      return component.calculated_price
    }
    return getPackagePrice((component.component_details || []), booking, component.discount, component.setup_time, component.takedown_time, late_fee)
  }
  return getComponentPrice(component, booking, null, null, null, late_fee)

}

const ItemList = ({cart, actions, noChange, onChange, dash, add, remove, edit, added, booking, setBooking, viewDetails, changeOffset, hideStartTime, disableStartTime, lateFee, admin, waiveFee, removeLateBookingFee, assignService, booked}) => {
  var added_ids = []
  if (added){
    for (let a of added){
      if(a.change !== "remove"){
        added_ids.push(Number(a.id))
      }
    }
  }
  console.log("lateFee", cart)
  return (
    <div className="cart-contents">
      {categories.map( (cat, i) =>
        cart[cat] && cart[cat].length > 0 ?
        <div key={i}>
          <SectionHeader name={cat} pack={cart.package && cart.package[0]} />
            {cart[cat].map(c =>
              <div key={c.id} className="list-member">
                <div className="list-member-details">
                  {dash ?
                    <ListItem hidePrice={true} setBooking={setBooking} booking={booking} viewDetails={viewDetails} noChange={noChange !== undefined ? noChange : (!added_ids.includes(c.id) && !c.component.event_component_id)} c_id={c.id} category={cat} component={c.component} package={cat === "package" || cat === "packages"} showModal={true} booked={booked}
                                  remove={remove} add={add} change={edit} to_add={c.component.change === "add" || c.component.change === "update" } to_remove={c.component.change === "remove"} changeable={added_ids.includes(Number(c.id))}/>
                  :
                  <CartPowerup hidePrice={true} noChange={noChange} showQty={true} c_id={c.id} category={cat} component={c.component} package={cat === "package"}/>
                  }
                  {notesSection(noChange, c, booking, onChange, cat, admin, waiveFee)}
                  {cat === "package" || cat === "packages" || hideStartTime ?
                    null
                    :
                    <Selector disabled={disableStartTime} pretext={"Start at: "} className={"start-time-selector"} value={c.component.start_offset ? {name: moment(booking.start_time).add(c.component.start_offset.value, "hours").format("h:mma"), value:c.component.start_offset.value} : {name: moment(booking.start_time).format("h:mma"), value: 0}} update={(value) => changeOffset(value, c.component, cat)} options={ getStartOptions(booking.start_time, booking.duration, c.component) }/>
                  }
                </div>
                <div className={"list-member-pricing" + (c.component.canceled ? " canceled" : "")}>
                  <div className="total">{currencyFormatter.format(getPrice(c.component, cat, booking, lateFee) , { code: 'USD', precision: 0})}</div>
                  <div className="per">{currencyFormatter.format(getPrice(c.component, cat, booking, lateFee)/getQty(c.component, booking) , { code: 'USD', precision: 0})}/{c.component.unit_name || c.component.unit || "person"}</div>
                  {admin && c.component.late_booking_fee && Number(c.component.late_booking_fee) ? <div className="remove" onClick={() => removeLateBookingFee(c.component.event_component_id)}>Remove Late Fee</div> : null }
                  {admin ? <div className="change" onClick={() => assignService(c.component.event_component_id)}>Assign Service</div> : null }
                </div>
              </div>
            )}
        </div>
        : null
      )}
    </div>
  )
}


const mapStateToProps = state => ({
  state: state
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList))
