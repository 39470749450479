import request from '../request'
import Track from '../components/Utils/Analytics'
const initialState = {}

function cartIsEmpty(clone){
  var cart_count = 0;
  for (var key in clone){
    cart_count += clone[key].length
  }
  return cart_count === 0
}

function findInCart(clone, id, category){
  console.log(clone, id, category)
  if(category){
    var comp = clone[category].find(c => c.id === id)
    return comp
  }
  for(var cat in clone){
    if(cat !== "package"){
      var comp = clone[cat].find(c => c.id === id)
      if(comp) return comp
    }
  }
  return undefined
}

export default function cart(state = initialState, action) {
  let clone = JSON.parse(JSON.stringify(state));
  var params;
  switch (action.type) {
    case 'ADD_TO_CART':
      Track.addToCart(action.component.name)
      clone[action.category] = clone[action.category] || []
      let c = Object.assign({}, action.component);
      c.is_available = true
      var comp = {
        id: action.component.id,
        component: c
      }
      action.category === "package" ? clone.package = [comp] : clone[action.category].push(comp)
      params = {method: "PUT", body: JSON.stringify({cart: clone})}
      request('/api/cart', params)
      return clone

    case 'ADD_MULTIPLE_CART_ITEMS':
      // eslint-disable-next-line
      for (var comp of action.components){
        clone[comp.categories[0]] = clone[comp.categories[0]] || []
        comp.is_available = true
        // eslint-disable-next-line
        var service = {
          id: comp.id,
          component: c
        }
        clone[comp.categories[0]].push(service)
      }
      params = {method: "PUT", body: JSON.stringify({cart: clone})}
      request('/api/cart', params)
      return clone

    case 'REPLACE_CART_ITEM':
      clone[action.category] = clone[action.category] || []
      // eslint-disable-next-line
      var comp = {
        id: action.component.id,
        component: action.component
      }
      var index = clone[action.category].findIndex(x => x.id === action.component.id);
      clone[action.category].splice(index, 1, comp);
      params = {method: "PUT", body: JSON.stringify({cart: clone})}
      request('/api/cart', params)
      return clone

    case 'DELETE_CART_ITEM':
      clone[action.category] = clone[action.category].filter(item =>
        item.id !== action.id
      )
      if(cartIsEmpty(clone)){
        params = {method: "DELETE"}
        request('/api/cart', params)
      }else{
        console.log("updating cart")
        params = {method: "PUT", body: JSON.stringify({cart: clone})}
        request('/api/cart', params)
      }
      return clone

    case 'DELETE_MULTIPLE_CART_ITEMS':
      for(let cat in clone){
        clone[cat] = clone[cat].filter(item => !action.ids.includes(item.id))
      }
      if(cartIsEmpty(clone)){
        params = {method: "DELETE"}
        request('/api/cart', params)
      }else{
        console.log("updating cart")
        params = {method: "PUT", body: JSON.stringify({cart: clone})}
        request('/api/cart', params)
      }
      return clone

    case 'EDIT_CART_ITEM':
      var item = findInCart(clone, action.id, action.category)
      item.component[action.field] = action.value
      console.log(item)
      params = {method: "PUT", body: JSON.stringify({cart: clone})}
      request('/api/cart', params)
      return clone

    case 'CLEAR_CART' :
      params = {method: "DELETE"}
      request('/api/cart', params)
      return {}

    case 'SET_CART':
      console.log("setting cart, ", action.cart)
      clone = action.cart;
      console.log(clone)
      if(cartIsEmpty(clone)){
        params = {method: "DELETE"}
        request('/api/cart', params)
      }else{
        console.log("updating cart")
        params = {method: "PUT", body: JSON.stringify({cart: clone})}
        request('/api/cart', params)
      }
      console.log("setting cart")

      return clone
    default:
      return state
  }
}
