import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { getStartTime } from './math'
import { Dropdown, MenuItem } from 'react-bootstrap'
import './Reschedule.css'
import Selector from './Selector'
import request from '../../request'

class NewEvent extends Component {

  constructor(props){
    super(props)
    this.state = {
      new_date: props.values.start ? moment(props.values.start) : "",
      start_time: props.values.start ? moment(props.values.start) : "",
      end_time: props.values.end ? moment(props.values.end) : "",
      forcused: false,
      start_open: false,
      end_open: false,
      service: "",
      services: []
    }
  }

  componentDidMount(){
    this.getServices()
  }

  async getServices(){
    var services = await request("/api/vendor/services")
    if(services.err || services.length === 0){
      console.log(services)
    }else{
      services = services.map(s => {return {value: s.id, name: s.component_name}})
      this.setState({services, service: services[0]})
    }

  }

  blockOut(date){
    return false
  }

  isOutsideRange(date){
    return false
  }

  render(){
    var start_time = getStartTime(this.state.new_date, this.state.start_time, this.props.values.timezone)
    var end_time = this.state.end_time
    var disable = false
    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="reschedule-modal booking-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")} onHide={() => this.props.onHide()}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Add Event</div>
          <div className="questions">Service:</div>
          {this.state.services.length === 1 ?
            <div style={{marginBottom: 12}} className="sort-box"><div className="sort-text">{this.state.services[0].name}</div></div>
          :  <Selector style={{marginBottom: 12}} value={this.state.service} update={(service) => this.setState({service})} options={this.state.services}/>
          }
          <div className="questions">Date and time for your event:</div>
          <div>
            <div className="text-field date">
              <SingleDatePicker
                date={moment(this.state.new_date)}
                focused={this.state.focused}
                onDateChange={(new_date) => this.setState({new_date})}
                onFocusChange={({ focused }) => this.setState({focused})}
                displayFormat={"ddd, MMM D, YYYY"}
                isOutsideRange={(date) => this.isOutsideRange(date)}
                isDayBlocked={(date) => this.blockOut(date)}
                hideKeyboardShortcutsPanel={true}
                placeholder="Date of event"
                readOnly={true}
                numberOfMonths={1}/>
            </div>
            <span className="to"> at </span>
            <TimePicker
               ref={(ref) => this.time_picker = ref }
               id={"start_time"}
               showSecond={false}
               value={this.state.start_time ? moment(start_time) : null}
               className={this.state.start_open ? "time open": "time"}
               placeholder="Start time"
               onChange={(value) => this.setState({start_time: value.format()}) }
               format={'h:mma'}
               minuteStep={15}
               getPopupContainer = {node => node}
               onOpen={() => this.setState({start_open: true})}
               onClose={() => this.setState({start_open: false})}
               use12Hours
             />
             <span className="to"> to </span>
             <TimePicker
                ref={(ref) => this.end_time_picker = ref }
                id={"end_time"}
                showSecond={false}
                value={this.state.end_time ? moment(end_time) : null}
                className={this.state.end_open ? "time open": "time"}
                placeholder="End time"
                onChange={(value) => this.setState({end_time: value.format()}) }
                format={'h:mma'}
                minuteStep={15}
                getPopupContainer = {node => node}
                onOpen={() => this.setState({end_open: true})}
                onClose={() => this.setState({end_open: false})}
                use12Hours
              />
          </div>
          <div className={"custom-button" + (disable ? " disabled" : "")} style={{float: "right", marginLeft: 9}} onClick={() => {disable ? null: this.props.save(this.state.service.value, start_time, end_time)}}>Save Event</div>
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEvent))
// export default CreateWishlist
