import React from 'react'
import TimePicker from 'rc-time-picker';
import moment from 'moment'
import CheckboxControl from '../Utils/CheckboxControl'
import './Workhours.css'
//
// var workinghours = {
//     0: null,
//     1: [['09:30:00', '17:00:00'],
//     2: [['09:30:00', '17:00:00'],
//     3: [['09:30:00', '17:00:00'],
//     4: [['09:30:00', '17:00:00'],
//     5: [['09:30:00', '17:00:00'],
//     6: null
// }

function getOpen(workinghours){
  var open = {}
  for(var i in workinghours){
    if(workinghours[i]){
      open[i] = workinghours[i].map(t => [false, false])

    }else{
      open[i] = [[false, false]]
    }
  }
  return open
}

var day_names = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday","Friday", "Saturday"]

export default class Workhours extends React.Component {
  constructor(props){
    super(props)
    var open = getOpen(props.workhours)
    this.state = {
      open
    }
  }

  componentWillReceiveProps(props){
    if(props.workhours) this.setState({open: getOpen(props.workhours)})
  }

  toggle(d, i, x, open){
    console.log("toggle", open)
    var clone = JSON.parse(JSON.stringify(this.state.open))
    clone[d][i][x] = open
    this.setState({open: clone})
  }

  newRow(d){
    var open = JSON.parse(JSON.stringify(this.state.open))
    open[d].push([false, false])
    this.setState({open})
    this.props.addRange(d)
  }

  removeRow(d){
    var open = JSON.parse(JSON.stringify(this.state.open))
    open[d].pop()
    this.setState({open})
    this.props.removeRange(d)
  }


  render(){
    var days = Object.keys(this.props.workhours).map( d => Number(d))
    return (
      <div className="workhours" style={this.props.style}>
      {days.map( d =>
        <div>
          <div className={this.props.workingdays.includes(d) ? "custom-button day" : "unselected-button day" } onClick={() => this.props.working(!this.props.workingdays.includes(d), d)}>{day_names[d]}</div>
          {this.props.workingdays.includes(d) ?
            <div style={{display: "inline-block", verticalAlign: "top"}}>
            {this.props.workhours[d].map((v, r) =>
              <div style={{paddingTop: 10}}>
                <TimePicker
                  ref={(ref) => this["time_picker"+ d + r + 0] = ref }
                  id={"start_time" + d + r}
                  showSecond={false}
                  value={this.props.workhours[d][r][0] ? moment().hour(this.props.workhours[d][r][0].split(":")[0]).minute(this.props.workhours[d][r][0].split(":")[1]) : null}
                  className={this.state.open[d][r][0] ? "time open": "time"}
                  placeholder="Start time"
                  onChange={(value) => this.props.changeTime(d, r, 0, value.format("HH:mm:00"))}
                  format={'h:mma'}
                  minuteStep={15}
                  getPopupContainer = {node => node}
                  onOpen={() => this.toggle(d, r, 0, true)}
                  onClose={() => this.toggle(d, r, 0, false)}
                  use12Hours
                />
                <div className="to">to</div>
                <TimePicker
                  ref={(ref) => this["time_picker"+ d + 1]  = ref }
                  id={"end_time" + d + r}
                  showSecond={false}
                  value={this.props.workhours[d][r][1] ? moment().hour(this.props.workhours[d][r][1].split(":")[0]).minute(this.props.workhours[d][r][1].split(":")[1]) : null}
                  className={this.state.open[d][r][1] ? "time open": "time"}
                  placeholder="End time"
                  onChange={(value) => this.props.changeTime(d, r, 1, value.format("HH:mm:00")) }
                  format={'h:mma'}
                  minuteStep={15}
                  getPopupContainer = {node => node}
                  onOpen={() => this.toggle(d, r, 1, true)}
                  onClose={() => this.toggle(d, r, 1, false)}
                  use12Hours
                />
                {this.props.workhours[d].length === r + 1 ? <div onClick={() => this.newRow(d)} className="add-remove-range" style={{marginLeft: 27}}> + </div> : null}
                {this.props.workhours[d].length === r + 1 && r > 0 ? <div onClick={() => this.removeRow(d)} className="add-remove-range"> - </div>
                : null }
              </div>


            )}
          </div>

          : null}
        </div>
      )}
      </div>
    )
  }
}
