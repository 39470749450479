import 'babel-polyfill'
import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import './assets/main.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import eventsApp from './reducers/'
import {BrowserRouter } from 'react-router-dom'
import {saveState, loadState} from './localStorage'
import ScrollToTop from './ScrollToTop'

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

var persistedState = loadState()
console.log(persistedState)
let store = createStore(eventsApp, persistedState)

store.subscribe(() => {
  saveState(store.getState())
} )

root.render(  <Provider store={store}>
  <BrowserRouter >
    <ScrollToTop>
      <App/>
    </ScrollToTop>
  </BrowserRouter>
</Provider>);

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter >
//       <ScrollToTop>
//         <App/>
//       </ScrollToTop>
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById('root'));
// registerServiceWorker();
unregister()
