import React from 'react'
import {MenuItem, Dropdown} from 'react-bootstrap'

const UnitPicker = ({value, units, select, className, style}) => {
  return (
    <div className={className || "sort-box"} style={ style || {display: "inline-block", verticalAlign: "top", marginLeft: 12}}>
      <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" >
        <Dropdown.Toggle noCaret >
          <div className="sort-text">
            <span>{value}</span>
            <span className="my-small-caret"/>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {units.map((u, i) =>
            (value === u ? null : <MenuItem key={i} active={value === u} onSelect={() => select(u)}>{u}</MenuItem> )
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

}

export default UnitPicker
