import React from "react"
import request from '../../request'
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, Table} from 'react-bootstrap'
import moment from 'moment-timezone'

export default class FakeEvents extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      search: "",
      search_start: "",
      search_id: "",
      events: []
    }
  }

  async getFakeEvents(){
    var events = await request('/api/admin/fakeEvents')
    if(events.err){
      console.log(events)
    }else{
      this.setState({events})
    }
  }

  componentDidMount(){
    this.getFakeEvents()
  }

  search(e){
    if(this.state.search){
      return e.component_name ? e.component_name.toLowerCase().includes(this.state.search.toLowerCase()) : false
    }else if(this.state.search_id){
      return this.state.search_id && Number(e.component_id) === Number(this.state.search_id)
    }else if(this.state.search_start){
      return moment(e.start_time).tz(e.timezone).format('LLL').toLowerCase().includes(this.state.search_start.toLowerCase())

    }
    return true
  }

  async delete(id){
    var services = await request('/api/admin/fakeEvents/' + id , {method: "DELETE"})
    this.getFakeEvents()
  }

  render(){
    return (
      <div className="card" style={{marginTop: 20, padding: 20}}>
        <div>
          <span>Search:  </span>
          <input type="text" className="text-field" placeholder="Search by name" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
          <input type="text" className="text-field" style={{marginLeft:20}} placeholder="Search by start" value={this.state.search_start} onChange={(e) => this.setState({search_start: e.target.value})}/>
          <input type="number"className="text-field" style={{marginLeft:20}} placeholder="Search by id" value={this.state.search_id} onChange={(e) => this.setState({search_id: e.target.value})}/>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Component Name</th>
              <th>Component ID</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {this.state.events.map((e, i) => (this.search(e) ?
                <tr key={i}>
                  <td>{e.id}</td>
                  <td>{e.component_name}</td>
                  <td>{e.component_id}</td>
                  <td>{moment(e.start_time).tz(e.timezone).format('LLL')}</td>
                  <td>{moment(e.end_time).tz(e.timezone).format('LLL')}</td>
                  <td><div className="remove" onClick={() => this.delete(e.id)} >Delete</div></td>
                </tr> : null)
              )}
          </tbody>
        </Table>
      </div>
    )
  }


}
