import React from 'react'
import '../../css/Utils/Sort.css'
import {Dropdown, MenuItem} from 'react-bootstrap'
import Selector from './Selector'

function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}


let timezones = [{name: 'Eastern Time', value: 'America/New_York'}, {name: 'Central Time', value: 'America/Chicago'}, {name: "Mountain Time", value: "America/Denver"}, {name: "Mountain Time (Arizona)", value: "America/Phoenix"}, {name: "Pacific Time", value: "America/Los_Angeles"}]


const TimezonePicker = ({value, update, style, className}) => {
  let zone = timezones.find(t => t.value === value) || {name: value, value: value}
  return (
    <Selector className={className} id="timezone-selector" style={style} value={zone} update={(timezone) => update(timezone.value)} options={timezones}/>
  )

}

export default TimezonePicker
