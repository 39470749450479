import React from 'react'
import Static from './StaticWrapper'
import './HowTo.css'
import Karaoke from '../../img/HowItWorks/Karaoke.jpg'
import Jungle from '../../img/HowItWorks/JungleParty.jpg'
import MarioCart from '../../img/HowItWorks/MarioCart.jpg'
import Collage from '../../img/HowItWorks/HowItWorksCollage@2x.png'
import Image from '../Utils/SpecialImage'

const HowTo = () => {
  return (
    <Static className="how-to-page max-w-full  relative lg:mt-[115px] mt-8">
      <div className="static-page-header  w-[1028px] px-4  max-w-full md:text-[44px] text-[36px]">How It Works</div>
      <div className="page-subheader  w-[1028px] px-4  max-w-full">{"Let's walk through each step of our process."}</div>
      <div className="section-wrapper max-w-full px-4 relative">
        <div className="section max-w-full">
          <div className="section-header">BEFORE YOUR EVENT</div>
          <div className="content max-w-full lg:mr-10">
            <div className="summary text-[24px] md:text-[28px]">First, book a package or custom event online using our website.</div>
            <div className="text">
              <p>Just enter your event date, number of people, location, and duration to check availability. Choose from our many all-inclusive event packages. Or create your own custom event by piecing together individual components.</p>
              <p>All pricing and availability is updated in real-time and is based on the number of people and duration you enter for your event.</p>
              <p>What you see is what you pay. No additional gratuity or service fees or delivery fees. The only thing added at checkout is tax.</p>
              <p>Review & Book once you’re ready to confirm. You will get a confirmation email once the booking is complete. And your card will be immediately be charged a 50% nonrefundable deposit.</p>
            </div>
          </div>
          <Image src={MarioCart} className={"max-w-full"} caption="Live Mario Kart at Twitch" />
        </div>
        <div className="section max-w-full pt-0 lg:pt-[60px]">
          <div className="content max-w-full lg:mr-10">
            <div className="summary">{"A few days before your event, we'll check in to re-confirm details."}</div>
            <div className="text">
              <p>We’ll email you to make sure all your event details are correct, such as: time, location, event day point of contact, etc.</p>
              <p>We’ll also notify you of your assigned event staff, their contact info, and when they will arrive at your location to start setting up.</p>
            </div>
          </div>
          <Image src={Karaoke} className={"max-w-full"} caption="Karaoke Night at Life360" />
        </div>
      </div>
      <div className="section-wrapper px-4 max-w-full  relative">
        <div className="section max-w-full">
          <div className="section-header">DAY OF EVENT</div>
          <div className="content max-w-full lg:mr-10">
            <div className="summary">Sit back, relax, and enjoy the event.</div>
            <div className="text">
              <p>Your event staff will arrive 1 to 2 hours before the event start time to begin setting up. They’ll take care of everything, so feel free to sit back and relax while they work their magic <span role="img" aria-label="smiley">🙂.</span></p>
              <p>During the event you’ll get to actually enjoy the festivities, whether it’s cuddling the bunnies, racing down the Mario Kart track, or singing your heart out during karaoke! Your event staff will be there to make sure everything is running smoothly.</p>
              <p>Afterwards, your event staff will take down all equipment and return the space to its original state. We leave no trace behind — scout’s honor.</p>
            </div>
          </div>
          <Image src={Jungle} caption="Jungle Party at Segment" className="segment max-w-full" />
        </div>
      </div>
      <div className="section-wrapper px-4 max-w-full  relative">
        <div className="section max-w-full">
          <div className="section-header">WEEK AFTER EVENT</div>
          <div className="content max-w-full lg:mr-10">
            <div className="summary">{"We'll charge your card and follow up to see how everything went."}</div>
            <div className="text">
              <p>After your event, we'll charge the remaining event balance, email a final receipt, and follow up asking for feedback. Please let us know if there's anything we can do to make your experience even better next time. We want to make sure we're consistently providing the best experience possible, and your feedback is critical to that.</p>
              <p>If you booked a photographer or photo booth from us, we’ll send the photos your way to share with your attendees. (Remember to tag <i>#1upevents</i> when posting your fabulous photos! <span role="img" aria-label="love">😍</span>)</p>
            </div>
          </div>
          <img className="collage max-w-full" src={Collage} alt="collage"/>
        </div>
      </div>
    </Static>
  )
}

export default HowTo
