import React, { Component } from 'react';
import '../../css/font-awesome.min.css'
import * as cartActions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import request from '../../request'
import Header from '../Header/PlainHeader'
import Footer from '../Utils/Footer'

class VerifyEmail extends Component{
  constructor(props){
    super(props)
    this.state = {
      referral: null,
      err: null
    }
  }

  async componentDidMount(){
    var contact_info = await request('/api/contactInfo')
    this.setState({referral: contact_info.referral_code})
  }

  render(){
    return (
        <div>
          <Header />
          <div className="card check-email">
            <div className="title">Sorry, you already have a 1up Account.</div>
            <div className="text">{"Give others your referral link and earn $20 when they sign up:"}</div>
            <div className="text-field" style={{marginTop: 8, marginBottom: 8, width:275, marginRight: "auto", marginLeft: "auto"}}>1upevents.com/ref/{this.state.referral} </div>
            <Link to='/' className="custom-button">Back to Home</Link>
          </div>
          <Footer />
        </div>
    )
  }
}

const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail))
