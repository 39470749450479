import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'

class Terms extends Component {

  render(){

    return (
      <Modal show={this.props.show} zIndex={9900} onHide={this.props.onHide} customClassName="customize-modal terms" backdropClassName="customize-backdrop">
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Terms, Conditions and Cancellation</div>
          <div className="text">
            <p>This document serves as a binding contract between you, hereafter known as “you,” or "customer" and 1up Events, hereafter known as “1up" or "1up Events." By booking, you have read and agreed to the following terms and both parties agree to abide by the following conditions:</p>
              <ol>
                  <li>Setup & Takedown: Typically setup starts 1-2 hours prior to your event start time, and takedown is completed within 1 hour following your event. Exact setup and takedown times vary depending on what you have ordered for your event. And some events are just drop-offs and do not include setup or take-down. Exact details can be found in the description for your event.</li>
                  <li>Payment: 1up Events will collect payment information upon confirmation of the event. You will be charged a nonrefundable deposit equal to 50% of the total event cost immediately once you have completed booking. The remaining balance of the total event cost will be charged upon completion of the event.</li>
                  <li>Headcount: You can edit your headcount within 20% of the headcount of your original booking up until 4 weeks before your event. You can edit your headcount within 10% of the headcount of your original booking up until 2 weeks before your event. Other details may be edited in your dashboard up until 3 days before your event. If any urgent changes need to be made less than 3 days before your event, please call us directly and we will try to accommodate.</li>
                  <li>Editing Your Event: You may edit the details of an event in your dashboard up until 3 days before your event. If any urgent changes need to be made less than 3 days before your event, please call us directly and we will try to accommodate.
</li>
                  <li>Cancelling Your Event:
                      <ol type = "a">
                          <li>If you cancel your event more than 2 weeks before the event date, no additional payment will be due. You will only be charged the initial nonrefundable 50% of your event cost that you were charged upon booking.</li>
                          <li>If you cancel your event less than 2 weeks before the event date, the remaining balance of your total event cost is due as well, on top of the nonrefundable 50% of your event cost that you were charged upon booking.</li>
                          <li>In the event of extenuating circumstances, we may issue a partial refund at our discretion.</li>
                      </ol>
                  </li>
                  <li>Rescheduling: If you need to reschedule your event and we are able to accommodate rescheduling, there is a 20% rescheduling fee. If your booking includes a venue, there may be an additional rescheduling fee. The remaining balance for your event will be due upon completion of your event. A new date must be chosen at the time of rescheduling and that new date must be within 6 months of the original event date or it will be considered a cancellation.</li>
                  <li>Liability:
                    <ol type="a">
                        <li>The customer acknowledges liability for any damage caused to the Venue (either your office or other outside venue), 1up Events' property, or vendor's property, by any guests or employees of the customer during the course of the events.</li>
                        <li>The customer will indemnify and hold 1up harmless and in no way accountable for any liability for personal injury to guests or employees of the customer during the course of the event.</li>
                        <li>The customer is responsible for ensuring compliance to any building requirements for an in-office event (e.g. does the building allow animals?).</li>
                    </ol>
                  </li>
                  <li>For Venue Finding Service:
                    <ol type="a">
                        <li>Venue finding fee is non-refundable.</li>
                        <li>The fee will be charged after the 3 options are presented to the customer.</li>
                        <li>1up will provide the customer with 3 options that meet all specifications based solely on the information provided by the customer at time of checkout.</li>
                        <li>At the time the options are presented, all venue options provided by 1up will be available at the date requested. If the customer chooses to book the venue at a later date or on a date that was not originally requested, venue availability is not guaranteed.</li>
                    </ol>
                  </li>
                  <li>LIMITATION OF LIABILITY: 1up will not be liable for any indirect, special, consequential, or punitive damages (including lost profits) arising out of or relating to this agreement or the transactions it contemplates (whether for breach of contract, tort, negligence, or other form of action) and irrespective of whether the Planner has been advised of the possibility of any such damage. In no event will 1up's liability exceed the price the customer paid to 1up for the specific event provided by 1up giving rise to the claim or cause of action.</li>
                  <li>DISCLAIMER OF WARRANTY; DUE DILIGENCE. The event is being sold “as is,” and 1updisclaims all warranties of quality, whether express or implied, including the warranties of merchantability and fitness for particular purpose.</li>
                  <li>Force Majeure: Nonperformance by either Party will be excused to the extent that performance is rendered impossible by strike, fire, flood, governmental acts or orders or restrictions, failure of suppliers, or any other reason where failure to perform is beyond the control and not caused by the negligence of the non-performing Party. Inclement weather and traffic shall not render performance impossible.</li>
                  <li>1up is allowed to use the customer's logo in publicizing the event, and on 1up’s website to showcase clients and past events.</li>
                  <li>1up is allowed to use any and all pictures taken at the event (including those with the customer’s logo) for their website and future marketing. It is at the customer's discretion to request removal of any pictures.</li>
              </ol>
          </div>
        </div>
      </Modal>
    )
  }

}

export default Terms
