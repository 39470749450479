import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import * as cartActions from '../actions/'
import moment from 'moment'
import '../css/Cart.css'
import CartPowerup from './Powerups/CartPowerup'
import CartPackage from './Packages/CartPackage'
import SectionHeader from './Utils/SectionHeader'
import '../css/Utils/Button.css'
import currencyFormatter from 'currency-formatter'
import { getTotal, getLateFee, getStartTime} from './Utils/math'
import { cartsContainSameItems } from './Utils/Helpers'
import Alert from './Utils/Alert'
import request from '../request'
import WarningIcon from '../img/WarningIcon.svg'
import _ from 'underscore'
import BookingModal from './Utils/BookingModal';
import { ChevronLeft, Trash} from 'lucide-react'
import EventDetailsBar from './Header/EventDetailsBar';
// import Track from './Utils/Analytics'

function getIdsFromCart(cart){
  let ids = []
  for(var cat in cart){
    if(cat !== "package"){
      for (var i of cart[cat]){
        ids.push(i.component.id)
      }
    }
  }
  return ids
}

function getCartAvailibilityStatus(cart){
  console.log("getCartAvailibilityStatus")
  for(var cat in cart){
    for (var i of cart[cat]){
      if (!i.component.is_available){
        console.log("getCartAvailibilityStatus", "NOT AVAILABLE")
        return false  
      } 
    }
  }
  console.log("getCartAvailibilityStatus", "ALL AVAILABLE")
  return true;
}


const Section = ({name, data, pack, actions}) => {
  return (
    <div>
      <SectionHeader name={name} pack={pack}/>
      {data.map(c =>
        <div className='flex'>
          <div className='flex-1'>
            <CartPowerup key={c.id} c_id={c.id} category={name} component={c.component} />
          </div>
          <div className=' flex flex-none ml-4 w-24px items-center mt-2 sm:hidden'>
            <button className='flex bg-gray-200 w-[26px] h-[26px] rounded-full items-center justify-center' onClick={() => actions.deleteCartItem(c.id, name)}>
            <Trash className='h-4 w-4 text-strong-content-color'/>
            </button>
          </div>
         </div>
        )
      }
    </div>
  )
}

function conditionalRender(category, store, pack, actions){
  // console.log(store[category])
  if(store[category] && store[category].length > 0){
    return <Section key={category} name={category} data={store[category]} pack={pack} actions={actions}/>
  }
  return null
}

function changeParams(id = "date_input"){
  var element = window.document.getElementById(id);
  element.focus()

  if(id === "zipcode"){
    setTimeout(() => {
      var element = window.document.getElementById("zipcode-text");
      element.focus()
      element.select()
    }, 0)

  }
}


function describeParams(params, change, cartView){
  // $4,800 for 40 people on Tues, Jan 15, 2018 for 2 hours
  let emptyParams = !params.date && !params.people && !params.duration && !params.zipcode
  console.log("describe params", params, emptyParams)
  return (
    <div className="params text-base sm:text-sm mb-3">
      {params.date ? <span className="emphasis">{moment(params.date).format("ddd, MMM D, YYYY")} </span>: null}
      {params.people ?  <span> { params.date ? "for" : "For"}<span className="emphasis"> {params.people} people </span></span> :  null}
      {params.duration ? <span>{ params.date || params.people ? "for" : "For"} <span className="emphasis">{params.duration + (params.duration === 1 ? " hour" : " hours")} </span></span> : null}
      {params.location === "venue" && params.zipcode  ? <span>{ params.date || params.people || params.duration ? "at" : "At"} <span className="emphasis"> your venue </span> ({params.zipcode})</span> : null}
      {cartView ? 
        <span className={"change text-base sm:text-sm " + (emptyParams ? '' : " pl-1" )} onClick={() => change() }> {emptyParams ? "Change Event Details" : "Change"} </span>
      : 
      <>
        <span className={"change hidden text-base sm:text-sm lg:inline-flex " + (emptyParams ? "" : ' pl-1' )} onClick={() => changeParams() }>  {emptyParams ? "Change Event Details" : "Change"} </span>
        <span className={"change lg:hidden text-base sm:text-sm " + (emptyParams ? '' : " pl-1" )} onClick={() => change() }> {emptyParams ? "Change Event Details" : "Change"} </span>
      </>
      }
      
    </div>
  )
}


var categories = ["remote", "food", "drink",  "animals", "entertainment", "decor", "logistics"]

function cartAvailibiltyChange(oldProps, nextProps){
  if(nextProps.params.location_id !== oldProps.params.location_id) return true
  if(nextProps.params.date !== oldProps.params.date) return true
  if(JSON.stringify(oldProps.store) !== JSON.stringify(nextProps.store)) return true
  return false
}

class Cart extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      missing: false,
      unavailable: false,
      inactive: null,
      inactive_cat: "", 
      showEventDetails: false,
    }
  }

  checkIfRemote(cart){
    let remote = true
    for(var cat in cart){
      if(cat === "package" && cart.package.length !== 0 ) return false
      for (var i of cart[cat]){
        if(i.component.remote !== true){
          return false
        }
      }
    }
    return true
  }

  async componentWillReceiveProps(nextProps) {
    console.log("will recieve props", nextProps.store)
    // this.getAvailability(nextProps.store)
  }

  async componentDidMount(){
    let cartView = window.location.pathname === "/cart"
    console.log("CART VIEW")
    if(cartView){
      console.log("CART VIEW closing cart")
      this.props.actions.closeCart();
    }
  }    

  missing(){
    window.scrollTo(0, 0)
    this.setState({missing: true})
    this.props.actions.assignMissing("missingAlert")
  }

  missingDismissed(){
    this.setState({missing: false})
    this.props.actions.assignMissing("missing")
  }

  hasHost(){
    for (var cat in this.props.store){
      if(cat === "package" && this.props.store.package && this.props.store.package.length >= 1){
        return true
      }else{
        if(this.props.store[cat]){
          for (let c of this.props.store[cat]){
            if(c.component.event_host) return true
          }
        }
      }
    }
    return false
  }

  getText(){
    if(this.props.params.location === "find"){
      if(this.hasHost()){
        return <span>{"Price includes event host, setup, takedown and"}<b> $500 </b>venue finding fee.</span>
      }else{
        return <span> Price includes <b>$500</b> venue finding fee.</span>
      }
    }else{
      if(this.hasHost()){
        return "Includes event host, setup and takedown."
      }else{
        return ""
      }
    }
  }

  getInactiveTitle(comp){
    console.log(comp)
    if(!comp) return ""
    return `${comp.name} is no longer offered.`
  }

  getInactiveText(comp){
    if(!comp) return ""
    return `We're so sorry, but we are no longer offering ${comp.name} at this time. Please remove it from your cart before checking out.`
  }

  hideInactive(comp){
    this.setState({inactive: null})
    this.props.actions.editCartItem(comp.id, this.state.inactive_cat, "is_available", false)
  }

  // async componentDidUpdate(prevProps, prevState){
  //   for(var key in prevState){
  //     if (prevState[key] !== this.state[key]){
  //       localStorage.setItem("checkout", JSON.stringify(this.state))
  //       break
  //     }
  //   }
  //   var old_start = getStartTime(prevProps.params.date, prevProps.params.start_time, prevProps.params.timezone)
  //   var start_time = getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone)
  //   var clone = JSON.parse(JSON.stringify(this.props.store))
  //   if(moment(start_time).isSame(old_start, "minute") && Number(prevProps.params.duration) === Number(this.props.params.duration) && (this.props.params.zipcode.length < 5 || prevProps.params.zipcode === this.props.params.zipcode)){
  //     if(!cartsContainSameItems(this.props.store, prevProps.store)){
  //       await this.getComponentsWithAvailability(prevProps, clone, start_time, true)
  //     }
  //     console.log(cartsContainSameItems(this.props.store, prevProps.store), this.props.store, prevProps.store)
  //     return
  //   }else{
  //     await this.getComponentsWithAvailability(prevProps, clone, start_time)
  //   }
  // }

  async getComponentsWithAvailability(prevProps, clone, start_time, cartChanged){
    var end_time = moment(start_time).add(this.props.params.duration, "hours")
    var unavailable = false
    var notify = false
    var ids = []
    var pack = clone.package && clone.package.length > 0 ? clone.package[0].component : null
    if(pack){
        ids = ids.concat(pack.components.map(c => Number(c.id)))
    }
    for (var cat in clone){
      if(cat !== "package"){
        for(var c of clone[cat]){
          ids = ids.concat(c.id)
        }
      }
    }
    let result = await request("/api/availability/components", {method: "POST", body: JSON.stringify({start_time, end_time, ids, zipcode: this.props.params.zipcode})})

    if(!result || result.err){
      console.log("Setting Cart", result)
      return
    }
    var s = new Set(result.map(c => c.id ))
    let pack_unavailable = []
    for (var cat in clone){
      if(cat === "package" && pack){
        let pack_ids = clone.package[0].component.components.map(c => Number(c.id))
        let avail =  true
        for (let c of pack_ids){
          if(!s.has(c) || result.find(p => Number(p.id) === Number(c)).wrong_location){
            clone.package[0].component.is_available = false
            avail = false
            unavailable = true
            pack_unavailable.push(result.find(p => Number(p.id) === Number(c)))
          }
        }
        if(avail || clone.package.canceled){
          clone.package[0].component.is_available = true
          unavailable = false
        }
      }else{
        for (var cc of clone[cat]){
          var c = cc.component
          // eslint-disable-next-line
          var updated = result.find(u => u.id === c.id)
          c.start_time = moment(start_time).add(c.start_offset ? c.start_offset.value : 0, "hours")
          c.end_time = moment(c.start_time).add(c.unit === "hour" ? c.qty : this.props.params.duration, "hours")
          c.start_offset = {name: moment(c.start_time).format("h:mma"), value: c.start_offset ? c.start_offset.value : 0 }
          c.remaining = updated ? updated.remaining : null
          if((!s.has(c.id) || updated.wrong_location || (updated.remaining !== null && Number(c.num_staff) > Number(updated.remaining))) && !c.canceled ){
            c.is_available = false
            unavailable = true
          }else{
            c.is_available = true
          }
        }
      }
    }
    console.log("Checked availabilty")
    this.props.actions.setCart(clone)
    this.setState({unavailable, pack_unavailable})
  }

  render(){
    console.log( "this.state.inactive" , this.state.inactive)
    var {store, width, params, show}  = this.props
    var cart_count = 0;
    for (var store_key in store){
      cart_count += store[store_key].length
    }
    if(this.props.params.location === "find") cart_count += 1

    var contents = () => {
      if(cart_count === 0){
        return (
          <div className="empty-cart">
            <div style={{display: "table-cell", verticalAlign: "middle", marginTop: -100}}>
              <div className="empty"> Empty Cart </div>
              <div className="add-items"> Add items to your cart to begin building your event! </div>
            </div>
          </div>
        )
      }
      return (
        <div className="item-list relative flex flex-1 flex-col top-[unset] bottom-[unset] overflow-scroll pt-3 ">
          <div className='w-full flex justify-center'>
            {store.package && store.package[0] ? <CartPackage c_id={store.package[0].id} pack={store.package[0].component} />: null}
          </div>
          {categories.map(c => conditionalRender(c, store, !!store.package && store.package[0], this.props.actions))}
        </div>
      )
    }

    var missingParams = false;
    for(var key in params){
      if(!params[key] && key !== "servicable"){
        missingParams = true
        console.log("params", params)
        break
      }
    }
    let remote = this.checkIfRemote(this.props.store)

    var late_fee = getLateFee(moment(), getStartTime(this.props.params.date, this.props.params.start_time, this.props.params.timezone))

    var checkout = () => {
      if(cart_count === 0){
        console.log("cart count is 0")
        return null
      }
      if(this.state.unavailable || !getCartAvailibilityStatus(store)){
        return (
          <div className="checkout unavailable">
            <div className='flex items-center'>
              <img src={WarningIcon} alt="" className="icon flex-inline"/>
              <span className="large-text mt-0 w-full">Some items are unavailable for the chosen event {this.state.unavailable}.</span>
            </div>
            <div className="small-text pb-1">Change the {this.state.unavailable} or remove unavailable items to book.</div>
          </div>
        )
      }

      if(missingParams){
        return (
          <div className="checkout">
            <div className="large-text">Enter in more details about your event to see prices as you add items.</div>
            <div style={{width: "100%"}} onClick={ () => this.missing() } className={"custom-button hidden sm:block h-[40px] flex items-center leading-[40px] justify-center text-lg"}>Enter Missing Information</div>
            <div style={{width: "100%"}} onClick={ () => this.setState({showEventDetails: true}) } className={"custom-button sm:hidden h-[40px] leading-[40px] flex items-center justify-center text-lg"}>Enter Missing Information</div>

          </div>
        )
      }

      if(!this.props.params.servicable && !remote){
        return (
          <div className="checkout">
            <div className="large-text"><b>Unfortunately we’re not serving your area yet.</b><Link className="link" to="/locations"> View Locations</Link></div>
            <div style={{width: "100%"}} onClick={ () => changeParams("zipcode") } className={"custom-button  flex items-center justify-center h-[40px] text-lg"}>Change Event Location</div>
          </div>
        )
      }

      return (
        <div className="checkout">
          <div className="flex">
            <div className="approximate flex">Approximate Total: </div>
            <div className="total-price flex ml-auto">{currencyFormatter.format(getTotal(store, params, late_fee) + (params.location === "find" ? 500: 0), { code: 'USD', precision: 0})} </div>
          </div>
          <div className="text">{this.getText()}</div>
          <Link to="/checkout" style={{width: "100%"}} className={"custom-button h-[40px]  flex items-center justify-center text-lg"}>Review & Book</Link>
        </div>
      )
    }
// h-[calc(100%-122px)]
    let cartView = window.location.pathname  === '/cart'
    width = cartView ? true : width
    return (
      <div className={"cart new flex flex-col z-[1] fixed top-0 bottom-0 left-0 right-0 h-full " + (width ? "w-full": "w-0") + (show || cartView ? " block" : ' hidden') + (cartView ? "" : " sm:w-[320px] sm:top-[74px] sm:left-[unset] sm:h-[unset]") }>
        <div className="cart-contents w-full flex flex-col" style={{position: "relative", height: 100+"%"}}>
        <div className={' bg-white w-full h-fit py-2 shadow-sm  flex flex-col ' + (cartView ? "" : "sm:hidden")}>
          <div className='flex w-full items-center relative pb-2'>
            <div className={" flex justify-center items-center cursor-pointer h-12 w-12 ml-2 z-10 cursor-pointer " + (cartView ? "" : ' sm:hidden ')}  onClick={() => this.props.onHide ? this.props.onHide() : window.history.back()}>
              <ChevronLeft color="#848484" className='w-7 h-7' />
            </div>
            <div className="cart-title absolute text-2xl fixed h-full w-full flex items-center justify-center m-0" >Event Cart</div>
          </div>
          {describeParams(params, () => this.setState({showEventDetails: true}), cartView)}
        </div>
          <div className={'hidden' + (cartView ? "" : " sm:block")}>
            <div className="cart-title text-xxl" >Event Cart</div>
            {describeParams(params, () => this.setState({showEventDetails: true}), cartView)}
          </div>
          
          {contents()}
          {checkout()}
        </div>
        <Alert show={this.state.missing} buttonText="Got It!" zIndex={9980} backdropClassName=" high-z" onHide={()=> this.missingDismissed()} title="Enter all your event information into the Book Bar." text="Once entered, we can show you pricing as you add items and let you Review & Book."/>
        <Alert show={!!this.state.inactive} buttonText="Ok" zIndex={9980} backdropClassName=" high-z" onHide={()=> this.hideInactive(this.state.inactive)} title={() => this.getInactiveTitle(this.state.inactive)} text={() => this.getInactiveText(this.state.inactive)}/>
        <BookingModal zIndex={"99999"} show={this.state.showEventDetails} onHide={() => this.setState({showEventDetails: false})}/>
      </div>
    )
    }
}

const mapStateToProps = state => ({
  store: state.cart, params: state.booking, show: state.app.cart
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart))
