import React, { Component } from 'react'
import '../../css/Utils/Button.css'
import '../../css/PackageBooked.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../actions/'
import { withRouter, Link, Redirect} from 'react-router-dom'
import Checkmark from '../../img/CheckmarkCircle.svg'
import ServiceItem from '../Services/ServiceItem'
import request from '../../request'
import Header from '../Header/Header'
import Footer from '../Utils/Footer'
import Alert from '../Utils/Alert'
import moment from 'moment-timezone'
import { getStartTime } from '../Utils/math'
import BottomTabBar from '../Services/BottomTabBar'

class PackageBooked extends Component {

  constructor(props){
    super(props)
    this.state = {
      addons: [],
      missing: false,
      componentsRemoved: props.location && props.location.state && props.location.state.componentsRemoved.length > 0 ? props.location.state.componentsRemoved : null,
    }
  }

  async componentDidMount(){
    var pack = this.props.pack
    // this.props.actions.openCart()
    pack = pack && pack[0] ? pack[0].component : null
    if(pack && pack.add_ons){
      this.getAddOns(pack.add_ons)
    }
  }

  async getAddOns(add_ons){
    var start_time = getStartTime(this.props.booking.date, this.props.booking.start_time, this.props.booking.timezone)
    var end_time = this.props.booking.duration ? moment(start_time).add(this.props.booking.duration, "hours") : moment(start_time).add(1, "hours")
    var params = { method: 'POST',
                  body: JSON.stringify({ids: Object.keys(add_ons), start_time, end_time, zipcode: this.props.booking.zipcode })
              };
    var res =  await request('/api/getComponentsByIDs', params)
    if (res.err){
      console.log(res.err)
      return
    }
    console.log("addons:", res)
    this.setState({addons: res})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.booking.date !== this.props.booking.date || this.props.booking.location_id !== prevProps.booking_id || prevProps.booking.start_time !== this.props.booking.start_time || prevProps.booking.duration !== this.props.booking.duration || (prevProps.booking.zipcode && prevProps.booking.zipcode.length === 5 && prevProps.booking.zipcode != this.props.booking.zipcode) ) {
      var pack = prevProps.pack
      pack = pack && pack[0] ? pack[0].component : null
      if(pack && pack.add_ons){
        this.getAddOns(pack.add_ons);
      }
    }
  }


  missing(params){
    var missingParams = false;
    for(var key in params){
      if(!params[key]){
        missingParams = true
        break
      }
    }
    return missingParams
  }

  missingClicked(){
    window.scrollTo(0, 0)
    this.setState({missing: true})
    this.props.actions.assignMissing("missingAlert")
  }

  missingDismissed(){
    this.setState({missing: false})
    this.props.actions.assignMissing("missing")
  }

  getAlertBody(name){
    let list = ""
    if(!this.state.componentsRemoved) return list
    if(this.state.componentsRemoved.length > 1){
      let last = this.state.componentsRemoved[this.state.componentsRemoved.length - 1]
      list = this.state.componentsRemoved.slice(0, this.state.componentsRemoved.length - 1).join(", ") + " and " + last
    }else{
      list = this.state.componentsRemoved[0]
    }

    return "Because "+name+" already includes "+ list +", we've removed "+(this.state.componentsRemoved.length > 1 ? "them": "it")+ " from your cart."

  }


  render(){
    var pack = this.props.pack
    pack = pack && pack[0] ? pack[0].component : null

    if(!pack){
      return <Redirect to="/events" />
    }

    var cartMargin = (this.props.app.cart) ? {marginRight: 320} : {marginRight: 0}

    var missing =  this.missing(this.props.booking)
    return (
    <div className='new min-h-[100vh] flex-col flex'>
      <div className='sticky top-0 z-10'><Header /></div>
      <div className="package-booked max-w-full flex flex-col items-center jusitfy-center" style={cartMargin}>
        <div className='w-full px-4 justify-center flex item-center flex-col'>
        <div className="block-header max-w-[800px] w-full min-w-[320px] flex flex-col justify-center">
          <img src={Checkmark} className="icon h-16" alt=""/>
          <div className="title text-[22px] sm:text-[28px]">{"You've added "}<span className="emphasis">{pack.name}</span> to your Event Cart!</div>
          {missing ?
            <div>
              <div className="subtitle w-full px-4">Huzzah! Enter missing information on your event in the Book Bar, then choose Review & Book.</div>
              <div>
                <div className="custom-button  h-[40px] leading-[40px] text-lg justify-center" onClick={()=> this.missingClicked()} style={{float: 'none', marginBottom: 8, width: 221}}>Enter Missing Info.</div>
              </div>
            </div>
            : <div>
              <div className="subtitle w-full px-4">Huzzah! Go to Review & Book to get the party started, or consider adding a few of these popular add-ons.</div>
              <div>
                <Link to="/checkout" className="custom-button h-[40px] leading-[40px] text-lg justify-center" style={{float: 'none', marginBottom: 8, width: 221}}>Review & Book</Link>
              </div>
            </div>

          }
              <div>
                <button onClick={() => this.props.history.goBack()} className="secondary-button" style={{float: 'none', marginBottom: 36, width: 221}}>Back to Browse</button>
              </div>
        </div>
        {this.state.addons && this.state.addons.length > 0 ?
        <div className="addons max-w-[800px] text-center md:text-left flex-cols w-full min-w-[320px]">
          <div className="title">Optional Add-Ons for {pack.name}</div>
          <div className="subtitle w-auto">{pack.name} is already all-inclusive, but these popular add-ons make it even more awesome.</div>
          <div className={`text-left mt-4 grid grid-cols-[repeat(auto-fill,_minmax(320px,_1fr))] gap-7 justify-items-center md:justify-items-start`}>
              {this.state.addons.map( (component, i) =>
                <div key={i} >
                  <ServiceItem addOn={true} component={component} path={"/builder/" + component.categories[0] + "/" + component.id + "/" + component.name.replace(/\//g,"-")} />
                </div>
              )}
            </div>
        </div>
        : null}
        </div>
      </div>
      <div className="hidden sm:block mt-auto" ><Footer className="hidden sm:block"/></div>
      <BottomTabBar selected={"browse"} />
      <Alert show={!!this.state.componentsRemoved} onHide={()=> this.setState({componentsRemoved: null})} title={"Some items in your cart are included in "+pack.name+"!"} text={ ()=> this.getAlertBody(pack.name)} />
      <Alert show={this.state.missing} buttonText="Got It!" zIndex={9980} backdropClassName=" high-z" onHide={()=> this.missingDismissed()} title="Enter all your event information into the Book Bar." text="Once entered, we can show you pricing as you add items and let you Review & Book."/>
    </div>
    )
  }
}

const mapStateToProps = state => ({
  pack: state.cart.package, app: state.app, booking: state.booking
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageBooked))
