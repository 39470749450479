import React, { Component } from 'react';
import {Form, FormGroup, FormControl, Dropdown, MenuItem} from 'react-bootstrap'
import request from "../../request"
import VerifyModal from './VerifyModal'
import Warning from '../../img/WarningIcon.svg'
import Track from '../Utils/Analytics'
import CircleCheck from '../../img/CheckmarkCircle.svg'
export default class Signup extends Component{

  constructor(props){
    super(props)
    this.state = {
      email: "",
      password: "",
      re_password:"",
      name: "",
      verify: false,
      error: "",
      referral: props.location.state ? (props.location.state.referral || "") :  "",
      referred: props.location.state ? !!props.location.state.referral : false,
      source: "Choose One"
    }
  }

  handleChange(key, value){
    this.setState({[key]: value})
  }

  async handleSubmit(event){
    event.preventDefault();
    var req = ["name", "email", "password", "re_password"]
    for(var r of req){
      if(!this.state[r]){
        document.getElementById(r).focus()
        return
      }
    }
    if(this.state.source === "Choose One" && !this.state.referred){
      this.setState({error: "Please tell us how you heard about us."})
      return

    }
    if(this.state.password !== this.state.re_password){
      this.setState({error: "Passwords do not match"})
      return
    }

    //to send to server
    let redirect;
    if(this.props.location.state && this.props.location.state.from){
      redirect = this.props.location.state.from
    }
    var source = this.state.source === "Other" ? this.state.referral : this.state.source
    var referral = this.state.referral
    var params = { method: 'POST',
                  body: JSON.stringify({email: this.state.email, password: this.state.password, redirect, contact_info: {contact_name: this.state.name, referral, source}})
              };
    var res =  await request('/api/signup', params)
    if(!res) this.setState({error: "Server could not be reached"})
    if (res.err){
      console.log(res.err)
      if(res.err.err === "That email has already been used"){
        this.setState({error: "That email has already been used."})
        return
      }
      this.setState({error: "Sorry. We've had an error."})
      return
    }else{
      Track.createdAccount()
      this.setState({verify: true})
      // this.props.history.push("/verify")
    }

  }

  async resend(){
    //to send to server
    let redirect;
    if(this.props.location.state && this.props.location.state.from){
      redirect = this.props.location.state.from
    }
    var source = this.state.source === "Other" ? this.state.referral : this.state.source
    var params = { method: 'POST',
                  body: JSON.stringify({email: this.state.email, redirect, contact_info: {contact_name: this.state.name, referral: this.state.referral, source}})
              };
    var res =  await request('/api/signup/resend', params)
    if(!res) this.setState({error: "Server could not be reached"})
    if (res.err){
      console.log(res.err)
    }else{
      // this.props.history.push("/verify")
    }

  }

  render(){

    var disabled = ""
    var referred = this.state.referred;
    if (!this.state.email || !this.state.password || !this.state.name || this.state.password !== this.state.re_password || (this.state.source === "Choose One" && !referred)) disabled = " disabled"
    return (
      <div className="signup-card max-w-full">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <div className="title">Create Account</div>
          <FormGroup controlId="name">
            <label className="label">Your Name</label>
            <FormControl className="text-field" type="text" placeholder="Full Name" value={this.state.name} onChange={(e)=> this.handleChange("name", e.target.value)}/>
          </FormGroup>
          <FormGroup controlId="email">
            <label className="label">Email</label>
            <FormControl className="text-field" type="email" placeholder="example@1upevents.com" value={this.state.email} onChange={(e)=> this.handleChange("email", e.target.value)}/>
          </FormGroup>
          <label className="label pass-label inline-flex">Password</label>
          { this.state.password && this.state.password === this.state.re_password ? <img className='inline-flex ml-2 h-3 mb-[2px]' src={CircleCheck} alt=""/> : null}
          <div>
          <FormGroup style={{marginBottom: 0}} controlId="password" className="password flex-1 flex w-[inherit]">
            <FormControl className="text-field" type="password" placeholder="Password" value={this.state.password} onChange={(e)=> this.handleChange("password", e.target.value)}/>
          </FormGroup>
          <FormGroup controlId="re_password" className="re-password flex-1 flex w-[inherit] ml-0">
            <FormControl className="text-field flex-1" type="password" placeholder="Re-Enter Password" value={this.state.re_password} onChange={(e)=> this.handleChange("re_password", e.target.value)}/>
          </FormGroup>
          </div>
          {!referred ?
            <FormGroup controlId="source" className="source">
              <label className="label">How did you hear about us?</label>
              <div className="sort-box" style={{display: "block", width: "100%", height: 38, float: "none"}}>
              <Dropdown onClick={(e) => e.stopPropagation()} id="dropdown-custom-3" className="source-dropdown">
                <Dropdown.Toggle noCaret >
                  <div className="text-field" style={{display: "block", width: "100%", height: 30, float: "none"}}>
                    <span>{this.state.source}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                <MenuItem eventKey="6" active={this.state.source === "Referred"} onSelect={() => this.setState({source: "Referred" })}>I have a referral code</MenuItem>
                  <MenuItem eventKey="1" active={this.state.source === "Google"}  onSelect={() => this.setState({source: "Google" })}>Google</MenuItem>
                  <MenuItem eventKey="2" active={this.state.source === "Yelp"} onSelect={() => this.setState({source: "Yelp" })}>Yelp</MenuItem>
                  <MenuItem eventKey="3" active={this.state.source === "Facebook"} onSelect={() => this.setState({source: "Facebook" })}>Facebook</MenuItem>
                  <MenuItem eventKey="4" active={this.state.source === "Email"} onSelect={() => this.setState({source: "Email" })}>Email</MenuItem>
                  <MenuItem eventKey="5" active={this.state.source === "EventBrite"} onSelect={() => this.setState({source: "EventBrite" })}>Eventbrite Event</MenuItem>
                  <MenuItem eventKey="6" active={this.state.source === "Other"} onSelect={() => this.setState({source: "Other" })}>Other</MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </FormGroup>
            : null
          }
          {referred || this.state.source === "Referred" ?
            <FormGroup  controlId="referral" className="referral">
              <label className="label">Referral</label>
              <FormControl className="text-field" type="text" placeholder="Enter referral code (if you have one)" value={this.state.referral} onChange={(e)=> this.handleChange("referral", e.target.value)}/>
            </FormGroup>
            :
            null
          }
          {this.state.source === "Other" ?
            <FormGroup  controlId="referral" className="referral">
              <label className="label">Other</label>
              <FormControl className="text-field" type="text" placeholder="How did you hear about us?" value={this.state.referral} onChange={(e)=> this.handleChange("referral", e.target.value)}/>
            </FormGroup>
            :
            null
          }

          {this.state.error ? <div className="error-state"><img src={Warning} alt="" /><span className="text"> {this.state.error}</span></div> : null}
          <button className={"custom-button" + disabled}  style={{width: 100+"%"}} type="submit">
               Create Account
          </button>
          <div className="disclaimer">By registering, you agree to our terms and conditions and privacy policy.</div>
        </Form>
        <VerifyModal show={this.state.verify} onHide={() => this.setState({verify: false})} resend={() => this.resend()} email={this.state.email} />
      </div>
    )
  }
}
