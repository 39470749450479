import React from 'react'
import '../../css/Utils/Tldr.css'

const Tldr = () =>{
  return (
    <div className="tldr">
      <div>Summary:</div>
      <ul>
        <li>You will be charged a nonrefundable deposit equal to 50% of the total event cost immediately once you have completed booking.</li>
        <li>The remaining balance of the total event cost will be charged upon completion of your event.</li>
        <li>You may edit the details of an event in your dashboard up until 3 days before your event.</li>
        <li>You may edit your headcount within 10% of the headcount in your original booking up until 2 weeks before your event. Other details may be edited in your dashboard up until 3 days before your event.</li>
        <li>If you cancel your event more than 2 weeks before the event date, no additional payment will be due beyond your deposit.</li>
        <li>If you cancel your event less than 2 weeks before the event date, the remaining balance of your total event cost is due as well.</li>
        <li>If you need to reschedule your event and we are able to accommodate rescheduling, there is a 20% rescheduling fee. If your booking includes a venue, there may be an additional rescheduling fee. A new date within 6 months of your original date must be chosen at the time of rescheduling or it will be considered a cancellation.</li>
      </ul>
    </div>
  )
}

export default Tldr
