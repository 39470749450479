import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'


class ConfirmationModal extends Component {

  render(){

    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} onHide={this.props.onHide} customClassName="customize-modal" backdropClassName="customize-backdrop">
        <div style={{display: "block", width: "100%"}}>
          <div className="title"> { typeof this.props.title === "string" ? this.props.title : this.props.title()}</div>
          <div className="text">{typeof this.props.text === "string" ? this.props.text : this.props.text()}</div>
          <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={this.props.onConfirm}>{this.props.confirmationText || "Add To Cart"}</div>
          <div className="secondary-button" onClick={this.props.secondaryAction || this.props.onHide}>{this.props.secondaryText || "Cancel"}</div>
          {this.props.tertiary ? <div className="tertiary-button" onClick={this.props.onHide}>Cancel</div> : null}
        </div>
      </Modal>
    )
  }

}

export default ConfirmationModal
