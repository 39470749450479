import { combineReducers } from 'redux'
import cart from './cart'
import login from './login'
import app from './app'
import booking from './booking'

const eventsApp = combineReducers({
  cart, login, app, booking
})

export default eventsApp
