import React, {Component} from 'react'
import Modal from '../Modal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import Options from './Options'
import LimitationModal from '../Utils/LimitationModal'


class OptionsModal extends Component {

  constructor(props){
    super(props)
    var options = props.component.options || {}
    this.state = {
      limitation: null,
      options
    }
  }


  getQty(){
    if(this.state.qty) return this.state.qty
    if(this.props.component.unit === "person") return this.props.store.people
    if(this.props.component.unit === "hour") return this.props.store.duration
    return 1
  }

  addToCart(){
    var qty = this.getQty()
    if(this.props.component.maximum && Number(this.props.component.maximum) > 0 && qty > this.props.component.maximum){
      this.setState({limitation: "max"})
    }else{
      var clone = Object.assign({}, this.props.component)
      clone.qty = null
      clone.option_values = this.state.options
      console.log("ADDING TO CART...")
      this.props.addToCart(clone, this.props.category)
      this.props.onHide()
    }
  }

  confirmedAddToCart(){
    this.setState({limitation: null})
    var clone = Object.assign({}, this.props.component)
    clone.qty = null
    clone.option_values = this.state.options
    this.props.addToCart(clone, this.props.category)
    this.props.onHide()
  }

  addable(){
    console.log(this.props.component, this.state.options)
    for(var o of this.state.options.types){
      if(!o.disabled){
        if(o.type === "multiple"){
          if(!this.state.options[o.name] || this.state.options[o.name].length === 0) return false
        }else{
          if(!this.state.options[o.name] ) return false
        }
      }
    }
    return true
  }


  render(){
    console.log("rendor options modal")
    var confirm = <button className={"custom-button" + (this.addable() ? "" : " disabled")} disabled={!this.addable()} style={{float: "right", marginLeft: 9}} onClick={() => this.addToCart()} >Add to Cart</button>
    var component = this.props.component
    return (
      <Modal show={this.props.show} zIndex={2050} onHide={this.props.onHide} customClassName="customize-modal" backdropClassName="customize-backdrop">
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Customize {component.name}</div>
          {component.options && component.options.types ?
            <Options component={component} save={(options) => this.setState({options})} fromCart={false} selectedOptions={this.state.options}/>
             : null}
          {confirm}
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
        <LimitationModal component={component} limitation={this.state.limitation} store={this.props.store} show={!!this.state.limitation} onHide={() => this.setState({limitation: false})} onConfirm={() => this.confirmedAddToCart()}/>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  store: state.booking
})

const mapDispatchToProps = dispatch => ({
    addToCart: bindActionCreators(cartActions, dispatch).addToCart
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionsModal))






// export default CustomizationModal
