const initialState = {}

export default function app(state = initialState, action) {
  var clone;
  switch (action.type) {
    case 'SET_SESSION':
      clone = Object.assign({}, state);
      clone.session = action.session
      return clone

    case 'TOGGLE_CART':
      clone = Object.assign({}, state);
      clone.cart = !clone.cart
      return clone

    case 'OPEN_CART':
      clone = Object.assign({}, state);
      clone.cart = true
      return clone

    case 'CLOSE_CART':
      clone = Object.assign({}, state);
      clone.cart = false
      return clone

    case 'ASSIGN_MISSING':
      clone = Object.assign({}, state);
      clone.missing = action.value
      return clone

    case 'ASSIGN_WISHLIST':
      clone = Object.assign({}, state);
      clone.wishlist = action.value
      return clone

    default:
      return state


  }
}
