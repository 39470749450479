import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, Table} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PackagePage from '../Packages/PackagePage'
import ComponentSearch from './ComponentSearch'
import CheckboxControl  from '../Utils/CheckboxControl'
import Header from '../Header/Header'
import Title from '../Title'
import CheckboxTree from 'react-checkbox-tree';
import { getCurrentRegion, regionsToNodes, getLocationNames } from '../Utils/Locations'

var fields = {description: "textarea", daily_max: "number", price: "number", minimum: "number", maximum: "number", hour_max: "number",hour_min: "number",setup_time: "number", discount: "number", takedown_time: "number", components: "textarea", add_ons: "textarea", is_active: "checkbox", component_details: "hide" }
function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}
var packageFields = ["name", "description", "short_description", "includes", "images", "price", "num_hours", "minimum","setup_time", "takedown_time", "categories", "seasonal", "components", "add_ons", "keywords", "is_active", "options", "discount"]


function setComponents(real_components, pricing_arguments){
  let components = []
  for(let p of pricing_arguments){
    let comp = real_components.find( c => c.id == p.id)
    comp = (({ name, price, minimum, maximum, unit, normal_duration, extra_hours_fee, setup_time, takedown_time}) => ({name, price, minimum, maximum, unit, normal_duration, extra_hours_fee, setup_time, takedown_time}))(comp);
    components.push(Object.assign(comp, p))
  }
  return components
}

class NewPackage extends Component{

  constructor(props){
    super(props)
    var pack = {}
    for(var i of packageFields){
      pack[i] = null
    }
    pack.is_active =  true
    pack.id = 1
    pack.components = "[]"
    pack.add_ons = "{}"
    this.state = {
      pack,
      preview: false,
      id: null,
      new_id: "",
      error: "",
      search: "",
      search_id: "",
      regions: [],
      locations: [],
      expanded: []
    }
  }

  async componentDidMount(){
    var regions = await request('/api/regions')
    regions = regionsToNodes(regions)
    this.setState({regions})
  }

  handleChange(key, value){
    let clone = Object.assign({}, this.state.pack)
    clone[key] = value
    if(this.state.error){
      this.setState({pack: clone, error: ""})
      return
    }
    this.setState({pack: clone})
  }

  preview(){
    var  pack = Object.assign({}, this.state.pack)
    pack.components = JSON.parse(pack.components)
    // pack.component_details = JSON.parse(pack.components)
    pack.add_ons = JSON.parse(pack.add_ons)
    pack.categories = JSON.parse(pack.categories)
    pack.keywords = JSON.parse(pack.keywords)
    pack.images = JSON.parse(pack.images)
    pack.seasonal = JSON.parse(pack.seasonal)
    return pack
  }

  async setPreview(){
    var validated = this.validate(this.state.pack)
    if(validated){
      if(JSON.parse(this.state.pack.components).length > 0){
        var ids = JSON.parse(this.state.pack.components).map(c => c.id)
        var components = await request('/api/components', {method: "POST", body: JSON.stringify({ids})})
        var pack = JSON.parse(JSON.stringify(this.state.pack))
        pack.component_details = setComponents(components, JSON.parse(this.state.pack.components))
      }
      this.setState({preview: true, pack})
    }
  }

  validate(pack){
    let jsonList = ["images", "components", "add_ons", "seasonal", "keywords", "categories"]
    try{
      for(var c of jsonList){
        if(pack[c]){
          try{
            JSON.parse(pack[c])
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      if(!pack.name){
        document.getElementById("name").focus()
        this.setState({error: "Discount can't be empty"})
        return false
      }
      if(!pack.discount){
        document.getElementById("discount").focus()
        this.setState({error: "Discount can't be empty"})
        return false
      }
      if(!pack.categories){
        this.setState({error: "Categories can't be empty"})
        return false
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate(this.state.pack)
    if(validated){
      var pack = Object.assign({}, this.state.pack)
      delete pack.component_details
      delete pack.id
      pack.locations = this.state.locations
      var res  = await request("/api/admin/package", {method: "POST", body: JSON.stringify({package: pack} )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err === "Invalid html"){
        let d = document.getElementById("description")
        console.log("Description:", d)
        d.focus()
        this.setState({error: res.err})
        return
      }else if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        this.props.history.push("/admin_panel/package/" + res.id)
      }
    }
  }

  render(){
    if (!this.state.pack) return null
    return (
      <div>
      <Header />
      <div style={{marginTop: 40, marginLeft: 40}}>
        <Title title="New Package"/>
      </div>
      <div className="card form" style={{display: "inline-block"}}>
        <Form onSubmit={ (event) => this.handleSubmit(event)}>
          {packageFields.map((key, i) => {
            if(fields[key] === "textarea"){
              return (
                <FormGroup key={i} controlId={key}>
                  <ControlLabel>{key}</ControlLabel>
                  <FormControl className="text-field" componentClass="textarea" rows="8" style={{resize: "vertical"}}  bsSize="sm" placeholder={key} value={this.state.pack[key]} onChange={(e)=> this.handleChange(key, e.target.value)}/>
                </FormGroup>
              )
            }if(fields[key] === "checkbox"){
              return (
                <div>
                <CheckboxControl checked={this.state.pack[key]} onChange={(check) => this.handleChange(key, check)} />
                <label style={{marginLeft: 10}} onClick={() => this.handleChange(key, !this.state.pack[key])}>{key}</label>
                </div>
              )
            }if(fields[key] === "hide"){
              return null
            }else{
              return (
                <FieldGroup
                     id={key}
                     key={i}
                     type={fields[key]}
                     label={key}
                     className="text-field"
                     placeholder={key}
                     value={this.state.pack[key] || ""}
                     onChange={(e)=> this.handleChange(key, e.target.value)}
                     bsSize="sm"
                   />
              )
            }
          })}
          <div className="locations-section">
            <ControlLabel>{"Locations"}</ControlLabel>
            <CheckboxTree
                nodes={this.state.regions}
                checked={this.state.locations}
                expanded={this.state.expanded}
                onCheck={locations => this.setState({ locations })}
                onExpand={expanded => this.setState({ expanded })}
            />
          </div>
          <Button style={{marginRight: 20}} onClick={()=> this.setPreview()}>Preview</Button>
          <Button style={{marginRight: 20}} type="submit">
               Submit
          </Button>
          {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
        </Form>
      </div>
      <ComponentSearch />
      {this.state.preview ? <PackagePage booking={this.props.booking} show={this.state.preview} inCart={false} component={this.preview()} onHide={()=> this.setState({preview: false})} addToCart={() => null } /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  booking: state.booking
})


export default withRouter(connect(
  mapStateToProps
)(NewPackage))
