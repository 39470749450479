import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl} from 'react-bootstrap'
import * as cartActions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import request from '../../request'
import Header from '../Header/SimpleHeader'
import Logo from '../../img/1upLogoHeart.svg'

class Forgot extends Component{

  constructor(props){
    super(props)
    this.state = {email: "", password: "", sent: false}
  }

  handleChange(key, value){
    this.setState({[key]: value})
  }

  async handleSubmit(event){
    event.preventDefault();
    //to send to server
    var params = { method: 'POST',
                  body: JSON.stringify({email: this.state.email})
              };
    var res =  await request('/api/reset', params)
    if (res.err){
      console.log(res.err)
      return
    }
    console.log("reset email has been sent")
    this.setState({sent: true})
  }

  render(){

    if(this.state.sent){
      return (
        <div>
          <Header />
          <div className="card check-email" style={{marginTop: 120}}>
            <div className="text">Password reset email sent!</div>
            <img src={Logo} className="heart-logo" alt=""/>
          </div>
        </div>
      )
    }

    return (
      <div>
      <Header />
      <div className="card form" style={{marginTop: 120, marginRight: "auto", marginLeft: "auto"}}>
      <Form onSubmit={this.handleSubmit.bind(this)} >
        <FormGroup controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl type="email"  className="text-field" placeholder="Email" value={this.state.email} onChange={(e)=> this.handleChange("email", e.target.value)}/>
        </FormGroup>
        <Button type="submit">
             Submit
        </Button>
      </Form>
      </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot))
