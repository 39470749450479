import React, {Component} from 'react'
import Modal from '../Modal'
import '../../css/Utils/Button.css'
import '../../css/Utils/CustomizationModal.css'
import request from '../../request'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as cartActions from '../../actions/'

class RenameWishlist extends Component {

  constructor(props){
    super(props)
    this.state = {
      name: props.name
    }
  }

  async create(name){
    var wishlist = await request('/api/wishlist/rename', {method: "PUT", body: JSON.stringify({name, list_id: this.props.list_id}) })
    if(wishlist.err){
      console.log(wishlist.err)
    }else{
      console.log(wishlist)
      this.props.rename(name)
      this.props.actions.renameWishlist(name, this.props.list_id)
    }
    this.props.onHide()

  }

  render(){
    var confirm = this.state.name === "" ?
    <div className="custom-button disabled" style={{float: "right", marginLeft: 9}}>Save</div>
    : <div className="custom-button" style={{float: "right", marginLeft: 9}} onClick={() => this.create(this.state.name)}>Save</div>

    return (
      <Modal show={this.props.show} zIndex={this.props.zIndex || 2050} customClassName="customize-modal" backdropClassName={"customize-backdrop" + (this.props.backdropClassName || "")}>
        <div style={{display: "block", width: "100%"}}>
          <div className="title">Enter a new wish list name.</div>
          <input className="text-field create-wishlist" placeholder="New List Name" type="text" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} />
          {confirm}
          <div className="secondary-button" onClick={this.props.onHide}>Cancel</div>
        </div>
      </Modal>
    )
  }

}


const mapStateToProps = state => ({
  app: state.app, login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RenameWishlist))
// export default CreateWishlist
