const moment = require('moment-timezone')

function cartToLists(cart){
  var pack = null;
  var components = []
  for (var cat in cart){
    if(cat === "package"){
      if(cart[cat].length > 0){
        pack = cart[cat][0].component
      }
    }else{
      components = components.concat(cart[cat].map( i => i.component))
    }
  }
  return {package: pack, components}
}

function arraysEqual(_arr1, _arr2) {
    if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
      return false;
    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();
    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
    return true;
}

function isSamePackage(p1, p2){
  if(!p1 && !p2) return true
  if((p1 && !p2) || (p2 && !p1)) return false
  if(p1.id === p2.id) return true
  return false
}

function cartsContainSameItems(cart_1, cart_2){
  cart_1 = cartToLists(cart_1)
  cart_2 = cartToLists(cart_2)
  let comp1_ids = cart_1.components.map( c => c.id)
  let comp2_ids = cart_2.components.map( c => c.id)
  var same_package = isSamePackage(cart_1.package, cart_2.package)
  return same_package && arraysEqual(comp1_ids, comp2_ids)
}


function getTimezoneShortName(time, timezone) {
  return moment(time).tz(timezone).format('z')
}


module.exports = {
  getTimezoneShortName,
  cartsContainSameItems,
  isSamePackage,
  arraysEqual,
  cartToLists
}
