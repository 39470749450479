import React from 'react'
import './Services.css'
import {Link} from 'react-router-dom'
import request from '../../request'
import currencyFormatter from 'currency-formatter'
import pluralize from 'pluralize'

const Row = ({data}) => {
  // <td className="pricing-row">
  //   <div>What info do you want here?</div>
  // </td>
  var image = ""
  if(data.images) image = "https://d2c831se9gn8j1.cloudfront.net/"  + data.images[0]
  console.log(data.late_booking_percentage)
  return (
    <tr>
      <td>
        <div className="powerup" >
          <div className="powerup-image-container" >
            <img className="powerup-image" src={image} alt={data.component_name || data.name}/>
          </div>
          <div className="powerup-wrapper">
            <div className={"powerup-details included"}>
              <div className="powerup-name"> {data.component_name || data.name} </div>
            </div>
          </div>
        </div>
      </td>
      <td className="pricing-row">
        <div>${data.price} per {data.unit}</div>
        {data.minimum ? <div>{data.minimum} {pluralize(data.unit || "person")} fixed minimum</div> : null}
        {data.normal_duration ? <div>Up to {data.normal_duration} hours included</div> : null}
        {data.extra_hours_fee ? <div>${data.extra_hours_fee} for each additional hour</div> : null}
        {Number(data.late_booking_percentage || 0) ? <div>Late booking fee: {data.late_booking_percentage * 100}%</div> : null}
        {data.late_booking_threshold ? <div>Number of days before fee: {data.late_booking_threshold} days</div> : null}
      </td>

      <td className="stl">{data.is_active === null ? <span><div className="status in-review"/>In Review</span> : (data.is_active ? <span><div className="status"/>Active</span> : <span><div className="status paused"/>Paused</span>) }</td>
      <td className="last"><Link to={"/vendors/services/" + data.id} className="secondary-button"style={{marginTop: 0, width: 88}}>Edit</Link></td>

    </tr>
  )
}

class Services extends React.Component{

  constructor(props){
    super(props)

    this.state = {
      services: []//[{cost: 95, name: "Goat Yoga", setup_time: 0.5, time_remaining: "3h 2m", num_people: 40 }, {kits: 2, cost: 95, name: "Goat Yoga", location: "San Francisco", time_remaining: "3h 2m", num_people: 40 }],
    }
  }

  async componentDidMount(){
    var services = await request('/api/vendor/services')
    if(services.err){
      console.log(services)
    }else{
      console.log(services)

      this.setState({services})
    }
  }

  render(){
    // <th><div className="small-divider"/>DETAILS</th>

    return (
      <div className="services-view">
        <div className="section-head" style={{width: "100%", position: "relative"}}>Services You Provide<Link to="/vendors/services/add" style={{right: 0, position: "absolute", margin: 0}} className="custom-button vendor">Add New Service</Link></div>
        <div className="card">
          <table className="services-table">
            <tbody>
            <tr>
              <th>TYPE</th>
              <th><div className="small-divider"/>PRICING</th>
              <th><div className="small-divider"/>STATUS</th>
              <th></th>
            </tr>
            {this.state.services.map((s, i) =>
              <Row key={i} data={s}/>
            )}
            </tbody>
          </table>
        </div>

      </div>
    )
  }
}

export default Services
