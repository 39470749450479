import React from 'react'
import Static from './StaticWrapper'
import MyMap from '../../img/ServiceMap@2x.png'
import Pin from '../../img/NowServingPin.svg'
import MiniMap from '../../img/NowServingBottomMap@2x.png'
import CircleCheck from '../../img/CheckmarkCircle.svg'
import request from '../../request'

import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'

async function getLocation(){
  var res = await request("https://ipinfo.io/json")
  return res
}

class Service extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      email: "",
      backup: "",
      zipcode: this.props.values.zipcode,
      subscribed: false
    }
  }

  async componentDidMount(){
    if(!this.state.zipcode){
      var zipcode = await getLocation()
      var backup = zipcode.postal
      this.setState({backup})

    }
  }

  async handleSubmit(e){
    e.preventDefault()
    if(!this.state.email){
      document.getElementById("email").focus()
      return
    }
    var params = {method: "POST", body: JSON.stringify({email: this.state.email, zipcode: this.state.zipcode || this.state.backup})}
    var subscribed = await request('/api/subscribe', params )
    if(!subscribed.err){
      this.setState({subscribed: true})
    }else{
      if(subscribed.err.code === "23505"){
        this.setState({subscribed: true})
      }
      console.log(subscribed.err)
    }
  }

  // <img className="service-map" src={MyMap} alt="service map"/>


  render(){
    return (
      <Static className="service  px-4">
          <div className="static-page-header">Locations</div>
          <div className="static-page-subheader">{"Learn where we provide our services"}</div>
          <div className="card" style={{padding: 35}}>
            <div className="info" >
              <div className="pin">
                <img className="pin-icon" src={Pin} alt=""/>
                <img className="mini-map" src={MiniMap} alt=""/>
              </div>

              <div className="title">Now serving the Bay Area, CA and New York City!</div>
              <div className="text section">
                <p>We provide <b>full service to the entire Bay Area</b>, including San Francisco, Berkeley, Oakland, San Jose, Santa Cruz, and everywhere in-between.</p>
                <p>We also provide to <b>NYC</b>, including Manhattan, Brooklyn, Queens, and the Bronx.</p>

                {/* <p>We’re expanding quickly and plan on adding new cities throughout 2019! <b>Seattle and Portland</b>: we’re lookin’ at you.</p> */}
              </div>
            </div>
            <div className="sign-up">
            <div className="text" >
              <div style={{lineHeight: "35px"}}><b>Want to be notified when we expand?</b></div>
              <div style={{paddingBottom: 10}}>Enter your email and zip code below and we’ll shoot you an email.</div>
              <form>
                <input type="email" id="email" className="text-field" value={this.state.email} placeholder="Email Address" onChange={(e)=> this.setState({email: e.target.value})} />
                <input type="number" id="zipcode" className="text-field zip" value={this.state.zipcode} placeholder="Zip code" onChange={(e)=> this.setState({zipcode: e.target.value})} />

                {this.state.subscribed ?
                  <span className="secondary-button accepted" style={{float: "none"}}><img src={CircleCheck} alt="" style={{height: 16, marginBottom: 2}} />  Subscribed!</span>
                : <button type="submit" onClick={(e) => this.handleSubmit(e)} className="secondary-button" style={{float: "none"}}> Sign Up for Updates </button>}
              </form>
            </div>
            </div>
          </div>
      </Static>
    )
  }

}

const mapStateToProps = state => ({
  values: state.booking
})

export default withRouter(connect(
  mapStateToProps,
)(Service))
