import React from "react"
import request from '../../request'
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, Table} from 'react-bootstrap'
import moment from 'moment-timezone'
import currencyFormatter from 'currency-formatter'
import Selector from '../Utils/Selector'

let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November", "December"]
let quarters_names = ['Q1', 'Q2', 'Q3', 'Q4']

function sortByMonth(events, field){
  let months = {}
  for (var e of events){
    let month =  moment(e[field]).tz(e.timezone).format('MMMM')
    // console.log(month, moment(e[field]).format(), field)
    if(months[month]){
      months[month].push(e)
    }else{
      months[month] = [e]
    }
  }
  return months
}

function determineQuarter(m){
  if(m < 3){
    return 'Q1'
  }
  if(m >= 3 && m < 6){
    return 'Q2'
  }
  if(m >=6 && m < 9){
    return 'Q3'
  }
  if(m >= 9){
    return 'Q4'
  }
}

function sortByQuarter(events, field){
  let quarters = {Q1: [], Q2: [], Q3: [], Q4: []}
  for (var e of events){
    let quarter = determineQuarter(moment(e[field]).tz(e.timezone).month())
    quarters[quarter].push(e)
  }
  return quarters
}

function sumField(events, field, target_year){
  if(!events) return 0
  let sum = 0
  for (var e of events){
    sum += e[field]
  }
  return sum
}

export default class Services extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      company: "",
      id: "",
      year: {value: '2025', name: '2025'},
      events: [],
      showEvents: false,
      booking_date: false,
    }
  }

  async getEvents(year){
    var events = await request(`/api/admin/analytics/${year}`)
    events.sort((a, b) => moment(a.date) < moment(b.date))
    if(events.err){
      console.log(events)
    }else{
      this.setState({events})
    }
  }

  componentDidMount(){
    this.getEvents(this.state.year.value)
  }

  filterBySearch(e){
    if (this.state.id) {
      return e.filter(a => a.id.toLowerCase().includes(this.state.id.toLowerCase()))
    }
    if (this.state.company) {
      return e.filter(a => (a.company ? a.company.toLowerCase().includes(this.state.company.toLowerCase()): false))
    }
    return e
  }

  filterByYear(arr, field, year){
    return arr.filter(a => String(moment(a[field]).tz(a.timezone).year()) === year)
  }


  updateYear(year){
    this.setState({year})
    this.getEvents(year.value)
  }

  render(){
    if(this.state.showEvents) {
      return this.showEvents()
    }
    let year = this.state.year.value
    let sortBy = (this.state.booking_date ? "booking_date" : 'date')
    let events = this.filterByYear(this.state.events, sortBy, year)
    let eventsByMonth = sortByMonth(events, sortBy)
    let quarters = sortByQuarter(events, sortBy)
    let total_revenue = sumField(events, 'price')
    let total_profit = sumField(events, 'profit')
    let total_margin = Math.round(total_profit/total_revenue * 100)
    let total_count = events.length
    let years =  [{value: '2018', name: '2018'},{value: '2019', name: '2019'},{value: '2020', name: '2020'}, {value: '2021', name: '2021'},{value: '2022', name: '2022'}, {value: '2023', name: '2023'}, {value: '2024', name: '2024'}, {value: '2025', name: '2025'}]
    return (
      <div className="card" style={{margin: 20, padding: 20}}>
      <Selector value={this.state.year} update={(year) => this.updateYear(year)} options={years} style={{display: 'inline-block'}}/>
      {this.state.booking_date ?
        <button onClick={() => this.setState({booking_date: false})} style={{float: "none", marginLeft: 20}} className="secondary-button">Event Date</button>
        : <button onClick={() => this.setState({booking_date: true})} style={{float: "none", marginLeft: 20}} className="secondary-button">Booking Date</button>
      }
      <button onClick={() => this.setState({showEvents: true})} style={{float: "right"}} className="secondary-button">Show Events</button>


        <Table style={{marginTop: 20}}>
          <thead>
            <tr>
              <th>Month</th>
              <th>Revenue</th>
              <th>Profit</th>
              <th>Margin</th>
              <th>Event Count</th>
            </tr>
          </thead>
          <tbody>
            {months.map((m, i) => {
              let price = 0
              let profit = 0
              let count = 0
              let margin = "N/A"
              let estimate = false
              if(eventsByMonth[m]){
                price = sumField(eventsByMonth[m], "price")
                profit = sumField(eventsByMonth[m], "profit")
                estimate = eventsByMonth[m].some( a => a.est )
                count = eventsByMonth[m].length
                margin = Math.round(profit/price * 100)
              }

              return (
                <tr key={i}>
                  <td>{m}</td>
                  <td>{currencyFormatter.format(price , { code: 'USD', precision: 2})}</td>
                  <td>{currencyFormatter.format(profit , { code: 'USD', precision: 2})}<i>{estimate ? " (est)" : ""}</i></td>
                  <td>{margin}%</td>
                  <td>{count}</td>
                </tr>
              )

              }
            )}
          </tbody>
        </Table>
        <Table style={{marginTop: 40}}>
          <thead>
            <tr>
              <th>Month</th>
              <th>Revenue</th>
              <th>Profit</th>
              <th>Margin</th>
              <th>Event Count</th>
            </tr>
          </thead>
          <tbody>
            {quarters_names.map((q, i) => {
              let price = 0
              let profit = 0
              let count = 0
              let margin = "N/A"
              if(quarters[q]){
                price = sumField(quarters[q], "price")
                profit = sumField(quarters[q], "profit")
                count = quarters[q].length
                margin = Math.round(profit/price * 100)
              }
              return (
                <tr key={i}>
                  <td>{q}</td>
                  <td>{currencyFormatter.format(price , { code: 'USD', precision: 2})}</td>
                  <td>{currencyFormatter.format(profit , { code: 'USD', precision: 2})}</td>
                  <td>{margin}%</td>
                  <td>{count}</td>
                </tr>
              )

              }
            )}
            <tr key={'total'}>
              <td>{"Total"}</td>
              <td>{currencyFormatter.format(total_revenue , { code: 'USD', precision: 2})}</td>
              <td>{currencyFormatter.format(total_profit , { code: 'USD', precision: 2})}</td>
              <td>{total_margin}%</td>
              <td>{total_count}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }


  showEvents(){
    let events = this.state.events.sort((a, b) => moment(a.date).isBefore(moment(b.date)))
    // if(!this.state.booking_date) events = this.filterByYear(events)
    events = this.filterBySearch(events)
    let total_revenue = sumField(events, 'price')
    let total_profit = sumField(events, 'profit')
    let total_margin = Math.round(total_profit/total_revenue * 100)
    let total_count = events.length
    return (
      <div className="card" style={{margin: 20, padding: 20}}>
        <div>
          <button onClick={() => this.setState({showEvents: false})} style={{float: "right"}} className="secondary-button">Hide Events</button>
          <span>Search:  </span>
          <input type="text" className="text-field" placeholder="Search by company" value={this.state.company} onChange={(e) => this.setState({company: e.target.value})}/>
          <input type="text" className="text-field" style={{marginLeft:20}} placeholder="Search by id" value={this.state.id} onChange={(e) => this.setState({id: e.target.value})}/>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Event ID</th>
              <th>Date</th>
              <th>Booking Date</th>
              <th>Revenue</th>
              <th>Profit</th>
              <th>Margin</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>
            {events.map((e, i) =>
                <tr key={i}>
                  <td><a href={"event/" + e.id} className='link'>{e.id}</a></td>
                  <td>{moment(e.date).format('MMM-DD')}</td>
                  <td>{moment(e.booking_date).format('MMM-DD')}</td>
                  <td>{currencyFormatter.format(e.price , { code: 'USD', precision: 2})}</td>
                  <td>{currencyFormatter.format(e.profit , { code: 'USD', precision: 2})}<i>{e.est ? " (est)" : ""}</i></td>
                  <td>{Math.round(e.margin*100)}%</td>
                  <td>{e.company}</td>
                </tr>
              )}
              <tr key={'total'}>
                <td>{"Total"}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{currencyFormatter.format(total_revenue , { code: 'USD', precision: 2})}</td>
                <td>{currencyFormatter.format(total_profit , { code: 'USD', precision: 2})}</td>
                <td>{total_margin}%</td>
                <td>{total_count} events</td>
              </tr>
          </tbody>
        </Table>
      </div>
    )
  }


}
