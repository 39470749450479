// eslint-disable-next-line
import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'


class ScrollToTop extends Component {

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname){
      if( (!prevProps.location.pathname.includes(this.props.location.pathname) && !this.props.location.pathname.includes(prevProps.location.pathname)) || prevProps.location.pathname === "/" ){
        console.log("ScrollToTop")
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
