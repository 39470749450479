import React, { Component } from 'react';
import {Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'
import '../../css/AdminDash.css'
import request from '../../request'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import pretty from 'pretty'
import ServicePage from '../Services/ServicePage'
import CheckboxControl  from '../Utils/CheckboxControl'
import Header from '../Header/Header'
import Title from "../Title"
// import { SingleDatePicker } from 'react-dates';
import CheckboxTree from 'react-checkbox-tree';
import { getCurrentRegion, regionsToNodes, getLocationNames } from '../Utils/Locations'
import BlockOff from '../Utils/BlockOff'
import TravelFees from '../Utils/TravelFees'


var fields = {description: "textarea", daily_max: "number", normal_duration: "number", extra_hours_fee: "number", options: "textarea", add_ons: "textarea", is_active: "checkbox", staff: "checkbox", sub_categories: "textarea", event_host: "checkbox", booking_lead_time: "number", delivery: "checkbox", pickup: "checkbox", taxable: "checkbox", remote: "checkbox"}
function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup key={id} controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}


class EditPowerup extends Component{

  constructor(props){
    super(props)
    this.state = {
      powerup: null,
      preview: false,
      id: null,
      new_id: "",
      error: "",
      regions: [],
      locations: [],
      expanded: [],
      book: false,
      sub_region_fees: {},
      region_fees: {},
      travel_fees: {}
    }
  }

  showComponentForm(){
    this.setState({showForm: true})
  }

  componentDidMount(){
    this.getRegions()
    this.getData(this.props)
  }

  async getRegions(){
    var regions = await request('/api/regions')
    regions = regionsToNodes(regions)
    this.setState({regions})
  }

  async getData(props){
    var powerup = await request('/api/component/'+props.match.params.id)
    powerup.options = JSON.stringify(powerup.options,null, 2)
    powerup.categories = JSON.stringify(powerup.categories)
    powerup.sub_categories = JSON.stringify(powerup.sub_categories,null, 2)
    powerup.related = JSON.stringify(powerup.related)
    powerup.keywords = JSON.stringify(powerup.keywords)
    powerup.images = JSON.stringify(powerup.images)
    powerup.seasonal = JSON.stringify(powerup.seasonal)
    powerup.description = pretty(powerup.description)
    delete powerup.id
    delete powerup.created_at
    delete powerup.modified_at
    console.log("Admin:", powerup)
    this.setState({powerup, id: props.match.params.id, locations: powerup.locations, travel_fees: powerup.travel_fees || {}})
  }

  componentWillReceiveProps(nextProps){
    if(this.props.match.params.id !== nextProps.match.params.id){
      this.getData(nextProps)
    }
  }

  handleChange(key, value){
    let clone = Object.assign({}, this.state.powerup)
    clone[key] = value
    if(this.state.error){
      this.setState({powerup: clone, error: ""})
      return
    }
    this.setState({powerup: clone})
  }

  preview(){
    var  powerup = Object.assign({id: this.state.id}, this.state.powerup)
    powerup.options = JSON.parse(powerup.options)
    powerup.categories = JSON.parse(powerup.categories)
    powerup.sub_categories = JSON.parse(powerup.sub_categories)
    powerup.related = JSON.parse(powerup.related)
    powerup.keywords = JSON.parse(powerup.keywords)
    powerup.images = JSON.parse(powerup.images)
    powerup.seasonal = JSON.parse(powerup.seasonal)
    powerup.travel_fees = this.state.travel_fees
    return powerup
  }

  async setPreview(){
    var validated = await this.validate(this.state.powerup)
    if(validated){
      this.setState({preview: true})
    }
  }

  async validate(powerup){
    let jsonList = ["images", "options", "sub_categories", "seasonal", "keywords", "categories", "related"]
    try{
      for(var c of jsonList){
        if(powerup[c]){
          try{
            JSON.parse(powerup[c])
          }catch(err){
            document.getElementById(c).focus()
            return false
          }
        }
      }
      return true
    }catch(err){
      console.log(err)
      return false
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    var validated = await this.validate(this.state.powerup)
    if(validated){
      var clone = Object.assign({}, this.state.powerup)
      clone.locations = this.state.locations
      clone.travel_fees = this.state.travel_fees
      var res = await request("/api/admin/component", {method: "PUT", body: JSON.stringify({component: clone, id: this.state.id} )})
      if(!res){
        this.setState({error: "Server did not respond"})
        return
      }
      if(res.err === "Invalid html"){
        let d = document.getElementById("description")
        console.log("Description:", d)
        d.focus()
        this.setState({error: res.err})
        return
      }else if(res.err){
        this.setState({error: res.msg || JSON.stringify(res.err)})
        return
      }else{
        window.location.reload();
      }
    }
  }

  async bookOut(){
    // eslint-disable-next-line
    var res = await request('/api/admin/bookOut/', {method: "POST", body: JSON.stringify({id: this.state.id, date: this.props.booking.date})})
  }

  async bookOne(start_time, end_time){
    // eslint-disable-next-line
    this.setState({book: false})
    var res = await request('/api/admin/book/', {method: "POST", body: JSON.stringify({id: this.state.id, start_time, end_time, zipcode: this.props.booking.zipcode, timezone: this.props.booking.timezone})})
  }

  async delete(){
    var res = await request('/api/admin/components/' + this.state.id, {method: "DELETE"})
  }

  // <Button style={{marginLeft: 20}} onClick={()=> this.bookOne()}>Book Powerup Once</Button>
  // <Button style={{marginLeft: 20}} onClick={()=> this.bookOut()}>Book Powerup to the Max</Button>


  render(){
    if (!this.state.powerup) return null
    return (
      <div>
      <Header />
      <div style={{marginTop: 40, marginLeft: 40, marginBottom: 20}}>
        <div><Title title="Edit Powerup"/></div>

        <input type="number" className="text-field" value={this.state.new_id} placeholder="powerup id" onChange={(e) => this.setState({new_id: e.target.value})}/>
        <Button style={{marginLeft: 20}} onClick={()=> this.props.history.push("/admin_panel/powerup/" + this.state.new_id )}>Change Powerup</Button>
        <Button style={{marginLeft: 20}} onClick={()=> this.setState({book: true})}>Book Powerup Once</Button>
        <Button style={{marginLeft: 20}} onClick={()=> this.delete()}>Delete</Button>
        <Link to={{pathname: "/admin_panel/new-powerup", state: this.state.powerup }}style={{marginLeft: 20, float: "none"}} className="secondary-button">Duplicate</Link>


      </div>
      <div className="card form">
      <Form onSubmit={ (event) => this.handleSubmit(event)}>
        {Object.keys(this.state.powerup).map((key, i) => {
          if(fields[key] === "textarea"){
            return (
              <FormGroup key={i} controlId={key}>
                <ControlLabel>{key}</ControlLabel>
                <FormControl className="text-field" componentClass="textarea" style={{resize: "vertical"}} rows="8"  bsSize="sm" placeholder={key} value={this.state.powerup[key]} onChange={(e)=> this.handleChange(key, e.target.value)}/>
              </FormGroup>
            )
          }if(fields[key] === "checkbox"){
            return (
              <div>
              <CheckboxControl checked={this.state.powerup[key]} onChange={(check) => this.handleChange(key, check)} />
              <label style={{marginLeft: 10}} onClick={() => this.handleChange(key, !this.state.powerup[key])}>{key}</label>
              </div>
            )
          }else{
            if(key === "locations" || key === "travel_fees" || key === "deleted"){
              return null
            }
            return (
              <FieldGroup
                   id={key}
                   key={i}
                   type={fields[key]}
                   label={key}
                   className="text-field"
                   placeholder={key}
                   value={this.state.powerup[key] || ""}
                   onChange={(e)=> this.handleChange(key, e.target.value)}
                   bsSize="sm"
                 />
            )
          }
        })}
        <div className="locations-section">
          <ControlLabel>{"Locations"}</ControlLabel>
          <CheckboxTree
              nodes={this.state.regions}
              checked={this.state.locations}
              expanded={this.state.expanded}
              onCheck={locations => this.setState({ locations })}
              onExpand={expanded => this.setState({ expanded })}
          />
        </div>
        <div className="locations-section">
          <ControlLabel>{"Travel Fees"}</ControlLabel>
          <TravelFees region_fees={this.state.region_fees} sub_region_fees={this.state.sub_region_fees} travel_fees={this.state.travel_fees} regions={this.state.regions} locations={this.state.locations}
              changeState={this.setState.bind(this)}
          />
        </div>
        <Button style={{marginRight: 20}} onClick={()=> this.setPreview()}>Preview</Button>
        <Button style={{marginRight: 20}} type="submit">
             Submit
        </Button>
        {this.state.error ? <span style={{color: "red"}}>{this.state.error}</span> : null}
      </Form>
      </div>
      {this.state.preview ? <ServicePage booking={this.props.booking} show={this.state.preview} inCart={false} component={this.preview()} onHide={()=> this.setState({preview: false})} addToCart={() => null } /> : null}
      <BlockOff onHide={() => this.setState({book: false})} show={this.state.book} save={(start, end) => this.bookOne(start, end)}  timezone={this.props.booking.timezone} confirmText={"Block Off"} title={"Block off one time"} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  booking: state.booking
})


export default withRouter(connect(
  mapStateToProps
)(EditPowerup))
