import React from 'react'
import Static from './StaticWrapper'
import './EventHost.css'
import './CustomEvent.css'
import Title from '../Title'
import request from '../../request'
import Alert from '../Utils/Alert'
import TimePicker from 'rc-time-picker';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'


class CustomEvent extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      email: "",
      company: "",
      phone: "",
      num_people: "",
      date: null,
      start_time:  moment().hour(12).minute(0),
      end_time: moment().hour(15).minute(0),
      address: "",
      looking_for: "",
      preference: "email",
      submitted: false,
      end_open: false,
      start_open: false,
      focused: false,
      missing: false
    }

  }


  async submit(){
    if(this.state.email || this.state.phone){
      var clone = Object.assign({}, this.state)
      clone.date = this.state.date.format("ddd, MMM D, YYYY")
      clone.start_time = this.state.start_time.format('h:mm a')
      clone.end_time = this.state.end_time.format('h:mm a')
      request('/api/custom_event', {method: "POST", body: JSON.stringify(clone)})
      this.setState({
        name: "",
        email: "",
        company: "",
        phone: "",
        num_people: "",
        date: null,
        start_time:  moment().hour(12).minute(0),
        end_time: moment().hour(15).minute(0),
        address: "",
        looking_for: "",
        preference: "email",
        submitted: true,
        end_open: false,
        start_open: false,
        focused: false,
        missing: false
      })
    }else{
      console.log("missing")
      this.setState({missing: true})
    }

  }

  displayFormat(){
    return "ddd, MMM D, YYYY"
  }

  blockOut(date){
    return date < moment().add(3, 'days')
  }


  render(){

    return (
      <Static className="custom-event">
        <div className="static-page-header">Custom Event Form</div>
        <div className="card my-form" style={{padding: 20}}>
          <div className="disclaimer">Please let us know what you have in mind, with as much detail as possible. We’ll work with you to turn your ideas into the perfect event. <b>All fields are optional.</b> </div>
          <Title title="About Your Event:"/>
          <div className="group time" >
            <div className="question">Event date</div>
            <SingleDatePicker id="date_input"
              date={this.state.date ? moment(this.state.date) : null}
              focused={this.state.focused}
              onDateChange={(date) => this.setState({date})}
              onFocusChange={({ focused }) => this.setState({focused})}
              displayFormat={this.displayFormat}
              isDayBlocked={this.blockOut}
              hideKeyboardShortcutsPanel={true}
              placeholder="Date of event"
              numberOfMonths={1}/>
            </div>
          <div className="group" >
            <div className="question">Number of people</div>
            <input type="number" className="text-field" value={this.state.num_people} onChange={(e) => this.setState({num_people: e.target.value})}/>
          </div>
          <div className="group time" >
            <div className="question">Start/end time</div>
            <TimePicker
               showSecond={false}
               value={this.state.start_time}
               className={this.state.time_open ? "time open": "time"}
               onChange={(value) => this.setState({start_time: value})}
               format={'h:mm a'}
               minuteStep={15}
               onOpen={() => this.setState({start_open: true})}
               onClose={() => this.setState({start_open: false})}
               use12Hours/>
             <TimePicker
                showSecond={false}
                value={this.state.end_time}
                className={this.state.end_open ? "time open": "time"}
                onChange={(value) => this.setState({end_time: value})}
                format={'h:mm a'}
                minuteStep={15}
                onOpen={() => this.setState({end_open: true})}
                onClose={() => this.setState({end_open: false})}
                use12Hours
              />
          </div>
          <div className="question">Address</div>
          <input type="text" className="text-field address" value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} />
          <div className="question">Let us know what you’re looking for from your event!</div>
          <textarea className="text-field" value={this.state.looking_for} onChange={(e) => this.setState({looking_for: e.target.value})}/>
          <div className="about-you">
            <Title title="About You:" />
            <div className="details">
              <div className="group" >
                <div className="question">Full Name:</div>
                <input className="text-field" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
              </div>
              <div className="group" >
                <div className="question">Email:*</div>
                <input type="email" className="text-field" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
              </div>
              <div className="group" >
                <div className="question">Company:</div>
                <input type="text" className="text-field" value={this.state.company} onChange={(e) => this.setState({company: e.target.value})} />
              </div>
              <div className="group" >
                <div className="question">Phone:</div>
                <input type="tel" className="text-field" value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} />
              </div>
            </div>
            <div className="question">How do you prefer us to contact you?</div>
            <div>
              <input type="radio" id="email-radio" value={"email"} name="email" onChange={(e) => this.setState({preference: e.currentTarget.value})} checked={this.state.preference === "email"} />
              <label htmlFor="email-radio">Email</label>
              <input type="radio" id="phone-radio" value={"phone"} name="phone" onChange={(e) => this.setState({preference: e.currentTarget.value})} checked={this.state.preference === "phone"} />
              <label htmlFor="phone-radio">Phone</label>
            </div>
          </div>
          <div className="custom-button" style={{display: "inline-block"}} onClick={()=> this.submit()} >Submit to 1up</div>
          <span style={{paddingLeft: 12, color: "var(--primary-content-color)"}}>We'll contact to you within 1-2 business days.</span>
          {this.state.missing && !this.state.email && !this.state.phone ? <div style={{color: "red", marginTop: 8}}>Please give us either your email or your phone number</div> : null}
        </div>
        <Alert show={this.state.submitted} title="Thank you for your submission!" text="We'll contact to you within 1-2 business days." onHide={()=>this.setState({submitted: false})}/>
      </Static>
    )
  }

}

export default CustomEvent
