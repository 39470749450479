import React from 'react'
import Static from '../StaticPages/StaticWrapper'
import '../StaticPages/EventHost.css'
import './AddService.css'
import { withRouter, Link } from 'react-router-dom'
import currencyFormatter from 'currency-formatter'
import Title from '../Title'
import request from '../../request'
import Alert from '../Utils/Alert'
import Image from '../Utils/SpecialImage'
import SurveyFlow from '../Utils/SurveyFlow'
import ServiceSelector from './ServiceSelector'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Checkmark from '../../img/CheckmarkCheck.svg'
import {MenuItem, Dropdown} from 'react-bootstrap'
import pluralize from 'pluralize'
import CheckIcon from '../../img/CheckmarkCircle.svg'
import moment from 'moment'
import CheckboxControl from '../Utils/CheckboxControl'
import _ from 'underscore'
import UnitPicker from '../Utils/UnitPicker'
import TravelFees from '../Utils/TravelFees'
import ValueSelector from '../Utils/ValueSelector'

async function getLocation(){
  var res = await request("https://ipinfo.io/json")
  return res
}

function getCurrentRegion(locations, regions){
  var expanded = []
  for (var r of regions){
    for(var s of r.sub_regions){
      for(var l of s.locations){
        if(locations.includes(l.id)){
          expanded.push(String(r.id + "r"))
          expanded.push(String(s.id + "sub"))
        }
      }
    }

  }
  return expanded
}

function regionsToNodes(regions){
  const nodes = []
  for(var r of regions){
    var n = {value: String(r.id) + "r", label: r.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
    for(var s of r.sub_regions){
      var p = {value: String(s.id) + "sub", label: s.name, icon: <img src={Checkmark} className="checkmark" />, children: []}
      for (var l of s.locations){
        p.children.push({value: String(l.id), label: l.name, icon: <img src={Checkmark} className="checkmark" />})
      }
      p.children = _(p.children).sortBy("label")
      n.children.push(p)
    }
    nodes.push(n)
  }
  return nodes
}

var requirement_options = [{name: "outlet",showQty: true, qty: 1},{name: "table", showQty:true, qty: 1}, {name: "chair", showQty: true, qty: 1},
{name: "regular elevator", showQty: false, qty: 1}, {name: "freight elevator", showQty: false, qty: 1}, {name: "sink", showQty: false, qty: 1}, {name: "WIFI", showQty: false, qty: 1}]

function getValues(values, checked, qty){
  var clone = JSON.parse(JSON.stringify(values))
  console.log("getting values", clone, checked, values)
  if(!!values.find(v => v.name === checked.name)){
    return clone.filter(v => v.name !== checked.name)
  }else{
    clone.push({name: checked.name, qty})
    return clone
  }
}

function setQty(values, opt, qty){
  var clone = JSON.parse(JSON.stringify(values))
  var v = clone.find(v => v.name === opt.name)
  if(v){
    v.qty = qty
  }
  return clone
}

const Requirements = ({values, setValues}) => {
  return (
    <div className="requirements">
      {requirement_options.map((opt, key) => {
        var v = values.find(v => v.name === opt.name)
        return (
          <div className="opt" key={key} >
            <CheckboxControl checked={!!values.find(v => v.name === opt.name)} onChange={checked => setValues(getValues(values, opt, opt.qty))} />
            <label onClick={() => setValues(getValues(values, opt, opt.qty))} htmlFor={opt.name}>{opt.name}</label>
            {opt.showQty ?
              <span>
                <input placeholder="How many?" className="text-field" type="number" value={v ? v.qty : ""} onChange={(e) => setValues(setQty(values, opt, e.target.value))}/>
                {pluralize(opt.name)}
              </span>
              :
            null}
          </div>
        )})
      }
    </div>
  )
}

function getBody(details){
  let late_booking_percentage = 0
  if(details.late_booking_percentage){
    late_booking_percentage = String(Number(details.late_booking_percentage)/100)
  }
  return {
    name: details.name,
    locations: details.locations,
    requirements: details.requirements,
    unit: details.unit,
    price: details.price,
    minimum: details.minimum,
    maximum: details.maximum,
    normal_duration: details.normal_duration, //kits only
    extra_hours_fee: details.extra_hours_fee, //kits only
    typical_num_staff: details.typical_num_staff,
    attendees_per_staff: details.attendees_per_staff,
    setup_time: details.setup_time,
    takedown_time: details.takedown_time,
    late_booking_percentage,
    late_booking_threshold: details.late_booking_threshold, //(days)
    max_events: details.max_events,
    booking_lead_time: details.booking_lead_time,
    description: details.description,
    options: details.options,
    deposit: details.deposit,
    flat_fee: details.flat_fee,
    travel_fees: details.travel_fees,
    extra_hours_fee_scalar: details.extra_hours_fee_scalar,
    extra_hours_fee_scalar_unit: details.extra_hours_fee_scalar_unit === "people" ? "person" : "kit",
    calendar_id: details.calendar_id
  }
}

class EditService extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      name: "",
      component_name: "",
      component_id: "",
      regions: [],
      locations: [],
      expanded: [],
      requirements: [],
      unit: "person",
      price: "1",
      minimum: "",
      maximum: "",
      normal_duration: "", //kits only
      extra_hours_fee: "", //kits only
      typical_num_staff: "1",
      setup_time: "",
      takedown_time: "",
      late_booking_percentage: "",
      late_booking_threshold: "", //(days)
      max_events: "1",
      booking_lead_time: "",
      description: "",
      included: "",
      vendor: null,
      extra_charge_late: "",
      extra_charge_too_long: "",
      deposit: "",
      deposit_required: "",
      options: "",
      attendees_per_staff: "",
      is_active: false,
      flat_fee: "0",
      travel_fees: {},
      extra_hours_fee_scalar_unit: "people",
      extra_hours_fee_scalar: "",
      extra_charge_scales: "",
      region_fees: {},
      synced_calendars: {},
      calendar_id: null,
      sub_region_fees: {},
      travel_fee: "no"
    }
  }

  changeNumber(field, val, char_limit = 7){
    console.log(field, val)
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < char_limit + 1){
        this.changeState({[field]: val})
      }
    }
  }

  changeDecimal(field, val){
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9.\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.changeState({[field]: val})
      }
    }
  }

  changeNegativeDecimal(field, val){
    if (!val){
      this.changeState({[field]: val})
      return
    }
    const re = /^[0-9.\-\b]+$/;
    // eslint-disable-next-line
    if (val == '' || re.test(val)) {
      if(val.length < 7){
        this.changeState({[field]: val})
      }
    }
  }


  changeState(state){
    this.setState(state)
  }

  async componentDidMount(){
    var url = '/api/vendor/service/' + this.props.match.params.id
    if(this.props.location.pathname.includes("admin_panel")){
      url = '/api/admin/service/' + this.props.match.params.id
    }
    var service = await request(url)
    if(service.err){
      this.setState({err: true, msg: "Can't find service"})
      return
    }
    console.log("mounting EditService", service)
    var synced_calendars = []
    if(!this.props.location.pathname.includes("admin_panel")){
      synced_calendars = await request('/api/vendor/syncedCalendars')
      console.log(synced_calendars)
      if (!synced_calendars.err){
        for(var c of synced_calendars){

          if(c.primary_calendar){
            c.name = `Primary Calendar (${c.title})`
          }else{
            c.name = c.title
          }
          c.value = c.calendar_id
        }
      }else{
        synced_calendars = []
      }
    }
    var regions = await request('/api/regions')
    // var expanded = getCurrentRegion(service.locations, regions)
    regions = regionsToNodes(regions)
    service.expanded = []
    service.regions = regions
    for(var key in service){
      if(key !== "is_active"){
        service[key] = service[key] || ""
      }
    }
    service.requirements = service.requirements || []
    service.travel_fees = service.travel_fees || {}
    service.extra_hours_fee_scalar_unit = service.extra_hours_fee_scalar_unit || "people"
    service.deposit_required = service.deposit ? "yes" : "no"
    service.travel_fee = Object.keys(service.travel_fees).length > 0 ? "yes" : "no"
    service.extra_charge_late = service.late_booking_threshold ? "yes" : "no"
    service.extra_charge_scales = Number(service.extra_hours_fee_scalar) > 0 ? "yes" : "no"
    service.extra_charge_too_long = Number(service.extra_hours_fee) > 0 ? "yes": "no"
    service.extra_hours_fee_scalar_unit = service.extra_hours_fee_scalar_unit === "person" ? "people" : "kits"
    if(service.late_booking_percentage){
      service.late_booking_percentage = String(Number(service.late_booking_percentage) * 100)
    }

    console.log("cal id", service.calendar_id)
    this.setState({...service, synced_calendars})
  }

  async submit(){
    var body = getBody(this.state)
    var url = '/api/vendor/service/' + this.props.match.params.id
    if(this.props.location.pathname.includes("admin_panel")){
      url = '/api/admin/service/' + this.props.match.params.id
      delete body.calendar_id
    }
    var result = await request(url, {method: "PUT", body: JSON.stringify(body)})
    if(!result.err){
      if(this.props.location.pathname.includes("admin_panel")){
        this.props.history.push("/admin_panel")
      }else{
        this.props.history.push("/vendors/services")
      }
    }
  }

  // <div className="formatted-description" >
  //   <div dangerouslySetInnerHTML={{ __html: this.state.component_description }} />
  //   <div style={{height: 25}}/>
  // </div>
  // <div className="question">Can you provide everything described in the item description? If not, explain.</div>
  // <textarea className="text-field" value={this.state.can_provide} onChange={(e) => this.changeState({can_provide: e.target.value})} />

  async activate(){
    var service = await request('/api/vendor/service/' + this.props.match.params.id + "/activate", {method: "PUT"})
    if(service.err){
      console.log(service)
    }else{
      this.setState({is_active: true})
    }
  }

  async deactivate(){
    var service = await request('/api/vendor/service/' + this.props.match.params.id + "/deactivate", {method: "PUT"})
    if(service.err){
      console.log(service)
    }else{
      this.setState({is_active: false})
    }
  }

  render(){
    if(this.state.err){
      return (
        <div className="survey">
          <div className="application add-service card">
            <Title title="Can't find service"/>
          </div>
        </div>
      )
    }
    console.log("ative ", this.state.is_active )
    return (
      <div className="survey">
        {this.state.is_active ?
          <div className="secondary-button" style={{position: "absolute",
    right: 20}} onClick={() => this.deactivate()} >Disable Service</div> :
        (String(this.state.is_active) === "false" ?
          <div className="secondary-button" style={{position: "absolute",
    right: 20}} onClick={() => this.activate()} >Activate Service</div> : null)
        }
        <div className="application add-service card">
          <Title title={"Edit: " + this.state.component_name } />
          {this.state.synced_calendars.length > 0 ?
            <div>
              <div className="question">Synced Calendar</div>
              <ValueSelector placeholder={"Primary"} value={this.state.calendar_id} options={this.state.synced_calendars} update={(calendar_id) => this.changeState({calendar_id})}/>
            </div>
            : null }
          <div className="question">What locations do you service?</div>
          <div className="locations-section" style={{marginBottom: 20}}>
            <CheckboxTree
                nodes={this.state.regions}
                checked={this.state.locations}
                expanded={this.state.expanded}
                onCheck={locations => this.changeState({ locations })}
                onExpand={expanded => this.changeState({ expanded })}
            />
          </div>
          {this.state.component_id ?
            <div>
              <div className="question">Please list whatever options you typically offer (flavors, themes, etc.)</div>
              <textarea className="text-field" value={this.state.options} onChange={(e) => this.changeState({options: e.target.value})} />
              <div className="question">What do you require on-site?</div>
              <Requirements values={this.state.requirements} setValues={requirements => this.changeState({requirements})} />
            </div>
          :
            <div>
              <div className="service-name question">What do you call your service?</div>
              <input type="text" className="text-field" value={this.state.name} onChange={(e) => this.changeState({name: e.target.value})}/>
              <div className="question">Please provide a description of your service including what you bring.</div>
              <textarea className="text-field" value={this.state.description} onChange={(e) => this.changeState({description: e.target.value})} />
              <div className="question">What do you require on-site?</div>
              <Requirements values={this.state.requirements} setValues={requirements => this.changeState({requirements})} />
              <div className="question">Please list whatever options you typically offer (flavors, themes, etc.)</div>
              <textarea className="text-field" value={this.state.options} onChange={(e) => this.changeState({options: e.target.value})} />
            </div>
          }
          <div className="question">What is your standard pricing? </div>
          <div style={{position: "relative"}}>
            <span className="desc-text" style={{position: "absolute", left: 10, top: 4}}>$</span>
            <input type="number" value={this.state.price} onChange={(e) => this.changeDecimal("price", e.target.value)} className="text-field hour" />
            <span className="desc-text" style={{marginLeft: 12}}> per </span>
            <UnitPicker units={["person", "hour", "kit"]} value={this.state.unit} select={(unit) => this.changeState({unit})} /></div>
          {this.state.unit !== "kit" ?
            <div>
              <div className="question">What is the minimum number of {pluralize(this.state.unit)} you are willing to serve?</div>
              <input type="number" className="text-field" value={this.state.minimum} onChange={(e) => this.changeDecimal("minimum", e.target.value)}/>
            </div>
            : null
          }
          <div className="question">What is the maximum number of {pluralize(this.state.unit)} you are able to serve?</div>
          <input type="number" className="text-field" value={this.state.maximum} onChange={(e) => this.changeDecimal("maximum", e.target.value)}/>
          <div className="question">Do you have any base fee associated with every event?</div>
          <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>Setup fees or other fixed per event costs</div>
          <div style={{position: "relative"}}>
            <span className="desc-text money">$ </span>
            <input type="text" style={{width: 62}} className="text-field hour" value={this.state.flat_fee} onChange={(e) => this.changeDecimal("flat_fee", e.target.value)}/>
          </div>
          <div className="question">Do you require a deposit?</div>
          <div className="four-hour">
            <div className="opt" >
              <input type="radio" id={"yes_deposit"} value={"yes"} name={"yes_deposit"} onChange={(e) => this.changeState({deposit_required: e.currentTarget.value})} checked={this.state.deposit_required === "yes"} />
              <label htmlFor={"yes_deposit"}>Yes</label>
            </div>
            <div className="opt" >
              <input type="radio" id={"no_deposit"} value={"no"} name={"no_deposit"} onChange={(e) => this.changeState({deposit_required: e.currentTarget.value})} checked={this.state.deposit_required === "no"} />
              <label htmlFor={"no_deposit"}>No</label>
            </div>
          </div>
          {this.state.deposit_required === "yes" ?
            <div>
              <div className="question">What percent deposit do you require upfront?</div>
              <div style={{position: "relative", width: "fit-content"}}>
                <input style={{width: 62}} type="text" placeholder="25" className="text-field" value={this.state.deposit} onChange={(e) => this.changeNumber("deposit", e.target.value, 3)}/>
                <span className="desc-text percent"> %</span>
              </div>
            </div>
          : null}
          <div className="question">Do have any travel or delivery fees based on the location of the event?*</div>
          <div className="four-hour">
            <div className="opt" >
              <input type="radio" id={"yes_travel"} value={"yes"} name={"yes_travel"} onChange={(e) => this.changeState({travel_fee: e.currentTarget.value})} checked={this.state.travel_fee === "yes"} />
              <label htmlFor={"yes_travel"}>Yes</label>
            </div>
            <div className="opt" >
              <input type="radio" id={"no_travel"} value={"no"} name={"no_travel"} onChange={(e) => this.changeState({travel_fee: e.currentTarget.value})} checked={this.state.travel_fee === "no"} />
              <label htmlFor={"no_travel"}>No</label>
            </div>
          </div>
          {this.state.travel_fee === "yes" ?
            <div>
            <div className="question">Set your travel/delivery fee*</div>
            <TravelFees region_fees={this.state.region_fees} sub_region_fees={this.state.sub_region_fees} travel_fees={this.state.travel_fees} regions={this.state.regions} locations={this.state.locations}
                changeState={this.changeState.bind(this)}
            />
            </div>
          : null}
          <div className="question">Do you charge extra for last minute bookings?</div>
          <div className="four-hour">
            <div className="opt" >
              <input type="radio" id={"yes_late"} value={"yes"} name={"yes_late"} onChange={(e) => this.changeState({extra_charge_late: e.currentTarget.value})} checked={this.state.extra_charge_late === "yes"} />
              <label htmlFor={"yes_late"}>Yes</label>
            </div>
            <div className="opt" >
              <input type="radio" id={"no_late"} value={"no"} name={"no_late"} onChange={(e) => this.changeState({extra_charge_late: e.currentTarget.value})} checked={this.state.extra_charge_late === "no"} />
              <label htmlFor={"no_late"}>No</label>
            </div>
          </div>
          {this.state.extra_charge_late === "yes" ?
            <div>
              <div className="question">What percentage extra?</div>
              <div style={{position: "relative", width: "fit-content"}}>
                <input style={{width: 62}} type="text" className="text-field" value={this.state.late_booking_percentage} onChange={(e) => this.changeNumber("late_booking_percentage", e.target.value, 3)}/>
                <span className="desc-text percent"> %</span>
              </div>
              <div className="question">Less than how many days prior to an event do you consider a last minute booking?</div>
              <input type="number" className="text-field" value={this.state.late_booking_threshold} onChange={(e) => this.changeNumber("late_booking_threshold", e.target.value, 3)}/>
            </div>
          :null}
          <div className="question">Do you charge extra for events lasting longer than a certain amount of time?</div>
          <div className="four-hour">
            <div className="opt" >
              <input type="radio" id={"yes_long"} value={"yes"} name={"yes_long"} onChange={(e) => this.changeState({extra_charge_too_long: e.currentTarget.value})} checked={this.state.extra_charge_too_long === "yes"} />
              <label htmlFor={"yes_long"}>Yes</label>
            </div>
            <div className="opt" >
              <input type="radio" id={"no_long"} value={"no"} name={"no_long"} onChange={(e) => this.changeState({extra_charge_too_long: e.currentTarget.value})} checked={this.state.extra_charge_too_long === "no"} />
              <label htmlFor={"no_long"}>No</label>
            </div>
          </div>
          {this.state.extra_charge_too_long === "yes" ?
            <div>
              <div className="question">How many hours long is the maximum duration before you charge the extra fee?</div>
              <input type="number" className="text-field" value={this.state.normal_duration} onChange={(e) => this.changeNumber("normal_duration", e.target.value)}/>
              <div className="question">How much more per extra hour?</div>
              <input type="number" className="text-field" value={this.state.extra_hours_fee} onChange={(e) => this.changeNegativeDecimal("extra_hours_fee", e.target.value)}/>
              <div className="question">Does your extra hours fee scale according to the details of the event?</div>
              <div className="four-hour">
                <div className="opt" >
                  <input type="radio" id={"yes_scales"} value={"yes"} name={"yes_scales"} onChange={(e) => this.changeState({extra_charge_scales: e.currentTarget.value})} checked={this.state.extra_charge_scales === "yes"} />
                  <label htmlFor={"yes_scales"}>Yes</label>
                </div>
                <div className="opt" >
                  <input type="radio" id={"no_scales"} value={"no"} name={"no_scales"} onChange={(e) => this.changeState({extra_charge_scales: e.currentTarget.value})} checked={this.state.extra_charge_scales === "no"} />
                  <label htmlFor={"no_scales"}>No</label>
                </div>
              </div>
              {this.state.extra_charge_scales === "yes" ?
                <div>
                  <div className="question">How does it scale?* </div>
                  <div className="disclaimer" style={{textAlign: "left", marginTop: -6, marginBottom: 15}}>E.g. An event with 100 attendees that scales with every 20 people will have an extra fee of 5x the extra hours fee per extra hour.</div>
                  <div style={{position: "relative"}}>
                    <span className="desc-text">Scales every </span>
                    <input type="text" value={this.state.extra_hours_fee_scalar} style={{textAlign: "center", padding: 0, width: 50, marginLeft: 6}} onChange={(e) => this.changeDecimal("extra_hours_fee_scalar", e.target.value)} className="text-field" />
                    <UnitPicker units={["people", "kits"]} value={this.state.extra_hours_fee_scalar_unit} select={(unit) => this.changeState({extra_hours_fee_scalar_unit: unit})} /></div>
                </div>

              : null}
            </div>
          :null}

          <div className="question">How many days in advance do you need to be notified of an event?</div>
          <input type="number" className="text-field" value={this.state.booking_lead_time} onChange={(e) => this.changeState({booking_lead_time: e.target.value})}/>
          <div className="question">What is the maximum number of events you are able to service at the same time?</div>
          <input type="number" className="text-field" value={this.state.max_events} onChange={(e) => this.changeState({max_events: e.target.value})}/>
          <div className="question">How many hours does it take you to set up?</div>
          <input type="number" className="text-field" value={this.state.setup_time} onChange={(e) => this.changeState({setup_time: e.target.value})}/>
          <div className="question">How many hours does it take you to take everything down?</div>
          <input type="number" className="text-field" value={this.state.takedown_time} onChange={(e) => this.changeState({takedown_time: e.target.value})}/>
          <div className="question">How many staff do you typically bring?</div>
          <input type="number" className="text-field" value={this.state.typical_num_staff} onChange={(e) => this.changeState({typical_num_staff: e.target.value})}/>
          <div className="question">{"If it's a ratio, how many attendees per staff member?"}</div>
          <span>1 staff per every <input type="number" style={{width: 45}} className="text-field" value={this.state.attendees_per_staff} onChange={(e) => this.changeState({attendees_per_staff: e.target.value})}/> attendees</span>
          <div className="bottom">
            <button className={"secondary-button"} onClick={() => this.props.history.push("/vendors/services")}>Cancel</button>
            <button className={"custom-button"} onClick={() => this.submit()}>Save Changes</button>
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(EditService)
