
export const loadState = () => {
  try{
    return JSON.parse(localStorage.getItem("store")) || []
  }catch(err){
    return
  }
}

export const saveState = (store) => {
  try{
    localStorage.setItem("store", JSON.stringify(store))
  }catch(err){
  }
}
