import React from 'react'
import Header from '../Header/SimpleHeader'
import MobileHeader from '../Header/MobileHeader'
import Footer from '../Utils/BigFooter'
import './StaticStyles.css'

const Static = ({children, className, vendor}) => {
  return (
    <div className='flex-col flex min-h-screen'>
      <Header vendor={vendor} className="hidden lg:block" />
      <MobileHeader className="lg:hidden"/>
      <div className={"static-page " + (className || "")}>
      {children}
      </div>
      <Footer />
    </div>
  )
}

export default Static
