import React, { Component } from 'react';
import '../../css/font-awesome.min.css'
import * as cartActions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import request from '../../request'
import Header from '../Header/PlainHeader'
import Footer from '../Utils/Footer'
import CheckIcon from '../../img/CheckmarkCircle.svg'
import Warning from '../../img/WarningIcon.svg'

class VerifyEmail extends Component{
  constructor(props){
    super(props)
    this.state = {
      verified: "",
      err: null
    }
  }

  async componentDidMount(){
    var token = this.props.match.params.token
    console.log(token)
    var res =  await request('/api/verified', {method: "POST", body: JSON.stringify({token})})
    console.log(res)
    this.props.actions.setLogin({loggedIn: res.verified, admin: res.is_admin, email: res.email, wishlists: res.wishlists, vendor_id: res.vendor_id, name: res.name})
    this.setState({verified: res.verified ? "verified": "not", err: res.err})
  }

  render(){
    console.log("render landing")
    if(!this.state.verified){
      return (
        <div>
          <Header />
          <div className="card check-email" style={{width: 300, height: 150}}>
            <div className="title">Verifying...</div>
          </div>
          <Footer />
        </div>
      )
    }

    if(this.state.verified === "not"){
      return (
        <div>
          <Header />
          <div className="card check-email">
            <img src={Warning} alt="" height="50px"/>
            <div className="title">{"We couldn't verify your email."}</div>
            <div className="text">{"If the problem is with us, we apologize. Please make sure you click the link directly or copy and paste the link in it's entirety."}</div>
          </div>
          <Footer />

        </div>
      )
    }
    if(this.props.match.params.checkout === "checkout"){
      return (
          <div>
            <Header />
            <div className="card check-email">
              <img src={CheckIcon} alt=""/>
              <div className="title">Your email has been verified!</div>
              <div className="text">You’re now signed in to your account and can continue booking your event.</div>
              <Link to='/checkout' className="custom-button">Continue With Review & Book</Link>
            </div>
            <Footer />
          </div>
      )
    }

    if(this.props.match.params.checkout === "vendor" && this.props.match.params.parameter === "application" ){
      return (
          <div>
            <Header />
            <div className="card check-email">
              <img src={CheckIcon} alt=""/>
              <div className="title">Your email has been verified!</div>
              <div className="text">You’re now signed in to your account and can continue booking your event.</div>
              <Link to='/vendor/application' className="custom-button">Continue To Application</Link>
            </div>
            <Footer />
          </div>
      )
    }

    return (
        <div>
          <Header />
          <div className="card check-email">
            <img src={CheckIcon} className="heart-logo" alt=""/>
            <div className="title">Your email has been verified!</div>
            <div className="text">{"You're now signed in to your account and can continue browsing our events."}</div>
            <Link to='/' className="custom-button">Return to Home</Link>
          </div>
          <Footer />
        </div>
    )
  }
}

const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(cartActions, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail))
